import {
    ADD_CONTENT_URL_OVERVIEW,
    EDIT_ADD_CONTENT_TITLE,
    GET_ADDED_CONTENT,
    GET_RECOMEND_CONTENTS,
    DELETE_ADDED_COURSE,
    GET_CATEGARY,
    GET_CONTINUE_STUDY_LIST,
    UPDATE_PLAYLIST_NAME,
    SEARCH_COURSE_NAME,
    CLEAR_SEARCHBAR,
    SEARCHED_COURSE,
    ADDED_CONTENT_DATA
} from './types';
import mernApi from '../apis/mern';

// GET RECOMENT CONTENTS

export const getrecommendContent = (user_id) => (dispatch) => {
    return mernApi.get(`api/library/getRecommended?user_id=${user_id}`).then(data => {
        if (data.status === 200) {
            dispatch({
                type: GET_RECOMEND_CONTENTS,
                payload: data
            })
        }
    })
};


// api/library/ContinueWatch?user_id=627baa847158019583a367c9

export const countinueStudyList = (id) => (dispatch) => {
    return mernApi.get(`api/library/ContinueWatch?user_id=${id}`).then(data => {
        if (data.status === 200) {
            dispatch({
                type: GET_CONTINUE_STUDY_LIST,
                payload: data
            })

        }
    })
};



// search filter coursename api on library page

export const searchCourseNameList = (searchText, type, user_id) => (dispatch) => {
    return mernApi.get(`api/library/searchContent?searchText=${searchText}&type=${type}&user_id=${user_id}`).then(data => {
        if (data.status === 200) {
            dispatch({
                type: SEARCH_COURSE_NAME,
                payload: data
            })
        }
    })
};

export const searchedContents = (title, user_id) => (dispatch) => {
    return mernApi.get(`api/library/getContentData?title=${title}&user_id=${user_id}`).then(data => {
        if (data.status === 200) {
            dispatch({
                type: SEARCHED_COURSE,
                payload: data
            })
        }
    })
};

export const searchCourseAll = (searchText, type, user_id) => (dispatch) => {  
    return mernApi.get(`api/library/searchContent?searchText=${searchText}&type=${type}&user_id=${user_id}`).then(data => {
        if (data.status === 200) {
            dispatch({
                type: SEARCHED_COURSE,
                payload: data
            })
        }
    })
};


// clear searchbar library page...
export const clearSearchbar = (data) => (dispatch) => {
    dispatch({
        type: CLEAR_SEARCHBAR,
        payload: data
    })
};



// ADD CONTENT URL => LIBRARARY OVERVIEW PAGE
export const addLibraryContent = (data) => (dispatch) => {
    try {
        return mernApi.post(`api/library/libraryaddLink`, data).then(data => {
            if (data.status === 200) {
                dispatch({
                    type: ADD_CONTENT_URL_OVERVIEW,
                    payload: data.responseData
                })
            }
        })
    } catch (err) {
        dispatch({
            type: ADD_CONTENT_URL_OVERVIEW,
            payload: 'err.data',
        });
    }
};



// ADD CONTENT FILE => LIBRARARY OVERVIEW PAGE
export const resetAddContentFile = (data) => (dispatch) => {
    
    dispatch({
        type: ADDED_CONTENT_DATA,
        payload: []
    })

};



// ADD CONTENT FILE => LIBRARARY OVERVIEW PAGE
export const addContentFile = (data) => (dispatch) => {
    try {
        return mernApi.post(`api/library/libraryuploadS3`, data).then(data => {
            if (data.status === 200) {

                if (data.data.errormessage) {
                    return data.data.errormessage
                } else {
                    dispatch({
                        type: ADDED_CONTENT_DATA,
                        payload: data
                    })
                }
            }
        })
    } catch (err) {
        dispatch({
            type: ADDED_CONTENT_DATA,
            payload: 'err.data',
        });
    }
};


// GET EDIT CONTENT LIST 
export const AddedContentsList = (id) => (dispatch) => {
    return mernApi.get(`api/library/getContent?user_id=${id}`).then(data => {
        if (data.status === 200) {
            dispatch({
                type: GET_ADDED_CONTENT,
                payload: data
            })

        }
    })
};


// EDIT CONTENT TITLE => LIBRARARY => OVERVIEW PAGE
export const updateContent = (data) => (dispatch) => {
    return mernApi.put(`api/library/updateContent`, data).then(data => {
        if (data.status === 200) {
            dispatch({
                type: EDIT_ADD_CONTENT_TITLE,
                payload: data
            })

        }
    })
};


// EDIT CONTENT TITLE => LIBRARARY => OVERVIEW PAGE
export const getCategory = () => (dispatch) => {
    return mernApi.get(`api/library/getCategory`).then(data => {
        if (data.status === 200) {
            dispatch({
                type: GET_CATEGARY,
                payload: data
            })

        }
    })
};



// LIBRARARY => OVERVIEW PAGE => EDIT COURSE
export const deleteCourse = (id) => (dispatch) => {
    return mernApi.delete(`api/library/deleteContent?id=${id}`).then(data => {
        if (data.status === 200) {
            dispatch({
                type: DELETE_ADDED_COURSE,
                payload: data
            })

        }
    })
};



export const filterContent = (body) => (dispatch) => {
     var data = ''
    body.category.forEach(element => {
        data+= '&category='+element;
    }) 
     var parms = 'title='+body.title+'&user_id='+body.user_id+data;
    return mernApi.get(`api/library/filterContent?`+parms, body).then(data => {
     
        if (data.status === 200) {
            //  dispatch({
            //     type: SEARCHED_COURSE,
            //     payload: data.data.responseData
            // })
            return data.data.responseData;
        }
    })
};


export const updatePlaylistName = (id) => (dispatch) => {
    return mernApi.get(`api/library/updatePlayListName?id=${id}`).then(data => {
        if (data.status === 200) {
            dispatch({
                type: UPDATE_PLAYLIST_NAME,
                payload: data
            })
        }
    })
};



import {
  GET_PRACTICE_PAGE_DATA,
  GET_PRACTICE_PAGE_DATA_FAILED,
  GET_TOPIC_PRACTICE_PAGE_DATA,
  GET_TOPIC_PRACTICE_PAGE_DATA_FAILED,
  GET_DOCUMENT_QUIZ,
  GET_DOCUMENT_QUIZ_FAILED,
  GET_TOPIC_QUIZ_FAILED,
  GET_TOPIC_QUIZ,
  QUIZ_RELATED_DATA,
  QUIZ_RELATED_DATA_FAILED,
  GET_RELATED_CONTENT,
  GET_RELATED_CONTENT_FAILED,
  GET_RELATED_CONTENT_TOPICS,
  GET_RELATED_CONTENT_TOPICS_FAILED,
  GET_FEEDBACK_FLAGS,
  GET_START_PRACTICISN_DATA,
  GET_TOTAL_SCORE,
  GET_PRACTICE_HOUR_SPENT,GET_TRY_THESE_TOPICS,GET_TRY_THESE_TOPICS_FAILED,GET_PRACTICE_TRANSCRIPTS,GET_PRACTICE_TRANSCRIPTS_FAILED,GET_ACHEIVEMENT_PRACTICE_FAILED,GET_ACHEIVEMENT_PRACTICE,GET_SKILL_UPGRADE_FAILED,GET_SKILL_UPGRADE,GET_ANSWER_STREAK_FAILED,GET_ANSWER_STREAK,GET_NOTIFICATION_FAILED,GET_NOTIFICATION,GET_MONTHLY_STREAK_FAILED,GET_MONTHLY_STREAK,GET_HIGHLIGHTS_PRACTICE
} from "./types";
import mernApi from "../apis/mern";

export const getPracticeData = (file_ID) => async (dispatch) => {
  try {
    
    return mernApi
      .get(`api/practice/getDocumentSessionQuiz?file_id=${file_ID}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: GET_PRACTICE_PAGE_DATA,
            payload: data,
          });
        }
        return data;
      });
  
}catch (error) {
    dispatch({
      type: GET_PRACTICE_PAGE_DATA_FAILED,
      payload: error?.message,
    });
  }
};

export const getPracticeTopicData = (topic_id) => async (dispatch) => {
  console.log(`got topic id: ${topic_id}`)
  try {
    return mernApi
      .get(`/api/practice/getTopicSessionQuiz?topic_id=${topic_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: GET_TOPIC_PRACTICE_PAGE_DATA,
            payload: data,
          });
        }
        return data;
      });
  } catch (error) {
    dispatch({
      type: GET_TOPIC_PRACTICE_PAGE_DATA_FAILED,
      payload: error?.message,
    });
  }
};

export const getDocumentQuiz = (file_id) => async (dispatch) => {
  try {
    return mernApi
      .get(`/api/practice/getDocumentQuiz?file_id=${file_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: GET_DOCUMENT_QUIZ,
            payload: data,
          });
        }
        return data;
      });
  } catch (error) {
    dispatch({
      type: GET_DOCUMENT_QUIZ_FAILED,
      payload: error?.message,
    });
  }
};

export const getTaskQuiz = (topic_id) => async (dispatch) => {
  try {
    return mernApi
      .get(`/api/practice/getTopicQuiz?topic_id=${topic_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: GET_TOPIC_QUIZ,
            payload: data,
          });
        }
        return data;
      });
  } catch (error) {
    dispatch({
      type: GET_TOPIC_QUIZ_FAILED,
      payload: error?.message,
    });
  }
};

export const getQuizStatus = (body) => async (dispatch) => {
  try {
    return mernApi
      .put(
        `api/practice/quizStatusUpdate`,body
      )
      .then((data) => {
        if (data.status === 200) {
        }
      });
  } catch (error) {}
};

export const quizRelatedContent = (word, quizId) => async (dispatch) => {
  try {
    return mernApi
      .get(`/api/study/getInfo?fileId=&word=${word}&quizId=${quizId}`)
      .then((data) => {
        if (data.status == 200) {
          dispatch({
            type: QUIZ_RELATED_DATA,
            payload: data,
          });
        }
        return data;
      });
  } catch (err) {
    dispatch({
      type: QUIZ_RELATED_DATA_FAILED,
      payload: err?.message,
    });
  }
};
export const getQuizNotes = (day, quizId) => async (dispatch) => {
  try {
    return mernApi
      .get(
        `/api/practice/getQuizNotes/${day}?quizId=${quizId}`
      )
      .then((data) => {
        if (data.status === 200) {
        }
        return data;
      });
  } catch (err) {
  }
};
export const getQuizFinalResult = (body) => async (dispatch) => {
  try {
    return mernApi.post(`/api/practice/getFinalResult`, body).then((data) => {
      if (data.status === 200) {
      }
      return data;
    });
  } catch (err) {
    console.log(err.message);
  }
};

export const getRelatedContentForTopics = (topic_id) => async (dispatch) => {
  try{
    return mernApi
    .get(`/api/practice/getRelatedContentTopics?topic_id=${topic_id}`)
    .then((data) => {
      if (data.status === 200) {
        dispatch({
          type: GET_RELATED_CONTENT_TOPICS,
          payload: data,
        });
      }
      return data;
    });
  }
  catch(error){
    dispatch({
      type: GET_RELATED_CONTENT_TOPICS_FAILED,
      payload: error?.message,
    });
  }
}

export const getTopicRelatedContent = (file_id) => async (dispatch) => {
  try {
    return mernApi
      .get(`/api/practice/getRelatedContent?file_id=${file_id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: GET_RELATED_CONTENT,
            payload: data,
          });
        }
        return data;
      });
  } catch (error) {
    dispatch({
      type: GET_RELATED_CONTENT_FAILED,
      payload: error?.message,
    });
  }
};
export const addQuizFeedBack = (body) => (dispatch) => {
  return mernApi.post(`/api/practice/quizFeedback`, body).then(
    (data) => {
      if (data.status === 200) {
        dispatch({
          type: "QUIZ_FEEDBACK",
          payload: data,
        });
      }
      return data;
    },
    (err) => {
      console.log(err, "error ");
    }
  );
};
export const getQuizFeedBackFlags = () => (dispatch) => {
  return mernApi.get(`/api/practice/feedbackFlags`).then(
    (data) => {
      if (data.status === 200) {
        dispatch({
          type: GET_FEEDBACK_FLAGS,
          payload: data,
        });
      }
      return data;
    },
    (err) => {
      console.log(err, "error ");
    }
  );
};

export const getStartPractising = () => (dispatch) => {
  return mernApi.get(`/api/practice/getStartPracticing`).then(
    (data) => {
      if (data.status === 200) {
        dispatch({
          type: GET_START_PRACTICISN_DATA,
          payload: data,
        });
      }
      return data;
    },
    (err) => {
      console.log(err);
    }
  );
};

export const getTotalScore = () => (dispatch) => {
  return mernApi.get(`/api/practice/getTotalScore`).then(
    (data) => {
      if (data.status === 200) {
        dispatch({
          type: GET_TOTAL_SCORE,
          payload: data,
        });
      }
      return data;
    },
    (err) => {
      console.log(err);
    }
  );
};

export const getPracticeHourSpent = (data) => (dispatch) => {
  return mernApi
    .get(`/api/practice/getPracticeHoursSpent?graphData=${data}`)
    .then(
      (data) => {
        if (data.status === 200) {
          dispatch({
            type: GET_PRACTICE_HOUR_SPENT,
            payload: data,
          });
        }
        return data;
      },
      (err) => {
        console.log(err);
      }
    );
};


export const practiceTimeTrack = (body) =>()=>{
  try{
      return mernApi
      .put(`api/practice/practiceTimeTrack`,body).then((data)=>{
        if(data.status===200){
          console.log(data)
        }
      })
  }
  catch(error){
  }
}

export const getTryTheseTopics = ()=>(dispatch)=>{
  mernApi.get(`api/practice/getTryTheseTopics`).then((data)=>{
    if(data.status===200){
      dispatch({
        type:GET_TRY_THESE_TOPICS,
        payload:data
      })
    }
    else{
      dispatch({
        type:GET_TRY_THESE_TOPICS_FAILED,
        payload:data?.message
      })
    }
    return data
  },(err)=>{
dispatch({
  type:GET_TRY_THESE_TOPICS_FAILED,
  payload:err?.message
})
  })
}
export const getPracticeTranscripts = (file_id)=>(dispatch)=>{
  mernApi.get(`api/practice/getTranscript?file_id=${file_id}`).then((data)=>{
    if(data.status===200){
      dispatch({
        type:GET_PRACTICE_TRANSCRIPTS,
        payload:data
      })
    }
    else{
      dispatch({
        type:GET_PRACTICE_TRANSCRIPTS_FAILED,
        payload:data?.message
      })
    }
    return data
  },(err)=>{
console.log(err)
  })
}


export const getAchievementPractice = ()=>(dispatch)=>{
  mernApi.get(`api/achievement/getPractice`).then((data)=>{
    if(data.status===200){
      dispatch({
        type:GET_ACHEIVEMENT_PRACTICE,
        payload:data
      })
    }
    else{
      dispatch({
        type:GET_ACHEIVEMENT_PRACTICE_FAILED,
        payload:data?.message
      })
    }
    return data
  },(err)=>{
console.log(err)
  })
}
export const getSkillUpgrade = ()=>(dispatch)=>{
  mernApi.get(`api/achievement/getSkillUpgrade`).then((data)=>{
    if(data.status===200){
      dispatch({
        type:GET_SKILL_UPGRADE,
        payload:data
      })
    }
    else{
      dispatch({
        type:GET_SKILL_UPGRADE_FAILED,
        payload:data?.message
      })
    }
    return data
  },(err)=>{
console.log(err)
  })
}
export const getAnswerStreak = ()=>(dispatch)=>{
  return mernApi.get(`api/achievement/getAnswerStreak`).then((data)=>{
    if(data.status===200){
      dispatch({
        type:GET_ANSWER_STREAK,
        payload:data
      })
    }
    else{
      dispatch({
        type:GET_ANSWER_STREAK_FAILED,
        payload:data?.message
      })
    }
    return data
  },(err)=>{
console.log(err)
  })
}
export const getMonthlyStreak = ()=>(dispatch)=>{
  return mernApi.get(`api/achievement/getMonthly`).then((data)=>{
    if(data.status===200){
      dispatch({
        type:GET_MONTHLY_STREAK,
        payload:data
      })
    }
    else{
      dispatch({
        type:GET_MONTHLY_STREAK_FAILED,
        payload:data?.message
      })
    }
    return data
  },(err)=>{
console.log(err)
  })
}
export const explanationUsed = (quizId,Status) =>(dispatch)=>{
  mernApi.put(`api/practice/quizExplainationUsed?quizId=${quizId}&explainationStatus=true`).then((res)=>{
    
  })
}
export const addAnswerStreak = (quizSetId) =>(dispatch)=>{
  mernApi.post(`api/achievement/addAnswerStreak?quizsetId=${quizSetId}`).then((res)=>{
    
  })
}
export const addSKillUpgrade = ()=>(dispatch)=>{
  mernApi.post(`api/achievement/addSkillUpgrade`).then((res)=>{
    
  })
}
export const addTotalPracticeTimeForReward = ()=>(dispatch)=>{
  mernApi.post(`api/achievement/addPractice`).then((res)=>{
    
  })
}

export const getNotification = ()=>(dispatch)=>{
  mernApi.get(`api/notification/getNotification`).then((data)=>{
    if(data.status===200){
      dispatch({
        type:GET_NOTIFICATION,
        payload:data
      })
    }
    else{
      dispatch({
        type:GET_NOTIFICATION_FAILED,
        payload:data?.message
      })
    }
    return data
  },(err)=>{
console.log(err)
  })
}

export const markAllAsRead = ()=>(dispatch)=>{
return  mernApi.put(`api/notification/markAllRead?readStatus=true`).then((res)=>{
    return res;
  })
}
export const removeNotification = ()=>(dispatch)=>{
 return  mernApi.put(`api/notification/removeNotificationIconHighlight`).then((res)=>{
   if(res.status===200){
    return res;
   }

  })
}

export const getHighlightPractice = (file_id)=>(dispatch)=>{
  return mernApi.get(`api/practice/getHighlight?file_id=${file_id}`).then((res)=>{
    if(res.status===200){
      dispatch({
        type:GET_HIGHLIGHTS_PRACTICE,
        payload:res
      })
    }
    return res;
  })
}


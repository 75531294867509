import React, { useState, useRef, useEffect } from "react";
import Header from "../../layouts/Header/Header";
import { useSelector, useDispatch } from "react-redux";
import "./practice.css";
import {
  clockQuiz,
  vectorQuiz,
  groupQuiz,
  groupQuizColor,
  learn_button,
  quizBgImg,
} from "../../staticContent/image";
import {
  getTaskQuiz,
  getPracticeTopicData,
  getTopicRelatedContent,
  getRelatedContentForTopics,
  practiceTimeTrack,
  getPracticeTranscripts,
  addTotalPracticeTimeForReward,
} from "../../store/actions/practice";
import { useHistory } from "react-router-dom";
import { getTopics } from "../../store/actions/study";
import SidebarCanvas from "../Librarypage/Overview/CanvasSidebar/sidebarCanvas";
import Skeleton from "react-loading-skeleton";
const DocumentPractice = (props) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const history = useHistory();
  const [tableContentsData, setTableContentData] = useState([]);
  const [data, setData] = useState(store?.getPracticeTopicData?.data);
  const [transcriptsData, setTranscriptsData] = useState([]);
  const [quizLoader, setQuizLoader] = useState(false);
  const [sideLoader, setSideLoader] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [sidebarData, setSidebarData] = useState({});
  const [screenLoader, setScreenLoader] = useState(false);
  const [relatedContent, setRelatedContent] = useState([]);

  const handleSlice = (text) => {
    if (text.length > 20) {
      return text.slice(0, 20);
    } else {
      return text;
    }
  };
  const [relatedMaterials, setRelatedMaterials] = useState([]);
  const divRef = useRef(null);
  const div2Ref = useRef(null);
  const div3Ref = useRef(null);
  const startTime = useRef();
  const endTime = useRef();
  
  useEffect(() => {
    const id = store?.getPracticeTopicData?.data?.response?._id;
    const d = new Date();
    startTime.current = d.getTime();
    return () => {
      const dx = new Date();
      endTime.current = dx.getTime();

      const body = {
        practiceTime: endTime.current - startTime.current,
        topicId: id,
      };
      dispatch(practiceTimeTrack(body));
      dispatch(addTotalPracticeTimeForReward());
    };
  }, []);

  useEffect(() => {
    setScreenLoader(true);
    let topic_id = store?.getPracticeData?.data?.response[0]?.topicId;
    setTimeout(() => {
      dispatch(getPracticeTopicData(topic_id)).then((res) => {
        if (res.status === 200) {
          setData(res?.data);
          // setCrousel(res?.data?.relatedContent);
          setScreenLoader(false);
        } else {
          setScreenLoader(false);
          alert("!404 server error");
        }
      });
    }, 1500);
    dispatch(getRelatedContentForTopics(topic_id)).then((res) => {
      setRelatedContent(res?.data?.response);
    });

    // setData(store?.getPracticeData?.data);
  }, []);

  function truncate(str, n) {
    return str?.length > n ? str.slice(0, n - 1) + "..." : str;
  }
  const handleStatus = (questionPoints, totalQuiz) => {
    if (totalQuiz >= 5 && totalQuiz < 15) {
      return "Medium";
    } else if (totalQuiz >= 15 && totalQuiz < 25) {
      return "Advanced";
    } else if (totalQuiz >= 25) {
      return "Maestro";
    } else if (questionPoints >= 100) {
      return "Medium";
    } else {
      return "Beginner";
    }
  };

  const handleQuiz = () => {
    setQuizLoader(true);
    let topic_id = store?.getPracticeData?.data?.response[0]?.topicId;
    dispatch(getTaskQuiz(topic_id)).then((res) => {
      if (res.status === 200) {
        history.push({
          pathname: "/practice/quiz",
          state: { prevPath: "documentpractice" },
        });
        setQuizLoader(false);
      } else {
        setQuizLoader(false);
      }
    });
  };
  const handleClickRecomend = (item) => {
    setSideLoader(true);

    setOpenSidebar(true);
    dispatch(getTopics(item._id)).then((res) => {
      setSidebarData(res);
      setSideLoader(false);
    });
  };

  const formatTime = (milliseconds) => {
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor(milliseconds / 1000 / 60 / 60);

    return [
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
    ].join(":");
  };
  return (
    <>
      <Header />

      {!screenLoader ? (
        <div
          className="row  quiz_mainBox  m-0"
          style={{ backgroundImage: `url(${quizBgImg})` }}
        >
          <div className="col-12 mt-4">
            <div className="contain mx-auto practice_width_dec  border pb-5 pt-4">
              <div className="row mx-auto">
                <div className="col-12 border-bottom">
                  <div className="row mx-auto">
                    <div className="col-12 pb-3 ps-4">
                      <h5 className="m-0 p-0 practice_heading all_pro_family practice_color">
                        Quiz
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-12 border-bottom">
                  <div className="row mx-auto">
                    <div className="col-7 ps-4 border-end">
                      <div className="row py-3">
                        <div className="col-12">
                          <p className="m-0 p-0 quiz_heading all_pro_family practice_color">
                            {console.log(`pracrice page data: ${data}`)}
                            {data?.response[0]?.topicName}
                          </p>
                        </div>
                        <div class="col-12 mt-3 d-flex align-items-center">
                          <button
                            type="button"
                            class="btn  border-0 p-1 shadow-none task_add_btn text-lowercase total_time_trake d-flex all_pro_family practice_color"
                          >
                            <img
                              src={clockQuiz}
                              className="me-2 clock_question"
                              alt="..."
                            />
                            {data?.response[0]?.totalTime} min
                          </button>
                          <button
                            type="button"
                            class="btn border-0 ms-2 p-1 shadow-none text-lowercase task_add_btn  total_time_trake d-flex all_pro_family practice_color"
                          >
                            <img
                              src={vectorQuiz}
                              className="me-2 clock_questions"
                              alt="..."
                            />
                            {data?.response[0]?.totalQuestion}  questions
                          </button>
                        </div>

                        <div className="col-12 mt-4">
                          <p
                            className="p-0 m-0 add_task_long_para all_pro_family practice_color"
                            dangerouslySetInnerHTML={{
                              __html: 
                                data?.topic_definition,
                            }}
                          ></p>
                        </div>
                        <div className="col-12 mt-4">
                          {!quizLoader ? (
                            <button
                              onClick={() => {
                                handleQuiz();
                              }}
                              type="button"
                              class=" border btn btn-light py-2 px-4 shadow-none offcan-study text-capitalize add_task_button all_pro_family practice_color"
                            >
                              Start Quiz
                            </button>
                          ) : (
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="col notes_width">
                      <div className="row mx-auto py-3">
                        <div className="col-12">
                          <p className="m-0 p-0 quiz_heading all_pro_family practice_color">
                            Topic Progress
                          </p>
                        </div>
                        <div className="col-12 mt-4 mb-3 ">
                          <p class="m-0 topics_proges_quiz_para all_pro_family practice_color">
                          You have {data?.response[0]?.totalPoints > 0 && 'scored over'}{' '}
                            <b class="m-0 topics_proges_bold">
                              {data?.response[0]?.totalPoints} {data?.response[0]?.totalPoints > 0 ? 'point' : 'points'}.
                            </b>
                            {" "}
                            your progress is
                            <b className="m-0 topics_proges_bold">
                              {" "}
                              {handleStatus(
                                data?.topicPoints,
                                data?.CompleteQuizz
                              )}
                            </b>
                          </p>

                          {/* <div className="col d-flex align-items-center justify-content-end">
                            <p className="m-0 topics_proges_quiz all_pro_family practice_color">
                              <b class="m-0 topics_proges_bold me-1">2:49</b>
                              hours spent
                            </p>
                          </div> */}
                        </div>
                        <div class="col-12 mb-2 ">
                          <div class="row d-flex align-items-center justify-content-between">
                            <div class="col-3 selectedBar px-1">
                              <hr />
                              <p class="m-0 text-center mt-2 mb-1 offcan-medium">
                                Beginner
                              </p>
                            </div>
                            <div
                              class={`${
                                handleStatus(
                                  data?.topicPoints,
                                  data?.CompleteQuizz
                                ) === "Medium" ||
                                handleStatus(
                                  data?.topicPoints,
                                  data?.CompleteQuizz
                                ) === "Advanced" ||
                                handleStatus(
                                  data?.topicPoints,
                                  data?.CompleteQuizz
                                ) === "Maestro"
                                  ? "selectedBar"
                                  : "unselectedBar"
                              } col-3 px-1`}
                            >
                              <hr />
                              <p class="m-0 text-center mt-2 mb-1 offcan-medium">
                                Medium
                              </p>
                            </div>
                            <div
                              class={`${
                                handleStatus(
                                  data?.topicPoints,
                                  data?.CompleteQuizz
                                ) === "Maestro" ||
                                handleStatus(
                                  data?.topicPoints,
                                  data?.CompleteQuizz
                                ) === "Advanced"
                                  ? "selectedBar"
                                  : "unselectedBar"
                              } col-3 px-1`}
                            >
                              <hr />
                              <p class="m-0 text-center mt-2 mb-1 offcan-medium">
                                Advanced
                              </p>
                            </div>
                            <div
                              class={`${
                                handleStatus(
                                  data?.topicPoints,
                                  data?.CompleteQuizz
                                ) === "Maestro"
                                  ? "selectedBar"
                                  : "unselectedBar"
                              } col-3 px-1`}
                            >
                              <hr />
                              <p class="m-0 text-center mt-2 mb-1 offcan-medium">
                                Maestro
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mt-4 mb-3 ">
                          <p class="m-0 topics_proges_quiz_para all_pro_family practice_color">
                            <b class=" m-0 topics_proges_bold"> Total time:</b>{" "}
                            {data?.response?.quizTotalTime
                              ? formatTime(data?.response?.quizTotalTime)
                              : "00:00"}{" "}
                            hours spent
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12">
                          <div class="row ">
                            <div class="col-12 border-bottom pt-4 pb-2 ps-4">
                              <div class="row mx-auto">
                                <div class="col d-flex align-items-center justify-content-start">
                                  <p className="m-0 p-0 quiz_heading all_pro_family practice_color font_weight_content">
                                    Related Content
                                  </p>
                                </div>
                                <div class="col d-flex justify-content-end align-items-center">
                                  <i
                                    onClick={() => {
                                      divRef.current.scrollLeft -= 200;
                                    }}
                                    class="fa fa-angle-left rounded-pill bg-dark up_down_icon mb-2 d-flex align-items-center justify-content-center text-white me-2"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    onClick={() => {
                                      divRef.current.scrollLeft += 200;
                                    }}
                                    class="fa fa-angle-right rounded-pill bg-dark up_down_icon mb-2 d-flex align-items-center justify-content-center text-white"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 ps-4 mt-4">
                              <div className="row mx-auto">
                                <div className="col-12">
                                  <p className=" p-0 m-0 related_practice_para">
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 ps-4 mt-3">
                              <div
                                ref={(ref) => (divRef.current = ref)}
                                className="row mx-auto  flex-nowrap overflow-auto"
                              >
                                {relatedContent?.map((item, ind) => {
                                  return (
                                    <div className="col-3">
                                      <div className="row mx-auto">
                                        <div className="col-12">
                                          <div className="row border overflow-hidden mb-1">
                                            <div className="col-6 position-relative">
                                              <div className="row">
                                                <div className="col-12 p-0">
                                                  <img
                                                    src={item?.thumbnail}
                                                    className=" secondLakhni"
                                                    alt="..."
                                                  />
                                                </div>
                                                <div className="col-12 p-0 ps-2 position-absolute bottom-0 mb-1">
                                                  {item?.category
                                                    ?.category_name && (
                                                    <button
                                                      className="btn text-white videoDoc_btn text-capitalize shadow-none rounded-pill  d-flex align-items-center justify-content-center"
                                                      style={{
                                                        cursor: "pointer",
                                                        backgroundColor: `${item?.category?.category_color}`,
                                                      }}
                                                    >
                                                      {
                                                        item?.category
                                                          ?.category_name
                                                      }
                                                    </button>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-6 custom_padding  d-flex">
                                              <div className="row ">
                                                <div className="col-12 mb-auto  d-flex flex-column justify-content-center">
                                                  <h6 className="card-data  m-0 d-flex align-items-start">
                                                    {truncate(item?.title, 20)}
                                                  </h6>
                                                </div>

                                                <div className="col-12 mt-auto">
                                                  <p className="card-texts mb-0 text-nowrap">
                                                    {item.studyPageTimeTrack
                                                      ? formatTime(
                                                          item.studyPageTimeTrack
                                                        )
                                                      : "0:00"}{" "}
                                                    spent · Beginner
                                                  </p>
                                                  <div class=" mt-2 topic">
                                                    <div class="progress progress_parent">
                                                      <div
                                                        class="progress-bar progress_bar"
                                                        role="progressbar"
                                                        aria-label="Basic example"
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        style={{
                                                          width: `${
                                                            item?.progressBarPercentage
                                                              ? item?.progressBarPercentage
                                                              : "0%"
                                                          }`,
                                                        }}
                                                      ></div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="col-12 mt-auto"
                                                  onClick={() => {
                                                    handleClickRecomend(item);
                                                  }}
                                                >
                                                  <div className="row d-flex align-items-center justify-content-start">
                                                    <div
                                                      className="col-auto"
                                                      style={{
                                                        marginRight: "-5px",
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className="card-data m-0 d-flex align-items-center"
                                                      >
                                                        Learn
                                                      </p>
                                                    </div>
                                                    <div
                                                      className="col-auto p-0 d-flex align-items-center justify-content-center rounded-pill"
                                                      // style={{
                                                      //   width: "16px",
                                                      //   height: "16px",
                                                      //   backgroundColor:
                                                      //     "#4E45C2",
                                                      // }}
                                                    >
                                                      <img
                                                        alt=""
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className="learn_arrowIcon"
                                                        src={learn_button}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Skeleton height={`3vh`} count={30} />
      )}
      <SidebarCanvas
        show={openSidebar}
        setShow={setOpenSidebar}
        sideLoader={sideLoader}
        data={sidebarData}
      />
    </>
  );
};

export default DocumentPractice;

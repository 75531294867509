import React from "react";

const Course = () => {
  return (
    <div>
      <h1>Course Page</h1>
    </div>
  );
};

export default Course;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-multi-carousel/lib/styles.css";
import { useHistory } from "react-router-dom";
import "./overview.css";
import Skeleton from "react-loading-skeleton";
import {
  linePractOver,
  downPractOver,
  upPractOver,
  learn_button,
} from "../../../staticContent/image";
import Carousel from "react-multi-carousel";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  getStartPractising,
  getPracticeHourSpent,
  getTotalScore,
  getTryTheseTopics,
  getPracticeData,
  getPracticeTopicData,
} from "../../../store/actions/practice";
import {
  getAchievementPractice,
  getSkillUpgrade,
  getAnswerStreak,
  getMonthlyStreak,
} from "../../../store/actions/practice";
const Overview = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const [graphLoader, setGraphLoader] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [totalScore, settotalScore] = useState([]);
  const [loader, setLoader] = useState(false);
  const [totalScoreLoader, setTotalScoreLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [graphValue, setGraphValue] = useState("weekly");
  const [practiceLoader, setPracticeLoader] = useState();
  const [tryTheseLoader, setTryTheseLoader] = useState();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const formatter = (value) => `${value} Hr`;

  const handleClickTryTheseTopics = (item, ind) => {
    setTryTheseLoader(ind);
    console.log('praci')
    console.log(item);
    let topic_ID = item?.topic?.topicId;
    dispatch(getPracticeTopicData(topic_ID)).then((res) => {
      if (res.status === 200) {
        history.push({
          pathname: "/practice",
          state: { prevPath: "documentPractice" },
        });
        setTryTheseLoader();
      } else {
        alert("server issue");
        setTryTheseLoader();
      }
    });
  };


  // const handleClickRecomend = (item, ind) => {
  //   setTryTheseLoader(ind);
  //   console.log('praci')
  //   console.log(item);
  //   let topic_ID = item?.topic?.topicId;
  //   dispatch(getPracticeTopicData(topic_ID)).then((res) => {
  //     if (res.status === 200) {
  //       history.push({
  //         pathname: "/practice",
  //         state: { prevPath: "documentpractice" },
  //       });
  //       setTryTheseLoader();
  //     } else {
  //       alert("server issue");
  //       setTryTheseLoader();
  //     }
  //   });
  // };
  const handlePractice = (item, ind) => {
    setPracticeLoader(ind);
    let file_ID = item?._id;
    dispatch(getPracticeData(file_ID)).then((res) => {
      if (res.data.status == 200) {
        history.push({
          pathname: "/practice",
          state: { prevPath: "videopractice" },
        });
        setPracticeLoader();
      } else {
        setPracticeLoader();
      }
    });
  };
  function truncate(str, n) {
    return str?.length > n ? str.slice(0, n - 1) + "..." : str;
  }

  const formatTime = (milliseconds) => {
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor(milliseconds / 1000 / 60 / 60);

    return [
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
    ].join(":");
  };
  let data = [
    { time: 80, value: "Sat" },
    { time: 60, value: "Sun" },
    { time: 40, value: "Mon" },
    { time: 20, value: "Tue" },
    { time: 50, value: "Wed" },
    { time: 80, value: "Thu" },
    { time: 80, value: "Fri" },
  ];

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      dispatch(getStartPractising());
      dispatch(getTryTheseTopics());
      dispatch(getPracticeHourSpent(graphValue)).then((res) => {
        if (res.status === 200) {
          setGraphData(res?.data?.responseData);
          setLoader(false);
        } else {
          setLoader(false);
          alert("!server error");
        }
      });
    }, 1500);
    dispatch(getAchievementPractice());
    dispatch(getSkillUpgrade());
    dispatch(getAnswerStreak());
    dispatch(getMonthlyStreak());
  }, []);
  useEffect(() => {
    setTotalScoreLoader(true);
    setTimeout(() => {
      dispatch(getTotalScore()).then((res) => {
        if (res.status === 200) {
          settotalScore(res?.data);
          setTotalScoreLoader(false);
        } else {
          setTotalScoreLoader(false);
        }
      });
    }, 1500);
  }, []);

  const handleChangeGraphVal = (val) => {
    setGraphLoader(true);
    setGraphValue(val);
    setShow(false);
    dispatch(getPracticeHourSpent(val)).then((res) => {
      if (res.status === 200) {
        setGraphData(res?.data?.responseData);
        setGraphLoader(false);
      } else {
        alert("!server error");
        setGraphLoader(false);
      }
    });
  };

  return (
    <>
      {!loader ? (
        <div className="row mx-auto mt-5">

          <div className="col-12 my-4">
            <div className="row mt-1 mx-auto">
              <div className="col-md-6 col-6 mb-md-0 mb-3 grid-margin stretch-card">
                <div className="row">
                  <div className="col-12 mb-4">
                    <h5 className="m-0 practice_spent_heading practice_color">
                      Hours Spent
                    </h5>
                  </div>
                  <div class="col-12">
                    <div className="row mx-auto py-3 px-2 border overflow-hidden height_add_totalScore">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 d-flex align-items-center justify-content-start ">
                            <p className="m-0 practice_total_heading practice_total_heading_weight">
                              Hours
                            </p>
                          </div>
                          <div className="col-6 position-relative d-flex align-items-center justify-content-end">
                            <p className="m-0 practice_total_heading_change">
                              Duration:
                              <span className="practice_total_heading_change  color_change_weekly_time m-0 text-capitalize">
                                {" "}
                                {graphValue}
                                <i
                                  class="fa fa-angle-down ms-2 cp"
                                  style={{ fontWeight: "700" }}
                                  aria-hidden="true"
                                  onClick={() => {
                                    setShow(!show);
                                  }}
                                ></i>{" "}
                              </span>
                            </p>
                            {show && (
                              <div className="col-auto add_weekly_daily_data">
                                <div className="row  px-2 mx-auto studyGenerate bg-white position-absolute border py-2 d-flex align-items-center justify-content-end flex-column materials_shadow">
                                  <div className="col-12 summaryStudy">
                                    <button
                                      disabled={graphLoader}
                                      type="button"
                                      class="btn m-0 p-0 studyGenerateFlashcards add_material_dropdwn py-1 shadow-none text-capitalize border-0"
                                      onClick={() => {
                                        handleChangeGraphVal("weekly");
                                      }}
                                    >
                                      Weekly
                                    </button>
                                  </div>

                                  <div
                                    className="col-12 summaryStudy"
                                    // onClick={() => {
                                    //   handleDelete();
                                    // }}
                                  >
                                    <button
                                      disabled={graphLoader}
                                      type="button"
                                      class="btn m-0 p-0 studyGenerateFlashcards add_material_dropdwn py-1 shadow-none text-capitalize border-0"
                                      onClick={() => {
                                        handleChangeGraphVal("monthly");
                                      }}
                                    >
                                      Monthly
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {!graphLoader ? (
                        <div class="col-12 p-0 activity_all_goal_sets activity_font_data bg-white d-flex align-items-center justify-content-center">
                          <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                              width={554}
                              height={"-webkit-fill-available"}
                              data={graphData}
                              margin={{
                                top: 20,
                                right: 0,
                                left: 0,
                                bottom: 30,
                              }}
                            >
                              {/* <CartesianGrid stroke="#F4F4F6" vertical={false} /> */}
                              <XAxis
                                tickLine={false}
                                dataKey="practiceCreateDate"
                                axisLine={false}
                              />
                              <YAxis
                                axisLine={false}
                                tickLine={false}
                                tickFormatter={formatter}
                              />

                              {/* <Tooltip cursor={{ fill: "transparent" }} /> */}
                              <Bar
                                dataKey="totalTime"
                                fill="#6A4BFF"
                                radius={[10, 10, 10, 10]}
                                barSize={48}
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      ) : (
                        <Skeleton height={`2vh`} count={10} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-6 mb-md-0 mb-3 grid-margin stretch-card">
                <div className="row mx-auto">
                  <div className="col-12 mb-4">
                    <h5 className="m-0 practice_spent_heading practice_color">
                      Total Score
                    </h5>
                  </div>

                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <div className="row mx-auto border overflow-hidden height_add_totalScore">
                          <div className="col-12 top_bottom_height">
                            <div className="row mx-auto">
                              <div className="col-1">
                                <p className="practice_total_heading m-0">#</p>
                              </div>
                              <div className="col-4 pe-0">
                                <p className="practice_total_heading m-0">
                                  Topics
                                </p>
                              </div>
                              <div className="col-3">
                                <p className="practice_total_heading m-0">
                                  Total Time
                                </p>
                              </div>
                              <div className="col-2">
                                <p className="practice_total_heading m-0">
                                  Trend
                                </p>
                              </div>
                              <div className="col-2">
                                <p className="practice_total_heading m-0">
                                  Progress
                                </p>
                              </div>
                            </div>
                          </div>
                          {!totalScoreLoader ? (
                            <div className="col-12 height_add_total_score">
                              <div className="row">
                                {totalScore?.responseData?.length > 5 ? (
                                  totalScore?.responseData
                                    ?.map((ele, ind) => {
                                      return (
                                        <div className="col-12 py-1 border-top  total_score_border ">
                                          <div className="row  mx-auto align-items-center">
                                            <div className="col-1">
                                              <p className="practice_total_para practice_color m-0">
                                                {ind + 1}.
                                              </p>
                                            </div>
                                            <div className="col-4 pe-0">
                                              <p className="practice_total_para practice_color m-0">
                                                {ele?.topicName}
                                              </p>
                                            </div>
                                            <div className="col-3">
                                              <p className="practice_total_para practice_color m-0">
                                                {ele?.quizTotalTime
                                                  ? formatTime(
                                                      ele?.quizTotalTime
                                                    )
                                                  : "00:00"}
                                              </p>
                                            </div>
                                            <div className="col-2">
                                              <img
                                                src={ele?.trend}
                                                className="groupQuiz_image"
                                                alt={""}
                                              />
                                            </div>
                                            <div className="col-2">
                                              <p className="practice_total_para practice_color m-0">
                                                {ele?.progress}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                ) : (
                                  <>
                                    {totalScore?.responseData?.map(
                                      (ele, ind) => {
                                        return (
                                          <div className="col-12 py-1 border-top total_score_border">
                                            <div className="row  mx-auto align-items-center">
                                              <div className="col-1">
                                                <p className="practice_total_para practice_color m-0">
                                                  {ind + 1}.
                                                </p>
                                              </div>
                                              <div className="col-4 pe-0">
                                                <p className="practice_total_para practice_color m-0">
                                                  {/* {ele?.topicId?.topic} */}
                                                  {truncate(
                                                    ele?.topicId?.topic,
                                                    25
                                                  )}
                                                </p>
                                              </div>
                                              <div className="col-3">
                                                <p className="practice_total_para practice_color m-0">
                                                  {ele?.quizTotalTime
                                                    ? formatTime(
                                                        ele?.quizTotalTime
                                                      )
                                                    : "00:00"}
                                                </p>
                                              </div>
                                              <div className="col-2">
                                                <img
                                                  src={ele?.trend}
                                                  className="groupQuiz_image"
                                                  alt={""}
                                                />
                                              </div>
                                              <div className="col-2">
                                                <p className="practice_total_para practice_color m-0">
                                                  {ele?.progress}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          ) : (
                            <Skeleton height={`2vh`} count={10} />
                          )}

                          <div className="col-12 top_bottom_height color_bg_time">
                            <div className="row px-2 ">
                              <div className="col d-flex align-items-center justify-content-start">
                                <p className="practice_total_para practice_color inc_font_quiz m-0">
                                  Total{""} Time:
                                </p>
                              </div>
                              <div className="col d-flex align-items-center justify-content-end">
                                <p className="practice_total_para practice_color m-0 d-flex ">
                                  <span className="practice_total_para practice_color inc_font_quiz me-1 m-0">
                                    {totalScore?.totalTime
                                      ? formatTime(totalScore?.totalTime)
                                      : "00:00"}
                                  </span>
                                  hours spent
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <div className="row mx-auto">
              <div className="col-12 mb-4">
                <h5 className="m-0 practice_spent_heading practice_color">
                  Start Practicing
                </h5>
              </div>
              {store?.getRelatedDocumentData?.data?.responseData && (
                <div className=" row mb-4">
                  <Carousel infinite={false} responsive={responsive}>
                    {store?.getRelatedDocumentData?.data?.responseData?.map(
                      (item, ind) => {
                        return (
                          <div className="row me-4 ms-auto border overflow-hidden mb-1">
                            <div className="col-6 position-relative">
                              <div className="row">
                                <div className="col-12 p-0">
                                  <img
                                    src={item.thumbnail}
                                    className=" secondLakhni"
                                    alt="..."
                                  />
                                </div>
                                <div className="col-12 p-0 ps-2 position-absolute bottom-0 mb-1">
                                  {item?.category?.category_name && (
                                    <button
                                      className="btn text-white videoDoc_btn text-capitalize shadow-none rounded-pill  d-flex align-items-center justify-content-center"
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: `${item?.category?.category_color}`,
                                      }}
                                    >
                                      {item?.category?.category_name}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-6 py-2 d-flex ps-3 pe-2">
                              <div className="row ">
                                <div className="col-12 mb-auto  d-flex flex-column justify-content-cente">
                                  <h6 className="card-data  m-0 d-flex align-items-start">
                                    {item?.title && truncate(item?.title, 20)}
                                  </h6>
                                </div>

                                <div className="col-12 mt-auto">
                                  <p className="card-texts_practice mb-0">
                                    {item?.practicePageTimeTrack
                                      ? formatTime(item?.practicePageTimeTrack)
                                      : "00:00"}{" "}
                                    min practiced
                                  </p>
                                  <div className="topic mt-2">
                                    {/* <hr /> */}
                                    <div class="progress progress_parent">
                                      <div
                                        class="progress-bar progress_bar"
                                        role="progressbar"
                                        aria-label="Basic example"
                                        aria-valuenow="25"
                                        aria-valuemin="0"
                                        style={{
                                          width: `${
                                            item?.progressBarPercentage
                                              ? item?.progressBarPercentage
                                              : "0%"
                                          }`,
                                        }}
                                        aria-valuemax="100"
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 mt-auto">
                                  {practiceLoader !== ind ? (
                                    <div
                                      className="row d-flex align-items-center justify-content-start"
                                      onClick={() => {
                                        handlePractice(item, ind);
                                      }}
                                    >
                                      <div
                                        className="col-auto"
                                        style={{ marginRight: "-5px" }}
                                      >
                                        <p
                                          style={{ cursor: "pointer" }}
                                          className="card-data m-0 d-flex align-items-center"
                                        >
                                          Practice
                                        </p>
                                      </div>
                                      <div
                                        className="col-auto p-0 d-flex align-items-center justify-content-center rounded-pill"
                                        style={{
                                          width: "16px",
                                          height: "16px",
                                          backgroundColor: "#4E45C2",
                                        }}
                                      >
                                        <img
                                          style={{ cursor: "pointer" }}
                                          alt=""
                                          className="w-100"
                                          src={learn_button}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className="spinner-border me-4"
                                      role="status"
                                      style={{
                                        width: "1.77rem",
                                        height: "1.77rem",
                                      }}
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </Carousel>
                </div>
              )}
            </div>
          </div>
          <div className="col-12 mt-4">
            <div className="row mx-auto">
              <div className="col-12 mb-4">
                <h5 className="m-0 practice_spent_heading practice_color">
                  Try these topics
                </h5>
              </div>
              {store?.getTryTheseTopics?.data?.responseData && (
                <div className=" row mb-4">
                  <Carousel infinite={false} responsive={responsive}>
                    {store?.getTryTheseTopics?.data?.responseData?.map(
                      (item, ind) => {
                        return (
                          <div className="row me-4 ms-auto border overflow-hidden mb-1">
                            <div className="col-6 position-relative">
                              <div className="row">
                                <div className="col-12 p-0">
                                  <img
                                    src={item?.subjectImage}
                                    className=" secondLakhni"
                                    alt="..."
                                  />
                                </div>
                                <div className="col-12 p-0 ps-2 position-absolute bottom-0 mb-1">
                                  {item?.topic?.subject && (
                                    <button
                                      className="btn text-white videoDoc_btn text-capitalize shadow-none rounded-pill  d-flex align-items-center justify-content-center"
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: `${item?.topic?.subjectColor}`,
                                      }}
                                    >
                                      {item?.topic?.subject}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-6 pt-3 pb-2 d-flex ps-3 pe-2">
                              <div className="row ">
                                <div className="col-12 mb-auto  d-flex flex-column justify-content-cente">
                                  <h6 className="card-data  m-0 d-flex align-items-start">
                                    {item?.topic?.topicName &&
                                      truncate(item?.topic?.topicName, 20)}
                                  </h6>
                                </div>

                                <div className="col-12 mt-auto">
                                  <p className="card-texts_practice card_texts_practice_weight mb-0">
                                    {item.topic?.quizTotalTime
                                      ? formatTime(item?.topic?.quizTotalTime)
                                      : "0:00"}{" "}
                                    spent · {item?.topic?.progress}
                                  </p>
                                  <div className="sm_progressBar d-flex mt-2">
                                    <div className="col topic pe-1">
                                      <hr />
                                    </div>
                                    <div
                                      className={`${
                                        item?.topic?.progress === "Medium" ||
                                        item?.topic?.progress === "Advanced" ||
                                        item?.topic?.progress === "Maestro"
                                          ? "topic"
                                          : "topicc"
                                      } col  px-1`}
                                    >
                                      <hr />
                                    </div>
                                    <div
                                      className={`${
                                        item?.topic?.progress === "Advanced" ||
                                        item?.topic?.progress === "Maestro"
                                          ? "topic"
                                          : "topicc"
                                      } col  px-1`}
                                    >
                                      <hr />
                                    </div>
                                    <div
                                      className={`${
                                        item?.topic?.progress === "Maestro"
                                          ? "topic"
                                          : "topicc"
                                      } col  px-1`}
                                    >
                                      <hr />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 mt-auto">
                                  {tryTheseLoader !== ind ? (
                                    <div
                                      className="row d-flex align-items-center justify-content-start"
                                      onClick={() => {
                                        handleClickTryTheseTopics(item, ind);
                                      }}
                                    >
                                      <div
                                        className="col-auto"
                                        style={{ marginRight: "-5px" }}
                                      >
                                        <p
                                          style={{ cursor: "pointer" }}
                                          className="card-data m-0 d-flex align-items-center"
                                        >
                                          Practice
                                        </p>
                                      </div>
                                      <div
                                        className="col-auto p-0 d-flex align-items-center justify-content-center rounded-pill"
                                        style={{
                                          width: "16px",
                                          height: "16px",
                                          backgroundColor: "#4E45C2",
                                        }}
                                      >
                                        <img
                                          style={{ cursor: "pointer" }}
                                          alt=""
                                          className="w-100"
                                          src={learn_button}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className="spinner-border me-4"
                                      role="status"
                                      style={{
                                        width: "1.69rem",
                                        height: "1.69rem",
                                      }}
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </Carousel>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Skeleton height={`3vh`} count={50} />
      )}
    </>
  );
};

export default Overview;

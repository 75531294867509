import { ADD_PROFILE_ONBOARDING, ADD_COURSE } from "../actions/types";

const INITIAL_STATE = {
  profileStatus: "1",
  courseList: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export const profileStatus = (state = INITIAL_STATE.profileStatus, action) => {
  const { payload, type } = action;
  switch (type) {
    case ADD_PROFILE_ONBOARDING:
      return payload;
    case "RESET":
      return INITIAL_STATE.profileStatus;

    default:
      return state;
  }
};

export const addCourseList = (state = INITIAL_STATE.courseList, action) => {
  const { payload, type } = action;
  switch (type) {
    case ADD_COURSE:
      return payload;
    case "RESET":
      return INITIAL_STATE.courseList;
    default:
      return state;
  }
};

import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";
import authReducer from "./auth.reducer";
import { profileStatus, addCourseList } from "./onBoarding";
import {
  addContentUrl,
  getAddedContentList,
  getRecommendContent,
  getCategoryList,
  getContinueStudyList,
  getsearchCourseName,
  getSearchedCourseList,
  addContentData,
} from "./libraryOverview";
import {
  onboardingSchoolList,
  onboardingProfileList,
  onboardingCourseList,
} from "./getProfile";
import {
  getPlaylist,
  createNewPlaylist,
  browsePlaylistContent,
  addContentToPlaylist,
  searchCourseFilter,
} from "./libraryPlaylist";
import { getStudyTopic, getNotes, getSubTopic,getFlashCards,getSummary,getFocusData } from "./study";
import {
  getUserProfilee,
  getUserPasswordCheck,
  password_Change,
  update_profile,
} from "./getUserProfile";
import {
  getTaskType,
  addTask,
  getTaskDetails,
  addTime,
  getContentFromLibrary,
  addContentLibrary,
  addGoal,
  trackTaskTime,
  getTrackerDetails,getTaskSuggestion
} from "./studyBuddy";
import { getPracticeData,getQuizData,getQuizRelatedData,getRelatedDocumentData,getQuizFeedBackFlags,getTryTheseTopics,getPracticeTranscripts,getAchievementPractice,getSkillUpgrade,getAnswerStreak,getNotification,getMonthlyStreak,getHighlightPractice} from "./practice";

const appReducer = (state, action) => {
  return combineReducers({
    router: connectRouter(state),
    form: formReducer,
    auth: authReducer,
    profileStatus: profileStatus,
    profileList: onboardingProfileList,
    schoolList: onboardingSchoolList,
    onboardcourse2: addCourseList,
    courseList: onboardingCourseList,
    searchedCourseList: getSearchedCourseList,
    addContentUrl: addContentUrl,
    addedContentList: getAddedContentList,
    addContentData: addContentData,
    categaryList: getCategoryList,
    recommendContents: getRecommendContent,
    continueStudyList: getContinueStudyList,
    createPlaylist: createNewPlaylist,
    browsePlaylistData: browsePlaylistContent,
    playlist: getPlaylist,
    searchCourseName: getsearchCourseName,
    addContentToPlaylist: addContentToPlaylist,
    getStudyTopic: getStudyTopic,
    getSubTopic: getSubTopic,
    notesList: getNotes,
    getUserProfile: getUserProfilee,
    passwordCheck: getUserPasswordCheck,
    password_Change: password_Change,
    update_profile: update_profile,
    searchCourseFilter: searchCourseFilter,
    getTaskType: getTaskType,
    addTask: addTask,
    getTaskDetails: getTaskDetails,
    addTime: addTime,
    getSummary:getSummary,
    getContentFromLibrary: getContentFromLibrary,
    addContentLibrary: addContentLibrary,
    addGoal: addGoal,
    trackTaskTime:trackTaskTime,
    getTrackerDetails:getTrackerDetails,
    getTaskSuggestion:getTaskSuggestion,
    getPracticeData:getPracticeData,
    getQuizData:getQuizData,
    getQuizRelatedData:getQuizRelatedData,
    getRelatedDocumentData:getRelatedDocumentData,
    getQuizFeedBackFlags:getQuizFeedBackFlags,
    getTryTheseTopics:getTryTheseTopics,
    getPracticeTranscripts:getPracticeTranscripts,
    getAchievementPractice:getAchievementPractice,
    getSkillUpgrade:getSkillUpgrade,
    getAnswerStreak,getAnswerStreak,
    getNotification:getNotification,
    getMonthlyStreak:getMonthlyStreak,
    getFlashCards:getFlashCards,
    getHighlightPractice:getHighlightPractice,
    getFocusData:getFocusData,
  });
};
const rootReducer = (state, action) => {
  if (action?.type === "SIGN_OUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
  // if (action?.type === "SIGN_OUT") {
  //   // for all keys defined in your persistConfig(s)
  //   storage.removeItem("persist:root");
  //   // storage.removeItem('persist:otherKey')

  //   state = undefined;
  // }

  // return appReducer(state, action);
};

export default rootReducer;

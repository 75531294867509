import React, { useState, useEffect, useRef } from "react";
import "../addContent.css";
import {
  updateContent,
  deleteCourse,
  getCategory,
} from "../../../../../store/actions/libraryOverview";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Store } from "react-notifications-component";
import {
  Deleteicon,
  Editicon,
  IIcon,
} from "../../../../../staticContent/image";
import {
  clearCourseName,
  courseSearch,
} from "../../../../../store/actions/libraryPlaylist";
// import { Steps, Hints } from "intro.js-react";

// import "intro.js/introjs.css";
const EditContent = (props) => {
  const timer = useRef(null);
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState();
  const [editTitle, setEditTitle] = useState(true);
  const [editCourse, setEditCourse] = useState(true);
  const [titletips, SetTitleTip] = useState(false);
  const [coursetips, SetCourseTips] = useState(false);
  const [tagtips, SetTagTips] = useState(false);
  const [tooltips, SetToolTips] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [time, setTime] = useState(null);

  const updateTitle = (index) => {
    setEditTitle(!editTitle);
    setUserData(
      userData.map((ele, ind) =>
        ind === index ? { ...ele, isEdit: !ele.isEdit } : { ...ele }
      )
    );
  };
  const [courseData, setCourseData] = useState([]);
  const handleOpen = (item) => {
    setUserData(
      userData.map((itemx, index) =>
        itemx?._id === item?._id
          ? { ...itemx, focus: true, modalShow: !itemx.modalShow }
          : { ...itemx, focus: false, modalShow: false }
      )
    );
  };
  const [disabled, setdisabled] = useState(true);
  const [color, setcolor] = useState(0);

  // delete content feild api implementation starts
  const deleteContent = (id) => {
    dispatch(deleteCourse(id));
    setUserData((userData) => userData.filter((items) => items._id !== id));
    if (userData?.length === 1) {
      if (props?.redirect === "task") {
        history.push("/organize/addcontent");
      } else {
        history.push("/");
      }
    }
  };
  // delete content feild api ends
  useEffect(() => {
    dispatch(clearCourseName([]));
    dispatch(getCategory());
    setUserData(
      store?.addContentData?.data?.responseData?.map((item, index) => ({
        ...item,
        courseName: "",
        courseTag: "",
        category: item.category ? item.category : "",
      }))
    );
  }, [store.addContentData]);
  const editHandleChange = (e, id, index) => {
    clearTimeout(time);
    let userId = store.auth?.user?.id;

    if (e.target.value?.length <= 0) {
      setShow(false);
      dispatch(clearCourseName([]));
    } else {
      const newTimer = setTimeout(() => {
        dispatch(courseSearch(e.target.value, userId)).then((res) => {
          setShow(true);
        });
      }, 500);
      setTime(newTimer);
    }
    setUserData(
      userData?.map((itemz, indexz) =>
        id === itemz._id
          ? { ...itemz, courseName: e.target.value, model: true }
          : { ...itemz, model: false }
      )
    );
  };
  function truncate(str, n) {
    return str?.length > n ? str.slice(0, n - 1) + "...." : str;
  }
  // edit page functionality starts
  const updateData = () => {
    // edit page api implementation
    dispatch(
      updateContent({
        data: userData?.map((itemx, indexz) => ({
          id: itemx._id,
          title: itemx.title,
          course_Name: itemx.courseName,
          tags: itemx.courseTag === "" ? null : itemx.courseTag,
          category: itemx.category === "" ? null : itemx.category,
        })),
      })
    ).then(() => {
      if (props?.redirect === "task") {
        history.push("/organize/addcontent");
      } else {
        history.push("/");
      }
    });
    setLoader(true);
  };
  // edit page functionality ends
  function showError(data) {
    Store.addNotification({
      message: data,
      type: "danger",
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  }
  const handleCourseSelect = (ele, id) => {
    setShow(false);
    setUserData(
      userData?.map((itemz, indexz) =>
        id === itemz._id ? { ...itemz, courseName: ele } : itemz
      )
    );
    setShow(false);
  };

  return (
    <>
      {/* edit content page starts  */}
      <div className="row   position-relative pb-4">
        <div className="col-12 position-absolute top_line top-0 end-0 start-0"></div>
        <div className="col-12 position-absolute edit_bottom_color end-0 start-0"></div>
        <div className="col-12 py-3 bg-light  border-bottom">
          <div className="row mx-auto">
            <div className="col-5 d-flex info justify-content-start align-items-center align-items-baseline">
              <h6 className="m-0 wrapper editcontent-title d-flex">
                Document Title
                <img
                  src={IIcon}
                  onMouseOver={() => {
                    SetTitleTip(true);
                  }}
                  onMouseOut={() => {
                    SetTitleTip(false);
                  }}
                  alt={""}
                  className="ms-2"
                />
                {titletips && (
                  <div className="tooltip">Edit the name of this document or resource for easier identification in your library.</div>
                )}
              </h6>
            </div>
            <div className="col-2 d-flex info justify-content-start align-items-center align-items-baseline">
              <h6 className="m-0 wrappes editcontent-title d-flex">
                Course Name
                <img
                  src={IIcon}
                  onMouseOver={() => {
                    SetCourseTips(true);
                  }}
                  onMouseOut={() => {
                    SetCourseTips(false);
                  }}
                  alt={""}
                  className="ms-2"
                />
                {coursetips && (
                  <div className="tooltipss">Tag this content with a course code (e.g., PHY 7A, CS 61) to organize materials by class. </div>
                )}
              </h6>
            </div>
            <div className="col-2 d-flex info justify-content-start align-items-center align-items-baseline">
              <h6 className="m-0 wrapps editcontent-title d-flex">
                Tags
                <img
                  src={IIcon}
                  onMouseOver={() => SetTagTips(true)}
                  onMouseOut={() => SetTagTips(false)}
                  alt={""}
                  className="ms-2"
                />
                {tagtips && (
                  <div className="tooltss">Add relevant keywords (e.g., "ADHD", "Gravity") to help categorize and search for this content later. </div>
                )}
              </h6>
            </div>

            <div className="col-3 d-flex info justify-content-start align-items-center align-items-baseline">
              <h6 className="m-0 wraps editcontent-title d-flex">
                Category
                <img
                  src={IIcon}
                  alt={""}
                  onMouseOver={() => {
                    SetToolTips(true);
                  }}
                  onMouseOut={() => SetToolTips(false)}
                  className="ms-2"
                />
                {tooltips && (
                  <div className="tools">Choose the type of content (e.g., document, lecture slide, video) to keep your library organized.</div>
                )}
              </h6>
            </div>
          </div>
        </div>
        {userData?.map((item, index) => {
          return (
            <div className="col-12 mt-4" key={index}>
              <div className="row mx-auto d-flex align-items-baseline">
                <div className="col-5 d-flex justify-content-start align-items-baseline">
                  <i
                    className="fa fa-file-o me-2 bg-light p-3 icon_edit_content"
                    aria-hidden="true"
                  ></i>

                  {!item.isEdit ? (
                    <p className="m-0 me-3 edit_content_para">
                      {truncate(item.title, 30)}
                    </p>
                  ) : (
                    <input
                      value={item.title}
                      name="title"
                      className="form-control rounded border-0 shadow-none edit_font_size"
                      type="text"
                      placeholder="Enter Title"
                      onChange={(e) => {
                        if (e.target.value.trim() !== "") {
                          setUserData(
                            userData.map((itemz, indexz) =>
                              item._id === itemz._id
                                ? { ...itemz, title: e.target.value }
                                : itemz
                            )
                          );
                        }
                      }}
                    />
                  )}
                  <img
                    src={Editicon}
                    className="cp"
                    alt=""
                    onClick={() => {
                      updateTitle(index);
                    }}
                  />
                </div>
                <div className="col-2  position-relative  d-flex align-items-center">
                  {false ? (
                    <>
                      <p className="m-0">
                        PHYS ED 55
                        <img src={Editicon} alt="" className="ms-2" />
                      </p>
                    </>
                  ) : (
                    <>
                      <input
                        ref={timer}
                        value={item.courseName}
                        type="text"
                        name="courseName"
                        className=" text-dark shadow-none ps-2 editcontent-select sizeTHreeBox"
                        id="#courseName"
                        aria-describedby="emailHelp"
                        placeholder="Add course"
                        onChange={(e) => editHandleChange(e, item._id, index)}
                      />
                      {item.model &&
                        show &&
                        store.searchCourseFilter?.data?.content?.length >=
                          1 && (
                          <>
                            {" "}
                            <div className="editcoursediv pb-2">
                              <p className="editcoursetag px-3 py-2 p-0 m-0">
                                Recently Added Courses
                              </p>

                              {store.searchCourseFilter?.data?.content?.map(
                                (ele, ind) => {
                                  return (
                                    <div className=" col-md-12 px-3 py-1 p-0 m-0">
                                      <h6
                                        className="m-0 p-0 courselist-serachcourse"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleCourseSelect(
                                            ele.course_Name,
                                            item._id
                                          )
                                        }
                                      >
                                        {ele.course_Name}
                                      </h6>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </>
                        )}
                    </>
                  )}
                </div>
                <div className="col-2 d-flex align-items-center ">
                  <input
                    type="text"
                    name="courseTag"
                    className=" editcontent-select ps-2 shadow-none text-dark"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Add tag"
                    value={item.courseTag}
                    onChange={(e) => {
                      const data = e.target.value;
                      setUserData(
                        userData?.map((itemz, indexz) =>
                          item._id === itemz._id
                            ? {
                                ...itemz,
                                courseTag:
                                  e.target.value === "" ? null : e.target.value,
                              }
                            : itemz
                        )
                      );
                    }}
                  />
                </div>
                <div className="col-2 pe-4">
                  <div className="row position-relative">
                    <div className="col-12">
                      <div
                        onClick={() => {
                          handleOpen(item);
                        }}
                        className={
                          item?.focus
                            ? "row mx-auto edit_data_add  d-flex align-items-center  isFocus"
                            : "row mx-auto  edit_data_add  d-flex align-items-center "
                        }
                      >
                        <div className="col-9 me-0 pe-0 ">
                          <p
                            type="search"
                            style={
                              item.value === ""
                                ? { color: "#737373" }
                                : { color: "#33333" }
                            }
                            className={
                              item.value
                                ? "border-0 edit_inputt  flex-grow-1  outline-none bg-transparent mb-0"
                                : "border-0 edit_input  flex-grow-1  outline-none bg-transparent mb-0"
                            }
                          >
                            {item.value
                              ? item.value
                              : item?.category?.category_name
                              ? item?.category?.category_name
                              : "Select"}
                          </p>
                        </div>
                        <div className="col-3 ms-0 ps-0 justify-content-end d-flex  drop-icon align-items-center">
                          {item?.modalShow ? (
                            <i
                              className="fa fifth-arrow fa-angle-up"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fifth-arrow fa-angle-down"
                              aria-hidden="true"
                            ></i>
                          )}
                        </div>
                      </div>
                    </div>

                    {item.modalShow && (
                      <>
                        <div className="col-12">
                          <div className="row border mt-2 bg-white sele_drpdown time_drpdown media_drpdown mx-auto">
                            {store.categaryList?.data?.map((itemz, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() => {
                                    setdisabled(false);

                                    setcolor(1);
                                    setUserData(
                                      userData?.map((itemx, index) =>
                                        itemx?._id === item?._id
                                          ? {
                                              ...itemx,
                                              category: itemz._id,
                                              value: itemz?.category_name,
                                              modalShow: !itemx.modalShow,
                                              color: true,
                                            }
                                          : itemx
                                      )
                                    );
                                  }}
                                  className="col-12 input-data p-2"
                                >
                                  <p
                                    className="m-0 edit_data_text "
                                    style={{ cursor: "pointer" }}
                                  >
                                    {itemz.category_name}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-1 d-flex align-items-center justify-content-end">
                  <img
                    src={Deleteicon}
                    alt=""
                    onClick={() => {
                      deleteContent(item._id);
                    }}
                    aria-hidden="true"
                  />
                  {/* <i
                    className="fa fa-trash mx-4"
                    onClick={() => {
                      deleteContent(item._id);
                    }}
                    aria-hidden="true"
                  ></i> */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="row d-flex justify-content-end">
        {userData?.length > 0 ? (
          !loader ? (
            <div className="col-auto ms-auto fw-normal">
              <button
                onClick={() => {
                  updateData();
                }}
                type="button"
                className="btn saves shadow-none text-white fw-normal add_content_add_button"
              >
                Add Content
              </button>
            </div>
          ) : (
            <div className="col-auto ms-auto fw-normal">
              <button
                type="button"
                className="btn saves shadow-none text-white fw-normal py-2 add_content_add_button"
              >
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          )
        ) : null}
      </div>
      {/* edit content page ends  */}
    </>
  );
};
export default EditContent;

import { AccountTieOutline } from "mdi-material-ui";
import {
  GET_PRACTICE_PAGE_DATA,
  GET_PRACTICE_PAGE_DATA_FAILED,
  GET_TOPIC_PRACTICE_PAGE_DATA,
  GET_TOPIC_PRACTICE_PAGE_DATA_FAILED,
  GET_DOCUMENT_QUIZ,
  GET_DOCUMENT_QUIZ_FAILED,
  GET_TOPIC_QUIZ_FAILED,
  GET_TOPIC_QUIZ,
  QUIZ_RELATED_DATA,
  QUIZ_RELATED_DATA_FAILED,
  GET_RELATED_CONTENT,
  GET_RELATED_CONTENT_FAILED, GET_FEEDBACK_FLAGS,GET_START_PRACTICISN_DATA,GET_TOTAL_SCORE,GET_TRY_THESE_TOPICS,GET_TRY_THESE_TOPICS_FAILED, GET_PRACTICE_TRANSCRIPTS, GET_PRACTICE_TRANSCRIPTS_FAILED,GET_ACHEIVEMENT_PRACTICE_FAILED,GET_ACHEIVEMENT_PRACTICE,GET_SKILL_UPGRADE_FAILED,GET_SKILL_UPGRADE, GET_ANSWER_STREAK_FAILED,GET_ANSWER_STREAK,GET_NOTIFICATION_FAILED,GET_NOTIFICATION,GET_MONTHLY_STREAK_FAILED,GET_MONTHLY_STREAK,GET_HIGHLIGHTS_PRACTICE
} from "../actions/types";

const INITIAL_STATE = {
  state: {},
};

export const getPracticeData = (state = INITIAL_STATE.state, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PRACTICE_PAGE_DATA:
      return payload;

    case GET_PRACTICE_PAGE_DATA_FAILED:
      return payload;

    case GET_TOPIC_PRACTICE_PAGE_DATA:
      return payload;

    case GET_TOPIC_PRACTICE_PAGE_DATA_FAILED:
      return payload;
    default:
      return state;
  }
};

export const getQuizData = (state = INITIAL_STATE.state, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DOCUMENT_QUIZ:
      return payload;
    case GET_DOCUMENT_QUIZ_FAILED:
      return payload;
    case GET_TOPIC_QUIZ_FAILED:
      return payload;
    case GET_TOPIC_QUIZ:
      return payload;
    default:
      return state;
  }
};

export const getQuizRelatedData = (state = INITIAL_STATE.state, action) => {
  const { type, payload } = action;
  switch (type) {
    case QUIZ_RELATED_DATA:
      return payload;
    case QUIZ_RELATED_DATA_FAILED:
      return payload;
      default:
        return state;
  }
  
};

export const getRelatedDocumentData = (state=INITIAL_STATE.state,action)=>{
  const {type,payload} = action;
  switch(type){
    case GET_RELATED_CONTENT :
      return payload;
      case GET_RELATED_CONTENT_FAILED:
        return payload;
        case GET_START_PRACTICISN_DATA:
        return payload
        default:
          return state
  }
}
export const getQuizFeedBackFlags = (state=INITIAL_STATE.state,action)=>{
  const {type,payload} = action;
  switch(type){
    case GET_FEEDBACK_FLAGS:
      return payload;
      case GET_TOTAL_SCORE:
        return payload;
      default:
        return state
  }
}

export const getTryTheseTopics = (state=INITIAL_STATE.state,action)=>{
  const {type,payload} = action;
  switch(type){
    case GET_TRY_THESE_TOPICS:
      return payload;
      case GET_TRY_THESE_TOPICS_FAILED:
      return payload;
      default:
        return state  
    }
}
export const getPracticeTranscripts = (state = INITIAL_STATE.state,action)=>{
  const {type,payload} = action;
  switch(type){
    case GET_PRACTICE_TRANSCRIPTS:
      return payload;
      case GET_PRACTICE_TRANSCRIPTS_FAILED:
        return payload;
        default:
          return state
  }
}

export const getAchievementPractice = (state=INITIAL_STATE.state,action)=>{
  const {type,payload} = action
  switch(type){
    case GET_ACHEIVEMENT_PRACTICE:
      return payload;
      case GET_ACHEIVEMENT_PRACTICE_FAILED:
        return payload;
        default:
          return state
  }
}
export const getSkillUpgrade = (state=INITIAL_STATE.state,action)=>{
  const {type,payload} = action
  switch(type){
    case GET_SKILL_UPGRADE:
      return payload;
      case GET_SKILL_UPGRADE_FAILED:
        return payload;
        default:
          return state
  }
}
export const getAnswerStreak = (state=INITIAL_STATE.state,action)=>{
  const {type,payload} = action
  switch(type){
    case GET_ANSWER_STREAK:
      return payload;
      case GET_ANSWER_STREAK_FAILED:
        return payload;
        default:
          return state
  }
}
export const getMonthlyStreak = (state=INITIAL_STATE.state,action)=>{
  const {payload,type} = action
  switch(type){
    case GET_MONTHLY_STREAK:
      return payload;
      case GET_MONTHLY_STREAK_FAILED:
        return payload;
        default:
          return state;
  }
}

export const getNotification = (state=INITIAL_STATE.state,action)=>{
  const {type,payload} = action
  switch (type){
    case GET_NOTIFICATION:
      return payload;
      case GET_NOTIFICATION_FAILED:
        return payload;
        default :
        return state

  }
}
export const getHighlightPractice = (state=INITIAL_STATE.state,action)=>{
  const {type,payload} = action
  switch(type){
    case GET_HIGHLIGHTS_PRACTICE:
      return payload;
      default :
      return state
  }
}

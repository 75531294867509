import {
    GET_ADDED_SCHOOL_LIST,
    GET_ADDED_PROFILE_LIST,
    GET_ADDED_COURSE_LIST
} from '../actions/types';
const INITIAL_STATE = {
    // isLoading: false
};
// eslint-disable-next-line import/no-anonymous-default-export


// add content url page 
export const onboardingSchoolList = (state = INITIAL_STATE, action) => {

    const { payload, type } = action;
    switch (type) {
        case GET_ADDED_SCHOOL_LIST:
            return payload;
        default:
            return state;
    }
}

export const onboardingProfileList = (state = INITIAL_STATE, action) => {
    const { payload, type } = action;
    switch (type) {
        case GET_ADDED_PROFILE_LIST:
            return payload;
        default:
            return state;
    }
}


export const onboardingCourseList = (state = INITIAL_STATE, action) => {
    const { payload, type } = action;
    switch (type) {
        case GET_ADDED_COURSE_LIST:
            return payload;
        default:
            return state;
    }
}
import React, { useState, useRef, useEffect } from "react";
import Header from "../../layouts/Header/Header";
import { useSelector, useDispatch } from "react-redux";
import "./practice.css";
import {
  clockQuiz,
  vectorQuiz,
  groupQuiz,
  groupQuizColor,
  learn_button,
  threeDotsHorizontal,
  quizBgImg,
} from "../../staticContent/image";
import { useHistory } from "react-router-dom";
import SidebarCanvas from "../Librarypage/Overview/CanvasSidebar/sidebarCanvas";
import { getTopics } from "../../store/actions/study";
import {
  getDocumentQuiz,
  getPracticeData,
  getTopicRelatedContent,
  practiceTimeTrack,
  getPracticeTranscripts,
  addTotalPracticeTimeForReward,
  getHighlightPractice,
} from "../../store/actions/practice";
import { getNotes } from "../../store/actions/study";
import Skeleton from "react-loading-skeleton";
const VideoPractice = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [tableContentsData, setTableContentData] = useState(
    store?.getPracticeData?.data?.relatedTopic
  );
  const handleSlice = (text) => {
    if (text.length > 20) {
      return text.slice(0, 20);
    } else {
      return text;
    }
  };
  const [relatedMaterials, setRelatedMaterials] = useState([]);
  const [data, setData] = useState(store?.getPracticeData?.data);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [screenLoader, setScreenLoader] = useState(false);
  const [sidebarData, setSidebarData] = useState({});
  const [sideLoader, setSideLoader] = useState(false);
  const [quizLoader, setQuizLoader] = useState(false);
  const [relatedData, setRelatedData] = useState([]);
  const [reslatedContentLoader, setRelatedContentLoader] = useState(false);
  const [notesData, setNotesData] = useState([]);
  const [type, setType] = useState("notes");
  const divRef = useRef(null);
  const div2Ref = useRef(null);
  const div3Ref = useRef(null);
  const startTime = useRef();
  const endTime = useRef();
  useEffect(() => {
    const id = store?.getStudyTopic?.title?._id;
    const d = new Date();
    startTime.current = d.getTime();
    return () => {
      const dx = new Date();
      endTime.current = dx.getTime();

      const body = {
        practiceTime: endTime.current - startTime.current,
        fileId: id,
      };
      dispatch(practiceTimeTrack(body));
      dispatch(addTotalPracticeTimeForReward());
    };
  }, []);

  useEffect(() => {
    setScreenLoader(true);
    setRelatedContentLoader(true);
    let file_id = store?.getPracticeData?.data?.relatedTopic[0]?.fileid;
    dispatch(getHighlightPractice(file_id)).then((res) => {
    });
    setTimeout(() => {
      dispatch(getPracticeData(file_id)).then((res) => {
        if (res.status === 200) {
          setScreenLoader(false);
          setData(res?.data);
          setTableContentData(store?.getPracticeData?.data?.relatedTopic);
        } else {
          setScreenLoader(false);
          alert("404! server issue");
        }
      });
    }, 1500);
    setTimeout(() => {
      dispatch(getTopicRelatedContent(file_id)).then((res) => {
        setRelatedData(res?.data?.response);
        setRelatedContentLoader(false);
      });
    }, 1500);

    // setData(store?.getPracticeData?.data);
    // setTableContentData(store?.getPracticeData?.data?.relatedTopic);
  }, []);
  useEffect(() => {
    let file_id = store?.getPracticeData?.data?.relatedTopic[0]?.fileid;
    dispatch(getNotes("all", file_id)).then((res) => {
      setNotesData(res?.data?.responseData);
    });
    dispatch(getPracticeTranscripts(file_id));
  }, []);

  function truncate(str, n) {
    return str?.length > n ? str.slice(0, n - 1) + "..." : str;
  }
  let Tags = data?.response?.tags?.split(",").filter((itmd, indxd) => itmd);
  const handleClickRecomend = (item) => {
    setSideLoader(true);

    setOpenSidebar(true);
    dispatch(getTopics(item._id)).then((res) => {
      setSidebarData(res);
      setSideLoader(false);
    });
  };
  const handleStartQuiz = () => {
    setQuizLoader(true);
    let file_id =store?.getPracticeData?.data?.relatedTopic[0]?.fileid;
    dispatch(getDocumentQuiz(file_id)).then((res) => {
      if (res.status === 200) {
        history.push({
          pathname: "/practice/quiz",
          state: { prevPath: "videopractice" },
        });
        setQuizLoader(false);
      } else {
        setQuizLoader(false);
      }
    });
  };
  const handleStatus = (questionPoints, totalQuiz) => {
    if (totalQuiz >= 5 && totalQuiz < 15) {
      return "Medium";
    } else if (totalQuiz >= 15 && totalQuiz < 25) {
      return "Advanced";
    } else if (totalQuiz >= 25) {
      return "Maestro";
    } else if (questionPoints >= 100) {
      return "Medium";
    } else {
      return "Beginner";
    }
  };
  const formatTime = (milliseconds) => {
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor(milliseconds / 1000 / 60 / 60);

    return [
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
    ].join(":");
  };

  const handletrnscriptsClick = (type) => {
    setType(type);
  };
  return (
    <>
      <Header />

      {!screenLoader ? (
        <div
          className="row quiz_mainBox d-flex align-items-cente justify-content-center m-0 "
          style={{ backgroundImage: `url(${quizBgImg})` }}
        >
          <div className="col-12 mt-4">
            <div className="contain mx-auto practice_width_dec  border pt-4 ">
              <div className="row mx-auto">
                <div className="col-12 border_bottom_color">
                  <div className="row mx-auto">
                    <div className="col-12 pb-3 ps-4">
                      <h5 className="m-0 p-0 practice_heading all_pro_family practice_color ">
                        Quiz
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-12 border-bottom">
                  <div className="row mx-auto py-3">
                    <div className="col-7">
                      <div className="row mx-auto">
                        <div className="col-12">
                          <p className="m-0 p-0 quiz_heading all_pro_family practice_color">
                            {data?.response?.title}
                          </p>
                        </div>
                        <div class="col-12 mt-2 ">
                          <div className="d-flex flex-wrap">
                            {Tags?.map((ele, ind) => {
                              return (
                                <button
                                  type="button"
                                  class=" py-1 me-1  border-0 px-2 btn_radius_dec shadow-none task_add_btn text-capitalize practice_btn_color practice_color"
                                >
                                  {ele}
                                </button>
                              );
                            })}
                          </div>
                        </div>
                        <div class="col-12 mt-2 d-flex align-items-center">
                          <button
                            type="button"
                            class="btn  border-0 p-1 shadow-none task_add_btn text-lowercase  d-flex all_pro_family color_change"
                          >
                            <img
                              src={clockQuiz}
                              className="me-2 clock_question"
                              alt="..."
                            />
                            {data?.time} min
                          </button>
                          <button
                            type="button"
                            class="btn border-0 ms-2 p-1 shadow-none text-lowercase task_add_btn   d-flex all_pro_family color_change"
                          >
                            <img
                              src={vectorQuiz}
                              className="me-2 clock_question"
                              alt="..."
                            />
                            {data?.totalQuestions} questions
                          </button>
                        </div>
                        <div className="col-12 mt-3">
                          <p className="p-0 m-0 add_task_long_para all_pro_family practice_color">
                            {truncate(data?.response?.description, 230)}
                          </p>
                        </div>
                        <div className="col-12 mt-4">
                          {!quizLoader ? (
                            <button
                              onClick={() => {
                                handleStartQuiz();
                              }}
                              type="button"
                              class=" border btn btn-light py-2 px-4 shadow-none offcan-study text-capitalize add_task_button all_pro_family practice_color"
                            >
                              Start Quiz
                            </button>
                          ) : (
                            <div>
                              <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="col  notes_width">
                      <div class="row mx-auto form_main">
                        <div class="col-12 ">
                          <div class="row mx-auto ">
                            <div class="col d-flex align-items-center justify-content-start">
                              <h6 class="m-0 activity_text_para_text activity_text_weight all_pro_family practice_color">
                              Key Topics
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 mt-3">
                          <div class="row mx-auto ">
                            <div class="col-11">
                              <div
                                ref={div2Ref}
                                class="row border mx-auto related_task_height"
                              >
                                {tableContentsData.map((itemdx, indexdx) => (
                                  <div className="col-12 border-bottom ">
                                    <div className="row ">
                                      <div className="col-12 related_task_box_height padding_add">
                                        <div className="row">
                                          <div className="col-auto">
                                            <p class="m-0 task_add_law all_pro_family practice_color">
                                              {/* {item?.topic_name} */}
                                              {console.log(store?.getPracticeData?.data?.relatedTopic)}
                                              {itemdx?.topicId?.topic &&
                                                truncate(
                                                  itemdx?.topicId?.topic,
                                                  30
                                                )}
                                              {/* {itemdx.title} */}
                                            </p>
                                          </div>
                                          <div className="col col d-flex align-items-center justify-content-end">
                                            <img
                                              onClick={() => {
                                                setTableContentData(
                                                  tableContentsData.map(
                                                    (itemc, indexc) =>
                                                      itemc._id === itemdx._id
                                                        ? {
                                                            ...itemc,
                                                            expand:
                                                              !itemc.expand,
                                                          }
                                                        : {
                                                            ...itemc,
                                                            expand: false,
                                                          }
                                                  )
                                                );
                                              }}
                                              src={
                                                itemdx.expand
                                                  ? groupQuizColor
                                                  : groupQuiz
                                              }
                                              className="groupQuiz_image"
                                              alt="..."
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {itemdx.expand && (
                                        <>
                                          <div className="col-12  mb-2 ">
                                            <div className="row">
                                              <div className="col">
                                                <p class="m-0 topics_proges_quiz all_pro_family practice_color">
                                                  Topic Progress:
                                                  <b class=" m-0 topics_proges_bold ms-1">
                                                    {handleStatus(
                                                      itemdx?.quizTotalPoint,
                                                      data?.CompleteQuizz
                                                    )}
                                                  </b>
                                                </p>
                                              </div>
                                              <div className="col d-flex align-items-center justify-content-end">
                                                <p className="m-0 topics_proges_quiz all_pro_family practice_color">
                                                  <b class="m-0 topics_proges_bold me-1">
                                                    {itemdx?.quizTotalTime
                                                      ? formatTime(
                                                          itemdx?.quizTotalTime
                                                        )
                                                      : "00:00"}
                                                  </b>
                                                  hours spent
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-12 mb-2 ">
                                            <div class="row mx-auto d-flex align-items-center justify-content-between">
                                              <div class="col topic px-1 topics_color">
                                                <hr />
                                                <p class="color_change m-0 text-center my-1 offcan-medium">
                                                  Beginner
                                                </p>
                                              </div>
                                              <div
                                                class={` ${
                                                  handleStatus(
                                                    itemdx?.quizTotalPoint,
                                                    data?.CompleteQuizz
                                                  ) === "Medium" ||
                                                  handleStatus(
                                                    itemdx?.quizTotalPoint,
                                                    data?.CompleteQuizz
                                                  ) === "Advanced" ||
                                                  handleStatus(
                                                    itemdx?.quizTotalPoint,
                                                    data?.CompleteQuizz
                                                  ) === "Maestro"
                                                    ? "topics_color"
                                                    : "topics_color_fade"
                                                } col topic px-1 topics_color`}
                                              >
                                                <hr />
                                                <p class="color_change m-0 text-center my-1 offcan-medium">
                                                  Medium
                                                </p>
                                              </div>
                                              <div
                                                class={` ${
                                                  handleStatus(
                                                    itemdx?.quizTotalPoint,
                                                    data?.CompleteQuizz
                                                  ) === "Advanced" ||
                                                  handleStatus(
                                                    itemdx?.quizTotalPoint,
                                                    data?.CompleteQuizz
                                                  ) === "Maestro"
                                                    ? "topics_color"
                                                    : "topics_color_fade"
                                                } col topic px-1 topics_color`}
                                              >
                                                <hr />
                                                <p class="color_change m-0 text-center my-1 offcan-medium">
                                                  Advanced
                                                </p>
                                              </div>
                                              <div
                                                class={` ${
                                                  handleStatus(
                                                    itemdx?.quizTotalPoint,
                                                    data?.CompleteQuizz
                                                  ) === "Maestro"
                                                    ? "topics_color"
                                                    : "topics_color_fade"
                                                } col topic px-1 topics_color`}
                                              >
                                                <hr />
                                                <p class="color_change m-0 text-center my-1 offcan-medium">
                                                  Maestro
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div class="col-1 p-0 d-flex align-items-center">
                              <div class="row">
                                <div class="col newton d-flex align-items-center justify-content-center flex-column">
                                  <i
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      div2Ref.current.scrollTop -= 20;
                                      // div2Ref.current.scrollIntoView({
                                      //   behavior: "smooth",
                                      //   block: "start",
                                      //   inline: "nearest",
                                      // });
                                    }}
                                    class="fa fa-angle-up black_arrowIcon rounded-pill bg-dark up_down_icon mb-2 d-flex align-items-center justify-content-center text-white"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    onClick={() => {
                                      div2Ref.current.scrollTop += 20;
                                    }}
                                    style={{ cursor: "pointer" }}
                                    class="fa fa-angle-down black_arrowIcon rounded-pill bg-dark up_down_icon d-flex align-items-center justify-content-center text-white"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-4 border-end width_left_notes me-0 pe-0">
                      <div className="row ">
                        <div className="col-12 pt-4 ps-4  border-bottom height_add_practice_notes">
                          <div className="row notes_highligthDiv mx-auto ps-3">
                            <div
                              className={` ${
                                type === "notes" && "border_bottom"
                              } col-auto px-0 pb-2 text_work me-4`}
                              onClick={() => {
                                handletrnscriptsClick("notes");
                              }}
                            >
                              <p
                                className={`${
                                  type === "notes"
                                    ? "practice_color"
                                    : "unselectedPracticemodel"
                                } m-0 p-0 cp quiz_heading all_pro_family `}
                              >
                                Notes
                              </p>
                            </div>
                            {data?.response?.type === "youtube url" ||
                            data?.response?.type === "video/mp4" ? (
                              <div
                                className={`${
                                  type === "transcripts" && "border_bottom"
                                } col-auto text_work p-0 m-0 pb-2 quiz_heading`}
                                onClick={() => {
                                  handletrnscriptsClick("transcripts");
                                }}
                              >
                                <p
                                  className={`${
                                    type === "transcripts"
                                      ? "practice_color"
                                      : "unselectedPracticemodel"
                                  } m-0 p-0 cp all_pro_family unselectedPracticemodel`}
                                >
                                  Transcripts
                                </p>
                              </div>
                            ) : (
                              <div
                                className={` ${
                                  type === "highlights" && "border_bottom"
                                }   col-auto text_work p-0 m-0 pb-2 quiz_heading`}
                                onClick={() => {
                                  handletrnscriptsClick("highlights");
                                }}
                              >
                                <p
                                  className={`${
                                    type === "highlights"
                                      ? "practice_color"
                                      : "unselectedPracticemodel"
                                  } m-0 p-0 cp all_pro_family  unselectedPracticemodel`}
                                >
                                  Highlights
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row mx-auto">
                            <div className="col pt-3">
                              <div
                                class="row ms-3 notes_heigth mb-2"
                                ref={div3Ref}
                              >
                                {type === "notes" && (
                                  <div class="col-10 large width_notes_box p-0">
                                    {notesData?.map((ele, ind) => {
                                      return (
                                        <div className="row px-3 mb-3 background_notes_color border py-2 my-1 mx-auto">
                                          <div className="col-12 p-0 ">
                                            <p class="m-0 notes_text_para all_pro_family practice_color break_word_notes">
                                              {truncate(ele?.notes, 175)}
                                            </p>
                                          </div>
                                          {/* <div className="col-auto ms-auto p-0">
                                            <img
                                              className="three_dots cp"
                                              src={threeDotsHorizontal}
                                              alt=""
                                            />
                                          </div> */}
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                                {type === "transcripts" && (
                                  <div class="col-10 large width_notes_box p-0 ">
                                    {store?.getPracticeTranscripts?.data?.response?.map(
                                      (ele, ind) => {
                                        return (
                                          <div className="row px-3 mb-3 background_notes_color border py-2 my-1 mx-auto">
                                            <div className="col-12 p-0 ">
                                              <p class="m-0 notes_text_para all_pro_family practice_color break_word_notes">
                                                {truncate(ele?.transcript, 135)}
                                              </p>
                                            </div>
                                            {/* <div className="col-auto ms-auto p-0">
                                          <img
                                            className="three_dots cp"
                                            src={threeDotsHorizontal}
                                            alt=""
                                          />
                                        </div> */}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                                {type === "highlights" && (
                                  <div class="col-10 large width_notes_box p-0 ">
                                    {store?.getHighlightPractice?.data?.response?.map(
                                      (ele, ind) => {
                                        return (
                                          <div className="row px-3 mb-3 background_notes_color border py-2 my-1 mx-auto">
                                            <div className="col-12 p-0 ">
                                              <p class="m-0 notes_text_para all_pro_family practice_color break_word_notes">
                                                {truncate(ele, 135)}
                                              </p>
                                            </div>
                                            {/* <div className="col-auto ms-auto p-0">
                                          <img
                                            className="three_dots cp"
                                            src={threeDotsHorizontal}
                                            alt=""
                                          />
                                        </div> */}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div class="col-auto p-0 d-flex align-items-center">
                              <div class="row mx-1">
                                <div class="col newton d-flex align-items-center justify-content-center flex-column">
                                  <i
                                    onClick={() => {
                                      div3Ref.current.scrollTop -= 20;
                                    }}
                                    class="fa fa-angle-up cp black_arrowIcon rounded-pill bg-dark up_down_icon mb-2 d-flex align-items-center justify-content-center text-white"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    onClick={() => {
                                      div3Ref.current.scrollTop += 20;
                                    }}
                                    class="fa fa-angle-down cp black_arrowIcon rounded-pill bg-dark up_down_icon d-flex align-items-center justify-content-center text-white"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-8 width_right_carsoual">
                      <div className="row">
                        <div className="col-12">
                          <div class="row ">
                            <div class="col-12 border-bottom pt-4 pb-2 px-5 height_add_practice_notes">
                              <div class="row mx-auto px-1">
                                <div class="col d-flex pb-1 align-items-center justify-content-start">
                                  <p className="m-0 p-0 quiz_heading all_pro_family practice_color font_weight_content">
                                    Explore Related Content
                                  </p>
                                </div>
                                <div class="col d-flex justify-content-end align-items-center pe-0">
                                  <i
                                    onClick={() => {
                                      divRef.current.scrollLeft -= 200;
                                    }}
                                    class="fa cp fa-angle-left black_arrowIcon rounded-pill bg-dark up_down_icon mb-2 d-flex align-items-center justify-content-center text-white me-2"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    onClick={() => {
                                      divRef.current.scrollLeft += 200;
                                    }}
                                    class="fa cp fa-angle-right black_arrowIcon rounded-pill bg-dark up_down_icon mb-2 d-flex align-items-center justify-content-center text-white"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 mt-4">
                              <div className="row mx-auto">
                                <div className="col-12 px-5">
                                  <p className=" p-0 px-1 m-0 related_practice_para">                                    
                                  Related content for <strong>{data?.response?.tags ? data?.response?.tags : data?.response?.title}</strong>;  Discover materials that complement your current topic.

                                  </p>
                                </div>
                              </div>
                            </div>
                            {reslatedContentLoader ? (
                              <Skeleton height={`3vh`} count={7} />
                            ) : (
                              <div className="col-12  mt-3 ">
                                <div
                                  ref={(ref) => (divRef.current = ref)}
                                  className="row carousel_Container flex-nowrap overflow-auto"
                                >
                                  {relatedData?.map((item, ind) => {
                                    const formatTime = (milliseconds) => {
                                      const minutes = Math.floor(
                                        (milliseconds / 1000 / 60) % 60
                                      );
                                      const hours = Math.floor(
                                        milliseconds / 1000 / 60 / 60
                                      );

                                      return [
                                        hours.toString().padStart(2, "0"),
                                        minutes.toString().padStart(2, "0"),
                                      ].join(":");
                                    };
                                    return (
                                      <div
                                        className="col-5 ps-0 quiz_width_carousel 
                                  "
                                      >
                                        <div className="row mx-auto">
                                          <div className="col-12">
                                            <div className="row border overflow-hidden mb-1">
                                              <div className="col-6 position-relative">
                                                <div className="row">
                                                  <div className="col-12 p-0">
                                                    <img
                                                      src={item?.thumbnail}
                                                      className=" secondLakhni"
                                                      alt="..."
                                                    />
                                                  </div>
                                                  <div className="col-12 p-0 ps-2 position-absolute bottom-0 mb-1">
                                                    {item?.category
                                                      ?.category_name && (
                                                      <button
                                                        className="btn text-white videoDoc_btn text-capitalize shadow-none rounded-pill  d-flex align-items-center justify-content-center"
                                                        style={{
                                                          cursor: "pointer",
                                                          backgroundColor: `${item?.category?.category_color}`,
                                                        }}
                                                      >
                                                        {
                                                          item?.category
                                                            ?.category_name
                                                        }
                                                      </button>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-6 custom_padding  d-flex">
                                                <div className="row">
                                                  <div className="col-12 mb-auto  d-flex flex-column justify-content-center">
                                                    <h6 className="card-data  m-0 d-flex align-items-start">
                                                      {truncate(
                                                        item?.title,
                                                        20
                                                      )}
                                                    </h6>
                                                  </div>

                                                  <div className="col-12 mt-auto">
                                                    <p className="card-texts mb-0 text-nowrap">
                                                      {item.studyPageTimeTrack
                                                        ? formatTime(
                                                            item.studyPageTimeTrack
                                                          )
                                                        : "0:00"}{" "}
                                                      spent · Beginner
                                                    </p>
                                                    <div class=" mt-2 topic">
                                                      <div class="progress progress_parent">
                                                        <div
                                                          class="progress-bar progress_bar"
                                                          role="progressbar"
                                                          aria-label="Basic example"
                                                          aria-valuenow="25"
                                                          aria-valuemin="0"
                                                          aria-valuemax="100"
                                                          style={{
                                                            width: `${
                                                              item?.progressBarPercentage
                                                                ? item?.progressBarPercentage
                                                                : "0%"
                                                            }`,
                                                          }}
                                                        ></div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="col-12 mt-auto"
                                                    onClick={() => {
                                                      handleClickRecomend(item);
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      className="row d-flex align-items-center justify-content-start"
                                                    >
                                                      <div
                                                        className="col-auto"
                                                        style={{
                                                          marginRight: "-5px",
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          className="card-data m-0 d-flex align-items-center"
                                                        >
                                                          Learn
                                                        </p>
                                                      </div>
                                                      <div
                                                        className="col-auto p-0 d-flex align-items-center justify-content-center rounded-pill"
                                                        // style={{
                                                        //   width: "16px",
                                                        //   height: "16px",
                                                        //   backgroundColor:
                                                        //     "#4E45C2",
                                                        // }}
                                                      >
                                                        <img
                                                          alt=""
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          className="learn_arrowIcon"
                                                          src={learn_button}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Skeleton height={`3vh`} count={30} />
      )}
      <SidebarCanvas
        show={openSidebar}
        setShow={setOpenSidebar}
        sideLoader={sideLoader}
        data={sidebarData}
      />
    </>
  );
};

export default VideoPractice;

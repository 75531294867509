import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./BrowseContent.css";
import {
  NewPlaylistAvtar,
  NewPlaylistActive,
  NonActive,
  learn_button,
  searchClose,
} from "../../../../staticContent/image";
import { useDispatch, useSelector } from "react-redux";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { filterContent } from "../../../../store/actions/libraryOverview";
import { getTopics } from "../../../../store/actions/study";
import { useHistory } from "react-router-dom";
import SidebarCanvas from "../CanvasSidebar/sidebarCanvas";
import { searchCourseAll } from "../../../../store/actions/libraryOverview";
import Skeleton from "react-loading-skeleton";

const categoryArry = [];

const moment = extendMoment(originalMoment);

const BrowseCourse = (props) => {
  const location = useLocation();
  const [sidebarData, setSidebarData] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [isListView, setIsListView] = useState(false);
  const [typeCategory, setTypeCategory] = useState(store?.categaryList?.data);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [sidebarLoader, setSideLoader] = useState(false);
  const [value, setBrowseValue] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const handleChange = (e) => {
    const { id } = e.target;
    let index = categoryArry.indexOf(id);
    if (index !== -1) {
      categoryArry.splice(index, 1);
    } else {
      categoryArry.push(id);
    }
    if (categoryArry.length < 1) {
      setFilteredData(store.searchedCourseList?.data);
    } else {
      const title = location?.state?.title;
      const body = {
        title: title,
        user_id: store.auth?.user?.id,
        category: categoryArry,
      };
      setLoader(true);
      dispatch(filterContent(body)).then((data) => {
        setLoader(false);
        setFilteredData(data);
      });
    }

    setTypeCategory(
      typeCategory.map((item, index) => {
        if (e.target.id === item._id) {
          return { ...item, isChecked: !item.isChecked };
        } else {
          return item;
        }
      })
    );
  };
  function truncate(str, n) {
    return str?.length > n ? str.slice(0, n - 1) + "...." : str;
  }

  useEffect(() => {
    setFilteredData(store.searchedCourseList?.data);
    setBrowseValue(location?.state?.title);
  }, [store]);

  const goToStudyPage = (item) => {
    setSideLoader(true);
    dispatch(getTopics(item._id)).then((res) => {
      //  setOpenSidebar(true);
      setSidebarData(res);
      setSideLoader(false);
    });
    setOpenSidebar(true);
    setSidebarData(item);
  };

  const searchFilterInput = (e) => {
    let value = e.target.value;
    if (value?.trim() === "") {
      history.push(`/library/overview`);
    }
    setBrowseValue(e.target.value);
  };

  var tags = [];
  if (filteredData) {
    filteredData.forEach((element) => {
      if (element.course_Name) {
        if (!tags.includes("course")) {
          tags.push("course");
        }
      } else {
        if (!tags.includes("topics")) {
          tags.push("topics");
        }
      }
    });
  }
  const searchCourseAlls = (item, type) => {
    const userId = store.auth?.user?.id;
    dispatch(
      searchCourseAll(item, type === "course" ? "topic" : "course", userId)
    );
  };
  const formatTime = (milliseconds) => {
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor(milliseconds / 1000 / 60 / 60);

    return [
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
    ].join(":");
  };
  const handleCrossButton = () => {
    history.push("/library/overview");
  };
  return (
    <div className="main-body">
      <div className="">
        <div className="row">
          <div className="col ms-4">
            <div className="row">
              <div className="col-md-12">
                <h2 className="mt-5 browse-overview">Browse</h2>
              </div>
              <div className="col-12 my-3">
                <div className="row">
                  <div className="col-md-9">
                    <div className="col-md-12 col-6 mb-md-0 mb-3 grid-margin stretch-card">
                      <div className="row mb-4 ">
                        <div className="col-12">
                          <div className="row border overflow-hidden bg-light rounded-7 mx-auto">
                            <div className="col-12 d-flex align-items-center">
                              <i className="fa fa-search ms-2"></i>
                              <input
                                type=""
                                onChange={(e) => {
                                  searchFilterInput(e);
                                }}
                                value={value}
                                className="border-0 flex-grow-1 p-2 outline-div outline-none bg-transparent"
                              ></input>
                              {value && (
                                <img
                                  onClick={handleCrossButton}
                                  src={searchClose}
                                  alt={""}
                                  className="cp"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col">
                          {tags &&
                            tags.map((item, index) => {
                              return (
                                <button
                                  type="button"
                                  className="btn btn-light offcan-btns-three shadow-none py-2 me-2"
                                >
                                  {item}{" "}
                                  <span
                                    aria-hidden="true"
                                    onClick={() => {
                                      searchCourseAlls(
                                        location?.state?.title,
                                        item
                                      );
                                    }}
                                  >
                                    &times;
                                  </span>
                                </button>
                              );
                            })}
                        </div>
                        <div className="col p-0 d-flex align-items-center justify-content-end">
                          <div className="row">
                            <div className="col-auto d-flex align-items-center">
                              <p className="m-0 browse-sort by ">
                                Sort by
                                <span className="browse-short ms-1">
                                  Revelance
                                </span>
                                <i
                                  className="fa fa-angle-down ms-2"
                                  aria-hidden="true"
                                ></i>
                              </p>
                            </div>
                            {/* <div className="col-auto">
                            {props.selectedSearchType}
                          </div> */}
                            <div className="col-auto d-flex justify-content-end ">
                              <div className="col-md-12 d-flex align-items-center justify-content-end">
                                <img
                                  onClick={() => setIsListView(false)}
                                  src={NewPlaylistActive}
                                  className=" me-2 browsecourse-lines-image"
                                  alt=""
                                />
                                <img
                                  onClick={() => setIsListView(true)}
                                  src={NonActive}
                                  className="me-3 browsecourse-lines-image"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*  */}
                    {loader ? (
                      <Skeleton count={50} />
                    ) : (
                      <>
                        {isListView ? (
                          <>
                            <div className="col-12">
                              <div className="row mx-auto browsercourse-first-title my-2 py-2 align-items-center">
                                <div className="col-1">
                                  <p className="m-0">#</p>
                                </div>
                                <div className="col-4">
                                  <p className="m-0">Title</p>
                                </div>
                                <div className="col-1">
                                  <p className="m-0">Category</p>
                                </div>
                                <div className="col-1">
                                  <p className="m-0">Type</p>
                                </div>
                                <div className="col-3">
                                  <p className="m-0">Tags</p>
                                </div>
                                <div className="col-2  d-flex align-items-center justify-content-end">
                                  <p className="m-0">Date Added</p>
                                </div>
                              </div>
                            </div>

                            {filteredData?.length > 0
                              ? filteredData.map((item, index) => {
                                  let Tags = item?.tags?.split(",");
                                  return (
                                    <div
                                      className="col-12"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <div
                                        onClick={() => {
                                          goToStudyPage(item);
                                        }}
                                        className="row mx-auto border title py-2 my-2 browsecourse-title-data align-items-center"
                                      >
                                        <div className="col-1">
                                          <p className="m-0">{index + 1}</p>
                                        </div>
                                        <div className="col-4">
                                          <div className="row">
                                            <div className="col-2 avater">
                                              <img
                                                className="Browse-list-image"
                                                src={
                                                  item.thumbnail
                                                    ? item.thumbnail
                                                    : NewPlaylistAvtar
                                                }
                                                alt="..."
                                              />
                                            </div>
                                            <div className="col my-auto">
                                              <h6 className="m-0 browsecourse-title">
                                                {truncate(item.title, 30)}
                                              </h6>
                                              {/* <p className="m-0">
                                          Yuval Noah Harari
                                        </p> */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-1">
                                          <p className="m-0">
                                            {item?.category?.category_name
                                              ? item?.category?.category_name
                                              : ""}
                                          </p>
                                        </div>
                                        <div className="col-1">
                                          <p className="m-0">
                                            {item.course_Name
                                              ? item.course_Name
                                              : ""}
                                          </p>
                                        </div>
                                        <div className="col-3 d-flex flex-wrap">
                                          {Tags?.map((ele, ind) => {
                                            return (
                                              <>
                                                <div
                                                  type="button"
                                                  className="me-2 mb-1 browsercourse-title-data px-3 py-2 bg-light rounded-2"
                                                >
                                                  {ele}
                                                </div>
                                              </>
                                            );
                                          })}
                                        </div>
                                        <div className="col-2 d-flex align-items-center justify-content-end">
                                          <p className="m-0">
                                            {moment(item.createdAt).format(
                                              "ll"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              : ""}
                          </>
                        ) : (
                          <div className="gridview-container ">
                            {/* <h6 className="mb-2 ms-2 font-weight-bold">
                              All Items
                            </h6> */}
                            <div className="row mt-4">
                              {filteredData?.length > 0
                                ? filteredData?.map((item, index) => {
                                    return (
                                      <div className="col-4 mb-3 ">
                                        <div
                                          onClick={() => {
                                            goToStudyPage(item);
                                          }}
                                          className="border overflow-hidden card_borderRadius bg-white"
                                          style={{
                                            postion: "relative",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <div className="row height mx-auto overflow-hidden  bg-white">
                                            <div className="col-6 height position-relative p-0">
                                              <img
                                                src={
                                                  item.thumbnail
                                                    ? item.thumbnail
                                                    : NewPlaylistAvtar
                                                }
                                                className="width_add height"
                                                alt="..."
                                              />
                                              {item?.category
                                                ?.category_name && (
                                                <div className="col-12 p-0 ps-2 position-absolute bottom-0 mb-1">
                                                  {item?.category
                                                    ?.category_name && (
                                                    <button
                                                      //  onClick={handleShow}
                                                      className="btn text-white videoDoc_btn text-capitalize shadow-none rounded-pill  d-flex align-items-center justify-content-center"
                                                      style={{
                                                        cursor: "pointer",
                                                        backgroundColor: `${item?.category?.category_color}`,
                                                      }}
                                                    >
                                                      {
                                                        item?.category
                                                          ?.category_name
                                                      }
                                                    </button>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                            <div className="col-6 height custom_padding d-flex">
                                              <div className="row">
                                                <div className="col-12 mb-auto  d-flex flex-column justify-content-center">
                                                  <h3 className="card-data m-0 font-weight-bold text-start">
                                                    {truncate(item.title, 30)}
                                                  </h3>

                                                  {/* <p className="card-texts mb-2">Yuval Noah Harari</p> */}
                                                </div>
                                                <div className="col-12 mt-auto ">
                                                  <p className="card-texts-min mb-0 text-start">
                                                    {item.studyPageTimeTrack
                                                      ? formatTime(
                                                          item.studyPageTimeTrack
                                                        )
                                                      : "0:00"}{" "}
                                                    spent · Beginner
                                                  </p>

                                                  <div class=" mt-2 topic">
                                                    <div class="progress progress_parent">
                                                      <div
                                                        class="progress-bar progress_bar"
                                                        role="progressbar"
                                                        aria-label="Basic example"
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        style={{
                                                          width: `${
                                                            item?.progressBarPercentage
                                                              ? item?.progressBarPercentage
                                                              : "0%"
                                                          }`,
                                                        }}
                                                      ></div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-12 mt-auto">
                                                  <div className="row d-flex align-items-center justify-content-start">
                                                    <div
                                                      className="col-auto"
                                                      style={{
                                                        marginRight: "-5px",
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className="card-data m-0 d-flex align-items-center"
                                                        // onClick={() => {
                                                        //   handleClickRecomend(
                                                        //     item
                                                        //   );
                                                        // }}
                                                      >
                                                        Learn
                                                      </p>
                                                    </div>
                                                    <div
                                                      className="col-auto p-0 d-flex align-items-center justify-content-center rounded-pill"
                                                      // style={{
                                                      //   width: "16px",
                                                      //   height: "16px",
                                                      //   backgroundColor:
                                                      //     "#4E45C2",
                                                      // }}
                                                    >
                                                      <img
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        // onClick={() => {
                                                        //   handleClickRecomend(
                                                        //     item
                                                        //   );
                                                        // }}
                                                        alt=""
                                                        className="learn_arrowIcon"
                                                        src={learn_button}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : ""}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="col-3 ms-auto">
                    <div className="row mx-auto">
                      <div className="col-7 ms-auto me-4">
                        <div className="border-bottom">
                          <h3 className="browsecourse-filter pb-2">Filters</h3>
                        </div>
                        <div>
                          <h3 className="my-4 browsecourse-filter">Types</h3>
                        </div>
                        {typeCategory?.map((item, index) => {
                          return (
                            <div
                              className="d-flex my-2 align-items-center"
                              key={index}
                            >
                              <div className="col-9 d-flex align-items-center">
                                {/* <img
                                  src={'../../../../staticContent/image/'+item.category_logo}
                                  className="filter-img"
                                  alt=""
                                /> */}
                                <img
                                  className="filter-img"
                                  alt=""
                                  src={
                                    require(`../../../../staticContent/img/${item.category_name}.svg`)
                                      .default
                                  }
                                />

                                <p className="ms-2 m-0 browsercourse-category">
                                  {item.category_name}
                                </p>
                              </div>
                              <div className="col-3 d-flex justify-content-end">
                                {item.isChecked ? (
                                  <div className="browsercourse-text d-flex align-items-center justify-content-center ">
                                    <i
                                      style={{ cursor: "pointer" }}
                                      id={item._id}
                                      onClick={(e) => {
                                        handleChange(e);
                                      }}
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                ) : (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    id={item._id}
                                    onClick={(e) => {
                                      handleChange(e);
                                    }}
                                    className="browsercourse-text d-flex align-items-center justify-content-center "
                                  ></div>
                                )}

                                {/* <div className="tick">
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    ></i>
                                  </div> */}
                                {/* <input
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    id={item.id}
                                    className="largerCheckbox"
                                    type="checkbox"
                                    value={item.id}
                                    name={item.category_name}
                                  /> */}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SidebarCanvas
        show={openSidebar}
        setShow={setOpenSidebar}
        data={sidebarData}
        sideLoader={sidebarLoader}
      />
    </div>
  );
};

export default BrowseCourse;

import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import AnonymousRoute from "../../components/accessControl/AnonymousRoute";
import ProtectedRoute from "../../components/accessControl/ProtectedRoute";
import SignIn from "../Auth/SignIn";
import SignUp from "../Auth/SignUp";
import VerifyEmail from "../Auth/VerifyEmail";
import { tryLocalSignIn } from "../../store/actions";
import RequestVerificationEmail from "../Auth/RequestVerificationEmail";
import RequestPasswordReset from "../Auth/RequestPasswordReset";
import ResetPassword from "../Auth/ResetPassword";
import { getIsSignedIn } from "../../store/selectors";
import Onboarding from "../Onboarding/Onboarding";
import MainLayout from "../../layouts/MainLayout/Mainlayout";
import OrganizeMain from "../../layouts/OrganizeMainLayout/OrganizeMain";
// import Study from "../Study/study";
import FocusMode from "../Study/focusmode/focusmode";
import Practice from "../Practice/Practice";
import Profile from "../Profile/Profile/Profile";
import Settings from "../Profile/Settings/Settings";
// import Overview from "../Librarypage/Overview/Overview";
// import Organize from "../Organize/Organize";
import practiceLayout from "../../layouts/PracticeLayout/practiceLayout";
import QuestionUpload from "../Admin-Upload/questionUpload";
import TopicUpload from "../Admin-Upload/topicUpload";
import Studyy from "../Study/study-new";
import StaticTable from "../Admin-Upload/staticTable";

class App extends React.Component {
  componentDidMount() {
    this.props.tryLocalSignIn();
  }

  getScreen() {
    return <Redirect to={`/select-profile/2`} />;
  }
  render() {
    return (
      
      <Switch>
        <AnonymousRoute exact path="/signin" component={SignIn} />
        <AnonymousRoute exact path="/signup" component={SignUp} />
        <AnonymousRoute
          exact
          path="/verify-email/:token"
          component={VerifyEmail}
        />
        <AnonymousRoute
          exact
          path="/request-verification-email"
          component={RequestVerificationEmail}
        />
        <AnonymousRoute
          exact
          path="/request-password-reset"
          component={RequestPasswordReset}
        />
        <AnonymousRoute
          exact
          path="/reset-password/:token"
          component={ResetPassword}
        />
        <ProtectedRoute exact path="/profile" component={Profile} />
        <ProtectedRoute exact path="/settings" component={Settings} />
        <ProtectedRoute exact path="/study/focusmode" component={FocusMode} />
        {/* <ProtectedRoute exact path="/" component={Overview} /> */}
        {/* protected Routing */}
        <ProtectedRoute path="/select-profile/:id" component={Onboarding} />
        <ProtectedRoute path="/library" component={MainLayout} />
        {/* <ProtectedRoute exact path="/study" component={Study} /> */}
        <ProtectedRoute path="/organize" component={OrganizeMain} />
        <ProtectedRoute   path="/practice/overview" component={practiceLayout} />
        <ProtectedRoute path="/practice" component={Practice} />
        <ProtectedRoute path="/admin-question-upload" component={QuestionUpload} />
        <ProtectedRoute path="/admin-topic-upload" component={TopicUpload} />
        <ProtectedRoute path = "/admin-static-topic-data-upload" component={StaticTable}/>
        <ProtectedRoute  path="/study" component={Studyy} />
        <Route exact path="/">
          {this.props.isSignedIn ? this.getScreen() : <Redirect to="/signin" />}
        </Route>
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: getIsSignedIn(state),
    state,
  };
};

export default connect(mapStateToProps, { tryLocalSignIn })(App);

import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { signIn, unloadAuthPage } from "../../store/actions";
import { clearStore } from "../../store/actions/getUserData";
import { getProcessing, getError } from "../../store/selectors";
import GoogleLoginOne from "./../../components/SocailLogin/googleLoginOne";
// import FacebookLoginOne from "./../../components/SocailLogin/facebookLoginOne";
import "./signin.css";
import {
  SelectProfileX32,
  SelectProfileX33,
  LogoWithName,
  // SignInEye,
} from "../../staticContent/image";
import validator from "validator";
import { getrecommendContent } from "../../store/actions/libraryOverview";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      email: "",
      password: "",
      errors: {
        email: { status: false, message: "" },
        password: { status: false, message: "" },
      },
      passwordShow: false,
    };
  }


  handleGoogleLoginError = (error) => {
    this.setState({ errorMessage: error });
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ ...this.state, [name]: value.trim() });
  };

  // On submit function start //

  onSubmit = () => {
    this.props.getrecommendContent("");

    this.setState({
      ...this.state,
      errors: { ...this.state.errors, password: { status: true, message: "" } },
    });
    if (this.validate()) {
      this.setState({ ...this.state, loader: true });
      return this.props
        .signIn({
          email: this.state.email,
          password: this.state.password,
        })
        .then(() => {
          if (this.props.errorMessage) {
            this.setState({
              ...this.state,
              loader: false,
              errors: {
                ...this.state.errors,
                password: { status: true, message: this.props.errorMessage },
              },
            });
          }
        });
    }
  };

  // On submit function end //

  // signIn Field validation start //
  validate = () => {
    let validateStatus = true;
    if (this.state.email === "") {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          email: { status: true, message: "Please enter your email" },
        },
      });
      validateStatus = false;
    } else if (validator.isEmail(this.state.email) === false) {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          email: { status: true, message: "" },
          password: { status: true, message: "Please enter a valid email" },
        },
      });
      validateStatus = false;
    } else if (this.state.password === "") {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          email: { status: true, message: "" },
          password: { status: true, message: "Please enter your password" },
        },
      });
      validateStatus = false;
    }

    return validateStatus;
  };

  // SignIn Field validation end //

  componentWillUnmount() {
    this.props.unloadAuthPage();
  }

  render() {
    const token = localStorage.getItem("authInfo");
    let checkAuth = false;
    if (token) {
      checkAuth = true;
    }
    return (
      <>
        {
          token ? (
            <div> </div>
          ) : (
            // <div className="background-color">
            <div className="container-fluid heigth_100vh">
              <div className="row mx-auto custom_heigth">
                <div className="col-12 p-0 custom_heigth">
                  <div className="row mx-auto custom_heigth">
                    <div className="col-12 d-flex align-items-center justify-content-center">
                      <img src={LogoWithName} alt="" />
                    </div>
                    <div className="col-12">
                      <div className="row mx-auto">
                        {/* mx-auto image-data my-auto */}
                        <div className="col-4 d-flex justify-content-center">
                          <div className="signIn_img mt-5">
                            <img src={SelectProfileX33} alt="" />
                          </div>
                        </div>
                        <div className="col-4">
                          {/* <div className="three d-flex align-items-center justify-content-center"></div> */}
                          <div className=" text-center">
                            <h1 className="text-dark account">Sign In</h1>
                            <p className="m-0 p-0 signs_platform mb-3">
                              Just sign in if you have an account in here.
                              <br />
                              Enjoy our platform
                            </p>
                          </div>

                          <div className="row mx-auto">
                            {/* Facebook & google login start */}

                              {/* <FacebookLoginOne /> */}
                              <GoogleLoginOne onGoogleLoginError={this.handleGoogleLoginError} />
                              {this.state.errorMessage && <p className="text-danger mt-2 p-0">{this.state.errorMessage}</p>}

                            {/* Facbook & google login end */}
                          </div>
                          <div className="row 3 mb-2 mt-4 d-flex justify-content-center align-items-center">
                            <div className="col text-center">
                              <p className="m-0 outline_div font_family d-flex align-items-center text-dark justify-content-center">
                                <span className="me-2 "></span>
                                OR
                                <span className="ms-2"></span>
                              </p>
                            </div>
                          </div>
                          <div className="row mx-auto d-flex justify-content-center align-items-center">
                            <label
                              for="email"
                              className=" mb-2 label_text font_family p-0"
                            >
                              {/* <b className="enjoy">Email</b> */}
                              Email
                            </label>

                            <div className="inputEyes_div d-flex align-items-center justify-content-center p-0 overflow-hidden">
                              <input
                                value={this.state.email}
                                onChange={(e) => {
                                  this.onChange(e);
                                }}
                                type="text"
                                placeholder="Type your email"
                                name="email"
                                className="p-3 border-0 "
                              />
                            </div>
                            {this.state.errors.email.message ? (
                              <p className="text-danger mt-2 p-0">
                                {this.state.errors.email.message}
                              </p>
                            ) : null}

                            <label
                              for="psw"
                              className="mb-2 font_family label_text p-0"
                            >
                              {/* <b className="enjoy ">Password</b> */}
                              Password
                            </label>
                            <div className="inputEyes_div position-relative d-flex align-items-center justify-content-center p-0 overflow-hidden">
                              <input
                                value={this.state.password}
                                onChange={(e) => {
                                  this.onChange(e);
                                }}
                                type={
                                  this.state.passwordShow ? "text" : "password"
                                }
                                placeholder="Type your password"
                                name="password"
                                className="p-3 border-0"
                              />
                              <div className="position-absolute end-0">
                                <i
                                  className={
                                    this.state.passwordShow
                                      ? "fa fa-eye eyes_Icon text-black me-2"
                                      : "fa fa-eye-slash eyes_Icon stag me-2"
                                  }
                                  aria-hidden="true"
                                  onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      passwordShow: !this.state.passwordShow,
                                    });
                                  }}
                                ></i>
                              </div>
                            </div>
                            {this.state.errors.password.message ? (
                              <p className="text-danger mt-2 p-0">
                                {this.state.errors.password.message}
                              </p>
                            ) : null}
                            <label htmlFor="psw-repeat" className="m-0 p-0">
                              <b className="confirm">Forgot Password ?</b>
                            </label>
                          </div>

                          {this.state.loader === false ? (
                            <div className="clearfix border-0 mt-3">
                              <button
                                onClick={() => {
                                  this.onSubmit();
                                  this.props.clearStore();
                                }}
                                type="button"
                                className="web_button"
                              >
                                Sign In
                              </button>
                            </div>
                          ) : (
                            <div className="clearfix border-0 mt-3">
                              <button type="button" className="web_button">
                                <div className="spinner-border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </button>
                            </div>
                          )}

                          <div className="trial mt-3 text-center">
                            <p className=" text p-0 m-0 text-dark confirm">
                              <span className="dontHave_txt font_family">
                                Don't have an account yet?
                              </span>
                            </p>
                            <Link to="/signup">
                              {/* <b> */}
                              <p className="m-0 p-0 signIn_trial font_family">
                                Sign up to start your trial
                              </p>
                              {/* </b> */}
                            </Link>
                          </div>
                        </div>
                        {/* mx-auto image-data my-auto */}
                        <div className="col-4 d-flex justify-content-center">
                          <div className="signIn_img  d-flex justify-content-center mt-5">
                            <img src={SelectProfileX32} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )

          // </div>
        }
      </>
    );
  }
}

// Send data to store via by maptStateToProps start //
const maptStateToProps = (state) => {
  return {
    isProcessing: getProcessing(state),
    errorMessage: getError(state),
  };
};

export default compose(
  connect(maptStateToProps, {
    signIn,
    unloadAuthPage,
    getrecommendContent,
    clearStore,
  })(SignIn)
);

import React, { useState, useRef, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  AvatarCardM2,
  AvatarCardM3,
  Educationvideo,
  threeDotsHorizontal,
  plus,
  DefaultImg,
  learn_button,
} from "../../../../staticContent/image";
import "../Overview.css";
import { useHistory } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactPlayer from "react-player";
import Skeleton from "react-loading-skeleton";
import { useSelector, useDispatch } from "react-redux";
import { getSubTopics } from "../../../../store/actions/study";
import { getPracticeData } from "../../../../store/actions/practice";
import { getContentFromLibrary } from "../../../../store/actions/studyBuddy";
import { getPlaylist } from "../../../../store/actions/libraryPlaylist";
import Practice from "../../../Practice/Practice";
import { AddToPlaylist } from "../../../../store/actions/libraryPlaylist";
import { Store } from "react-notifications-component";
import {  getPracticeTranscripts } from "../../../../store/actions/practice"
import {
  updatePlayTimeStamp,
  getTopics,
} from "../../../../store/actions/study";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1024 },
    items: 1,
    paritialVisibilityGutter: 60,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    paritialVisibilityGutter: 60,
  },
};
const SidebarCanvas = (props) => {
  const [showdrop, setShowDrop] = useState(false);
  const [selectedLearn, setSelectedLearn] = useState();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const history = useHistory();
  const [showDown, setShowDown] = useState(false);
  const [loader, setLoader] = useState(false);
  const [addPLaylistLoader, setAddPlayListLoader] = useState(false);
  const [practiceLoader, setPracticeLoader] = useState(false);
  const [selectedTimestamp, setSelectedTimeStamp] = useState();
  const { show, setShow, data, sideLoader } = props;

  var showType = "";
  if (data?.title) {
    var docType = data?.title?.type;

    if (docType === "youtube url") {
      var urlVid = "";
      showType = "video";
      urlVid = data?.title?.s3Key;
      //  youtubeVid = youtubeVid.split('=')[1];
    } else if (
      docType === "video/mp4" ||
      docType === "video/mov" ||
      docType === "video/wmv" ||
      docType === "video/avi" ||
      docType === "video/mvk" ||
      docType === "video/html5"
    ) {
      showType = "video";
      urlVid = "https://seekh-local.s3.amazonaws.com/" + data?.title?.s3Key;
    } else {
      showType = "doc";
    }
  }
  // study button side bar function start
  const goToStudyPage = () => {
    setLoader(true);
    if(showType === "video"){
      dispatch(getPracticeTranscripts(store?.getStudyTopic?.title?._id))
      setTimeout(() => {
        history.push({
          pathname: "/study",
        });
        setLoader(false);
      }, 1000);
    }
    else{
      setTimeout(() => {
        history.push({
          pathname: "/study",
        });
        setLoader(false);
      }, 1000);
    }
  };
  // study button sidebar function end

  const scrollSidebar = useRef(null);

  function truncate(str, n) {
    return str?.length > n ? str.slice(0, n - 1) + "...." : str;
  }

  const formatTime = (milliseconds) => {
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor(milliseconds / 1000 / 60 / 60);

    return [
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
    ].join(":");
  };
  useEffect(() => {
    setSelectedLearn();
    setSelectedTimeStamp();
    dispatch(getPlaylist(store.auth?.user?.id));
  }, []);

  const hanldeslice = (text) => {
    return text.slice(0, 15) + "...";
  };
  const handlePlayListClick = (item) => {
    setAddPlayListLoader(true);
    setShowDrop(false);
    let userId = store.auth?.user?.id;
    let playList_Id = item?._id;
    let contentId = store?.getStudyTopic?.response[0]?.fileid;

    let body = {
      userId: userId,
      playListId: playList_Id,
      contentid: contentId,
    };
    dispatch(AddToPlaylist(body)).then((data) => {
      if (data.data.status === 200) {
        showAlert("Added successfully", "success");
        setAddPlayListLoader(false);
      } else {
        showAlert("Content already exist in this playlist", "danger");
        setAddPlayListLoader(false);
      }
    });
  };
  function showAlert(data, type) {
    Store.addNotification({
      // title: "Wonderful!",
      message: data,
      type: type,
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  }
  const handleSidebarLearn = (item) => {
    setSelectedLearn(item);
    dispatch(getTopics(item._id)).then((res) => {
      if (res.status === 200) {
        history.push({
          pathname: "/study",
        });
      }
    });
  };
  const handleStatus = (questionPoints, totalQuiz) => {
    if (totalQuiz >= 5 && totalQuiz < 15) {
      return "Medium";
    } else if (totalQuiz >= 15 && totalQuiz < 25) {
      return "Advanced";
    } else if (totalQuiz >= 25) {
      return "Maestro";
    } else if (questionPoints >= 100) {
      return "Medium";
    } else {
      return "Beginner";
    }
  };
  const getRecommdedContent = (data) => {
    let content = [];
    for (let item of data) {
      content.push(
        <div className="row me-4 ms-auto border overflow-hidden mb-1">
          <div className="col-6 position-relative">
            <div className="row">
              <div className="col-12 p-0">
                
                <img src={item.thumbnail} className=" secondLakhni" alt="..." />
              </div>
              <div className="col-12 p-0 ps-2 position-absolute bottom-0 mb-1">
                {item?.category?.category_name && (
                  <button
                    className="btn text-white videoDoc_btn text-capitalize shadow-none rounded-pill  d-flex align-items-center justify-content-center"
                    style={{
                      cursor: "pointer",
                      backgroundColor: `${item?.category?.category_color}`,
                    }}
                  >
                    {item?.category?.category_name}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="col-6 py-2 d-flex ps-3 pe-2">
            <div className="row">
              <div className="col-12 mb-auto  d-flex flex-column justify-content-center">
                <h6 className="card-data  m-0 d-flex align-items-start">
                  {item.title && truncate(item.title, 20)}
                </h6>
                {item?.authorName && (
                  <p className="card-texts mb-2">{item?.authorName}</p>
                )}
              </div>
              <div className="col-12 my-auto">
                <p className="card-texts m-0">
                  {item.studyPageTimeTrack
                    ? formatTime(item?.studyPageTimeTrack)
                    : "0:00"}{" "}
                  spent · Beginner
                </p>

                {/* <div className="sm_progressBar d-flex mt-2">
                  <div className="col topic pe-1">
                    <hr />
                  </div>
                  <div className="col topicc px-1">
                    <hr />
                  </div>
                  <div className="col topicc px-1">
                    <hr />
                  </div>
                  <div className="col topicc px-1">
                    <hr />
                  </div>
                </div> */}
                <div class=" mt-2 topic">
                  <div class="progress progress_parent">
                    <div
                      class="progress-bar progress_bar"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{
                        width: `${
                          item?.progressBarPercentage
                            ? item?.progressBarPercentage
                            : "0%"
                        }`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-auto">
                {selectedLearn !== item ? (
                  <div
                    className="row d-flex align-items-center justify-content-start"
                    onClick={() => {
                      handleSidebarLearn(item);
                    }}
                  >
                    <div
                      className="col-auto"
                      style={{
                        marginRight: "-5px",
                      }}
                    >
                      <p
                        style={{
                          cursor: "pointer",
                        }}
                        className="card-data m-0 d-flex align-items-center"
                      >
                        Learn
                      </p>
                    </div>
                    <div
                      className="col-auto p-0 d-flex align-items-center justify-content-center rounded-pill"
                      style={{
                        width: "16px",
                        height: "16px",
                        backgroundColor: "#4E45C2",
                      }}
                    >
                      <img
                        style={{
                          cursor: "pointer",
                        }}
                        // onClick={() => {
                        //   handleClickRecomend(
                        //     item
                        //   );
                        // }}
                        alt=""
                        className="w-100"
                        src={learn_button}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="spinner-border me-4" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return content;
  };

  const getTablesContent = (data) => {
    let content = [];
    for (let item of data) {
      let num = item?.topic?.timestamp;
      let div = Math.floor(num / 60);
      let result = num - div * 60;
      content.push(
        <div className="col-12 pb-2 border-bottom mb-3 offcan-accor-time">
          <div className="row">
            <div className="col d-flex align-items-center justify-content-start">
              <i className="fa fa-caret-right me-2" aria-hidden="true"></i>
              <p className="m-0">{item?.topic?.topic_name} </p>
            </div>
            <div className="col d-flex align-items-center justify-content-end">
              <p className="m-0">
                {Math.floor(item?.topic?.timestamp / 60)} : {result}
              </p>
            </div>
          </div>
        </div>
      );
    }

    return content;
  };

  function truncate(str, n) {
    return str?.length > n ? str.slice(0, n - 1) + "...." : str;
  }

  const handlePractice = () => {
    setPracticeLoader(true);
    let file_ID = store?.getStudyTopic?.title?._id;
    dispatch(getPracticeData(file_ID)).then((res) => {
      if (res.data.status == 200) {
        history.push({
          pathname: "/practice",
          state: { prevPath: "videopractice" },
        });
        setPracticeLoader(false);
      } else {
        setPracticeLoader(false);
      }
    });
  };
  const handlePlayButton = (item, ind) => {
    setSelectedTimeStamp(ind);
    const fileId = store?.getStudyTopic?.response[0]?.fileid;
    let body = {
      fileId: fileId,
      timestamp: item?.timestamp ? item?.timestamp : 0,
    };
    dispatch(updatePlayTimeStamp(body)).then((res) => {
      dispatch(getTopics(fileId)).then((data) => {
        if (data.status === 200) {
          setLoader(false);
          history.push({
            pathname: "/study",
          });
        }
      });
    });
  };
  const Tags = data?.title?.tags?.split(",").filter((itmd, indxd) => itmd);
  return (
    //  sidebar data start
    <Offcanvas
      show={show}
      onHide={() => setShow(false)}
      {...props}
      placement={"end"}
    >
      <Offcanvas.Header closeButton></Offcanvas.Header>
      <Offcanvas.Body>
        {sideLoader ? (
          <Skeleton height={`100vh`} count={1} />
        ) : (
          <>
            <div className="row">
              <div className="col-12 p-0">
                <div className="">
                  <div className="">
                    {showType === "video" && (
                      <ReactPlayer
                        className="react-player"
                        url={urlVid}
                        controls={true}
                        width="480"
                        height="280"
                      />
                    )}
                    {showType !== "video" && (
                      <img
                        className="position-relative offcan-image-video"
                        src={
                          data?.title?.thumbnail
                            ? data?.title?.thumbnail
                            : DefaultImg
                        }
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 d-flex justify-content-between align-items-center mt-4">
                  <h5 className="offcan-title m-0">{data?.title?.title}</h5>
                  {/* <img className="" src={data?.title?.thumbnail} alt="" /> */}
                </div>
                <div className="col-12 mt-3 mb-4">
                  <p className="m-0 sidebar_title_data">{data?.title?.title}</p>
                </div>
                <div className="col-12 mb-3">
                  <p className="m-0 offcan-content">
                    {data?.title?.description &&
                      truncate(data?.title?.description, 150)}
                  </p>
                </div>

                {data?.title?.tags && (
                  <div className="col-12 d-flex flex-wrap">
                    {Tags.map((ele, ind) => (
                      <button
                        type="button"
                        className="mb-1 border-0 btn btn-light hover_remove offcan-btns-three shadow-none me-3"
                      >
                        {ele}
                      </button>
                    ))}
                  </div>
                )}
                <div className="col-12 my-4">
                  <p className="m-0 offcan-no-progress">
                    <b className="offcan-topic-progress">Topic Progress:</b>{" "}
                    {handleStatus(data?.topicPoints, data?.CompleteQuizz)}
                  </p>
                </div>
              </div>

              <div className="col-12 ">
                <div className="row mx-auto d-flex align-items-center justify-content-between">
                  <div className="col selectedBar px-1">
                    <hr />
                    <p className="m-0 text-center mt-2 mb-1 offcan-medium">
                      Beginner
                    </p>
                  </div>
                  <div
                    className={`${
                      handleStatus(data?.topicPoints, data?.CompleteQuizz) ===
                        "Medium" ||
                      handleStatus(data?.topicPoints, data?.CompleteQuizz) ===
                        "Advanced" ||
                      handleStatus(data?.topicPoints, data?.CompleteQuizz) ===
                        "Maestro"
                        ? "selectedBar"
                        : "unselectedBar"
                    } col  px-1`}
                  >
                    <hr />
                    <p className="m-0 text-center mt-2 mb-1 offcan-medium">
                      Medium
                    </p>
                  </div>
                  <div
                    className={`${
                      handleStatus(data?.topicPoints, data?.CompleteQuizz) ===
                        "Advanced" ||
                      handleStatus(data?.topicPoints, data?.CompleteQuizz) ===
                        "Maestro"
                        ? "selectedBar"
                        : "unselectedBar"
                    } col  px-1`}
                  >
                    <hr />
                    <p className="m-0 text-center mt-2 mb-1 offcan-medium">
                      Advanced
                    </p>
                  </div>
                  <div
                    className={`${
                      handleStatus(data?.topicPoints, data?.CompleteQuizz) ===
                      "Maestro"
                        ? "selectedBar"
                        : "unselectedBar"
                    } col  px-1`}
                  >
                    <hr />
                    <p className="m-0 text-center mt-2 mb-1 offcan-medium">
                      Maestro
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="row">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item border-0 shadow-none">
                      <h5
                        className="accordion-header offcan-table-content"
                        id="headingOne"
                      >
                        <button
                          className="accordion-button  p-0 m-0 shadow-none bg-white"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          onClick={() => setShowDown(!showDown)}
                        >
                          <div className="col-12 mt-4 mb-0 table d-flex align-items-center justify-content-between">
                            <h5 className="m-0 offcan-table-content">
                              Table of contents
                            </h5>
                            {showDown ? (
                              <i
                                className="fa fa-angle-up offcan-down-icon d-flex align-items-center justify-content-center text-white border-0"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                className="fa fa-angle-down offcan-down-icon d-flex align-items-center justify-content-center text-white border-0"
                                aria-hidden="true"
                              ></i>
                            )}
                          </div>
                        </button>
                      </h5>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse mb-4"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        {/* {data?.response?.length > 0 && */}

                        {data.response?.map((ele, ind) => {
                          let num = ele?.timestamp;
                          let div = Math.floor(num / 60);
                          let result = num - div * 60;
                          return (
                            <>
                              <div
                                className={`border-bottom col-12 pb-2  my-3 offcan-accor-time `}
                              >
                                <div className="row">
                                  <div className="col d-flex align-items-center justify-content-start">
                                    {selectedTimestamp !== ind ? (
                                      <i
                                        onClick={() => {
                                          handlePlayButton(ele, ind);
                                        }}
                                        className="fa fa-caret-right me-2 cp"
                                        aria-hidden="true"
                                      ></i>
                                    ) : (
                                      <div
                                        class="spinner-border me-2"
                                        style={{
                                          width: "1rem",
                                          height: "1rem",
                                        }}
                                        role="status"
                                      >
                                        <span class="sr-only">Loading...</span>
                                      </div>
                                    )}
                                    <p className="m-0">
                                      {ele?.topicId?.topic}
                                    </p>
                                  </div>
                                  {docType === "youtube url" && (
                                    <div className="col d-flex align-items-center justify-content-end">
                                      <p className="m-0">
                                      {" "}
                                      {Math.floor(ele?.timestamp / 60) < 10
                                        ? `0${Math.floor(ele?.timestamp / 60)}`
                                        : Math.floor(ele?.timestamp / 60)}{" "}
                                      :
                                      {Math.floor(ele?.timestamp % 60) < 10
                                        ? `0${Math.floor(ele?.timestamp % 60)}`
                                        : Math.floor(ele?.timestamp % 60)}
                                      </p>
                                    </div>
                                  )}
                                  {docType === "video/mp4" && (
                                    <div className="col d-flex align-items-center justify-content-end">
                                      <p className="m-0">
                                        {" "}
                                        {Math.floor(ele?.timestamp / 60) < 10
                                          ? `0${Math.floor(
                                              ele?.timestamp / 60
                                            )}`
                                          : Math.floor(
                                              ele?.timestamp / 60
                                            )}{" "}
                                        :{result < 10 ? `0${result}` : result}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-4">
                <div className="row position-relative">
                  <div
                    className="
                  col-auto position-relative three d-flex align-items-center justify-content-start"
                  >
                    {!addPLaylistLoader ? (
                      <button
                        onClick={() => {
                          setShowDrop(!showdrop);
                        }}
                        style={{ cursor: "pointer" }}
                        type="button"
                        className="btn offcan-add-playlist-btn py-2 px-4 shadow-none"
                      >
                        <img className="offcan-plus me-2" src={plus} alt="" />
                        Add to Playlist
                      </button>
                    ) : (
                      <div className="spinner-border me-4" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                  </div>
                  {showdrop && store.playlist?.data.length > 0 && (
                    <div className="col add_dropdown_data d-flex pe-1 ">
                      <div className="row  px-2  width_add_play studyGenerate  position-absol border py-2 d-flex align-items-center justify-content-end flex-column materials_shadow">
                        <div className="playlistScrollbar">
                          {store.playlist?.data?.map((ele, ind) => {
                            return (
                              <div
                                className="col-12 summaryStudy"
                                onClick={() => {
                                  handlePlayListClick(ele);
                                }}
                              >
                                <button
                                  type="button"
                                  class="btn m-0 p-0 studyGenerateFlashcards add_material_dropdwn py-1 shadow-none text_size_add  border-0"
                                >
                                  {ele.playList_Name.length > 15
                                    ? hanldeslice(ele.playList_Name)
                                    : ele?.playList_Name}
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col three d-flex align-items-center justify-content-end">
                    {!loader ? (
                      <>
                        <button
                          onClick={() => {
                            goToStudyPage();
                          }}
                          type="button"
                          className="btn btn-light py-2 px-4 me-2 shadow-none offcan-study"
                        >
                          Study
                        </button>
                      </>
                    ) : (
                      <div className="spinner-border me-4" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    {practiceLoader ? (
                      <div className="spinner-border me-4" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <button
                        onClick={() => {
                          handlePractice();
                        }}
                        type="button"
                        className="btn btn-light py-2 px-4 shadow-none offcan-study"
                      >
                        Practice
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 my-4 ">
                <h5 className="offcan-related">Related to this</h5>
              </div>
            </div>
            <div>
              <div>
                {/* Vaidy to do: add a new api for related content*/}
                <div className="mb-4 row mx-auto ">
                  <Carousel
                    // autoPlaySpeed={2000}
                    // infinite={true}
                    responsive={responsive}
                    
                    partialVisbile
                    // deviceType={deviceType}
                    itemclassName="image-item"
                    className="image-item"
                  >
                    
                    {data?.recommended?.length > 0 &&
                      getRecommdedContent(data?.recommended)}
                  </Carousel>
                </div>
              </div>
            </div>
            <div></div>{" "}
          </>
        )}
        {/* </div> */}
      </Offcanvas.Body>
    </Offcanvas>
    // sidebar data end
  );
};

export default SidebarCanvas;

import {
  GET_TOPIC,
  GET_HTML,
  ADD_NOTES,
  GET_NOTES,
  GET_INFO,
  DELETE_NOTES,
  EDIT_NOTES,
  GET_SUBTOPIC,GET_FLASH_CARDS_FAILED,GET_FLASH_CARDS
} from "./types";
import mernApi from "../apis/mern";

// Create Playlist first screen
export const getTopics = (file_id, userId, category) => (dispatch) => {
  // https://seekhoapp.herokuapp.com/api/study/getTopics?file_id=62d7aa72d980cdbc1fc653bd&user_id=62c7db603923fcd28faf5d27&category_name=Video
  return mernApi.get(`api/study/getTopics?file_id=${file_id}`).then((data) => {
    if (data.status === 200) {
      dispatch({
        type: GET_TOPIC,
        payload: data.data,
      });

      return data.data;
    }
  });
};

// Create Playlist first screen
export const getHtml = (file_id, userId, category) => (dispatch) => {
  return mernApi.get(`api/study/getHtml?file_id=${file_id}`).then((data) => {
    if (data.status === 200) {
      dispatch({
        type: GET_HTML,
        payload: data.data,
      });

      return data.data;
    }
  });
};

// export const getSubTopics = (file_id, topic_id) => (dispatch) => {
//   try {
//     return mernApi.get(`api/study/getSubTopics?file_id=${file_id}&topic_id=${topic_id}`).then((data) => {
//       if (data.status === 200) {
//         dispatch({
//           type: GET_SUBTOPIC,
//           payload: data
//         })
//         return data;
//       }
//     })
//   }
//   catch (err) {
//     console.log(err)
//   }
// }

export const addNotes = (body) => (dispatch) => {
  return mernApi.post(`api/study/addNotes`, body).then((data) => {
    if (data.status === 200) {


      return dispatch({
        type: ADD_NOTES,
        payload: data.data.responseData,
      });
    }
    if (data.status === 401) {
      return dispatch({
        type: ADD_NOTES,
        payload: data.data
      })
    }
  });
};

export const getNotes = (type, topicId) => (dispatch) => {
  return mernApi
    .get(`api/study/getfilterNotes/${type}?file_id=` + topicId)
    .then((data) => {
      if (data.status === 200) {

        dispatch({
          type: GET_NOTES,
          payload: data.data,
        });
      }
      return data
    });
};

export const getSearchNotes = (name, topicId) => (dispatch) => {
  return mernApi
    .get(`api/study/searchNotes?search=` + name + "&file_id=" + topicId)
    .then((data) => {
      if (data.status === 200) {

        dispatch({
          type: GET_NOTES,
          payload: data.data,
        });
      }
    });
};

export const setBookMark = (id, type) => (dispatch) => {
  return mernApi
    .put(`api/study/addBookmark/${type}?transcriptId=` + id)
    .then((data) => { });
};

export const deleteNotes = (topicId) => (dispatch) => {
  return mernApi.delete(`api/study/deleteNotes?id=` + topicId).then((data) => {
    if (data.status === 200) {
      return dispatch({
        type: DELETE_NOTES,
        payload: topicId,
      });
    }
  });
};

export const editNotes = (body) => (dispatch) => {
  return mernApi
    .put(`https://seekh.xyz/api/study/editNotes`, body)
    .then((data) => {
      if (data.status === 200) {


        return dispatch({
          type: EDIT_NOTES,
          payload: data.data.response,
        });
      }
    });
};

export const getToolTipInfo = (titleId, word) => (dispatch) => {
  return mernApi
    .get(`api/study/getInfo?fileId=${titleId}&word=${word}&quizId=`)
    .then((data) => {
      if (data.status === 200) {
        dispatch({
          type: GET_INFO,
          payload: data.data,
        });
        return data.data;
      }
    });
};

export const getSummeryAction = (titleId) => (dispatch) => {
  return mernApi
    .get(`api/library/getSummary?fileId=${titleId}`)
    .then((data) => {
      if (data.status === 200) {
        dispatch({
          type: "GET_SUMMARY",
          payload: data,
        });
      }
      return data;
    });
};

export const summeryAddToLibrary = (body) => async (dispatch) => {
  return mernApi.post(`api/library/uploadSummary`, body).then((data) => {

    return data;
  });
};

export const getSearchTranscipt = (feild, search) => (dispatch) => {
  return mernApi
    .get(`api/study/searchTranscript?fileid=${feild}&search=${search}`)
    .then((data) => {
      if (data.status === 200) {

        dispatch({
          type: "GET_TRANSCRIPT",
          payload: data.data,
        });
      }
      return data;
    });
};

export const addMarkers = (body) => async (dispatch) => {
  return mernApi.post(`api/study/highLightText`, body).then((data) => {

    return data;
  });
};



export const addTime = (body) => async (dispatch) => {
  return mernApi.put(`api/library/timeTrack`, body).then((data) => {
    if(data.status===200){
      dispatch({
        type:"STUDY_PAGE_TIMETRACK",
        payload:data,
      })
    }
    return data;
  })
}

export const updatePlayTimeStamp = (body) =>async (dispatch)=>{
  return mernApi.put(`api/study/updatePlayTimestamp`,body).then((data)=>{
    return data
  })
}

export const dailyLoginCheck = (body) =>async (dispatch)=>{
  return mernApi.get(`api/users/login-check`,body).then((data)=>{
    return data
  })
}

export const getFlashCards = (fileId) =>async (dispatch)=>{
  return mernApi.get(`api/study/getFlashCards?fileId=${fileId}`).then((data)=>{
    if(data.status===200){
      dispatch({
        type:GET_FLASH_CARDS,
        payload:data
      })
    }
    else{
      dispatch({
        type:GET_FLASH_CARDS_FAILED,
        payload:data
      })
    }
    return data
  }
  )
}
export const firstTimeDocLogin = () =>(dispatch)=>{
  return mernApi.put(`api/study/firstTimeDocumentToolTipShown`).then((data)=>{

  })

}
export const firstTimeVidLogin = () =>(dispatch)=>{
  return mernApi.put(`api/study/firstTimeVideoToolTipShown`).then((data)=>{

  })

}
export const topiccsvUpload = (body) =>(dispatch)=>{
  return mernApi.post(`api/csvUpload/uploadTopicCsv`,body).then((data)=>{
return data;
  },(err)=>{
return err;
  }

  
  )

}
export const QuestioncsvUpload = (body) =>(dispatch)=>{
  return mernApi.post(`api/csvUpload/questionCsvUpload`,body).then((data)=>{
return data;
  })

}


export const  flashCardReadStatus = (status,flash_id) =>(dispatch)=>{
  return mernApi.put(`api/study/readStatusUpdate?readStatus=${status}&flashCardId=${flash_id}`).then((data)=>{
    return data
  })
}

export const staticTopicUpload= (body) => (dispatch)=>{
  return mernApi.post('/api/csvUpload/staticTables',body).then((data)=>{
    return data
  })
}

export const addFocusData = (data) =>(dispatch)=>{
  dispatch({
      type:"ADD_FOCUS_DATA",
      payload:data
    })
}


import React from 'react'
import Header from "../Header/Header";
import Organize from '../../containers/Organize/Organize';

function OrganizeMain() {
  return (
    <>
      <div className="row mx-auto">
        <div className="col-12">
          <Header />
        </div>
        <div className="col-12">
          <Organize />
        </div>
      </div>
    </>
  )
}

export default OrganizeMain

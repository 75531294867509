import React, { useState } from "react";

import "./KeepTrack.css";
import {
  SelectProfileX32,
  SelectProfileX33,
  SelectProfileDarkLogo,
  KeepTrackImg,
  LeftArrow,
} from "../../staticContent/image";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserData,
  skipPage,
  goBackPage,
} from "../../store/actions/getUserData";
import { Redirect } from "react-router-dom";

const KeepTrack = () => {
  const store = useSelector((state) => state);
  const [loader, setloader] = useState(false);

  const dispatch = useDispatch();
  // on click (yes i want Button) Method
  const sendData = () => {
    setloader(true);
    dispatch(
      getUserData({
        body: {},
        onBoardingStatus: "4",
        setCode: "5",
      })
    );
  };

  // Redirect To Next Page

  if (store.profileStatus === "5") {
    return <Redirect to={`/select-profile/${store.profileStatus}`} />;
  } else if (store.profileStatus === "7") {
    return <Redirect to={`/library`} />;
  }
  // Back Button Function
  const goBack = () => {
    dispatch(goBackPage(store.profileStatus - 1));
  };

  return (
    <div className="container-fluid d-flex flex-column">
      <div className="row pt-5">
        <div className="col-12 amet  align-items-center">
          <div className="row">
            <div className="col-11 d-flex justify-content-between align-items-center mx-auto">
              <img
                className="onboard_img ps-5"
                src={SelectProfileDarkLogo}
                alt=""
              />
              <div className="progressSign">
                <div className="completed-progressbar5"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row margin_top">
            <div className="col-4 d-flex justify-content-start">
              <div className="signIn_img">
                <img src={SelectProfileX33} alt="" />
              </div>
            </div>
            <div className="col-lg-4  set_onboarding_width p-0">
              <div className="col-1 text-dark mb-3">
                <div
                  className="d-flex align-items-center back-data"
                  onClick={() => {
                    goBack();
                  }}
                  style={{ display: "inline-block", cursor: "pointer" }}
                >
                  {/* <i className="fa fa-arrow-left" aria-hidden="true"></i> */}
                  <img src={LeftArrow} alt="" style={{ width: "1rem" }} />
                  <p className="m-0 ms-2">Back</p>
                </div>
              </div>
              <div className="col-12 sign  mt-2">
                <h3 className="heading_onboard">Do you want to keep track of your goals?</h3>
                <p className="pragraph_onboard">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
                  <br />
                  Ac ornare quis leo ac nunc pretium amet, viverra.
                </p>
              </div>
              <div className="col-12 next d-flex justify-content-center align-items-center">
                <img src={KeepTrackImg} alt="" />
              </div>
              {loader === false ? (
                <div className=" col-12 clearfix border-0">
                  <button
                    type="button"
                    className="customebtn step mt-4 d-flex align-items-center justify-content-center "
                    onClick={sendData}
                  >
                    {/* <i className="fa fa-spinner fa-spin me-3" aria-hidden="true" style={{ fontSize: "20px" }}></i> */}
                    Yes, I want
                  </button>
                </div>
              ) : (
                <div className=" col-12 clearfix border-0 ">
                  <button
                    type="button"
                    className="customebtn step mt-4  "
                    style={{ background: "#6A4BFF" }}
                  >
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              )}

              <div className="col-12 d-flex align-items-center justify-content-center mt-3">
                <p
                  onClick={() => {
                    dispatch(skipPage());
                  }}
                  className="text-decoration-none plus"
                  style={{ cursor: "pointer" }}
                >
                  Skip Step
                </p>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-end">
              <div className="signIn_img">
                <img src={SelectProfileX32} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeepTrack;

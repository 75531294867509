import React from "react";
import { useState } from "react";
import Header from "../../../layouts/Header/Header";
import "../Profile/Profile.css";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReactNotifications, Store } from "react-notifications-component";
import {
  updateProfile,
  removeAvatar,
} from "../../../store/actions/getUserProfile";
import { getSchoolList } from "../../../store/actions/getUserData";
function Profile() {
  const store = useSelector((state) => state);
  const data = store.getUserProfile?.data?.responseData;
  const updateData = store.update_profile?.data?.responseData;
  const [showSchool, setShowSchool] = useState(false);
  const [loader, setloader] = useState(false);
  const [btn, setBtn] = useState(false);
  let [num, setnum] = useState(0);
  const [schoollist, setSchoolList] = useState([]);
  const [border, setborder] = useState({
    fname: false,
    lname: false,
    bio: false,
  });
  const [show, setShow] = useState(false);
  const [SelectedSchoolVal, setSelectedSchoolVal] = useState(
    data?.school?.school_name
  );
  const [form, setForm] = useState({
    file: "",
    picture: "",
    image: "",
    school: data?.school?._id,
    firstName: "",
    lastName: "",
    bio: "",
    value: "",
  });
  const dispatch = useDispatch();
  const newData = updateData ? updateData : data;
  useEffect(() => {
    setForm({
      file: "",
      picture: data?.picture ? data.picture : "",
      image: "",
      school: data?.school?._id,
      firstName: data?.firstName,
      lastName: data?.lastName,
      bio: data.bio,
    });
  }, []);

  useEffect(() => {
    // get school list on scrool api call
    dispatch(getSchoolList(num)).then((res) => {
      setSchoolList([...schoollist, ...res.data]);
    });
  }, [num]);

  // chnage user name and value function start
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  // change user name and value function ends
  // avtaar on chnage funtion strats
  const handleAvtaarChange = (e) => {
    let files = e.target.files[0];
    const [file] = e.target.files;

    setForm({ ...form, file: files, image: URL.createObjectURL(file) });
    setBtn(true);
  };
  // avtaar profile page funtion ends
  // remove avtaar api call funtion starts
  const handleAvataar = () => {
    dispatch(removeAvatar(store.auth?.user?.id));
    setForm({ ...form, picture: "" });
  };
  // remove avtaar api call funtion ends
  // select school value in profile page implementation function starts
  const handleSelectProfile = (item) => {
    setSelectedSchoolVal(item.school_name);
    setShowSchool(false);
    setForm({ ...form, school: item._id });
  };
  // select school value in profile page ends
  // profile page  save button function call starts
  const handleClick = (type) => {
    let body = new FormData();
    body.append("user_id", store.auth?.user?.id);
    body.append("firstName", form.firstName);
    body.append("lastName", form.lastName);
    body.append("school", form.school);
    body.append("bio", form.bio);
    if (form.image === "") {
      body.append("file", "");
    } else {
      body.append("file", form.file);
    }
    dispatch(updateProfile(body)).then((res) => {
      setForm({
        ...form,
        file: "",
        picture: res?.data?.responseData?.picture,
        image: "",
        school: res?.data?.responseData?.school?._id,
        firstName: res?.data?.responseData?.firstName,
        lastName: res?.data?.responseData?.lastName,
        bio: res?.data?.responseData?.bio,
      });
      if (res.status === 200) {
        setloader(false);
      }
    });
    setloader(true);
    Store.addNotification({
      title: "Success",
      message: "your profile has been updated successfully",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };
  // profile page save button functionality ends
  // profile page cancel button functionality starts
  const handleCancel = () => {
    setForm({
      file: "",
      picture: data?.picture,
      image: "",
      firstName: data?.firstName,
      lastName: data?.lastName,
      school: data?.school?._id,
      bio: data?.bio,
      value: "",
    });
    setSelectedSchoolVal(data?.school?.school_name);
    setShow(!show);
    setBtn(false);
  };
  // profile page cancel button funtion ends
  // school data api call on scrolll funtion starts
  const handleScroll = (e) => {
    if (
      window.innerHeight + e.target.scrollTop + 1 >=
      e.target.scrollHeight + 300
    ) {
      setnum(num++);
    }
  };
  // school data api call onScroll function ends
  return (
    <>
      <div>
        <Header />
      </div>
      <div className="profile-div">
        <div className="container">
          <div className="row mx-5">
            <div className="col-12">
              <div className="row">
                <div className="col-12 mt-3">
                  <h2 className="txt_heading pb-2 m-0">Profile</h2>
                  <div className="col-12 border-bottom pb-4">
                    <p className="para_text m-0">
                      Update your photo and personal details here.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-4 displayed border-bottom">
                <div className="col-12 pt-2">
                  <h6 className="small_heading m-0">Your avatar</h6>
                </div>
                <div className="col-12 pb-4 ">
                  <p className="smpara_txt m-0">
                    This will be displayed on your profile.
                  </p>
                </div>

                <div className=" col-4 mb-3 v-blue d-flex align-items-center justify-content-start ">
                  <div className="mr-5">
                    {form.picture !== "" ? (
                      <img
                        src={form.image === "" ? form.picture : form.image}
                        alt="head"
                        className=" profile-logo"
                      />
                    ) : form.image === "" ? (
                      <p className="m-0 text-white rounded-pill usernamee d-flex align-items-center justify-content-center p-3">
                        {data?.firstName?.slice(0, 1).toUpperCase()}
                      </p>
                    ) : (
                      <img
                        src={form.image}
                        alt="head"
                        className="profile-logo"
                      />
                    )}
                  </div>

                  <div className="image-uploadd   mt-3">
                    <label for="file-input">
                      <p className="profile-btn">Change</p>
                    </label>
                    <input
                      value={form.value}
                      id="file-input"
                      type="file"
                      className="profile_input border"
                      name="file"
                      accept="image/png, image/jpeg"
                      onChange={handleAvtaarChange}
                    />
                  </div>
                  <button
                    type="files"
                    className="remove-btn"
                    onClick={handleAvataar}
                    disabled={btn}
                  >
                    Remove
                  </button>
                </div>
              </div>
              <div className="row mt-4 displayed border-bottom">
                <div className="col-12 mt-2">
                  <h6 className="small_heading m-0">Personal Information</h6>
                </div>
                <div className="col-12 mb-4">
                  <p className="smpara_txt m-0">Short information about you.</p>
                </div>
                <form>
                  <div className="row mb-4">
                    <div className="col">
                      <label
                        for="exampleInputEmail1"
                        className="form-label urna "
                      >
                        <b className="label_txt">First Name</b>
                      </label>
                      <input
                        type="text"
                        className={
                          border.fname
                            ? " form-control name boder"
                            : "form-control name"
                        }
                        aria-describedby="emailHelp"
                        name="firstName"
                        value={form.firstName}
                        onClick={() => {
                          setborder({
                            fname: true,
                            lname: false,
                            bio: false,
                          });
                        }}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col">
                      <label
                        for="exampleInputEmail1"
                        className="form-label urna "
                      >
                        <b className="label_txt">Last Name</b>
                      </label>
                      <input
                        type="text"
                        className={
                          border.lname
                            ? "form-control name boder"
                            : "form-control name"
                        }
                        name="lastName"
                        onChange={handleChange}
                        aria-describedby="emailHelp"
                        value={form.lastName}
                        onClick={() => {
                          setborder({
                            fname: false,
                            lname: true,
                            bio: false,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <div className="col-6">
                      <div className="row mx-auto position-relative">
                        <div className="col-12 p-0">
                          <label for="email" className="mb-2 mt-1">
                            <b className="label_txt">Enter your school</b>
                          </label>
                        </div>
                        <div className="col-12">
                          <div className="row">
                            <div
                              onClick={() => {
                                setborder({
                                  fname: false,
                                  lname: false,
                                  bio: false,
                                });
                                setShowSchool(!showSchool);
                              }}
                              className={
                                showSchool
                                  ? "col-12  profile-borderwithselection  school p-2"
                                  : "col-12  profile-borderr  school p-2"
                              }
                            >
                              <div className="row p-2">
                                <div className="col flex-1 d-flex align-items-center">
                                  <input
                                    disabled
                                    type="search"
                                    className={
                                      "border-0  flex-grow-1 outline-none bg-transparent"
                                    }
                                    value={SelectedSchoolVal}
                                  />
                                </div>
                                <div className="col-auto d-flex align-items-center drop-icon justify-content-end">
                                  <i
                                    className={
                                      showSchool
                                        ? "fa fa-angle-up"
                                        : "fa fa-angle-down"
                                    }
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            {showSchool ? (
                              <div
                                className="col-12 border sele_drpdown"
                                onScroll={handleScroll}
                              >
                                <div className="row">
                                  {schoollist?.map((item, index) => {
                                    return (
                                      <div
                                        onClick={() => {
                                          handleSelectProfile(item);
                                        }}
                                        className="col-12 input-data p-3 border-bottom"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <p className=" topics m-0 text-capitalize ">
                                          {item.school_name}
                                        </p>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col"></div>
                  </div>
                </form>
              </div>
              <div className="row mt-4 displayed border-bottom">
                <div className="col-12">
                  <h6 className="small_heading m-0">Your Bio</h6>
                </div>
                <div className="col-12 mb-3">
                  <p className="smpara_txt m-0">Write a short introduction.</p>
                </div>
                <div className="col-6 mb-4">
                  <textarea
                    className={
                      border.bio
                        ? "form-control namee boder"
                        : "form-control namee"
                    }
                    value={form.bio === undefined ? "" : form.bio}
                    onClick={() => {
                      setborder({
                        fname: false,
                        lname: false,
                        bio: true,
                      });
                    }}
                    onChange={handleChange}
                    name="bio"
                  />
                </div>
              </div>
              <div className="row  d-flex align-items-center justify-content-end my-3 ">
                <div className=" col-3  v-blue d-flex align-items-center justify-content-end ">
                  <button
                    type="button"
                    className="btn border shadow-none me-4 px-3 py-2 changepass "
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>

                  {!loader ? (
                    <button
                      type="button"
                      className="btn  shadow-none  savepass py-2 px-3"
                      onClick={() => handleClick()}
                    >
                      Save
                    </button>
                  ) : (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Profile;
import React, { useState, useEffect } from "react";
import "./createplaylist.css";
import {
  NewPlaylistActive,
  NewPlaylistAvtar,
  DeleteIcon,
  NonActive,
  CreatePlus,
  CloseSearch,
  searchClose,
  updateThumbnail,
  learn_button,
} from "../../../../staticContent/image";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  searchContentForPlaylist,
  addContentForPlaylist,
  clearPlaylistFilter,
  editPlaylist,
  deletePlaylist,
} from "../../../../store/actions/libraryPlaylist";
import { clearSearchbar } from "../../../../store/actions/libraryOverview";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Dropdown from "react-bootstrap/Dropdown";
import { createPlaylist } from "../../../../store/actions/libraryPlaylist";
import SidebarCanvas from "../../Overview/CanvasSidebar/sidebarCanvas";
import { getTopics } from "../../../../store/actions/study";
const CreatePlaylist = () => {
  const moment = extendMoment(originalMoment);
  const history = useHistory();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const store = useSelector((state) => state);
  const [isEditPlaylist, setIsEditPlaylist] = useState(false);
  const [isListView, setIsListView] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const [addedPlaylistContent, setAddedPlaylistContent] = useState([]);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [sidebarData, setSidebarData] = useState({});
  const [openSidebar, setOpenSidebar] = useState(false);
  const [sideLoader, setSideLoader] = useState(false);

  const Loader = () => {
    setMainLoader(true);
  };
  const [formDetails, setFormDetails] = useState({
    playlistname: store.createPlaylist.playList_Name,
    imgfile: "",
    playlistId: "",
    image: "",
  });
  useEffect(() => {
    setTimeout(Loader, 2000);
  }, []);
  useEffect(() => {
    dispatch(createPlaylist(store.auth?.user?.id)).then((ressd) => {
      setFormDetails({
        playlistname: ressd.data?.playList_Name,
        imgfile: ressd.data?.thumbnail,
        playlistId: ressd.data?._id,
        image: "",
      });
    });
    let timer1 = setTimeout(() => setShow(true), 500);
    return () => {
      clearTimeout(timer1);
    };
  }, []);
  //  create playlist search content api and function
  const searchInputval = (e) => {
    setValue(e.target.value);
    let title = e.target.value;
    let userId = store.auth?.user?.id;
    if (e.target.value === "" || e.target.value?.length <= 0) {
      dispatch(clearPlaylistFilter([]));
    } else {
      dispatch(searchContentForPlaylist(title, userId));
    }
  };
  const handleSideBar = (item) => {
    setSideLoader(true);
    setOpenSidebar(true);
    dispatch(getTopics(item._id)).then((res) => {
      setSidebarData(res);
      setSideLoader(false);
    });
  };
  const addContent = (item) => {
    setValue("");
    let body = {
      contentid: item._id,
      playListId: formDetails.playlistId,
      userId: item.user_id,
    };
    dispatch(addContentForPlaylist(body));
    setAddedPlaylistContent([...addedPlaylistContent, item]);
    dispatch(clearPlaylistFilter());
  };
  const editPlaylistData = () => {
    setLoader(true);
    const body = new FormData();
    body.append("file", formDetails.imgfile);
    body.append(
      "playlist_Name",
      formDetails?.playlistname.trim() == ""
        ? store.createPlaylist.playList_Name
        : formDetails.playlistname
    );
    body.append("id", formDetails.playlistId);
    dispatch(editPlaylist(body)).then((res) => {
      if (res.data.status === 200) {
        setLoader(false);
      }
    });
    setIsEditPlaylist(false);
    setFormDetails({ ...formDetails, image: "" });
  };
  const deletePlaylistButton = () => {
    dispatch(deletePlaylist(store.createPlaylist?._id, store.auth?.user?.id));
    history.push({ pathname: "/library/playlist" });
  };
  const formatTime = (milliseconds) => {
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor(milliseconds / 1000 / 60 / 60);

    return [
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
    ].join(":");
  };
  const handleClickRecomend = (item) => {
    setSideLoader(true);

    setOpenSidebar(true);
    dispatch(getTopics(item._id)).then((res) => {
      setSidebarData(res);
      setSideLoader(false);
    });
  };
  return (
    <>
      <div className="row mx-auto">
        <div className="col-12">
          {mainLoader ? (
            <div className="row mx-auto">
              <div className="col-lg-12">
                <div className="row mx-auto">
                  <div className="col-md-10 mt-5 mb-3 d-flex justify-content-start align-items-center">
                    <Link className="liblists" to="/library/overview">
                      Library
                      <i
                        className="fa fa-angle-right mx-2"
                        aria-hidden="true"
                      ></i>
                    </Link>
                    <Link to="/library/playlist" className="liblists">
                      Your playlists
                      <i
                        className="fa fa-angle-right mx-2"
                        aria-hidden="true"
                      ></i>
                    </Link>
                    <Link to="#" className="labslist_last ">
                      {`${store.createPlaylist.playList_Name}`}
                    </Link>
                  </div>
                  {show ? (
                    <div className="col-md-10 mt-4 d-flex justify-content-start align-items-center">
                      <div className="d-flex row">
                        <div className="col-auto ">
                          {isEditPlaylist ? (
                            <div className="image-upload">
                              <label for="file-input">
                                <img
                                  src={
                                    formDetails.image === ""
                                      ? updateThumbnail
                                      : formDetails.image
                                  }
                                  alt=""
                                  className="img-upload-data"
                                />
                              </label>
                              <input
                                accept=" image/jpeg"
                                onChange={(e) => {
                                  const [file] = e.target.files;
                                  setFormDetails({
                                    ...formDetails,
                                    imgfile: e.target.files[0],
                                    image: URL.createObjectURL(file),
                                  });
                                }}
                                id="file-input"
                                type="file"
                              />
                            </div>
                          ) : (
                            <div className="image-upload">
                              <label for="file-input">
                                <img
                                  className="img-upload-data"
                                  src={
                                    store.createPlaylist.thumbnail
                                      ? store.createPlaylist.thumbnail
                                      : formDetails.image
                                  }
                                  alt=""
                                />
                              </label>
                            </div>
                          )}
                        </div>
                        <div className="col-auto my-auto">
                          <div className="row">
                            <div className="col-12">
                              {isEditPlaylist ? (
                                <input
                                  onChange={(e) => {
                                    setFormDetails({
                                      ...formDetails,
                                      playlistname: e.target.value,
                                    });
                                  }}
                                  type="text"
                                  className="form-control  shadow-none editcontent-select create-playlist-input"
                                  placeholder={
                                    store.createPlaylist.playList_Name
                                  }
                                  value={formDetails.playlistname}
                                />
                              ) : (
                                <>
                                  {!loader ? (
                                    <h6 className=" new-list">
                                      {`${store.createPlaylist.playList_Name}`}{" "}
                                    </h6>
                                  ) : (
                                    <div
                                      className="spinner-border "
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  )}
                                </>
                              )}
                              <p className="my-2 items m-0 p-0">
                                {` ${addedPlaylistContent?.length} items`}{" "}
                              </p>
                            </div>
                            <div className="col-12 d-flex align-items justify-content-start">
                              {!isEditPlaylist ? (
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="white shadow-none  bg-white border-0 align-items-start p-0 m-0"
                                    id="dropdown-basic"
                                  >
                                    <img
                                      src={DeleteIcon}
                                      alt=""
                                      className="bg-white"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="rounded-0">
                                    <Dropdown.Item
                                      onClick={() => {
                                        setIsEditPlaylist(true);
                                      }}
                                    >
                                      Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        deletePlaylistButton();
                                      }}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              ) : (
                                <div className="mt-2">
                                  <button
                                    onClick={() => editPlaylistData()}
                                    className="btn data-save text-white shadow-none"
                                  >
                                    {" "}
                                    Save
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Skeleton />
                      <Skeleton count={15} />
                    </div>
                  )}
                  {addedPlaylistContent?.length > 0 && (
                    <div className="col-2 d-flex align-items-center justify-content-end mt-auto">
                      <img
                        style={{ cursor: "pointer" }}
                        src={NewPlaylistActive}
                        alt=""
                        onClick={() => {
                          setIsListView(false);
                        }}
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => setIsListView(true)}
                        src={NonActive}
                        className=" ms-3  browsecourse-lines-image"
                        alt=""
                      />
                    </div>
                  )}
                </div>

                {isListView ? (
                  <>
                    <div className="mb-5 row mx-auto">
                      <div className="col-12 mt-4 ">
                        {addedPlaylistContent?.length > 0 && (
                          <div className="row mx-auto my-2 py-2  createplaylist-first-line align-items-center">
                            <div className="col-auto">
                              <p className="m-0">#</p>
                            </div>
                            <div className="col-3">
                              <p className="m-0">Title</p>
                            </div>
                            <div className="col-2 me-4">
                              <p className="m-0">Category</p>
                            </div>
                            <div className="col-2 me-4">
                              <p className="m-0">Course</p>
                            </div>
                            <div className="col-3">
                              <p className="m-0">Tags</p>
                            </div>
                            <div className="col-1 p-0 d-flex align-items-center justify-content-end">
                              <p className="m-0">Date Added</p>
                            </div>
                          </div>
                        )}
                      </div>
                      {addedPlaylistContent?.map((item, index) => {
                        const Tags = item?.tags?.split(",");
                        return (
                          <div className="col-12" key={`54555${index}`}>
                            <div className="row mx-auto border title py-2 my-1 createplaylist-second-line align-items-center">
                              <div className="col-auto">
                                <p className="m-0">{index + 1}</p>
                              </div>
                              <div className="col-3">
                                <div className="row">
                                  <div className="col-2 avater">
                                    <img
                                      className="side-image-createplaylist"
                                      src={
                                        item.thumbnail
                                          ? item.thumbnail
                                          : NewPlaylistAvtar
                                      }
                                      alt="..."
                                    />
                                  </div>
                                  <div className="col-10 my-auto">
                                    <h6 className="m-0 Browse_title">
                                      {item?.title?.length > 35
                                        ? item.title?.slice(0, 35)
                                        : item.title}{" "}
                                      ...
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              <div className="col-2 me-4">
                                <p className="m-0">
                                  {item.category?.category_name}
                                </p>
                              </div>
                              <div className="col-2 me-4">
                                <p className="m-0"> {item.course_Name}</p>
                              </div>
                              {item.tags ? (
                                <div className="col-3 d-flex flex-wrap">
                                  {Tags.map((ele, ind) => {
                                    return (
                                      <div className="-4 d-flex">
                                        <div className="me-2 mb-1 browsercourse-title-data browse-tags p-2">
                                          {ele}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <div className="col-3"></div>
                              )}
                              <div className="col-1 p-0 d-flex align-item-center justify-content-end">
                                <p className="m-0">
                                  {moment(item.createdAt).format("ll")}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <div className="gridview-containe col-12 mb-2">
                    {addedPlaylistContent?.length > 0 && (
                      <div className="row mx-auto">
                        <div className="col-12 mb-3 mt-4 all_items">
                          <h6 className="p-0 m-0"> All items</h6>
                        </div>
                      </div>
                    )}
                    <div className="row mx-auto">
                      {addedPlaylistContent?.map((item, index) => {
                        return (
                          <div
                            className="col-3 mb-3"
                            id={`test${index}`}
                            key={index}
                          >
                            <div className="row mx-auto  border overflow-hidden p-0">
                              <div className="col-6 position-relative p-0">
                                <div className="row">
                                  <div className="col-12">
                                    <img
                                      src={
                                        item.thumbnail
                                          ? item.thumbnail
                                          : NewPlaylistAvtar
                                      }
                                      className="secondLakhni"
                                      alt="..."
                                    />
                                  </div>
                                  <div className="col-12  position-absolute bottom-0 mb-2 ps-3">
                                    {item?.category?.category_name && (
                                      <button
                                        //  onClick={handleShow}
                                        className="btn text-white videoDoc_btn text-capitalize shadow-none rounded-pill  d-flex align-items-center justify-content-center"
                                        style={{
                                          cursor: "pointer",
                                          backgroundColor: `${item?.category?.category_color}`,
                                        }}
                                      >
                                        {item?.category?.category_name}
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 py-2 d-flex ps-3 pe-2">
                                <div className="row ">
                                  <div className="col-12 mb-auto  d-flex flex-column justify-content-center">
                                    <h3 className="card-data m-0 font-weight-bold">
                                      {item?.title?.length > 20
                                        ? item.title.slice(0, 20) + "..."
                                        : item.title}
                                    </h3>
                                  </div>
                                  {/* <div className="col-12">
                                  <p className="card-texts mb-2">
                                    Yuval Noah Harari
                                  </p>
                                </div> */}
                                  {/* <div className="col-12"></div> */}
                                  <div className="col-12 mt-auto">
                                    <p className="card-texts-min mb-0">
                                      {item.studyPageTimeTrack
                                        ? formatTime(item.studyPageTimeTrack)
                                        : "0:00"}{" "}
                                      spent · Beginner
                                    </p>
                                    <div class="topic mt-2">
                                      {/* <hr /> */}
                                      <div class="progress progress_parent">
                                        <div
                                          class="progress-bar progress_bar"
                                          role="progressbar"
                                          aria-label="Basic example"
                                          aria-valuenow="25"
                                          aria-valuemin="0"
                                          style={{
                                            width: `${
                                              item?.progressBarPercentage
                                                ? item?.progressBarPercentage
                                                : "0%"
                                            }`,
                                          }}
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 mt-auto">
                                    <div
                                      className="row d-flex align-items-center justify-content-start"
                                      onClick={() => {
                                        handleClickRecomend(item);
                                      }}
                                    >
                                      <div
                                        className="col-auto"
                                        style={{
                                          marginRight: "-5px",
                                        }}
                                      >
                                        <p
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="card-data m-0 d-flex align-items-center"
                                        >
                                          Learn
                                        </p>
                                      </div>
                                      <div
                                        className="col-auto p-0 d-flex align-items-center justify-content-center rounded-pill"
                                        // style={{
                                        //   width: "16px",
                                        //   height: "16px",
                                        //   backgroundColor: "#4E45C2",
                                        // }}
                                      >
                                        <img
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          alt=""
                                          className="learn_arrowIcon"
                                          src={learn_button}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {/* search filter starting here */}
                <div className="col-md-10 col-6 mb-md-0 mb-3 grid-margin stretch-card">
                  <div className="first mb-4 mx-auto row">
                    <h4 className="font-weight-bold my-3">
                      Add content for your playlist
                    </h4>
                    <div className="row mx-auto">
                      <div className="col-12">
                        <div className="row border overflow-hidden  rounded-7">
                          <div className="col-12 d-flex align-items-center  search_color">
                            <i className="fa fa-search me-1"></i>
                            <input
                              value={value}
                              onChange={(e) => searchInputval(e)}
                              // type="search"
                              className="border-0 p-2 flex-grow-1 outline-div outline-none bg-transparent"
                              placeholder="Search by keywords, tags, topic and etc..."
                            ></input>
                            {value && (
                              <img
                                onClick={() => {
                                  setValue("");
                                  dispatch(clearSearchbar());
                                }}
                                src={searchClose}
                                alt={""}
                                className="ca"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-10 linedata">
                  <div className="row mx-auto">
                    {store.addContentToPlaylist?.data?.map((item, index) => {
                      const Tags = item?.tags?.split(",");
                      return (
                        <div className="col-lg-12 col-12 ">
                          <div className="row mx-auto border title browsercourse-title-data  py-2 my-1 align-items-center">
                            <div className="col-4">
                              <div className="row">
                                <div className="col-2 avater d-flex align-items-center justufy-content-center">
                                  <img
                                    className="side-image-createplaylist"
                                    src={
                                      item.thumbnail
                                        ? item.thumbnail
                                        : NewPlaylistAvtar
                                    }
                                    alt="..."
                                  />
                                </div>
                                <div className="col-10 my-auto">
                                  <h6 className="m-0 Browse_title">
                                    {item?.title?.length > 35
                                      ? item.title?.slice(0, 35)
                                      : item.title}{" "}
                                    ...
                                  </h6>
                                  {/* <p className="m-0">Yuval Noah Harari</p> */}
                                </div>
                              </div>
                            </div>
                            <div className="col-2">
                              <p className="m-0">
                                {item.category?.category_name}
                              </p>
                            </div>
                            <div className="col-2 ">
                              <p className="m-0">{item.course_Name}</p>
                            </div>
                            <div className="col-3 d-flex flex-wrap">
                              {item.tags &&
                                Tags.map((ele, ind) => {
                                  return (
                                    <div
                                      type="button"
                                      className="me-2 mb-1 browsercourse-title-data px-3 py-2 bg-light rounded-2"
                                    >
                                      {ele}
                                    </div>
                                  );
                                })}
                            </div>
                            <div className="col-1 d-flex justify-content-end">
                              <p
                                className="m-0 createplaylist-plus-icon p-3 rounded-2 d-flex align-items-center justify-content-center"
                                onClick={() => {
                                  addContent(item);
                                }}
                              >
                                <img src={CreatePlus} alt="" />
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <Skeleton />
              <Skeleton count={50} />
            </div>
          )}
          <SidebarCanvas
            show={openSidebar}
            setShow={setOpenSidebar}
            sideLoader={sideLoader}
            data={sidebarData}
          />
        </div>
      </div>
    </>
  );
};
export default CreatePlaylist;

import React from "react";
// import Alert from "@material-ui/lab/Alert";
// import Button from "@material-ui/core/Button";
// import Snackbar from "@material-ui/core/Snackbar";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  signUp,
  unloadAuthPage,
  // facebookSignIn,
  // googleSignIn,
} from "../../store/actions";
import { getProcessing, getError } from "../../store/selectors";
import "./signup.css";
import {
  SelectProfileX32,
  SelectProfileX33,
  LogoWithName,
  // facebookLogo,
  // googleLogo,
} from "../../staticContent/image";
import GoogleLoginOne from "./../../components/SocailLogin/googleLoginOne";
// import FacebookLoginOne from "./../../components/SocailLogin/facebookLoginOne";
import "./signup.css";
import { Link } from "react-router-dom";
import validator from "validator";

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    // state starts :
    this.state = {
      email: "",
      password: "",
      con_password: "",
      loader: false,
      errors: {
        email: { status: false, message: "" },
        password: { status: false, message: "" },
        con_password: { status: false, message: "" },
      },
      passwordShow: false,
      con_passwordShow: false,
    };
    // state ends
  }

  componentDidUpdate() {
    if (this.props.errorMessage?.status === 200) {
      this.props.history.push("/signin");
    }
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ ...this.state, [name]: value?.trim() });
  };

  // @ signup button functionality starts
  onSubmit = () => {
    if (this.state.email === "") {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          email: {
            status: true,
            message: "This is required field",
          },
        },
      });
    } else if (validator.isEmail(this.state.email) === false) {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          email: {
            status: true,
            message: "Please enter a valid email",
          },
        },
      });
    } else if (this.state.password === "") {
      this.setState({
        ...this.state,
        password: "",
        con_password: "",
        errors: {
          ...this.state.errors,
          password: {
            status: true,
            message: "This is required field",
          },
          email: { status: true, meassage: "" },
        },
      });
    } else if (this.state?.password?.length < 8) {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          password: {
            status: true,
            message: "Password must be 8 characters long",
          },
          email: { status: true, meassage: "" },
        },
      });
    } else if (this.state.con_password === "") {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          con_password: {
            status: true,
            message: "Confirm your password",
          },
          password: { status: true, message: "" },
          email: { status: true, meassage: "" },
        },
      });
    } else if (this.state.password !== this.state.con_password) {
      this.setState({
        ...this.state,
        errors: {
          con_password: {
            status: true,
            message: "Password doesn't matched",
          },
          password: { status: true, message: "" },
          email: { status: true, meassage: "" },
        },
      });
    } else if (this.state.password === this.state.con_password) {
      this.setState({
        ...this.state,
        loader: true,
        errors: {
          ...this.state.errors,
          con_password: {
            status: true,
            message: "",
          },
          password: { status: true, message: "" },
          email: { status: true, meassage: "" },
        },
      });

      if (this.validate()) {
        return this.props
          .signUp({
            email: this.state.email,
            password: this.state.password,
          })
          .then(() => {
            if (this.props.errorMessage) {
              // throw new SubmissionError({ _error: this.props.errorMessage });
              this.setState({ ...this.state, loader: false });
              alert(
                this.props.errorMessage.message
                  ? this.props.errorMessage.message
                  : this.props.errorMessage
              );
            }
          });
      }
    }
  };

  // signup button  functionality ends

  // signup feild validation starts
  validate = () => {
    let validateStatus = true;
    if (this.state.email === "") {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          email: { status: true, message: "This is required field" },
        },
      });
      validateStatus = false;
    } else if (this.state.password === "") {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          password: { status: true, message: "This is required field" },
          email: { status: true, message: "" },
        },
      });

      validateStatus = false;
    }

    return validateStatus;
  };
  // signup feild validation ends
  componentWillUnmount() {
    this.props.unloadAuthPage();
  }

  render() {
    return (
      <div>
        <div className="container-fluid heigth_100vh">
          <div className="row mx-auto custom_heigth">
            <div className="col-12 p-0 custom_heigth">
              <div className="row mx-auto custom_heigth">
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <img src={LogoWithName} alt="" />
                </div>
                <div className="col-12">
                  <div className="row mx-auto">
                    {/* mx-auto image-data my-auto */}
                    <div className="col-4 d-flex justify-content-center">
                      <div className="signIn_img mt-5">
                        <img src={SelectProfileX33} alt="" />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      {/* <div className="three d-flex justify-content-center align-items-center">
                        <img src={LogoWithName} alt="img" />
                        <h6 className="seekh ms-2">{process.env.SITE_NAME}</h6>
                      </div> */}
                      <div
                        className="text-center 
                        "
                      >
                        <h1 className="text-dark account">Sign Up</h1>
                        <p className="m-0 p-0 signs_platform mb-3">
                          Just sign in if you have an account in here.
                          <br />
                          Enjoy our Platform
                        </p>
                      </div>
                      <div className="row mx-auto">
                        {/* google and facebook signin login starts  */}
                        {/* <FacebookLoginOne /> */}
                        <GoogleLoginOne />
                        {/* google and facebook signin login ends  */}
                      </div>
                      <div className="row 3 mb-2 mt-4 d-flex justify-content-center align-items-center">
                        <div className="col text-center">
                          <p className="m-0 outline_div font_family d-flex align-items-center text-dark justify-content-center">
                            <span className="me-2 "></span>
                            OR
                            <span className="ms-2"></span>
                          </p>
                        </div>
                      </div>
                      {/* <div className="row mx-auto d-flex justify-content-center align-items-center"></div> */}
                      <label
                        for="email"
                        className="  mb-2 mt-3 label_text font_family p-0"
                      >
                        Email
                      </label>
                      <div className="inputEyes_div d-flex align-items-center justify-content-center mb-3 p-0 overflow-hidden">
                        <input
                          value={this.state.email}
                          onChange={(e) => {
                            this.onChange(e);
                          }}
                          type="text"
                          placeholder="Type your email"
                          name="email"
                          className="p-3 border-0"
                        />
                      </div>
                      {this.state.errors.email.message ? (
                        <p className="text-danger mt-2 p-0 mb-0">
                          {this.state.errors.email.message}
                        </p>
                      ) : null}
                      <label
                        for="psw"
                        className="mb-2 font_family label_text p-0"
                      >
                        Create Password
                      </label>
                      <div className="inputEyes_div mb-3 position-relative d-flex align-items-center justify-content-center p-0 overflow-hidden">
                        <input
                          value={this.state.password}
                          onChange={(e) => {
                            this.onChange(e);
                          }}
                          type={this.state.passwordShow ? "text" : "password"}
                          placeholder="Type your password"
                          name="password"
                          className="p-3 border-0"
                        />
                        <div className="position-absolute end-0">
                          <i
                            className={
                              this.state.passwordShow
                                ? "fa fa-eye text-black eyes_Icon me-2"
                                : "fa fa-eye-slash stag eyes_Icon me-2"
                            }
                            aria-hidden="true"
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                passwordShow: !this.state.passwordShow,
                              });
                            }}
                          ></i>
                        </div>
                      </div>

                      {this.state.errors.password.message ? (
                        <p className="text-danger mt-2 p-0">
                          {this.state.errors.password.message}
                        </p>
                      ) : null}

                      <label
                        for="psw"
                        className="mb-2 font_family label_text p-0"
                      >
                        Confirm Password
                      </label>
                      <div className="inputEyes_div position-relative d-flex align-items-center justify-content-center p-0 overflow-hidden">
                        <input
                          value={this.state.con_password}
                          onChange={(e) => {
                            this.onChange(e);
                          }}
                          type={this.state.passwordShoww ? "text" : "password"}
                          placeholder="Type your password"
                          name="con_password"
                          className="p-3 border-0"
                        />
                        <div className="position-absolute end-0">
                          <i
                            className={
                              this.state.passwordShoww
                                ? "fa fa-eye text-black eyes_Icon me-2"
                                : "fa fa-eye-slash stag eyes_Icon me-2"
                            }
                            aria-hidden="true"
                            onClick={() => {
                              this.setState({
                                ...this.state,

                                passwordShoww: !this.state.passwordShoww,
                              });
                            }}
                          ></i>
                        </div>
                      </div>

                      {this.state.errors.con_password.message ? (
                        <p className="text-danger mt-2 p-0">
                          {this.state.errors.con_password.message}
                        </p>
                      ) : null}

                      <div className="clearfix border-0 mt-3">
                        {!this.state.loader ? (
                          <button
                            onClick={() => {
                              this.onSubmit();
                            }}
                            type="button"
                            className="web_button"
                          >
                            Sign Up
                          </button>
                        ) : (
                          <button type="button" className="web_button">
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </button>
                        )}
                      </div>

                      <div className="trial mt-3 text-dark text-center">
                        <p className=" text p-0 m-0 confirm ">
                          <span>Already have an account?</span>
                        </p>

                        <Link to="/signin" variant="body2">
                          <p className="m-0 p-0 signIn_trial font_family">
                            Sign in to Seekh
                          </p>
                        </Link>
                      </div>
                    </div>
                    <div className="col-4 d-flex justify-content-center">
                      <div className="signIn_img  d-flex justify-content-center mt-5">
                        <img src={SelectProfileX32} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const maptStateToProps = (state) => {
  return {
    isProcessing: getProcessing(state),
    errorMessage: getError(state),
  };
};

export default compose(connect(maptStateToProps, { signUp, unloadAuthPage }))(
  SignUp
);

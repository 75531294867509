import React, { useState, useEffect } from "react";
import "./addContent.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { IIcon } from "../../../../staticContent/image";
import AddCourse from "./AddNewContent/AddContent";
import EditCourse from "./EditContent/EditContent";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useLocation } from "react-router-dom";
// import { Steps, Hints } from "intro.js-react";

// import "intro.js/introjs.css";

const AddContent = (props) => {
  const location = useLocation();
  const [key, setKey] = useState("home");
  const [tooltip, setTooltip] = useState(false);
  const [redirect, setRedirect] = useState("");
  
  useEffect(() => {
    setTooltip(false);
    setKey(location.state == undefined ? "home" : location.state.tab);
    setRedirect(location?.state?.redirection);
  }, []);

  return (
    <>

      {/* Add content main page :add content and  edit page starts */}
      <div className="col-lg-12">
        <div className="row mt-5 mb-3 mx-3">
          <div className="col-8 addcontentWalkTooltip">
            <h5 className="profile wrappers d-flex align-items-center mb-2">
            Add Content
              {/* <i className="fa fa-info-circle adding ms-2"></i> */}
              <div>
                <img
                  src={IIcon}
                  alt={""}
                  onMouseOver={() => {
                    setTooltip(true);
                  }}
                  onMouseOut={() => {
                    setTooltip(false);
                  }}
                  className="m-2 cp"
                />
              </div>
              {tooltip && (
                <div className="tooltips">
                  Upload PDFs, slides, research papers, or other materials directly from your computer.
                </div>
              )}
            </h5>

            <div className="col-12">
              <p className="para_text">
              Easily organize your learning materials all in one place. Add links to articles, videos, and other online resources, or upload files from your device to start building your library.
              </p>
            </div>
          </div>
        </div>

        <div className="row me-4 mx-3">
          <div className="col-12">
            <div className="row mx-auto border  enter">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                className="p-0 m-0 ps-4"
                variant="pills"
              >
                <Tab
                  eventKey="home"
                  title="Add Content"
                  className="m-0 p-0"
                >
                  {/* add course page starts  */}
                  <AddCourse setTab={setKey} />
                  {/* add course page ends  */}
                </Tab>
                <Tab
                  eventKey="profile"
                  title="Review & Finalize"
                  className="text-dark "
                >
                  {/* editcourse page starts  */}
                  <EditCourse setTab={setKey} redirect={redirect} />
                  {/* editcourse page ends  */}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      {/* Add content main page :add content and  edit page ends  */}
    </>
  );
};

export default AddContent;

import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../layouts/Header/Header";
import Topics from "./topic/topics";
import "./study.css";
import {
  Copy,
  Focus,
  ThreeDotsStudy,
  SideNoteLeft,
  copyFocus,
} from "../../staticContent/image";
import FlashCards from "./flashCards/flashCards";
import DocContent from "./DocContent/DocContent";
import VideoContent from "./videoContent/VideoContent";
import SummeryMode from "../Study/getsummery/SummeryMode";
import { 
    getFlashCards, 
    getSummeryAction, 
    getToolTipInfo, 
    firstTimeVidLogin, 
    addTime, 
    getTopics, 
    getHtml, 
    addFocusData 
} from "../../store/actions/study";

import { getPracticeData } from "../../store/actions/practice";
import { getUserProfile } from "../../store/actions/getUserProfile";
import SidebarNotes from "./sidebarNotesMenu/sidebarNotesMenu";
import NotesDialague from "./addnotesdialogue/addnotesdialogue";
import InfoTooltips from "./infoTooltip/infoTooltip";
import { Steps, Hints } from "intro.js-react";
import { useReactToPrint } from "react-to-print";
import "intro.js/introjs.css";


function Studyy() {
  const dispatch = useDispatch();
  const history = useHistory();
  const videoPlayerRef = useRef(null);
  const store = useSelector((state) => state);
  const [getContent, setContent] = useState([]);
  const [contentType, setContentType] = useState("video");
  const [getUrlVid, setUrlVid] = useState("");
  const [highlightContent, setHighlightContent] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const [highlightId, setHighlighId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [TopicHead, setTopicHead] = useState("Topics");
  const [showmode, setShowMode] = useState(false);
  const [Flashcarddata, setFlashcarddata] = useState([]);
  const [flashCardLoader, setFlashCardLoader] = useState(false);
  const [practiceLoader, setPracticeLoader] = useState(false);
  const [summary, setSummery] = useState("");
  const [showNotesDialauge, setShowNotesDialauge] = useState(false);
  const [isSummary, setIsSummary] = useState(false);
  const [summaryLoader, setSummaryLoader] = useState(false);
  const [isShowVideoContent, setIsShowVideoContent] = useState(false);
  const [selectedProps, setSelectedProps] = useState(null);
  const [autoplay, setAutoPlay] = useState(false);
  const [sideLoader, setSideLoader] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [sidebarData, setSidebarData] = useState({});
  const [infoStep, setInfoStep] = useState(false);
  const [isFlashCards, setIsFlashCards] = useState(false);
  const [mode, setMode] = useState("study");
  const [wallkThroughVideo, setwallkThroughVideo] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const [docLoader, setDocLoader] = useState(false);
  const [stepss, setStepss] = useState({
    initialStep: 0,
    info: [
      {
        element: ".offcanvas-body",
        intro:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elitId nunc eget semper pharetra diam at pharetra, sit",
        position: "left",
      },
    ],
  });
  const { stepsEnabled, info, initialStep } = stepss;
  const [stepsVideo, setStepsVideo] = useState({
    video: true,
    initial: 0,
    stepsVid: [
      {
        element: ".notesGuide",
        intro:
          "Follow along with the video using the transcript. You can bookmark sections and search through the transcript.",
        position: "left",
        tooltipClass: "sixtooltip",
      },
      {
        element: ".table_of_content_guide",
        intro:
          "Open the list of topics covered in this video, along with timestamps for when each topic is introduced.",
        position: "top",
        tooltipClass: "seventooltip",
      },
      {
        element: ".tableofvideo",
        intro:
          "See the key topics and timestamps as you watch. Click on a topic to jump to that part of the video.",
        position: "top",
        tooltipClass: "eighttooltip",
      },
    ],
  });
  const { stepsVid, video, showBullets, initial, hintsEnabled, hints } =
    stepsVideo;
  const topic_id = store?.getStudyTopic?.title?._id;
  const startTime = useRef();
  const endTime = useRef();
  const HighlighContent = (data) => {
  };
  const sideToggle = (type) => {
    setIsOpen(type);
  };
  const hanldeThreedotsClick = () => {
    setShowMode(!showmode);
  };
  const openAddNotesDialauge = (value) => {
    setShowNotesDialauge(value);
  };

  const handleSummary = (data) => {
    setTopicHead(data?.topic);

    setIsSummary(data.isSummary);
  };
  const setTableContent = () => {
    setIsShowVideoContent(false);
  };
  const onSelectedPropsText = (data) => {
    setSelectedProps(data);
  };
  const searchCourseInfo = () => {
    setSideLoader(true);
    setOpenSidebar(true);
    const titleId = store?.getStudyTopic?.title?._id;
    dispatch(getToolTipInfo(titleId, selectedProps)).then((res) => {
      setSidebarData(res);
      setSideLoader(false);

      setInfoStep(true);
    });
  };

  var path = history.location.pathname;
  const getScreen = () => {
    if (path === "/study") {
      if (contentType !== "video") {
        return (
          <DocContent
            docLoader={docLoader}
            html={htmlContent}
            highlightId={highlightId}
            handleSummary={handleSummary}
            selectedPara={onSelectedPropsText}
            infoTooltip={searchCourseInfo}
            openNotes={openAddNotesDialauge}
          />
        );
      } else {
        return (
          <div className="tableofvideo">
            <VideoContent
              videoPlayerRef={videoPlayerRef}
              getsubTopic={store?.getSubTopic}
              setTableContent={setTableContent}
              show={isShowVideoContent}
              Autoplay={autoplay}
              getContent={getContent}
              urlVid={getUrlVid}
            />
          </div>
        );
      }
    } else if (path === "/study/flashcards") {
      return (
        <FlashCards
          cards={Flashcarddata}
          isPdf={isPdf}
          bottomButton={true}
          setSideBar={sideToggle}
          getShowType={contentType}
          isOpen={isOpen}
          handleSummary={handleSummary}
        />
      );
    } else if (path === "/study/summary") {
      return (
        <SummeryMode
          title={getContent?.title?.title}
          isOpen={isOpen}
          getSummery={summary}
          handleSummary={handleSummary}
          setSideBar={sideToggle}
        />
      );
    } else {
      if (contentType !== "video") {
        return (
          <DocContent
            html={highlightContent}
            highlightId={highlightId}
            docLoader={docLoader}
            handleSummary={handleSummary}
            selectedPara={onSelectedPropsText}
            openNotes={openAddNotesDialauge}
          />
        );
      } else {
        return (
          <div className="tableofvideo">
            <VideoContent
              videoPlayerRef={videoPlayerRef}
              getsubTopic={store.getSubTopic}
              setTableContent={setTableContent}
              show={isShowVideoContent}
              Autoplay={autoplay}
              getContent={getContent}
              urlVid={getUrlVid}
            />
          </div>
        );
      }
    }
  };

  const handleFocusMode = () => {
    if (contentType === "video") {
      let data = {
        content: store?.getStudyTopic,
        // fontFamily: selectTextMode,
        getShowType: contentType,
        // getUrlVid: getUrlVid,
        type: "focus",
        // focus:showSubTopic,
        topicdata: store?.getSubTopic?.data?.response[0]?.s3definition,
      }
     dispatch( addFocusData(data))
      history.push({
        pathname: "/study/focusmode",
        state: {
          content: store?.getStudyTopic,
          // fontFamily: selectTextMode,
          getShowType: contentType,
          // getUrlVid: getUrlVid,
          type: "focus",
          // focus:showSubTopic,
          topicdata: store?.getSubTopic?.data?.response[0]?.s3definition,
        },
      });
    } else {
      let data =  {
        // content: store?.getStudyTopic,
        // fontFamily: selectTextMode,
        getShowType: contentType,
        // getUrlVid: getUrlVid,
        type: "focus",
        focus: "" //document.getElementById("textHilight").innerHTML,
        // topicdata: store?.getSubTopic?.data?.response[0]?.topic_definition,
      }
      dispatch( addFocusData(data))
      history.push({
        pathname: "/study/focusmode",
        state: {
          // content: store?.getStudyTopic,
          // fontFamily: selectTextMode,
          getShowType: contentType,
          // getUrlVid: getUrlVid,
          type: "focus",
          focus: "" //document.getElementById("textHilight").innerHTML,
          // topicdata: store?.getSubTopic?.data?.response[0]?.topic_definition,
        },
      });
    }
  };

  const generateSummery = () => {
    const titleId = store?.getStudyTopic?.title?._id;
    setSummaryLoader(true);
    dispatch(getSummeryAction(titleId)).then((res) => {
      setSummaryLoader(false);
      setSummery(res?.data?.summary);
      setShowMode(false);
      setTopicHead("Table of Contest");
      if (res.status === 200) {
        history.push("/study/summary");

        setIsSummary(true);
      } else {
        setIsSummary(true);
      }
    });
  };

  const handleFlashhCards = () => {
    setFlashCardLoader(true);
    const fileId = store?.getStudyTopic?.title._id;
    dispatch(getFlashCards(fileId)).then(
      (res) => {
      
        setShowMode(false);
        setFlashcarddata(res?.data?.flashCards);
        if (res.data.status === 200) {
          history.push("/study/flashcards");
          setFlashCardLoader(false);
        } else {
          setShowMode(false);
          setFlashCardLoader(false);
        }
      },
      (err) => {
        setFlashCardLoader(false);
      }
    );
  };
  useEffect(() => {
    setHighlighId(store?.getSubTopic?.title?._id);
  }, [store?.getSubTopic]);
  const handlePractice = () => {
    setPracticeLoader(true);
    let file_ID = store?.getStudyTopic?.title?._id;
    dispatch(getPracticeData(file_ID)).then((res) => {
      if (res.data.status == 200) {
        history.push({
          pathname: "/practice",
          state: { prevPath: "videopractice" },
        });
        setPracticeLoader(false);
      } else {
        setPracticeLoader(false);
      }
    });
  };

  useEffect(() => {
    setIsSummary(false);
    setContent(store?.getStudyTopic);
    var data = store?.getStudyTopic;
    var showType = "";
    if (data?.title) {
      var docType = data?.title?.type;
      if (docType === "youtube url") {
        var urlVid = "";
        showType = "video";
        urlVid = data?.title?.s3Key;
        if (
          store?.getUserProfile?.data?.responseData?.firstTimeLoginVideo ===
          true
        ) {
          setwallkThroughVideo(true);
        }

      } else if (
        docType === "video/mp4" ||
        docType === "video/mov" ||
        docType === "video/wmv" ||
        docType === "video/avi" ||
        docType === "video/mvk" ||
        docType === "video/html5"
      ) {
        showType = "video";
        urlVid =
          "https://seekh-dev-local.s3.ap-south-2.amazonaws.com/" + data?.title?.s3Key;
        if (
          store?.getUserProfile?.data?.responseData?.firstTimeLoginVideo ===
          true
        ) {
          setwallkThroughVideo(true);
        }

      } else {
        showType = "doc";
        setwallkThroughVideo(false);
      }
      setUrlVid(urlVid);
      setContentType(showType);
    }
  }, []);

  useEffect(() => {
    if (path === "/study") {
      setMode("study");
    } else if (path == "/study/flashcards") {
      setMode("flashcards");
    } else if (path == "/study/summary") {
      setMode("summary");
    } else {
      setMode("study");
    }
  }, [path]);

  
  useEffect(() => {
    const id = store?.getStudyTopic?.title._id;
    const d = new Date();
    startTime.current = d.getTime();
    return () => {
      const dx = new Date();
      endTime.current = dx.getTime();
      const body = {
        studyTime: endTime.current - startTime.current,
        fileId: id,
      };
      dispatch(addTime(body));
    };
  }, []);


  useEffect(() => {
    setDocLoader(true);
    let fileId = store?.getStudyTopic?.title._id;

    dispatch(getTopics(fileId)).then((res) => {
      setDocLoader(false);
      setHighlightContent(res?.response[0]?.topicId?.definition);
    });
  }, []);

  useEffect(() => {
    setDocLoader(true);
    let fileId = store?.getStudyTopic?.title.documentHash;
      dispatch(getHtml(fileId)).then((res) => {
      setDocLoader(false);
       setHtmlContent(res);
    });

  }, []);

  const downloadFileDocument = useReactToPrint({
    content: () => document.querySelector(".questionExportPdvf"),
    // setPdf("auto")
  });
  const handleExportPdf = () => {
    setIsPdf(true);
    setTimeout(() => {
      downloadFileDocument();
      setIsPdf(false);
    }, 1000);
  };

  const Tags = getContent?.title?.tags?.split(",");
  return (
    <div className="main_bgColorSho">
      <Header />

      {wallkThroughVideo && (
        <Steps
          enabled={video}
          steps={stepsVid}
          initialStep={initialStep}
          onComplete={() => {
            dispatch(firstTimeVidLogin());
            setwallkThroughVideo(false);
            dispatch(getUserProfile(store.auth?.user?.id));
          }}
          onExit={() => {
            dispatch(firstTimeVidLogin());
            setwallkThroughVideo(false);
            dispatch(getUserProfile(store.auth?.user?.id));
          }}
          onChange={(e) => {
            if (e == 2) {
              setIsShowVideoContent(true);
            }
          }}
          options={{
            showButtons: true,
            showBullets: false,
            prevLabel: "Previous",
            skipLabel: "Skip",
            // skipLabel:"skip"
          }}
        />
      )}
      <div className="d-flex">
        <div className="container-fluid">
          <div className="row ">
            <div
              className={
                !isOpen
                  ? "col-lg-10 pt-5"
                  : "col-lg-12 pt-5 add_padding_close_sidebar"
              }
            >
              <div className="row mx-auto mt-2">
                <div className="col-md-12 ms-2 mt-5 mb-4 d-flex justify-content-start align-items-center">
                  <div className="row">
                    <div className="col-12 ">
                      <Link to={"library/overview"} className="m-0 study-link">
                        Library
                        <i
                          className="fa fa-angle-right mx-2"
                          aria-hidden="true"
                        ></i>
                      </Link>
                      <Link
                        to={"/study"}
                        className="m-0 study-link"
                        style={{ cursor: "default" }}
                      >
                        Study
                        <i
                          className="fa fa-angle-right mx-2"
                          aria-hidden="true"
                        ></i>
                      </Link>
                      <Link
                        className="m-0 study-link-title"
                        style={{ cursor: "default" }}
                        to={"/study"}
                      >
                        {getContent?.title?.title}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row">
                    {contentType !== "video" && (
                      <div className="col-2  ">
                        <div className="row bg-light rounded-4 mx-auto topicsstudyTooltip ">
                          <div className="col-12">
                            <div className="row border-bottom mx-auto ">
                              <div
                                className="col-12 pt-4 pb-2 "
                                style={{ height: "50px", marginTop: "0px" }}
                              >
                                <div
                                  className="position-absolute"
                                  style={{ left: "32px" }}
                                >
                                  <h6 className=" studyTopics ms-2 ">
                                    {TopicHead}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="row mx-auto  ">
                              <div className="col-12 pb-3  ">
                                <Topics
                                  setTopicContent={HighlighContent}
                                  topicData={getContent}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* main content   */}

                    <div
                      className={
                        contentType !== "video"
                          ? !isOpen
                            ? "col-10"
                            : "col-10 add_padding_close_sidebar_doc"
                          : "col-12"
                      }
                    >
                      {mode !== "summary" ? (
                        <div className="row mx-auto">
                          <div className="col-12 mb-2">
                            <h6 className="study-human m-0">
                              {getContent?.title?.title}
                            </h6>
                          </div>
                          <div className="col-12">
                            <p className="study-noah m-0">
                              {getContent?.title?.title}
                            </p>
                          </div>
                          <div className="col-12 mt-1 ">
                            <div className="row">
                              <div className="col-6 d-flex align-items-center justify-content-start">
                                {Tags?.length > 0 &&
                                  Tags?.map((ele, ind) => {
                                    return (
                                      <div className=" ">
                                        <button
                                          // type="button"
                                          className="btn border-0 btn-light offcan-btns-three shadow-none me-3 hover_remove practice_btn_diff_color"
                                        >
                                          {ele}
                                        </button>
                                      </div>
                                    );
                                  })}
                              </div>

                              {mode == "flashcards" ? (
                                <div class="col-6 d-flex align-items-center  justify-content-end  study-science">
                                  <button
                                    type="button"
                                    class="btn shadow-none colorfull d-flex align-items-center text-none  me-3"
                                    style={{ fontWeight: 700 }}
                                  >
                                    Add to Library
                                  </button>
                                  <button
                                    onClick={handleExportPdf}
                                    type="button"
                                    class="btn border shadow-none colorfulls text-white "
                                  >
                                    Export PDF
                                  </button>
                                </div>
                              ) : (
                                <div className="col-6 position-relative d-flex align-items-center justify-content-end">
                                  <img
                                    src={ThreeDotsStudy}
                                    alt={""}
                                    onClick={hanldeThreedotsClick}
                                    className="  px-3 py-2 border rounder-3 d-flex align-items-center justify-content-center study-option"
                                    aria-hidden="true"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              )}

                              {showmode && (
                                <div className="col justify-content-end d-flex ">
                                  <div className="row mx-auto mt-2 studyGenerate bg-white position-absolute border p-2 d-flex align-items-center justify-content-end flex-column">
                                    {!summaryLoader ? (
                                      <div
                                        className="col-12  summaryStudy"
                                        onClick={() => {
                                          generateSummery();
                                        }}
                                      >
                                        <h6 className="m-0 p-0 py-2 studyGenerateFlashcards">
                                          Generate summary
                                        </h6>
                                      </div>
                                    ) : (
                                      <div
                                        class="spinner-border studyGenerateFlashcards"
                                        role="status"
                                      >
                                        <span class="sr-only">Loading...</span>
                                      </div>
                                    )}

                                    <div
                                      className="col-12  summaryStudy"
                                      onClick={() => {
                                        handleFlashhCards();
                                      }}
                                    >
                                      {!flashCardLoader ? (
                                        <h6 className="m-0 p-0 py-2 studyGenerateFlashcards">
                                          Generate flashcards
                                        </h6>
                                      ) : (
                                        <div
                                          class="spinner-border studyGenerateFlashcards"
                                          role="status"
                                        >
                                          <span class="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={
                            contentType !== "video"
                              ? !isOpen
                                ? "col-10"
                                : "col-10 add_padding_close_sidebar_doc "
                              : "col-12"
                          }
                        >
                          <div className="row mx-auto">
                            <div className="col-12">
                              <h5 style={{ fontWeight: "700" }}>
                                Generated Summary
                              </h5>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row  my-4 mx-auto ">{getScreen()}</div>
                      {mode == "study" && (
                        <div className="row mx-auto d-flex align-items-center justify-content-end mt-4 pe-0">
                          <div className="col-5 d-flex align-items-center  justify-content-end  study-science me-0 pe-0">
                            {contentType === "video" && (
                              <button
                                style={
                                  isShowVideoContent
                                    ? {
                                        backgroundColor: "#8852ff",
                                        color: "white",
                                      }
                                    : {}
                                }
                                onClick={() => {
                                  setIsShowVideoContent(!isShowVideoContent);
                                }}
                                type="button"
                                className="btn colorfull shadow-none  me-3 table_of_content_guide d-flex align-items-center justify-content-center"
                              >
                                Table of contents
                                {isShowVideoContent ? (
                                  <img
                                    src={copyFocus}
                                    alt=""
                                    className=" study-imageHover ms-2 "
                                  />
                                ) : (
                                  <img
                                    src={Copy}
                                    alt=""
                                    className="study-imageHover ms-2 "
                                  />
                                )}
                              </button>
                            )}
                            <button
                              disabled={docLoader}
                              onClick={() => {
                                handleFocusMode();
                              }}
                              type="button"
                              className="btn shadow-none colorfull d-flex align-items-center   me-3"
                            >
                              Focus
                              <img
                                src={Focus}
                                alt=""
                                className="study-image1 ms-2 "
                              />
                            </button>
                            {practiceLoader ? (
                              <div
                                className="spinner-border me-4"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <button
                                onClick={() => {
                                  handlePractice();
                                }}
                                type="button"
                                className="btn shadow-none me-2 colorfulls text-white wraps-tablemode  border position-relative d-flex align-items-center justify-content-center"
                              >
                                Practice
                              </button>
                            )}
                            {isOpen === true && (
                              <div
                                onClick={() => {
                                  sideToggle(false);
                                }}
                                className="row position-absolute imageHideSidebar"
                              >
                                <div className="col d-flex align-items-center justify-content-end">
                                  <img
                                    src={SideNoteLeft}
                                    alt=""
                                    className="study-image-next"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`col-2 pt-5 border-start  ${isOpen ? "collapse" : ""}`}
            >
              <SidebarNotes
                // onClick={Play}
                data={getContent}
                showType={contentType}
                openAddNotesDialauge={openAddNotesDialauge}
                getNotes={store.notesList?.responseData}
                NotesId={topic_id}
                setSideBar={sideToggle}
                videoPlayerRef={videoPlayerRef}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <NotesDialague
          show={showNotesDialauge}
          setShow={setShowNotesDialauge}
          NotesId={topic_id}
          type={"add"}
        />
      </div>
      <div className="">
        <InfoTooltips
          sideLoader={sideLoader}
          data={sidebarData}
          show={openSidebar}
          selectedText={selectedProps}
          setShow={setOpenSidebar}
          onHandlePractice={handlePractice}
          practiceLoader={practiceLoader}
        />
      </div>
    </div>
  );
}

export default Studyy;

import {
  GET_PLAYLIST,
  SEARCH_CONTENT_FOR_PLAYLIST,
  EDIT_PLAYLIST,
  DELETE_PLAYLIST,
  CREATE_NEW_PLAYLIST,
  BROWSE_PLAYLIST_CONTENT,
  ADD_CONTENT_FOR_PLAYLIST,
  ADD_CONTENT_To_PLAYLIST
} from "./types";
import mernApi from "../apis/mern";

// Create Playlist first screen
export const createPlaylist = (userId) => (dispatch) => {
  return mernApi
    .get(`api/playlist/createPlaylist/static?userId=${userId}`)
    .then((data) => {

      if (data.status === 200) {
        dispatch({
          type: CREATE_NEW_PLAYLIST,
          payload: data.data,
        });
      }
      return data;
    });
};

export const getPlaylist = (id) => (dispatch) => {
  return mernApi
    .get(`api/playlist/getUserPlayList?user_id=${id}`)
    .then((data) => {
      if (data.status === 200) {
        dispatch({
          type: GET_PLAYLIST,
          payload: data,
        });
      }
    });
};

export const playlistContent = (user_id, playListId) => (dispatch) => {
  return mernApi
    .get(
      `api/library/getPlayListContent?user_id=${user_id}&playListId=${playListId}`
    )
    .then((data) => {
      if (data.status === 200) {
        dispatch({
          type: BROWSE_PLAYLIST_CONTENT,
          payload: data,
        });
      }
      return data
    });
};
// 62ee56a452e4b69bef0bcefa
// 62ee558c52e4b69bef0bce93
export const editPlaylist = (body) => (dispatch) => {
  return mernApi.put(`api/playlist/updatePlayList`, body).then((data) => {
    if (data.status === 200) {
      dispatch({
        type: EDIT_PLAYLIST,
        payload: data?.data?.responseData,
      });
    }
    return data
  });
};

export const deletePlaylist = (playlist_id, user_id) => (dispatch) => {
  return mernApi
    .delete(
      `api/playlist/deletePlayList?user_id=${user_id}&playlist_id=${playlist_id}`
    )
    .then((data) => {
      if (data.status === 200) {
        dispatch({
          type: DELETE_PLAYLIST,
          payload: data.data.responseData,
        });
      }
    });
};

export const searchContentForPlaylist = (tittle, user_id) => (dispatch) => {
  return mernApi
    .get(`api/library/searchCourseName?tittle=${tittle}&user_id=${user_id}`)
    .then((data) => {
      if (data.status === 200) {
        dispatch({
          type: SEARCH_CONTENT_FOR_PLAYLIST,
          payload: data,
        });
      }
    });
};

export const addContentForPlaylist = (body) => (dispatch) => {
  return mernApi.put(`api/library/addPlayListContent`, body).then((data) => {
    if (data.status === 200) {
      dispatch({
        type: ADD_CONTENT_FOR_PLAYLIST,
        payload: data,
      });
    }
  });
};

export const clearPlaylistFilter = (data) => (dispatch) => {
  dispatch({
    type: ADD_CONTENT_FOR_PLAYLIST,
    payload: data,
  });
};
// export const countItems = (user, playList_id) => (dispatch) => {
//   return mernApi.get(`api/playlist/createPlaylist/items?userId=${user}&playList_Id=${playList_id}`).then((data) => {
//     if (data.status === 200) {
//       dispatch({
//         type: "count-items",
//         payload: data,
//       });
//     }
//   });
// };

export const courseSearch = (course, user_id) => (dispatch) => {
  return mernApi
    .get(`api/library/CourseName?course=${course}&user_id=${user_id}`)
    .then((data) => {
      if (data.status === 200) {
        dispatch({
          type: "SEARCH_COURSE",
          payload: data,
        });
      }
      return data;

    }
    );

};

export const clearCourseName = (data) => (dispatch) => {
  dispatch({
    type: "SEARCH_COURSE_FILTER",
    payload: data,
  });
};

export const AddToPlaylist = (body)=>(dispatch)=>{

  return mernApi
  .put(`/api/library/addPlayListContent`,body).then((data)=>{
    if(data.status===200){
      dispatch({
        type:ADD_CONTENT_To_PLAYLIST,
        payload:data,
      })
    }
    return data;
  },(err)=>{
  }
  )
}


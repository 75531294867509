import {
    GET_USER_PROFILE, GET_USER_PASSWORD
} from "./types.js"
import mernApi from '../apis/mern';

export const getUserProfile = (user_id) => (dispatch) => {
    return mernApi.get(`api/users/getProfile?user_id=${user_id}`).then(data => {
        if (data.status === 200) {
            dispatch({
                type: GET_USER_PROFILE,
                payload: data
            })
        }
    })
};


export const getPasswordCheck = (body) => (dispatch) => {
    return mernApi.post(`api/users/checkCurrentPassword`, body).then(data => {
        if (data.status === 200) {
            dispatch({
                type: GET_USER_PASSWORD,
                payload: data
            })
        }
    })
}

export const passwordChange = (body) => (dispatch) => {
    return mernApi.post(`api/users/changePassword`, body).then(data => {
        if (data.status === 200) {
            dispatch({
                type: "password_change",
                payload: data
            })
        }
    })
}

export const updateProfile = (body) => (dispatch) => {
    return mernApi.put(`api/users/updateProfile`, body).then(data => {
        if (data.status === 200) {
            dispatch({
                type: "update_profile",
                payload: data
            })
        }
        return data
    })
}


export const emailSetting = (body) => (dispatch) => {
    return mernApi.post(`api/users/addEmailNotification`, body).then(data => {
        if (data.status === 200) {
            dispatch({
                type: "emailSetting",
                payload: data
            })
        }
    })
}


export const removeAvatar = (id) => (dispatch) => {
    return mernApi.put(`api/users/removeAvatar?user_id=${id}`).then(data => {
        if (data.status === 200) {
            dispatch({
                type: "removeAvataar",
                payload: data
            })
        }
    })
}


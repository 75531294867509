import React, { useState, useEffect } from "react";
import {
  AvatarCardM2,
  DefaultImg,
  carosual,
  addThumnails,
  CloseSearch,
  searchClose,
  learn_button,
} from "../../../staticContent/image";
import "../Overview/Overview.css";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getrecommendContent,
  countinueStudyList,
  searchCourseNameList,
  clearSearchbar,
  searchedContents,
  searchCourseAll,
} from "../../../store/actions/libraryOverview";
import SidebarCanvas from "./CanvasSidebar/sidebarCanvas";
import Skeleton from "react-loading-skeleton";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getTopics, dailyLoginCheck } from "../../../store/actions/study";
import axios from "axios";
import { ControllerClassicOutline } from "mdi-material-ui";
import { set } from "lodash";
import { getNotification } from "../../../store/actions/practice";
// import { Steps, Hints } from "intro.js-react";

// import "intro.js/introjs.css";
import Overview from "../../Practice/practiceSidebar/overview";

const LibraryOverview = (props) => {
  const history = useHistory();
  const data = useParams();
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [key, setKey] = useState("home");
  const [tooltip, setTooltip] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [sidebarData, setSidebarData] = useState({});
  const [showSearchModel, setShowSearchModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [sideLoader, setSideLoader] = useState(false);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    // pre selected data in oerview page api :
    setLoader(false);
    dispatch(getrecommendContent(store.auth?.user?.id)).then((res) => {
      setLoader(true);
    });
    dispatch(dailyLoginCheck());
    // overview page coninue study list api
    // dispatch(countinueStudyList(store.auth?.user?.id));
    // overview page clear serachbar api implementation
    dispatch(clearSearchbar());
  }, [store.addTime]);

  // overview serachbar for all adeed content function start:
  const searchFilterInput = (e) => {
    const userId = store.auth?.user?.id;
    let value = e.target.value;
    setSearchInput(value);
    if (value?.trim() === "") {
      setShowSearchModal(false);
      setSearchInput("");
      dispatch(clearSearchbar());
    } else {
      if (value?.length >= 3) {
        setShowSearchModal(true);
        dispatch(searchCourseNameList(e.target.value?.trim(), "all", userId));
      } else {
        setShowSearchModal(false);
      }
    }
  };
  // Overview search bar for all adeed content function end

  // Overview top search for course vise search api implementation function start
  const courseSearch = () => {
    const userId = store.auth?.user?.id;
    dispatch(searchCourseNameList(searchInput, "course", userId));
    history.push(`/library/overview/browsecourse`);
  };
  // Overview top search for course vise search api implementation function start

  // Overview top search for topic vise search api implementation function start
  const searchTopic = () => {
    const userId = store.auth?.user?.id;
    dispatch(searchCourseNameList(searchInput, "topic", userId));
    history.push({
      pathname: "/library/overview/browsecourse",
      state: {
        type: "topic",
      },
    });
  };
  // Overview top search for topic vise search api implementation function end

  // Overview top search for see all result search api implementation function start:
  const searchCourseAlls = (item) => {
    const userId = store.auth?.user?.id;
    dispatch(searchCourseAll(searchInput, "all", userId));
    history.push({
      pathname: `/library/overview/browsecourse`,
      state: { title: item.title },
    });
  };

  // Overview top search for see all result search api implementation function end:

  const searchSelectedCourse = (item) => {
    const userId = store.auth?.user?.id;
    dispatch(searchedContents(item.title, userId));
    history.push({
      pathname: `/library/overview/browsecourse`,
      state: { title: item.title },
    });
  };

  // Overview add content page function and route  start
  const addContentButton = () => {
    history.push({
      pathname: `/library/overview/addcontent`,
      state: {
        tab: "home",
      },
    });
  };
  // Overview add content page function and route end
  // recomend content side bar data data and funtion  start:
  const handleClickRecomend = (item) => {
    setSideLoader(true);

    setOpenSidebar(true);
    dispatch(getTopics(item._id)).then((res) => {
      setSidebarData(res);
      setSideLoader(false);
    });
  };
  // recomend content side bar data and function end

  // useEffect(() => {
  //   let timer1 = setTimeout(() => setLoader(true), 1000);
  //   return () => {
  //     clearTimeout(timer1);
  //   };
  // }, []);
  // slice funtionu start
  function truncate(str, n) {
    return str?.length > n ? str.slice(0, n - 1) + "...." : str;
  }
  // slice function end
  const formatTime = (milliseconds) => {
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor(milliseconds / 1000 / 60 / 60);

    return [
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
    ].join(":");
  };
  //  const  handleProgressBar = (milli)=>{
  //    let milliInFiveHour = 18000000
  //    let milliseconds = parseInt(milli)
  //    if(milliseconds<milliInFiveHour){
  //      let progressPercent = milliseconds*100/milliInFiveHour
  //      return progressPercent+"%"
  //     }
  //     else{
  //       return "100%"
  //     }
  //   }
  // useEffect(() => {
  //   setTimeout(() => {
  //     setWalkThrough(true);
  //   }, 4000);
  // }, []);

  return (
    <>
      {/* {wallkThrough && (
        <Steps
          enabled={wallkThrough}
          steps={steps}
          onExit={() => {
            
          }}
          initialStep={initialStep}
          onComplete={() => {

          }}
          options={{
            showButtons: true,
            showBullets: false,
            prevLabel: "Previous",
            skipLabel: "Skip",

          }}
        />
      )} */}
      <div className="row mx-auto">
        <div className="col-lg-12 pe-4">
          {loader ? (
            <>
              <div className="row mx-auto">
                <div className="col-md-12 col-6 mb-md-0 mb-3 grid-margin stretch-card">
                  <div className="first mt-5">
                    <h4>Overview</h4>
                    <div className="row position-relative d-flex align-items-center mx-auto mt-3">
                      <div className="col-10 position-absolute top-0 bg-white search-data-all">
                        <div className="row border overflow-hidden">
                          <div className="col-12 d-flex align-items-center outline-div">
                            <i className="fa fa-search ms-2 me-3"></i>
                            <input
                              value={searchInput}
                              onChange={(e) => {
                                searchFilterInput(e);
                              }}
                              // type="search"
                              // id="search"
                              className="border-0 w-100 flex-grow-1 datasearch bg-transparent black "
                              placeholder="Search by keywords, tags, topic and etc..."
                            ></input>
                            {searchInput && (
                              <img
                                onClick={() => {
                                  setSearchInput("");
                                  dispatch(clearSearchbar());
                                }}
                                src={searchClose}
                                alt={""}
                              />
                            )}
                          </div>
                          <div className="search-data-lab overflow-scroll p-0 m-0">
                            {showSearchModel && (
                              <div className="">
                                {searchInput === "" ? null : (
                                  <div className="">
                                    <div
                                      className="col-12 input-data p-0 "
                                      style={{ cursor: "pointer" }}
                                    >
                                      <h6
                                        onClick={() => {
                                          courseSearch();
                                        }}
                                        className="px-4 py-2 m-0 overview-title-items"
                                      >
                                        {`Search topics for `}
                                        <span className="overview-diff-color">
                                          {searchInput}
                                        </span>
                                      </h6>
                                    </div>
                                    <div
                                      className="col-12 input-data p-0 border-bottom"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <h6
                                        onClick={() => {
                                          searchTopic();
                                        }}
                                        className="px-4 py-2 m-0 overview-title-items "
                                        style={{ cursor: "pointer" }}
                                      >
                                        {`Search courses for `}
                                        <span className="overview-diff-color">
                                          {searchInput}
                                        </span>
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                            <div className="all_search_data">
                              {store?.searchCourseName?.data
                                ?.slice(0, 4)
                                .map((item, index) => {
                                  return (
                                    <div
                                      className="col-12 input-data p-0"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <p
                                        className="px-4 py-2 mb-0 text-capitalize "
                                        onClick={() => {
                                          searchSelectedCourse(item);
                                        }}
                                      >
                                        {item.course_Name ? (
                                          <span>
                                            <h6 className="overview-title-items">
                                              {item.course_Name} - {item.title}
                                            </h6>
                                            <p
                                              className="overview-title-text my-2 m-0 p-0"
                                              style={{}}
                                            >
                                              {item.subHead}
                                            </p>{" "}
                                          </span>
                                        ) : (
                                          <span>
                                            <h6 className="overview-title-items">
                                              {item.title}
                                            </h6>
                                            <p
                                              className="overview-title-text my-2 m-0 p-0"
                                              style={{}}
                                            >
                                              {item.subHead}
                                            </p>{" "}
                                          </span>
                                        )}
                                      </p>
                                    </div>
                                  );
                                })}
                            </div>
                            {searchInput?.length > 0 && (
                              <div className="row mx-auto">
                                <div className="col-12 my-3 all-results">
                                  <button
                                    onClick={() => {
                                      searchCourseAlls({ title: searchInput });
                                    }}
                                    type="button"
                                    className="btn bg-light rounded-5 py-2 shadow-none result "
                                  >
                                    See all results
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2 ms-auto">
                        {/* Add content button start */}
                        <button
                          onClick={() => {
                            addContentButton();
                          }}
                          className="addcontent-btn ms-2 clearfixs border text-white w-100"
                        >
                          Add Content
                        </button>
                        {/* Add content button :overview page end    */}
                      </div>
                    </div>
                    {"\n"}       
                    <div className="col-12 overview-intro">
                      <div className="row mx-auto">
                        <h3 className="practice_main_heading m-0 practice_color text-capitalize">
                          Hello{" "}
                          {store?.getUserProfile?.data?.responseData?.firstName?.trim()},
                        </h3>
                        <p className="related_practice_para m-0 mt-2">
                          Lets learn something new today!
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <div className="col mt-5 mb-4 ">
                        <h4 className="m-0">Recommended for you</h4>
                      </div>
                      {/* no content on overview page with tooltip button start  */}
                      {!store?.recommendContents?.data?.responseData?.content?.length >
                        0 && (
                        <div className="row">
                          <div
                            className="col-auto"
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              onClick={() => {
                                addContentButton();
                              }}
                              src={addThumnails}
                              alt={""}
                              onMouseOver={() => {
                                setTooltip(true);
                              }}
                              onMouseOut={() => {
                                setTooltip(false);
                              }}
                              className="wrapperrs ms-3 topicsstudyTooltip"
                            />

                            {tooltip && (
                              <div className="tooltiaps mt-2">
                                Add some content to your library to start
                                studying !
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {/* no content on overview page with tooltip end  */}
                  </div>
                </div>
              </div>
              
              {/* content overview page with crousel start */}
              {store?.recommendContents?.data?.responseData && (
                <div className="row Recommended_carousel mx-auto col-12">
                  <Carousel
                    infinite={false}
                    autoPlay={false}
                    autoPlaySpeed={4000}
                    responsive={responsive}
                    containerclassName="w-100"
                  >
                    {store?.recommendContents?.data?.responseData?.content?.map(
                      (item, index) => {
                        return (
                          <div className="col me-4 height  carousel-card overflow-hidden">
                            <div
                              onClick={() => {
                                handleClickRecomend(item);
                              }}
                              className="mx-auto row"
                              key={`te33st${index}`}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="col-12 position-relative p-0">
                                <div className="row mx-auto">
                                  <div className="col-12  p-0 ">
                                    <img
                                      src={
                                        item.thumbnail
                                          ? item.thumbnail
                                          : DefaultImg
                                      }
                                      className="video_Imge"
                                      alt="..."
                                    />
                                  </div>
                                  <div className="col-12 position-absolute bottom-0 mb-3 ">
                                    {item?.category?.category_name && (
                                      <button
                                        className="btn text-white videoDoc_btn text-capitalize shadow-none rounded-pill  d-flex align-items-center justify-content-center"
                                        style={{
                                          backgroundColor: `${item?.category?.category_color}`,
                                        }}
                                      >
                                        {item?.category?.category_name}
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 py-3">
                                <div className="video_infoTxt">
                                  {/* className="card-h6 text-truncate mb-1" */}
                                  <h6 class="mb-1 card-h6 text-truncate">
                                    {item.title && truncate(item.title, 37)}
                                  </h6>
                                  {item?.authorName && (
                                    <p className="overView-crousel-name m-0">
                                      {item?.authorName}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </Carousel>
                </div>
              )}
              <Overview/>
              {/* content overview page with crousel end  */}
              {/*  */}
              {store?.recommendContents?.data?.responseData?.content?.length > 0 && (
                <div className="row mx-auto">
                  <div className="col first mt-5 mb-4 d-flex align-items-center ">
                    <h4 className="m-0">Continue Studying</h4>
                    <p className="ms-3 mt-2 mb-0 overviewViewAll">
                      View all{" "}
                      <i
                        className="fa fa-angle-right ms-1"
                        aria-hidden="true"
                      ></i>
                    </p>
                  </div>
                </div>
              )}
              {/* <Carousel infinite={true} responsive={responsive}> */}
              {store?.recommendContents?.data?.responseData && (
                <div className=" row mx-auto mb-4">
                  <Carousel infinite={false} responsive={responsive}>
                    {store?.recommendContents?.data?.responseData?.content?.map(
                      (item, ind) => {
                        return (
                          <div className="row me-4 ms-auto border overflow-hidden mb-1">
                            <div className="col-6 position-relative">
                              <div className="row">
                                <div className="col-12 p-0">
                                  <img
                                    src={item.thumbnail}
                                    className=" secondLakhni"
                                    alt="..."
                                  />
                                </div>
                                <div className="col-12 p-0 ps-2 position-absolute bottom-0 mb-1">
                                  {item?.category?.category_name && (
                                    <button
                                      className="btn text-white videoDoc_btn text-capitalize shadow-none rounded-pill  d-flex align-items-center justify-content-center"
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: `${item?.category?.category_color}`,
                                      }}
                                    >
                                      {item?.category?.category_name}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-6 py-2 d-flex px-auto">
                              <div className="row ">
                                <div className="col-12 mb-auto  d-flex flex-column justify-content-cente">
                                  <h6 className="card-data  m-0 d-flex align-items-start">
                                    {item.title && truncate(item.title, 23)}
                                  </h6>
                                  {/* {item?.authorName && (
                                    <p className="card-texts mb-2">
                                      {item?.authorName}
                                    </p>
                                  )} */}
                                  {/* <p className="m-0">testing data</p> */}
                                </div>
                                {/* <div className="col-12"></div> */}
                                {/* <div className="col-12 mt-1">
                                  
                                </div> */}
                                <div className="col-12 mt-auto">
                                  <p className="card-texts mb-0">
                                    {item.studyPageTimeTrack
                                      ? formatTime(item?.studyPageTimeTrack)
                                      : "0:00"}{" "}
                                    spent · Beginner
                                  </p>
                                  {/* <div className="sm_progressBar d-flex mt-2">
                                    <div className="col topic pe-1">
                                      <hr />
                                    </div>
                                    <div className="col topicc px-1">
                                      <hr />
                                    </div>
                                    <div className="col topicc px-1">
                                      <hr />
                                    </div>
                                    <div className="col topicc px-1">
                                      <hr />
                                    </div>
                                  </div> */}
                                  <div class=" mt-2 topic">
                                    <div class="progress progress_parent">
                                      <div
                                        class="progress-bar progress_bar"
                                        role="progressbar"
                                        aria-label="Basic example"
                                        aria-valuenow="25"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{
                                          width: `${
                                            item?.progressBarPercentage
                                              ? item?.progressBarPercentage
                                              : "0%"
                                          }`,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 mt-auto">
                                  <div className="row d-flex align-items-center justify-content-start">
                                    <div
                                      className="col-auto"
                                      style={{ marginRight: "-5px" }}
                                    >
                                      <p
                                        style={{ cursor: "pointer" }}
                                        className="card-data m-0 d-flex align-items-center"
                                        onClick={() => {
                                          handleClickRecomend(item);
                                        }}
                                      >
                                        Learn
                                      </p>
                                    </div>
                                    <div
                                      className="col-auto p-0 d-flex align-items-center justify-content-center rounded-pill"
                                      style={{
                                        width: "16px",
                                        height: "16px",
                                        backgroundColor: "#4E45C2",
                                      }}
                                    >
                                      <img
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          handleClickRecomend(item);
                                        }}
                                        alt=""
                                        className="w-100"
                                        src={learn_button}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </Carousel>
                </div>
              )}

            
              {/* </Carousel> */}

                   {/* content overview page with crousel end  */}
              {/*  */}
              
              {/* {store?.recommendContents?.data?.responseData?.allTopics?.length > 0 && (
                <>
                  { store?.recommendContents?.data?.responseData?.allTopics?.map(
                  (item, ind) => {
                    return (
                      <div className="row mx-auto">
                      <div className="col first mt-5 mb-4 d-flex align-items-center ">
                        <h4 className="m-0">
                          
                          {item?.topicId?.topic}</h4>
                        <p className="ms-3 mt-2 mb-0 overviewViewAll">
                          View all{" "}
                          <i
                            className="fa fa-angle-right ms-1"
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                    </div>
                    );
                  }
                )}
                </>
              )} */}
              
            </>
          ) : (
            <div>
              <Skeleton />
              <Skeleton height={`100vh`} count={1} />
            </div>
          )}
          <SidebarCanvas
            show={openSidebar}
            setShow={setOpenSidebar}
            sideLoader={sideLoader}
            data={sidebarData}
          />
        </div>
      </div>
    </>
  );
};

export default LibraryOverview;

import {
  ADD_PROFILE_ONBOARDING,
  GET_ADDED_PROFILE_LIST,
  GET_ADDED_SCHOOL_LIST,
  GET_ADDED_COURSE_LIST,
  ADD_DOCUMENT,
  DELETE_COURSE,
  ADD_COURSE,
} from "./types";
import mernApi from "../apis/mern";

export const getUserData =
  ({ body, onBoardingStatus, setCode, data }) =>
  (dispatch) => {
    return mernApi

      .post(`api/onboard/addOnBoard/${onBoardingStatus}`, body)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: ADD_PROFILE_ONBOARDING,
            payload: setCode,
          });
          if (onBoardingStatus === "2") {
            dispatch({
              type: ADD_COURSE,
              payload: data.data,
            });
          }
        }
      });
  };

export const getSchoolList = (param) => (dispatch) => {
  return mernApi.get(`api/onboard/getSchoolList?skip=${param}`).then((data) => {
    if (data.status === 200) {
      dispatch({
        type: GET_ADDED_SCHOOL_LIST,
        payload: data,
      });
    }
    return data;
  });
};
export const getSchoolListWithSearch = (text, num) => (dispatch) => {
  return mernApi
    .get(`/api/onboard/searchSchool?school=${text}&skip=${num}`)
    .then((data) => {
      return data;
    });
};

export const getProfileList = () => (dispatch) => {
  return mernApi.get(`api/onboard/getProfilee`).then((data) => {
    if (data.status === 200) {
      dispatch({
        type: GET_ADDED_PROFILE_LIST,
        payload: data,
      });
    }
  });
};

// /api/onboard/deleteCourse?id=62e761506b73281456135db9
export const deleteCourse = (id) => (dispatch) => {
  return mernApi.get(`api/onboard/deleteCourse?id=${id}`).then((data) => {
    if (data.status === 200) {
      dispatch({
        type: DELETE_COURSE,
        payload: data,
      });
    }
  });
};

export const getCourseList = (id) => (dispatch) => {
  return mernApi.get(`api/onboard/getCourse?user_id=${id}`).then((data) => {
    if (data.status === 200) {
      dispatch({
        type: GET_ADDED_COURSE_LIST,
        payload: data,
      });
    }
  });
};

export const addDocument =
  ({ body, onBoardingStatus }) =>
  (dispatch) => {
    return mernApi
      .post(`api/onboard/uploadS3/${onBoardingStatus}`, body)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: ADD_DOCUMENT,
            payload: data,
          });
        }
      });
  };

export const skipPage = () => (dispatch) => {
  return mernApi.put(`api/onboard/skipApi`).then((data) => {
    if (data.status === 200) {
      dispatch({
        type: ADD_PROFILE_ONBOARDING,
        payload: "7",
      });
    }
  });
};

export const goBackPage = (profileStatus) => (dispatch) => {
  dispatch({
    type: ADD_PROFILE_ONBOARDING,
    payload: profileStatus,
  });
};

export const getPreviousPageData = (pageId) => async (dispatch) => {
  return mernApi
    .get(`api/onboard/getProfileBack?status=${pageId}`)
    .then((data) => {
      return data;
    });
};

export const clearStore = () => (dispatch) => {
  dispatch({
    type: "RESET",
  });
  dispatch({
    type: "SIGN_OUT",
  });
};

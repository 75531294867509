import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Root from './containers/Root/Root';
import * as serviceWorker from './serviceWorker';
import { history } from './store/configureStore';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import '../node_modules/font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import { store, persistor } from './store/configureStore.dev'


// const config = configureStore();
ReactDOM.render(
  // store from redux
  <Root store={store} history={history} persist={persistor} />,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
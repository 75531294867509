import React, { useState, useEffect, useRef } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { jsPDF } from "jspdf";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import html2canvas from "html2canvas";
import { useReactToPrint } from "react-to-print";
import { addContentFile } from "../../../store/actions/libraryOverview";
import { SideNoteLeft } from "../../../staticContent/image";
import { useDispatch, useSelector } from "react-redux";
import { summeryAddToLibrary } from "../../../store/actions/study";
const SummeryMode = (props) => {
  const dispatch = useDispatch();
  const { isOpen, setIsOpen } = props;
  const store = useSelector((state) => state);

  const [getSummery, setSummery] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(store?.getSummary?.data?.summary)
      )
    )
  );
  // const [getSummery, setSummery] = useState("vishal")

  const [loader, setLoader] = useState(false);
  const [addLibraryStatus, setAddLibraryStatus] = useState(false);
  const [getLoad, setLoad] = useState(false);
  const [pdf, setPdf] = useState("440px");
  const onEditorStateChange = (data) => {
    setSummery(data);
  };
  //  pdf functionality starts
  const pdfDownoad = () => {
    window.html2canvas = html2canvas;
    var doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      // format: [4, 2]
    });

    var content = document.getElementById("content-22");

    doc.html(content, {
      callback: function (doc) {
        setLoad(false);
        doc.save();
      },
    });
  };
  useEffect(() => {
    props.handleSummary({
      mode: "summary",
      issummary: true,
      topic: "Table of Contest",
    });
  }, [props]);
  const downloadFileDocument = useReactToPrint({
    content: () => document.querySelector(".rdw-editor-main"),
    // setPdf("auto")
  });

  const print = () => {
    // editorClassName.rdw-editor-main
    document.querySelector(".wrapperClassName").style.height = "auto"
   
    downloadFileDocument();

    document.querySelector(".wrapperClassName").style.height = "438px"
    // setTimeout(() => {
    //   setPdf("440px");
    // }, 2000);
  };
  // download pdf :export pdf functionality ends
  // export to library functionality starts
  const handleLibrary = () => {
    setLoader(true);
    const input = document.querySelector(".public-DraftEditor-content");
    const file_id = store.getStudyTopic?.title._id;
    dispatch(
      summeryAddToLibrary({
        summaryText: input.innerHTML,
        title: props.title,
        fileId: file_id,
      })
    ).then((res) => {
      if (res.status === 200) {
        setAddLibraryStatus(true);
        setLoader(false);
      }
    });
  };
  // export to library functionality ends

  const componentRef = useRef();

  return (
    <>
      <div
        ref={componentRef}
        className="mt-4 mb-1"
        style={{  overflow: "hidden", overflowY: "scroll" }}
      >
        <Editor
          editorState={getSummery}
          toolbarClassName="toolbarClassName border-0 border-bottom bg-light py-1"
          wrapperClassName="wrapperClassName border rounded-3"
          editorClassName="editorClassName px-3"
          onEditorStateChange={onEditorStateChange}
        />
      </div>
      <div className="row d-flex align-items-center justify-content-end mt-4 pe-0 mx-auto">
        <div className="col-5 d-flex align-items-center  justify-content-end  study-science me-0 pe-0">
          {!store?.getStudyTopic?.title?.isSummary && (
            <div>
              {!loader ? (
                <button
                  disabled={addLibraryStatus}
                  type="button"
                  className="btn shadow-none colorfull d-flex align-items-center text-none  me-3"
                  onClick={handleLibrary}
                  style={{ fontWeight: "700" }}
                >
                  {addLibraryStatus ? "Successfully added" : "Add to Library"}
                </button>
              ) : (
                <>
                  <div className="spinner-border me-5" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </>
              )}
            </div>
          )}
          <button
            type="button"
            className="btn border shadow-none colorfulls text-white "
            onClick={print}
          >
            Export PDF
          </button>
          {getLoad && (
            <div
              id="content-22"
              dangerouslySetInnerHTML={{
                __html: draftToHtml(
                  convertToRaw(getSummery.getCurrentContent())
                ),
              }}
            />
          )}
          {isOpen === true && (
            <div
              onClick={() => {
                props.setSideBar(false);
              }}
              className="row position-absolute imageHideSidebar cp"
            >
              <div className="col d-flex align-items-center justify-content-end ">
                <img src={SideNoteLeft} alt="" className="study-image-next" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SummeryMode;

export const SIGN_UP = '[Auth] Sign Up';
export const SIGN_UP_SUCCESS = '[Auth] Sign Up Success';
export const SIGN_UP_FAIL = '[Auth] Sign Up Fail';
export const SIGN_IN = '[Auth] Sign In';
export const SIGN_IN_SUCCESS = '[Auth] Sign In Success';
export const SIGN_IN_FAIL = '[Auth] Sign In Fail';
export const FACEBOOK_SIGN_IN = '[Auth] Facebook Sign In';
export const FACEBOOK_SIGN_IN_SUCCESS = '[Auth] Facebook Sign In Success';
export const FACEBOOK_SIGN_IN_FAIL = '[Auth] Facebook Sign In Fail';
export const GOOGLE_SIGN_IN = '[Auth] Google Sign In';
export const GOOGLE_SIGN_IN_SUCCESS = '[Auth] Google Sign In Success';
export const GOOGLE_SIGN_IN_FAIL = '[Auth] Google Sign In Fail';
export const TRY_LOCAL_SIGN_IN = '[Auth] Try Local Sign In';
export const TRY_LOCAL_SIGN_IN_SUCCESS = '[Auth] Try Local Sign In Success';
export const TRY_LOCAL_SIGN_IN_FAIL = '[Auth] Try Local Sign In Fail';
export const SIGN_OUT = '[Auth] Sign Out';
export const SIGN_OUT_SUCCESS = '[Auth] Sign Out Success';
export const SET_ATTEMPTED_PATH = '[Auth] Set Attempted Path';
export const VERIFY_EMAIL = '[Auth] Verify Email';
export const VERIFY_EMAIL_SUCCESS = '[Auth] Verify Email Success';
export const VERIFY_EMAIL_FAIL = '[Auth] Verify Email Fail';
export const REQUEST_VERIFICATION_EMAIL = '[Auth] Request Verification Email';
export const REQUEST_VERIFICATION_EMAIL_SUCCESS =
  '[Auth] Request Verification Email Success';
export const REQUEST_VERIFICATION_EMAIL_FAIL =
  '[Auth] Request Verification Email Fail';
export const REQUEST_PASSWORD_RESET = '[Auth] Request Password Reset';
export const REQUEST_PASSWORD_RESET_SUCCESS =
  '[Auth] Request Password Reset Success';
export const REQUEST_PASSWORD_RESET_FAIL = '[Auth] Request Password Reset Fail';
export const RESET_PASSWORD = '[Auth] Reset Password';
export const RESET_PASSWORD_SUCCESS = '[Auth] Reset Password Success';
export const RESET_PASSWORD_FAIL = '[Auth] Reset Password Fail';
export const UNLOAD_AUTH_PAGE = '[Auth] Unload Auth Page';

export const ADD_PROFILE_ONBOARDING = 'ADD_PROFILE_ONBOARDING';
export const RESET_STORE = 'RESET_STORE';
export const PREVIOUS_PAGE_DATA = 'PREVIOUS_PAGE_DATA';

// onboarding page
export const GET_ADDED_SCHOOL_LIST = 'GET_ADDED_SCHOOL_LIST';
export const GET_ADDED_PROFILE_LIST = 'GET_ADDED_PROFILE_LIST';
export const DELETE_COURSE = 'DELETE_COURSE';
export const GET_ADDED_COURSE_LIST = 'GET_ADDED_COURSE_LIST';
export const ADD_LINK = 'ADD_LINK';
export const ADD_DOCUMENT = 'ADD_DOCUMENTS';


export const SKIP_PAGE = 'SKIP_PAGE';
// library page
export const ADD_CONTENT_URL_OVERVIEW = 'ADD_CONTENT_URL_OVERVIEW';
export const ADDED_CONTENT_DATA = 'ADDED_CONTENT_DATA';
export const ADD_CONTENT_FILE_OVERVIEW = 'ADD_CONTENT_FILE_OVERVIEW';
export const GET_ADDED_CONTENT = 'GET_ADDED_CONTENT';
export const GET_ADDED_COURSE = 'GET_ADDED_COURSE';
export const EDIT_ADD_CONTENT_TITLE = "EDIT_ADD_CONTENT_TITLE";
export const GET_CATEGARY = "GET_CATEGARY";
export const GET_CONTINUE_STUDY_LIST = "GET_CONTINUE_STUDY_LIST";
export const DELETE_ADDED_COURSE = "DELETE_ADDED_COURSE";
export const GET_RECOMEND_CONTENTS = "GET_RECOMEND_CONTENTS";
export const SEARCH_COURSE_NAME = "SEARCH_COURSE_NAME";
export const SEARCHED_COURSE = "SEARCHED_COURSE"
export const CLEAR_SEARCHBAR = "CLEAR_SEARCHBAR";
export const SEARCH_CONTENT = "SEARCH_CONTENT";
export const FILTER_CONTENT = "FILTER_CONTENT";
export const UPDATE_PLAYLIST_NAME = "UPDATE_PLAYLIST_NAME";


// store add course
export const ADD_COURSE = "ADD_COURSE";
export const EDIT_COURSE = "EDIT_COURSE";
export const DELETE_COURSE_ONBOARD = "DELETE_COURSE_ONBOARD";


// playlist page
export const CREATE_NEW_PLAYLIST = "CREATE_NEW_PLAYLIST";
export const PLAYLIST_ADDED_ITEMS = "PLAYLIST_ADDED_ITEMS";
export const GET_PLAYLIST = "GET_PLAYLIST";
export const EDIT_PLAYLIST = "EDIT_PLAYLIST"
export const BROWSE_PLAYLIST_CONTENT = "BROWSE_PLAYLIST_CONTENT"
export const DELETE_PLAYLIST = "DELETE_PLAYLIST";
export const SEARCH_CONTENT_FOR_PLAYLIST = "SEARCH_CONTENT_FOR_PLAYLIST";
export const ADD_CONTENT_FOR_PLAYLIST = "ADD_CONTENT_FOR_PLAYLIST";
export const GET_LIBRARY_PLAYLIST_CONTENT = "GET_LIBRARY_PLAYLIST_CONTENT";
export const GET_PLAYLIST_CONTENT = "GET_PLAYLIST_CONTENT";
export const ADD_CONTENT_To_PLAYLIST = "ADD_CONTENT_To_PLAYLIST"


// study page api calls

export const GET_TOPIC = "GET_TOPIC";
export const GET_HTML = "GET_HTML";
export const GET_SUBTOPIC = "GET_SUBTOPIC"
export const ADD_NOTES = "ADD_NOTES";
export const GET_NOTES = "GET_NOTES";
export const DELETE_NOTES = "DELETE_NOTES";
export const EDIT_NOTES = "EDIT_NOTES";
export const GET_FLASH_CARDS_FAILED = "GET_FLASH_CARDS_FAILED";
export const GET_FLASH_CARDS="GET_FLASH_CARDS"
export const GET_INFO = "GET_INFO"


// user profiles api calls 

export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_PASSWORD = "GET_USER_PASSWORD"

// studyBuddy api calls 

export const GET_TASK_TYPE = "GET_TASK_TYPE"
export const ADD_TASK = "ADD_TASK"
export const GET_TASK_DETAILS = "GET_TASK_DETAILS"
export const ADD_TIME = "ADD_TIME"
export const GET_CONTENT_FROM_LIBRARY = "GET_CONTENT_FROM_LIBRARY"
export const ADD_CONTENT_LIBRARY = "ADD_CONTENT_LIBRARY"
export const GET_ALL_TASK_LIST = "GET_ALL_TASK_LIST"
export const Add_Goal = "Add_Goal"
export const GET_ACTIVITY_DATA = "GET_ACTIVITY_DATA"
export const TRACK_TASK_TIME = "TRACK_TASK_TIME"
export const DELETE_TASK_API = "DELETE_TASK_API"
export const GET_TRACKER_DETAILS = "GET_TRACKER_DETAILS"
export const GET_TASK_SUGGESTION = "GET_TASK_SUGGESTION"
export const GET_GOAL_CATEGORY = "GET_GOAL_CATEGORY"
export const Get_GOAL_DATA = "Get_GOAL_DATA"


// practice api calls  

export const GET_PRACTICE_PAGE_DATA = "GET_PRACTICE_PAGE_DATA"
export const GET_PRACTICE_PAGE_DATA_FAILED = "GET_PRACTICE_PAGE_DATA_FAILED"

export const GET_TOPIC_PRACTICE_PAGE_DATA = "GET_TOPIC_PRACTICE_PAGE_DATA"
export const GET_TOPIC_PRACTICE_PAGE_DATA_FAILED = "GET_TOPIC_PRACTICE_PAGE_DATA_FAILED"

export const   GET_DOCUMENT_QUIZ = "GET_DOCUMENT_QUIZ"
export const   GET_DOCUMENT_QUIZ_FAILED="GET_DOCUMENT_QUIZ_FAILED"

export const GET_TOPIC_QUIZ_FAILED = "GET_TOPIC_QUIZ_FAILED"
export const GET_TOPIC_QUIZ = "GET_TOPIC_QUIZ"

export const QUIZ_RELATED_DATA = "QUIZ_RELATED_DATA"
export const QUIZ_RELATED_DATA_FAILED = "QUIZ_RELATED_DATA_FAILED"

export const GET_RELATED_CONTENT = "GET_RELATED_CONTENT"
export const GET_RELATED_CONTENT_FAILED = "GET_RELATED_CONTENT_FAILED"
export const GET_RELATED_CONTENT_TOPICS = "GET_RELATED_CONTENT_TOPICS"
export const GET_RELATED_CONTENT_TOPICS_FAILED = "GET_RELATED_CONTENT_TOPICS_FAILED"
export const  GET_FEEDBACK_FLAGS = "GET_FEEDBACK_FLAGS"
export const GET_START_PRACTICISN_DATA = "GET_START_PRACTICISN_DATA"
export const GET_TOTAL_SCORE = "GET_TOTAL_SCORE"
export const GET_PRACTICE_HOUR_SPENT= "GET_PRACTICE_HOUR_SPENT"
export const GET_TRY_THESE_TOPICS = "GET_TRY_THESE_TOPICS"
export const GET_TRY_THESE_TOPICS_FAILED = "GET_TRY_THESE_TOPICS_FAILED"
export const GET_PRACTICE_TRANSCRIPTS = "GET_PRACTICE_TRANSCRIPTS"
export const GET_PRACTICE_TRANSCRIPTS_FAILED = "GET_PRACTICE_TRANSCRIPTS_FAILED"
export const GET_HIGHLIGHTS_PRACTICE = "GET_HIGHLIGHTS_PRACTICE"


// acheivement api calls 
export const GET_ACHEIVEMENT_PRACTICE = "GET_ACHEIVEMENT_PRACTICE"
export const GET_ACHEIVEMENT_PRACTICE_FAILED = "GET_ACHEIVEMENT_PRACTICE_FAILED"
export const GET_SKILL_UPGRADE_FAILED = "GET_SKILL_UPGRADE_FAILED"
export const GET_SKILL_UPGRADE = "GET_SKILL_UPGRADE"
export const GET_ANSWER_STREAK_FAILED = "GET_ANSWER_STREAK_FAILED"
export const GET_ANSWER_STREAK = "GET_ANSWER_STREAK"
export const GET_MONTHLY_STREAK_FAILED = "GET_MONTHLY_STREAK_FAILED"
export const GET_MONTHLY_STREAK = "GET_MONTHLY_STREAK"



// notifications api 

export const GET_NOTIFICATION_FAILED = "GET_NOTIFICATION_FAILED"
export const GET_NOTIFICATION = "GET_NOTIFICATION"









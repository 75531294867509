import React, { useEffect } from "react";
import { gapi } from "gapi-script";
import {GoogleLogin} from "react-google-login";
import { googleSignIn } from "../../store/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { googleLogo } from "../../staticContent/image";
import { getProcessing, getError } from "../../store/selectors";

// const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const GoogleLoginOne = (props) => {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId:
          "652152404136-bh6sc9jc9jl395pvhnt51ke845jf8agj.apps.googleusercontent.com",
        scope: "email",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const onGoogleResponse = (response) => {
    console.log(response);
    const email = response.profileObj.email;
    if (email.endsWith('.edu')) {
      const payload = {
        idToken: response.tokenId,
      };
      props.googleSignIn(payload).then((data) => {
        if (props.errorMessage) {
          props.onGoogleLoginError(props.errorMessage);
        }
      });
      console.log('Login successful');
    } else {
      props.onGoogleLoginError('We are currently accepting only .edu email addresses for sign-in');
    }
  };

  return (
    <GoogleLogin
      clientId="652152404136-bh6sc9jc9jl395pvhnt51ke845jf8agj.apps.googleusercontent.com"
      buttonText="Google Login"
      onSuccess={onGoogleResponse}
      onFailure={onGoogleResponse}
      hostedDomain=".edu"
      render={(renderProps) => (
        <div
          disabled={renderProps.disabled}
          onClick={renderProps.onClick}
          className="col social_box facbook ms-2 border d-flex align-items-center facebook-login-hover"
          style={{ cursor: "pointer" }}
        >
          <div className="row ms-1">
            <div className="col-auto p-0">
              <img src={googleLogo} alt="" className=" signin_image" />
            </div>
            <div className="col d-flex align-items-center">
              <p className="google text-dark m-0 mt-auto">
                Sign in with Google
              </p>
            </div>
          </div>
        </div>
      )}
    />
  );
};

const maptStateToProps = (state) => {
  return {
    isProcessing: getProcessing(state),
    errorMessage: getError(state),
  };
};

export default compose(connect(maptStateToProps, { googleSignIn }))(
  GoogleLoginOne
);

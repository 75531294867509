import React from "react";
import { useEffect, useState } from "react";
import Header from "../../../layouts/Header/Header";
import "./Settings.css";
import { useSelector, useDispatch } from "react-redux";
import {
  getPasswordCheck,
  passwordChange,
  emailSetting,
} from "../../../store/actions/getUserProfile";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { ReactNotifications, Store } from "react-notifications-component";

function Settings() {
  const moment = extendMoment(originalMoment);
  const dispatch = useDispatch();
  const [border, setborder] = useState({
    curren: false,
    newPass: false,
    conpass: false,
  });
  const [curpassword, setCurPassword] = useState("");
  const [logout, setLogout] = useState("");
  const [data, setData] = useState({
    password: "",
    con_password: "",
    emailSetting: "",
    errors: "",
  });
  const store = useSelector((state) => state);
  useEffect(() => {
    setData({
      ...data,
      emailSetting: store.getUserProfile?.data?.responseData?.emailSetting,
    });
  }, []);

  const handleCurrChange = (e) => {
    setCurPassword(e.target.value.trim());
  };

  const handleClick = () => {
    let body = {
      user_id: store.auth?.user?.id,
      password: curpassword,
    };
    dispatch(getPasswordCheck(body));
    setCurPassword("");
    setData({
      password: "",
      con_password: "",
      emailSetting: "",
      errors: "",
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value.trim() });
  };
  // password change function start
  const ConfirmClick = () => {
    let body = {
      user_id: store.auth?.user?.id,
      newPassword: data.password,
      confirmPassword: data.con_password,
    };
    if (data.password === "") {
      setData({ ...data, errors: "password can't be empty" });
    } else if (data.password !== data.con_password) {
      setData({
        ...data,
        password: "",
        con_password: "",
        errors: "pasword not matched",
      });
    } else if (
      store.passwordCheck?.data?.errormessage === "Current Password is wrong"
    ) {
      setData({
        ...data,
        password: "",
        con_password: "",
        errors: "Current password not matched",
      });
    } else {
      dispatch(passwordChange(body));
      setData({
        password: "",
        con_password: "",
        emailSetting: "",
        errors: "",
      });

      Store.addNotification({
        title: "Password",
        message: "your password has been updated successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  // email setting functionality
  const emailClick = (e) => {
    setData({ emailSetting: e.target.value });
  };
  const handleCancel = () => {};
  // setting save button functionality
  const handleSave = () => {
    let body = {
      user_id: store.auth?.user?.id,
      emailSetting: data.emailSetting,
    };
    dispatch(emailSetting(body));
    Store.addNotification({
      title: "Notification",
      message: "your email setting has been updated successfully",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  };
  useEffect(() => {
    setData({
      ...data,
      emailSetting: store.getUserProfile?.data?.responseData?.emailSetting,
    });
  }, []);

  const value = store.getUserProfile?.data?.responseData;

  return (
    <>
      <Header />
      <div className="profile-div  ">
        <div className="container">
          <div className="row mx-5">
            <div className="col-12">
              <div className="row mt-4">
                <div className="col-12">
                  <h2 className="txt_heading pb-2 m-0">Settings</h2>
                  <div className="col-12 border-bottom pb-4">
                    <p className="para_text m-0">
                      Manage your preferences here.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-3 displayed border-bottom">
                <div className="col-12 pt-2">
                  <h6 className="small_heading mb-2">Your Password</h6>
                </div>
                {store.getUserProfile?.data?.responseData?.provider?.google ||
                store.getUserProfile?.data?.responseData?.provider
                  ?.facebook ? null : (
                  <div className="col-12 ">
                    <p className="smpara_txt m-0">
                      Your password was last updated on{" "}
                      {moment(value?.updatedPasswordDate)
                        .startOf(value?.updatedPasswordDate)
                        .fromNow()}
                    </p>
                  </div>
                )}
                <div className=" col-2 my-4 v-blue d-flex align-items-center justify-content-start ">
                  {store.getUserProfile?.data?.responseData?.provider?.google ||
                  store.getUserProfile?.data?.responseData?.provider
                    ?.facebook ? (
                    <button
                      type="button "
                      className="btn border shadow-none px-3 py-2 changeword "
                      data-bs-toggle="modal"
                      href="#exampleModalToggle"
                      disabled
                    >
                      Change Password
                    </button>
                  ) : (
                    <button
                      type="button disabled"
                      className="btn border shadow-none px-2 py-2 changeword "
                      data-bs-toggle="modal"
                      href="#exampleModalToggle"
                    >
                      Change Password
                    </button>
                  )}
                </div>
              </div>
              <div className="row mt-4 displayed border-bottom">
                <div className="col-12 mt-2">
                  <h6 className="small_heading mb-2">Email Settings</h6>
                </div>
                <div className="col-12 mb-4">
                  <p className="smpara_txt m-0">
                    Receive the latest news, updates and notifications from us.
                  </p>
                </div>
                <div className="row">
                  <div className="form-check ms-2 col-12 col-md-4 border d-flex justify-content-between align-items-center py-3">
                    <p className="m-0 setting-daily-data ">Daily</p>
                    <input
                      className="form-check-input"
                      onChange={emailClick}
                      type="radio"
                      name="emailSetting"
                      value="daily"
                      checked={data?.emailSetting === "daily"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-check ms-2 col-12 col-md-4 border  d-flex justify-content-between align-items-center mt-3 py-3">
                    <p className="m-0 setting-daily-data ">Weekly</p>
                    <input
                      className="form-check-input"
                      onChange={emailClick}
                      type="radio"
                      name="emailSetting"
                      value="weekly"
                      checked={data.emailSetting === "weekly"}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="form-check ms-2 col-12 col-md-4 border  d-flex justify-content-between align-items-center mt-3 py-3">
                    <p className="m-0 setting-daily-data ">None</p>
                    <input
                      className="form-check-input"
                      onChange={emailClick}
                      type="radio"
                      name="emailSetting"
                      value="none"
                      checked={data.emailSetting === "none"}
                    />
                  </div>
                </div>
              </div>
              <div className="row d-flex align-items-center justify-content-end">
                <div className=" col-2 shadow-none my-4 v-blue d-flex align-items-center justify-content-end ">
                  <button
                    type="button"
                    className="btn border me-4 shadow-none px-3 py-2 changeword"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <div
                    className="modal fade color_change_modal"
                    id="exampleModalToggle"
                    aria-hidden="true"
                    aria-labelledby="exampleModalToggleLabel"
                    tabindex="-1"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content area px-4 mx-4 border-0">
                        <div className="modal-header px-0">
                          <h4
                            className="modal-title setting-confirm-pass"
                            id="exampleModalToggleLabel"
                          >
                            Confirm Current Password
                          </h4>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body setting-modal-body px-0">
                          Enter your previous password that you
                          <br />
                          used to log in to the platform
                        </div>
                        <div className="modal-body px-0">
                          <label
                            for="changepassword1"
                            className="form-label setting-form-label"
                          >
                            Current Password
                          </label>
                          <input
                            type="password"
                            value={curpassword}
                            onChange={handleCurrChange}
                            className={
                              border.curren
                                ? "form-control confirm form-check setting-input-data py-3 boderrr"
                                : "form-control confirm form-check setting-input-data py-3 "
                            }
                            id="changepassword1"
                            aria-describedby="emailHelp"
                            onClick={() => {
                              setborder({
                                curren: true,
                                pass: false,
                                conpass: false,
                              });
                            }}
                            placeholder="Type your current password"
                          />
                        </div>
                        <div className="modal-footer d-flex align-items-center justify-content-start  px-0 col-3 mb-4 border-0">
                          <button
                            className="btn setting-btn text-white py-2 px-3"
                            data-bs-target="#changepassword2"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            onClick={() => handleClick()}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade color_change_modal"
                    id="changepassword2"
                    aria-hidden="true"
                    aria-labelledby="exampleModalToggleLabel2"
                    tabindex="-1"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content area px-4 mx-4 border-0">
                        <div className="modal-header px-0">
                          <h4
                            className="modal-title setting-confirm-pass"
                            id="exampleModalToggleLabel"
                          >
                            Create New Password
                          </h4>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body setting-modal-body px-0">
                          Your new password must be different
                          <br />
                          from previous used passwords
                        </div>
                        <div className="modal-body p-0">
                          <label
                            for="exampleInputEmail1"
                            className="form-label setting-form-label"
                          >
                            New Password
                          </label>
                          <input
                            type="password"
                            value={data.password}
                            name="password"
                            onChange={handleChange}
                            className={
                              border.pass
                                ? "form-control confirm form-check setting-input-data py-3 boderrr"
                                : "form-control confirm form-check setting-input-data py-3 "
                            }
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onClick={() => {
                              setborder({
                                curren: false,
                                pass: true,
                                conpass: false,
                              });
                            }}
                            placeholder="Type your new password"
                          />
                        </div>
                        <div className="modal-body px-0">
                          <label
                            for="exampleInputEmail1"
                            className="form-label setting-form-label"
                          >
                            Confirm New Password
                          </label>
                          <input
                            type="password"
                            value={data.con_password}
                            name="con_password"
                            onChange={handleChange}
                            onClick={() => {
                              setborder({
                                curren: false,
                                pass: false,
                                conpass: true,
                              });
                            }}
                            className={
                              border.conpass
                                ? "form-control confirm form-check setting-input-data py-3 boderrr"
                                : "form-control confirm form-check setting-input-data py-3 "
                            }
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Type your new password"
                          />

                          <p className="text-danger mt-2 p-0">{data.errors}</p>
                        </div>
                        <div className="modal-footer pt-0 border-0 px-0 d-flex align-items-center justify-content-start mb-3">
                          <button
                            className="setting-btn py-2 px-3 border-0 text-white"
                            data-bs-dismiss={logout}
                            onClick={ConfirmClick}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn save py-2 px-3 shadow-none changeword"
                    role="button"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;

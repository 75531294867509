import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory } from "history";
import reduxThunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers";
import mernApi from "./apis/mern";
import storage from "redux-persist/lib/storage";
//presist
import { persistStore, persistReducer } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "auth",
    "searchCourseName",
    "addContentToPlaylist",
    "passwordCheck",
    "getSearchedCourseList",

  ],
};
export const history = createBrowserHistory();
const rootReducer = createRootReducer(history);
const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancer = composeWithDevTools({
  actionsBlacklist: ["@@redux-form"],
});

export const store = createStore(
  persistedReducer,
  composeEnhancer(
    applyMiddleware(
      routerMiddleware(history),
      reduxThunk.withExtraArgument({ mernApi })
      //  logger
    )
  )
);
if (module.hot) {
  module.hot.accept("./reducers", () =>
    store.replaceReducer(createRootReducer(history))
  );
}
export const persistor = persistStore(store);

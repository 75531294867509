import React, { useState, useEffect } from "react";
import "../Organize.css";
import { useDispatch, useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  addGoalPlus,
  circleImg,
  checkmark,
  hourGlass,
  hourGlassRound,
  tick,
  union,
  superStudent,
} from "../../../staticContent/image.js";
import {
  getAllActivityDetails,
  goalStatus,
  taskStatus,
  getAllTaskList,
  UpdateTrackTime,
  getGoalData,
  getTaskData,
} from "../../../store/actions/studyBuddy";
import { Link, useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import TimeInput from "react-input-time";

function Activity() {
  const moment = extendMoment(originalMoment);
  const history = useHistory();
  const dispatch = useDispatch();
  const [goalData, setGoalData] = useState([]);
  const [todayTasks, setTodayTasks] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [num, setNum] = useState(0);
  const [loader, setLoader] = useState(true);
  const [showSchool, setShowSchool] = useState(false);
  const [selectOption, setSelectOption] = useState("Specify the task");
  const [openModel, setOpenModel] = useState(false);
  const [time, setTime] = useState({ start: "00:00", stop: "00:00" });
  const [optionData, setOptionData] = useState([]);
  const [specifyTaskId, setSpecificTaskId] = useState();
  const [skip, setSkip] = useState(0);
  const [graphType, setGraphType] = useState("weekly");
  const [taskDataSkip, setTaskDataSkip] = useState(0);
  const [graphLoader, setGraphLoader] = useState(false);

  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    setTime({ ...time, [name]: value });
  };

  const hanldeAddTime = async () => {
    let valuestart = time.start;
    let valuestop = time.stop;
    let timeStart = new Date("12/21/2022 " + valuestart);
    let timeEnd = new Date("12/21/2022 " + valuestop);
    let difference = timeEnd - timeStart;
    let body = { taskId: specifyTaskId, timeTrack: Math.abs(difference) };
    dispatch(UpdateTrackTime(body)).then((res) => {
      if (res.status === 200) {
        document.getElementById("closeButton").click();
        setTime({ start: "", stop: "" });
      }
    });
  };
  const tasksScroll = (e) => {
    if (e.target.scrollTop * 2 + 100 >= window.innerHeight) {
      setTaskDataSkip(taskDataSkip + 1);
    }
  };
  useEffect(() => {
    dispatch(getGoalData(num)).then((res) => {
      setGoalData([...goalData, ...res?.data?.goalList]);
    });
  }, [num]);
  useEffect(() => {
    dispatch(getTaskData(taskDataSkip)).then((res) => {
      setTodayTasks([...todayTasks, ...res?.data?.responseData]);
    });
  }, [taskDataSkip]);

  useEffect(() => {
    dispatch(getAllTaskList("work", skip)).then((res) => {
      if (res.status === 200) {
        setOptionData([...optionData, ...res?.data?.responseData]);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getAllActivityDetails(graphType)).then((res) => {
      if (res.status === 200) {
        setGraphData(res?.data?.graphDataArr);
        // SetReward(res?.data?.reward);
        setLoader(false);
      }
    });
  }, []);
  const timing = (data) => {
    setGraphLoader(true);
    setGraphType(data);
    dispatch(getAllActivityDetails(data)).then((res) => {
      if (res.status === 200) {
        setGraphData(res?.data?.graphDataArr);
        setGraphLoader(false);
      }
    });
  };
  function truncate(str, n) {
    return str?.length > n ? str.slice(0, n - 1) + "...." : str;
  }

  const handleScroll = (e) => {
    if (e.target.scrollTop * 4 >= window.innerHeight) {
      setSkip(skip++);
    }
  };

  const formatter = (value) => `${value}h`;

  const handleActivityScroll = (e) => {
    if (e.target.scrollTop * 2 + 100 >= window.innerHeight) {
      setNum(num + 1);
    }
  };

  return (
    <div>
      {loader ? (
        <Skeleton height={`3vh`} count={50} />
      ) : (
        <div class="row mx-auto">
          <div class="col-12 mt-5">
            <div class="row mx-auto">
              <div class="col-12 col-6 mb-md-0 mb-3 grid-margin stretch-card">
                <div class="activity_heading">
                  <h4 class="m-0">Activity</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-auto d-flex align-items-center justify-content-start">
            <div class="col-md-6 col-6 mb-md-0 mb-3 grid-margin stretch-card">
              <div class="row my-3 mx-auto">
                <div class="col-12 p-0 ">
                  <div class="row my-3">
                    <div class="col activity_goal_heading d-flex align-items-center justify-content-start">
                      <h6 class="m-0">My Goals</h6>
                    </div>
                    <div class="col activity_goal_heading color_differ d-flex align-items-center justify-content-end">
                      <Link to="/organize/goal">
                        <h6 class="m-0">
                          <img className="me-2" src={addGoalPlus} alt="" />
                          Add Goal
                        </h6>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 bg-white activity_all_goal_set"
                  onScroll={handleActivityScroll}
                >
                  <div class="row px-2 pt-4  ">
                    {goalData?.map((ele, ind) => {
                      return (
                        <>
                          <div class="col-12 pb-2 ">
                            <div class="row mx-auto border-bottom pb-2">
                              <div class="col p-0">
                                <div className="row ">
                                  <div className="col-5  overflow-hidden">
                                    <p class="m-0 activity_text_para text-truncate ">
                                      {ele.goalStatus ? (
                                        <s class="goal_row">
                                          {ele?.taskId?.topic ||
                                            ele?.taskId?.title}
                                        </s>
                                      ) : (
                                        ele?.taskId?.topic ||
                                        ele?.taskId?.title
                                      )}
                                    </p>
                                  </div>
                                  <div className="col-5">
                                    <div className="row mx-auto">
                                      <div className="col-4 px-0">
                                        <button
                                          style={{
                                            backgroundColor: `${ele?.goalCategory?.color}`,
                                          }}
                                          class="btn text-dark border-0 videoDoc_btn text-capitalize shadow-none  d-flex align-items-center text-white justify-content-center time_date_activity"
                                        >
                                          {ele?.goalCategory?.goalCategory}
                                        </button>
                                      </div>
                                      <div
                                        className="col-7 text-start
                                       px-0"
                                      >
                                        <button
                                          style={{
                                            backgroundColor: `${ele?.time?.color}`,
                                          }}
                                          class="text-white btn border-0 text-dark videoDoc_btn text-lowercase shadow-none  d-flex align-items-center justify-content-center time_date_activity"
                                        >
                                          {ele?.time?.goalTime}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-auto p-0 d-flex align-items-center justify-content-end">
                                <img
                                  onClick={() => {
                                    dispatch(goalStatus(ele._id, true));
                                    if (!ele.goalStatus) {
                                      setGoalData(
                                        goalData?.map((itemc, indexc) =>
                                          itemc._id === ele._id
                                            ? { ...itemc, goalStatus: true }
                                            : itemc
                                        )
                                      );
                                    }
                                  }}
                                  className="circle_img time_data_activity_circle"
                                  src={ele.goalStatus ? tick : circleImg}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-6 mb-md-0 mb-3 grid-margin stretch-card">
              <div class="row mx-auto my-3">
                <div class="col-12">
                  <div class="row my-3">
                    <div class="col p-0 activity_goal_heading d-flex align-items-center justify-content-start">
                      <h6 class="m-0">Activity Total</h6>
                    </div>
                    <div className="col d-flex align-items-center justify-content-end">
                      <div className="row">
                        <div class="col p-0 activity_goal_heading add_goal_activity d-flex align-items-center justify-content-end">
                          {/* <h6 class="m-0">
                        <img className="me-2" src={addGoalPlus} alt="" />
                        Add Activity
                      </h6> */}
                          <h6
                            type="button"
                            class=" btn p-0 m-0 shadow-none activity_goal_heading add_goal_activity border-0 text-capitalize"
                            data-bs-toggle="modal"
                            href="#exampleModalToggle"
                          >
                            <img className="me-1" src={addGoalPlus} alt="" />{" "}
                            Add Activity
                          </h6>
                          <div
                            class="modal fade modal_bg_color"
                            id="exampleModalToggle"
                            aria-hidden="true"
                            aria-labelledby="exampleModalToggleLabel"
                            tabindex="-1"
                          >
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header  px-4 py-3 activity_heading border-bottom">
                                  <h4
                                    class="modal-title"
                                    id="exampleModalToggleLabel"
                                  >
                                    Add New Time
                                  </h4>
                                  <button
                                    id="closeButton"
                                    // onClick={() => {
                                    //   setOpen(false);
                                    // }}
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div class="modal-body px-4 pt-4">
                                  <div className="row">
                                    <div class="col-12">
                                      <div className="row">
                                        <div className="col-12">
                                          <div className="row mx-auto position-relative">
                                            <div className="col-12 p-0">
                                              <label
                                                for="email"
                                                className="mb-2 mt-1"
                                              >
                                                <b className="label_txt time_trake_heading">
                                                  Specify the tasks
                                                </b>
                                              </label>
                                            </div>
                                            <div className="col-12">
                                              <div className="row">
                                                <div
                                                  onClick={() => {
                                                    setOpenModel(!openModel);
                                                  }}
                                                >
                                                  <div
                                                    className={
                                                      showSchool
                                                        ? "row  profile-borderwithselection popup_drop_height enter school p-2"
                                                        : "row  profile-borderr  school popup_drop_height enter p-2"
                                                    }
                                                  >
                                                    <div className="col ps-2 pe-0 flex-1 d-flex align-items-center">
                                                      <input
                                                        disabled
                                                        type="search"
                                                        placeholder="Specify the task"
                                                        className={
                                                          "border-0  flex-grow-1 outline-none bg-transparent placholder_color_font "
                                                        }
                                                        value={selectOption}
                                                      />
                                                    </div>
                                                    <div className="col-auto pe-2 d-flex align-items-center drop-icon justify-content-end">
                                                      <i
                                                        aria-hidden="true"
                                                        className={
                                                          openModel
                                                            ? "fa fa-angle-up"
                                                            : "fa fa-angle-down"
                                                        }
                                                      ></i>
                                                    </div>
                                                  </div>
                                                </div>

                                                {openModel && (
                                                  <div
                                                    onScroll={(e) => {
                                                      handleScroll(e);
                                                    }}
                                                    className="col-12 border popup_drpdown  px-3 py-2  position-absolute"
                                                  >
                                                    <div className="row">
                                                      {optionData.map(
                                                        (item) => (
                                                          <>
                                                            <div
                                                              className="col-12 input-data py-1"
                                                              onClick={() => {
                                                                setOpenModel(
                                                                  !openModel
                                                                );
                                                                setSpecificTaskId(
                                                                  item._id
                                                                );
                                                                setSelectOption(
                                                                  item.title
                                                                );
                                                              }}
                                                            >
                                                              <p className=" topics task_add_law m-0 text-capitalize ">
                                                                {item.title}
                                                              </p>
                                                            </div>
                                                          </>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="modal-body  pb-0 pt-4 px-4">
                                  <div class="row">
                                    <div class="col">
                                      <div class="mb-3">
                                        <label
                                          for="exampleInputPassword1"
                                          class="form-label"
                                        >
                                          <b className="label_txt">Start</b>
                                        </label>
                                        <TimeInput
                                          name="newTime"
                                          className="input-time form-control shadow-none enter py-2 placholder_color_font popup_drop_height"
                                          initialTime="00:00"
                                          onChange={(event) => {
                                            handleTimeChange(event);
                                          }}
                                        />
                                        {/* <input
                                          type="time"
                                          class="form-control shadow-none enter py-2 placholder_color_font popup_drop_height"
                                          id="exampleInputPassword1"
                                          placeholder="05:40AM"
                                          name="start"
                                          value={time.start}
                                          onChange={(e) => {
                                            handleTimeChange(e);
                                          }}
                                        /> */}
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="mb-3">
                                        <label
                                          for="exampleInputPassword1"
                                          class="form-label"
                                        >
                                          <b className="label_txt">Stop</b>
                                        </label>
                                        <TimeInput
                                          name="newTime"
                                          className="input-time form-control shadow-none enter py-2 placholder_color_font popup_drop_height"
                                          initialTime="00:00"
                                          onChange={(event) => {
                                            handleTimeChange(event);
                                          }}
                                        />
                                        {/* <input
                                          type="time"
                                          class="form-control shadow-none enter py-2 placholder_color_font popup_drop_height"
                                          id="exampleInputPassword1"
                                          placeholder="05:40AM"
                                          value={time.stop}
                                          name="stop"
                                          onChange={(e) => {
                                            handleTimeChange(e);
                                          }}
                                        /> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="modal-footer mb-3 pb-4 pt-3 px-4 border-0 d-flex align-items-center justify-content-start">
                                  <button
                                    class="border btn btn-light py-2 px-4  mx-0 shadow-none offcan-study text-capitalize add_task_button"
                                    onClick={() => {
                                      hanldeAddTime();
                                    }}
                                  >
                                    Add Time
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto p-0 activity_goal_heading add_goal_activity d-flex align-items-center justify-content-end">
                          <button
                            disabled={graphLoader}
                            type="button"
                            class="btn bg-white rounded-pill shadow-none border p-0 large ms-2 px-2 py-1"
                          >
                            <i
                              onClick={() => {
                                timing("weekly");
                              }}
                              class="fa fa-angle-left me-2"
                              aria-hidden="true"
                              style={{ color: "#9EA3AE", cursor: "pointer" }}
                            ></i>
                            {graphType}
                            <i
                              onClick={() => {
                                timing("monthly");
                              }}
                              class="fa fa-angle-right ms-2"
                              aria-hidden="true"
                              style={{ color: "#9EA3AE", cursor: "pointer" }}
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!graphLoader ? (
                  <div class="col-12 p-0 activity_chartDiv activity_all_goal_sets bg-white d-flex align-items-center justify-content-center">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        width={554}
                        height={"-webkit-fill-available"}
                        data={graphData}
                        margin={{
                          top: 20,
                          right: 20,
                          left: -20,
                          bottom: 20,
                        }}
                      >
                        <CartesianGrid stroke="#F4F4F6" vertical={false} />
                        <XAxis
                          tickLine={false}
                          dataKey="taskCreateDate"
                          axisLine={false}
                        />
                        <YAxis
                          axisLine={false}
                          tickLine={false}
                          tickFormatter={formatter}
                        />

                        {/* <Tooltip cursor={{ fill: "transparent" }} /> */}
                        <Bar
                          dataKey="totalTime"
                          fill="#6A4BFF"
                          radius={[4, 4, 4, 4]}
                          barSize={16}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                ) : (
                  <Skeleton height={`2vh`} count={10} />
                )}
              </div>
            </div>
          </div>
          <div class="row my-4 mx-auto d-flex justify-content-start">
            <div class="col-8">
              <div class="row">
                <div class="col-12 mb-2">
                  <div class="row">
                    <div class="col activity_goal_heading d-flex align-items-center justify-content-start">
                      <h6 class="m-0">Tasks for Today</h6>
                    </div>
                    <div class="col activity_goal_heading add_goal_activity d-flex align-items-center justify-content-end">
                      {" "}
                      <Link to="/organize/tasks">
                        <h6 class="m-0 me-2">
                          <img className="me-2" src={addGoalPlus} alt="" />
                          Add Task
                        </h6>
                      </Link>
                      <Link to="/organize/tasks">
                        <h6 class="m-0 ms-4 ">
                          Show More
                          <i
                            class="fa fa-angle-right ms-2"
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className=" activity_all_goal_set" onScroll={tasksScroll}>
                  {todayTasks?.map((item, ind) => {
                    let tags_topics = item?.tags_topics;
                    let tagsArr = tags_topics?.split(",");

                    let resTags = tagsArr?.filter((ele, ind) => {
                      return ele != "";
                    });
                    if (item.taskStatus === true || item.taskStatus === null) {
                      return (
                        <>
                          <div class="col-12 mb-2">
                            <div
                              class="row border large bg-white mx-auto mt-2"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <div class="col-12">
                                <div class="row">
                                  <div class="col-2 add_padding_two_items align-items-center justify-content-center border-end">
                                    <div class="row mx-auto d-flex align-items-center justify-content-center">
                                      <div class="col  align-items-center justify-content-start">
                                        <div
                                          className="row d-flex align-items-center"
                                          onClick={() => {
                                            localStorage.setItem(
                                              "taskDetailid",
                                              item._id
                                            );
                                            history.push({
                                              pathname: "/organize/addcontent",

                                              state: {
                                                id: item._id,
                                              },
                                            });
                                          }}
                                        >
                                          <div className="col-auto px-0">
                                            <img
                                              className="add_task_img"
                                              src={item?.taskType?.task_icon}
                                              alt=""
                                            />
                                          </div>
                                          <div className="col px-0">
                                            <h6 class="m-0 job ms-2 text-nowrap">
                                              {item?.taskType?.task_type}
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-8 py-1 border-end">
                                    <div class="row d-flex flex-column justify-content-start">
                                      <div class="col activity_para_text">
                                        <h6 class="m-0 ">{item?.title}</h6>
                                      </div>
                                      <div class="col-12">
                                        <div className="row d-flex align-items-center">
                                          <div className="col-auto pe-0">
                                            <button
                                              type="button"
                                              class=" btn remove_border_color job border shadow-none px-2 text-uppercase"
                                            >
                                              #{item?.course}
                                            </button>
                                          </div>
                                          <div className="col px-0">
                                            {resTags?.map((ele, ind) => {
                                              return (
                                                <button
                                                  type="button"
                                                  class="border-0 job bg-light shadow-none px-2 ms-2"
                                                >
                                                  {ele}
                                                </button>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-2">
                                    <div class="row">
                                      <div class="col-8 py-3 px-3 border-end d-flex justify-content-center align-items-center">
                                        <div class="row d-flex">
                                          <div class="col d-flex activity_date_pik justify-content-center align-items-center">
                                            <i
                                              class="fa fa-flag-o me-2"
                                              aria-hidden="true"
                                            ></i>
                                            <h6 class="m-0 activity_date_pik">
                                              {moment(item.createdAt).format(
                                                "L"
                                              )}
                                            </h6>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-4 py-3 px-2 me-0 d-flex justify-content-center align-items-center position-relative">
                                        <div class="circle_div position-relative d-flex align-items-center justify-content-center">
                                          <img
                                            onClick={() => {
                                              setTodayTasks(
                                                todayTasks.map(
                                                  (itemd, indexd) =>
                                                    itemd._id === item._id
                                                      ? {
                                                          ...itemd,
                                                          tooltipShow:
                                                            !itemd.tooltipShow,
                                                        }
                                                      : {
                                                          ...itemd,
                                                          tooltipShow: false,
                                                        }
                                                )
                                              );
                                            }}
                                            className="circle_img"
                                            src={
                                              item.taskStatus === true
                                                ? hourGlassRound
                                                : circleImg
                                            }
                                            alt=""
                                          />
                                          {item.tooltipShow && (
                                            <div className="checkbox_dropdown position-absolute">
                                              <div className="row mx-auto">
                                                <div className="col-12 position-absolute height">
                                                  <div className="row mx-auto height">
                                                    <div className="col-12 ms-1 mt-3 d-flex align-items-center justify-content-center">
                                                      <img
                                                        onClick={() => {
                                                          dispatch(
                                                            taskStatus(
                                                              item._id,
                                                              false
                                                            )
                                                          );
                                                          setTodayTasks(
                                                            todayTasks.map(
                                                              (itemd, indexd) =>
                                                                itemd._id ===
                                                                item._id
                                                                  ? {
                                                                      ...itemd,
                                                                      taskStatus: false,
                                                                      tooltipShow: false,
                                                                    }
                                                                  : itemd
                                                            )
                                                          );
                                                        }}
                                                        className="circle_img"
                                                        src={checkmark}
                                                        alt=""
                                                      />
                                                    </div>
                                                    <div className="col-12 mt-1 mb-3 ms-1 d-flex align-items-center justify-content-center">
                                                      <img
                                                        onClick={() => {
                                                          dispatch(
                                                            taskStatus(
                                                              item._id,
                                                              true
                                                            )
                                                          );
                                                          setTodayTasks(
                                                            todayTasks.map(
                                                              (itemd, indexd) =>
                                                                itemd._id ===
                                                                item._id
                                                                  ? {
                                                                      ...itemd,
                                                                      taskStatus: true,
                                                                      tooltipShow: false,
                                                                    }
                                                                  : itemd
                                                            )
                                                          );
                                                        }}
                                                        className="circle_img"
                                                        src={hourGlass}
                                                        alt=""
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-12">
                                                  <img
                                                    className="width"
                                                    src={union}
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>

                                        {/* <div class="form-check">
                                      <input
                                        class="form-check-input activity_radio_icon"
                                        type="radio"
                                        name="flexRadioDefault2"
                                        id={ind}
                                      />
                                      <label
                                        class="form-check-label"
                                        for={ind}
                                      ></label>
                                    </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="row">
                <div class="col-12 mb-3 d-flex align-items-center justify-content-center">
                  <h5 class="m-0 activity_time_goal">
                    You're just a short time away from unlocking a{" "}
                    <span className="activity_time_goal">new achievement.</span>
                  </h5>
                </div>
                <div class="col-12 mt-2 d-flex align-items-center justify-content-center">
                  <div className="row super_height_width">
                    <div className="col-12 flex-column d-flex align-items-center justify-content-center">
                      <img
                        className=" achiveGolaImg"
                        src={superStudent}
                        alt=""
                      />
                      <h5 className="m-0 activity_time_goal text-white mt-2">
                        Super Student
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Activity;

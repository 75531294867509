import React,{useState} from 'react'
import { useDispatch } from 'react-redux'
import { staticTopicUpload } from '../../store/actions/study'
import { Store } from "react-notifications-component";

function StaticTable() {
    const dispatch = useDispatch()
    const[file,setFile] = useState("")


    const handleChange = (e)=>{
   
        setFile(e.target.files[0])
          }
          const handleClick = ()=>{
            let body = new FormData();
            body.append("file", file);
            dispatch(staticTopicUpload(body)).then((res)=>{
             if(res?.data?.status===200){
              showError("Uploaded Successfully","success","Congratulations")
             }
             else{
        
               showError(res?.data?.Response,"danger","Error!")
             }
        
            }
            )
            
          }
          function showError(data,type,message) {
            Store.addNotification({
              title: message,
              message: data,
              type: type,
              insert: "bottom",
              container: "bottom-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
        
        
          }
  return (
    <div>
        <h1>upload your static table data (school,profile,category etc.)from here: please select csv format only</h1>
      <input type="file" accept=".csv" onChange={handleChange} />
      <button onClick={handleClick}>upload</button>
    </div>
  )
}

export default StaticTable

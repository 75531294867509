import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./focusmode.css";
import TableMode from "../tablemode/TableMode";
import {
  CrossFocus,
  CloseSearch,
  focusModeData,
  framelightMode,
  sideLineLightMode,
  focusLightMode,
  lastFrame,
  sideLine,
  moonFocusDarkMode,
  moonLightMode,
  StudyGroup,
  Note,
} from "../../../staticContent/image";
import { useLocation } from "react-router-dom";
import { addMarkers, getSubTopics } from "../../../store/actions/study";
import { useSelector, useDispatch } from "react-redux";
import { getTopics } from "../../../store/actions/study";
import Popover from "react-text-selection-popover";
import ClearIcon from "@mui/icons-material/Clear";
import { Bar } from "../studyui";
import NotesDialague from "../addnotesdialogue/addnotesdialogue";
import { CosineWave } from "mdi-material-ui";
import FlashCards from "../flashCards/flashCards";
import { getUserProfile } from "../../../store/actions/getUserProfile";
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';

// Set the workerSrc to the pdfjs-dist CDN
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const Focusmode = () => {
  const store = useSelector((state) => state);
  const location = useLocation()
  const dispatch = useDispatch();
  const [darkmode, setDarkMode] = useState(false);
  const refParagraph = React.createRef();
  const [fontSize, setFontSize] = useState(22);
  const [lineHeight, setLineHeight] = useState(40);
  const [showNotesDialauge, setShowNotesDialauge] = useState(false);
  const [highLightedId, setHighlightedId] = useState();
  const topic_id = store?.getStudyTopic?.title?._id;
  const history = useHistory();
  const [focusdata, setFocusData] = useState("");

  useEffect(() => {
    dispatch(getUserProfile(store.auth?.user?.id));
    setHighlightedId(store?.getStudyTopic?.title?._id);
    setFocusData(store?.getFocusData?.focus);


  }, []);
  // useEffect(() => {
  //   if(location?.state==undefined){
  // console.log("trueee")
  //   }
  //   else{
  //     setFocusData(props?.location?.state?.focus);
  //     console.log("false")
  //   } 

  // }, []);

  const openAddNotesDialauge = (value) => {
    setShowNotesDialauge(value);
  };

  //  close focus button function starts
  const closeFocusMode = () => {
    history.push("/study");
  };

  // darkmode button function starts
  const darkMode = () => {
    setDarkMode(true);
  };
  // dark mode function ends
  //   light mode function starts
  const lightMode = () => {
    setDarkMode(false);
  };
  // light mode function ends
  // const handleTrigger = () => {
  //     props.setTableContent();
  // }

  const data =store?.getFocusData;
 
  const handleHighlighterr = (arr, string) => {
    var strr = string;
    arr.forEach((item, ind) => {
      let start = item.start;
      let end = item?.end;
      let text = item?.text;
      let stIn, enIn;
      stIn = strr.indexOf(text, start);
      enIn = stIn + text.length;
      strr = `${strr.slice(
        0,
        stIn
      )}<span id ="notSelectedHighlight">${text}</span>${strr.slice(
        enIn,
        strr.length
      )}`;
    });

    return strr;
  };

  const getSelection = () => {
    if (window.getSelection) {
      var sel = window.getSelection();
      var range = sel.getRangeAt(0);
      let selectedText = sel.toString();
      if (
        sel.rangeCount &&
        range.startContainer.isSameNode(range.endContainer)
      ) {
        var selectionAnchorOffset = sel.anchorOffset;
        var selectionFocusffset = sel.focusOffset;
        if (selectionAnchorOffset > selectionFocusffset) {
          selectionAnchorOffset = sel.focusOffset;
          selectionFocusffset = sel.anchorOffset;
        }
        let parentNodeSelection = sel.anchorNode.parentNode;
        let childNodesArr = parentNodeSelection.childNodes;
        for (let i = 0; i < childNodesArr.length; i++) {
          if (childNodesArr[i].nodeType === 3) {
            if (childNodesArr[i].isEqualNode(sel.anchorNode)) {
              let contentNodeText = childNodesArr[i].textContent;
              let startTextNode = document.createTextNode(
                contentNodeText.slice(0, selectionAnchorOffset)
              );
              let endTextNode = document.createTextNode(
                contentNodeText.slice(
                  selectionFocusffset,
                  contentNodeText.length
                )
              );
              let highlightSpan = document.createElement("span");
              highlightSpan.innerHTML = sel.toString();
              highlightSpan.setAttribute("id", "notSelectedHighlight");
              childNodesArr[i].after(endTextNode);
              childNodesArr[i].after(highlightSpan);
              childNodesArr[i].after(startTextNode);
              childNodesArr[i].remove();
              dispatch(
                addMarkers({
                  file_id: highLightedId,
                  htmlText: refParagraph?.current?.innerHTML,
                  selectedText: [selectedText],
                })
              );
                
            }
          }
        }
      }
    }
  };

  const pdfurl = 'https://seekh-assets.s3.amazonaws.com/Rewiredpages.pdf'; // Replace with your public URL
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
      {data.getShowType !== "video" && (
        <div
          className={
            !darkmode
              ? "main-div  text-dark h-100"
              : "main-div bg-dark text-light"
          }
        >
          <div className="container">
            <div className="row">
              <div className="col-12 p-5">
                <div className="row">
                  {data.type === "focus" ? (
                    <div className="col-9 processes scrollbar-track ms-5 ps-5">
                      <div className="row ms-5 ps-5">
                        {/* <div className="co-12 mb-4"></div> */}
                        <div className="col-12">
                          <div
                            
                            id="focushighlight "
                            className="m-0 focus_mode_height"
                            style={{
                              fontSize: `${fontSize}px`,
                              lineHeight: `${lineHeight}px`,
                            }}
                          >
                            <Document file={pdfurl} onLoadSuccess={onDocumentLoadSuccess} id="textHilight">
                              <Page pageNumber={pageNumber} />
                            </Document>
                            <p>
                              Page {pageNumber} of {numPages}
                            </p>
                            {/* {data?.focus && (
                              <div
                                ref={refParagraph}
                                className={data?.fontFamily}
                                dangerouslySetInnerHTML={{
                                  __html: focusdata,
                                }}
                              />
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-9">
                      <FlashCards bottomButton={false} />
                    </div>
                  )}

                  <Popover
                    selectionRef={refParagraph}
                    className="select_popup"
                    style={{ cursor: "pointer" }}
                  >
                    <div>
                      <Bar bg="black" style={{ borderRadius: "7px" }}>
                        <div className="d-flex">
                          <div className="text-light d-flex ">
                            <div
                              className="mx-2 py-2 ps-2"
                              onClick={() => {
                                getSelection();
                              }}
                            >
                              <img
                                src={StudyGroup}
                                alt={""}
                                className="studyInfo"
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                            <div className="mx-3 py-2 ps-2">
                              <img
                                src={Note}
                                onClick={() => {
                                  openAddNotesDialauge(true);
                                }}
                                alt={""}
                                className="studyInfo"
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </div>
                          <div>
                            <div
                              className="mx-2 py-2 pe-2 ps-3"
                              style={{
                                borderLeft: "3px solid white",
                                color: "white",
                                fontWeight: "500",
                              }}
                            >
                              <ClearIcon
                                onClick={() => {
                                  if (window.getSelection) {
                                    window.getSelection().removeAllRanges();
                                  } else if (document.selection) {
                                    document.selection.empty();
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Bar>
                    </div>
                  </Popover>

                  <div className="col">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-end align-items-center mb-4 icon-time">
                        {darkmode && (
                          <img
                            src={CloseSearch}
                            onClick={() => {
                              closeFocusMode();
                            }}
                            style={{ cursor: "pointer" }}
                            alt={""}
                            className="dark_light_size"
                          />
                        )}

                        {!darkmode && (
                          <img
                            src={CrossFocus}
                            onClick={() => {
                              closeFocusMode();
                            }}
                            style={{ cursor: "pointer" }}
                            alt={""}
                            className="dark_light_size"
                          />
                        )}
                      </div>
                      <div className="col-12 d-flex align-items-center justify-content-end">
                        <div
                          className={
                            darkmode
                              ? "row flex-column multi-icon py-4 rounded-3"
                              : "row flex-column py-4 bg-light rounded-3"
                          }
                        >
                          <div className="col mb-4 d-flex align-items-center justify-content-center">
                            <p className="m-0 lineHeightsize">{fontSize}</p>
                            <span className="col-auto d-flex flex-column ms-1">
                              <i
                                className="fa fa-angle-up studyicon"
                                onClick={() => {
                                  setFontSize(fontSize + 1);
                                }}
                              ></i>
                              <i
                                className="fa fa-angle-down studyicon"
                                onClick={() => {
                                  fontSize > 1 && setFontSize(fontSize - 1);
                                }}
                              ></i>
                            </span>
                          </div>
                          <div className="col mb-4  d-flex align-items-center justify-content-center">
                            <img
                              className="me-1"
                              src={darkmode ? sideLine : sideLineLightMode}
                              alt={""}
                            />
                            <p className="m-0 lineHeightsize">{lineHeight}</p>
                            <span className="col-auto d-flex flex-column ms-1">
                              <i
                                className="fa fa-angle-up studyicon"
                                onClick={() => {
                                  setLineHeight(lineHeight + 1);
                                }}
                              ></i>
                              <i
                                className="fa fa-angle-down studyicon"
                                onClick={() => {
                                  lineHeight > 1 &&
                                    setLineHeight(lineHeight - 1);
                                }}
                              ></i>
                            </span>
                          </div>
                          <div className="col mb-4  d-flex align-items-center justify-content-center">
                            <img
                              src={!darkmode ? focusLightMode : focusModeData}
                              onClick={() => {
                                lightMode();
                              }}
                              style={{ cursor: "pointer" }}
                              alt={""}
                            />
                          </div>
                          <div className="col mb-4  d-flex align-items-center justify-content-center">
                            <img
                              src={darkmode ? moonFocusDarkMode : moonLightMode}
                              onClick={() => {
                                darkMode();
                              }}
                              style={{ cursor: "pointer" }}
                              alt={""}
                            />
                          </div>
                          <div className="col border-top pt-4  d-flex align-items-center justify-content-center">
                            <img
                              src={darkmode ? lastFrame : framelightMode}
                              alt={""}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {data.getShowType === "video" && (
        <div>
          <div className="focusmodeVideo">
            <div className="row">
              <div className="col-12 ">
                <div className="row">
                  <div className="col-12 processes scrollbar-track">
                    <div className="row">
                      <div className="co-12 ">
                        {/* <h4 className="m-0">Newton's First Law of Motion</h4> */}
                      </div>
                      <div className="col-12">
                        <TableMode
                          getContent={data.content}
                          setTableContent={closeFocusMode}
                          showType={data.getShowType}
                          getUrlVid={data.getUrlVid}
                          type={data.type}
                          button="focus"
                          getsubTopic={data.topicdata}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <NotesDialague
          show={showNotesDialauge}
          setShow={setShowNotesDialauge}
          NotesId={topic_id}
          type={"add"}
        />
      </div>
    </>
  );
};

export default Focusmode;

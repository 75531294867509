import { GET_USER_PROFILE,GET_USER_PASSWORD }  from "../actions/types"

const INITIAL_STATE = {}

export const getUserProfilee = (state = INITIAL_STATE, action) => {
  if(action.type === GET_USER_PROFILE){
    return action.payload
  }
  else{
    return state
  }
    
}

export const getUserPasswordCheck = (state=INITIAL_STATE,action)=>{
  if(action.type===GET_USER_PASSWORD){
    return action.payload
  }
  else{
    return state
  }

}
export const password_Change = (state=INITIAL_STATE,action)=>{
  if(action.type==="password_change"){
    return action.payload
  }
  else{
    return state
  }

}
export const update_profile = (state=INITIAL_STATE,action)=>{
  if(action.type==="update_profile"){
    return action.payload
  }
  else{
    return state
  }

}
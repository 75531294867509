import React, { useEffect, useState } from "react";
import "./Goal.css";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllTaskList,
  addGoal,
  getGoalCategory,
  getGoalTime,
} from "../../../store/actions/studyBuddy";
import { Store } from "react-notifications-component";
import Skeleton from "react-loading-skeleton";
import TimeInput from "react-input-time";
function Goal() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [goal, setGoal] = useState({
    goalCategory: "",
    taskId: "",
    time: "",
    frequency: "Every Week",
  });
  const [data, setData] = useState([]);
  const [selectedCategory, setSelectedCAtegory] = useState(0);
  let [num, setNum] = useState(0);
  const [loader, setLoader] = useState(true);
  const [taskLoader, setTaskLoader] = useState(false);
  const [goalCategoryy, setGoalCategoryy] = useState("work");
  const [numVal, setNumVal] = useState("");
  const [edittime, setEditTime] = useState(false);
  const [time, setTime] = useState([]);
  const [frequency, setFrequency] = useState([
    { value: "Every Day" },
    { value: "Every Week", selected: true },
    { value: "Every Month" },
  ]);
  const [goalCategory, setGoalCategory] = useState([
    { value: "practice" },
    { value: "work", selected: true },
  ]);
  const [btn_disabled, setBtnDisabled] = useState(false);

  useEffect(() => {
    if (goalCategoryy == "work") {
      dispatch(getAllTaskList("work", num)).then((res) => {
        setData([...data, ...res?.data?.responseData]);
        setLoader(false);
      });
    } else {
      dispatch(getAllTaskList("practice", num)).then((res) => {
        setData([...data, ...res?.data?.responseData]);
        setLoader(false);
      });
    }
  }, [num]);

  useEffect(() => {
    dispatch(getGoalCategory()).then((res) => {
      setGoalCategory(res?.data?.responseData);
      setGoal({ ...goal, goalCategory: res?.data?.responseData[0]?._id });
    });
    dispatch(getGoalTime()).then((res) => {
      setTime(res?.data?.responseData);
    });
  }, []);

  const handleScroll = (e) => {
    if (e.target.scrollTop * 2 >= window.innerHeight) {
      setNum(num++);
    }
  };
  const handleTimeClick = (value, id) => {
    setTime(
      time.map((ele, ind) => {
        return id === ind
          ? { ...ele, selected: true }
          : { ...ele, selected: false };
      })
    );
    setGoal({ ...goal, time: value?._id });
  };

  const handleCustomChange = (e) => {
    setEditTime(true);
    setNumVal(e.target.value);
    let timee = e.target.value;
    setGoal({ ...goal, time: e.target.value });

    setTime(
      time.map((ele, ind) => {
        return { ...ele, selected: false };
      })
    );
  };
  const handleFrequency = (value, index) => {
    setFrequency(
      frequency.map((ele, ind) => {
        return index === ind
          ? { ...ele, selected: true }
          : { ...ele, selected: false };
      })
    );
    setGoal({ ...goal, frequency: value });
  };

  const handleCategory = (value, ind) => {
    setSelectedCAtegory(ind);
    setEditTime(false);
    setBtnDisabled(true);
    setTaskLoader(true);
    setGoal({ ...goal, goalCategory: value?._id });
    if (value?.goalCategory === "Work") {
      setGoalCategoryy("work");
      let type = "work";
      dispatch(getAllTaskList(type, 0)).then((res) => {
        if (res.status === 200) {
          setData(res?.data?.responseData);
          setTaskLoader(false);
          setBtnDisabled(false);
        }
      });
    } else {
      setGoalCategoryy("practice");
      dispatch(getAllTaskList("practice", 0)).then((res) => {
        if (res.status === 200) {
          setData(res?.data?.responseData);
          setTaskLoader(false);
          setBtnDisabled(false);
        }
      });
    }
  };
  const handleAddGoal = () => {
    if (goal.taskId === "") {
      showError(" Please add atleast one Task", "Warning", "danger");
    } else if (goal.time === "") {
      showError(" Please add time", "Warning", "danger");
    } else {
      if (goalCategoryy === "work") {
        if (edittime) {
          dispatch(
            addGoal({
              goalCategory: goal.goalCategory,
              taskId: goal.taskId,
              editTime: goal.time,
              frequency: goal.frequency,
            })
          ).then((res) => {
            if (res.status === 200) {
              history.push("/organize/activity");
            }
          });
        } else {
          dispatch(
            addGoal({
              goalCategory: goal.goalCategory,
              taskId: goal.taskId,
              time: goal.time,
              frequency: goal.frequency,
            })
          ).then((res) => {
            if (res.status === 200) {
              history.push("/organize/activity");
            }
          });
        }
      } else {
        if (edittime) {
          dispatch(
            addGoal({
              goalCategory: goal.goalCategory,
              topicId: goal.taskId,
              editTime: goal.time,
              frequency: goal.frequency,
            })
          ).then((res) => {
            if (res.status === 200) {
              history.push("/organize/activity");
            }
          });
        } else {
          dispatch(
            addGoal({
              goalCategory: goal.goalCategory,
              topicId: goal.taskId,
              time: goal.time,
              frequency: goal.frequency,
            })
          ).then((res) => {
            if (res.status === 200) {
              history.push("/organize/activity");
            }
          });
        }
      }

      showError("Your goal is created", "Wonderfull !", "success");
    }
  };

  const handleRadioChange = (e, item) => {
    if (e.target.checked === true) {
      setGoal({ ...goal, taskId: item?._id });
    } else {
    }
  };
  function showError(data, warn, type) {
    Store.addNotification({
      title: warn,
      message: data,
      type: type,
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  }
  const handleBack = () => {
    history.push("/organize/activity");
  };

  const HandleTime = (x) => {
    let res = x.split(" ");
    let first = res[0];
    let second = res[1].slice(0, 1);
    return first + second;
  };

  return (
    <div>
      {loader ? (
        <Skeleton height={`3vh`} count={100} />
      ) : (
        <div className="row mx-auto">
          <div className="col-12">
            <div className="row mx-auto">
              <div className="col-12 mt-5">
                <p
                  className="add_new_task_para m-0"
                  onClick={() => {
                    handleBack();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
                  Back to Activity
                </p>
              </div>
            </div>
            <div className="row mx-auto my-4 pb-5">
              <div className="col-6 ">
                <div className="row mx-auto bg-white form_main pb-4">
                  <div className="col-12 px-4">
                    <div className="row">
                      <div className="col-12 mt-4 mb-3">
                        <h6 className="add_new_task_heading m-0">
                          Create New `Goal
                        </h6>
                      </div>
                      <div className="col-12 mb-2">
                        <p className="dd_new_task_form_weight add_new_task_form p-0 m-0">
                          Select Goal Category:
                        </p>
                      </div>
                      <div className="col-12 d-flex align-items-center justify-content-start">
                        {goalCategory?.map((ele, ind) => {
                          return (
                            <>
                              <button
                                disabled={btn_disabled}
                                onClick={() => {
                                  handleCategory(ele, ind);
                                }}
                                type="button"
                                className={` ${
                                  selectedCategory === ind &&
                                  "time_button_color"
                                } btn border py-2 text-capitalize  me-2 shadow-none task_add_btn btn-weight btn_width `}
                              >
                                {ele?.goalCategory}
                              </button>
                            </>
                          );
                        })}
                      </div>
                      <div className="col-12 mt-3 mb-2">
                        <p className="dd_new_task_form_weight add_new_task_form m-0 p-0 ">
                          Select a Task:
                        </p>
                      </div>
                      <div className="col-12 bg-white ">
                        <div className="row mx-auto ">
                          <div
                            className="col-12  border select_task_scroll"
                            onScroll={(e) => {
                              handleScroll(e);
                            }}
                          >
                            <div className="row ">
                              {taskLoader ? (
                                <Skeleton height={`3vh`} count={20} />
                              ) : (
                                <>
                                  {data.map((item, ind) => {
                                    return (
                                      <>
                                        <div className="col-12 ">
                                          <div className="row border-bottom py-3">
                                            <div className="col-1 p-0 d-flex align-items-start justify-content-center">
                                              <div className="">
                                                <input
                                                  className="form-check-input activity_radio_icon shadow-none mx-auto"
                                                  type="radio"
                                                  name="form-check"
                                                  id={ind}
                                                  onChange={(e) => {
                                                    handleRadioChange(e, item);
                                                  }}
                                                />
                                                <label
                                                  className="form-check-label"
                                                  for={ind}
                                                ></label>
                                              </div>
                                            </div>
                                            <div className="col-11 d-flex align-items-center justify-content-start p-0">
                                              <div className="row d-flex flex-column justify-content-start">
                                                <div className="col activity_para_text">
                                                  <h6 className="m-0 add_new_task_form select_data_weight">
                                                    {item?.title
                                                      ? item?.title
                                                      : item?.topicId?.topic}
                                                  </h6>
                                                </div>
                                                {item?.course && (
                                                  <div className="col mt-1">
                                                    <button
                                                      type="button"
                                                      className="bg-white remove_border_color btn_radius_dec border text-uppercase py-1 px-2 shadow-none task_add_btn"
                                                    >
                                                      #{item?.course}
                                                    </button>
                                                    {item?.tags_topics
                                                      .split(",")
                                                      .map((itmd) => (
                                                        <button
                                                          type="button"
                                                          className="border-0 task_add_btn bg-light btn_radius_dec shadow-none text-capitalize px-2 py-1 ms-2"
                                                        >
                                                          {itmd}
                                                        </button>
                                                      ))}
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-3 mb-2">
                        <p className="dd_new_task_form_weight add_new_task_form m-0 p-0 ">
                          Select a Time:
                        </p>
                      </div>
                      <div className="col-12">
                        <div className="row mx-auto">
                          {time?.map((item, ind) => {
                            return (
                              <>
                                <div className="col-auto me-2 mb-2 p-0 m-0 d-flex align-items-center justify-content-center">
                                  <button
                                    type="button"
                                    className={` ${
                                      item.selected && "time_button_color"
                                    }  btn  p-0  border shadow-none m-0 task_add_btn btn_width_frequency  btn-weight btn_shadow_color text-lowercase`}
                                    onClick={() => handleTimeClick(item, ind)}
                                  >
                                    {HandleTime(item.goalTime)}
                                  </button>
                                </div>
                              </>
                            );
                          })}
                          {/* <div className="col-2   mb-2 p-0 ms-0 d-flex "> */}
                          {/* <input type="text" className="px-3 py-2 time border">00:00</input> */}
                          {/* </div> */}
                          <div
                            className="col-auto me-2 mb-2 p-0 m-0 d-flex align-items-center justify-content-center"
                            style={{ width: "58px" }}
                          >
                            <TimeInput
                              name="time"
                              className="time_input form-control shadow-none enter height_add_time_data py-2 px-2 btn_shadow_color form-check-input"
                              initialTime="00:00"
                              onChange={(event) => {
                                handleCustomChange(event);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-3 mb-2">
                        <p className="dd_new_task_form_weight add_new_task_form m-0 p-0 ">
                          Select a Frequency:
                        </p>
                      </div>
                      <div className="col-12 d-flex align-items-center justify-content-start">
                        {frequency?.map((ele, ind) => {
                          return (
                            <button
                              onClick={() => {
                                handleFrequency(ele.value, ind);
                              }}
                              type="button"
                              className={`${
                                ele.selected && "time_button_color"
                              } btn border py-2 text-capitalize  me-2 shadow-none task_add_btn btn-weight add_width_height `}
                            >
                              {ele.value}
                            </button>
                          );
                        })}
                      </div>
                      <div className="col-12 mt-3 d-flex align-items-center justify-content-end">
                        <button
                          onClick={() => {
                            handleAddGoal();
                          }}
                          type="button"
                          className=" border btn btn-light py-2 px-4 shadow-none offcan-study text-capitalize add_task_button"
                        >
                          Create Goal
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Goal;

import React, { useState, useRef, useEffect, useMemo } from "react";
import Header from "../../../layouts/Header/Header";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import NotesDialague from "../../Study/addnotesdialogue/addnotesdialogue.js";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import { getTopics, getSubTopics } from "../../../store/actions/study";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  getQuizStatus,
  quizRelatedContent,
  getQuizNotes,
  getQuizFinalResult,
  getQuizFeedBackFlags,
  addQuizFeedBack,
  practiceTimeTrack,
  explanationUsed,
  addAnswerStreak,
  addSKillUpgrade,
  addTotalPracticeTimeForReward,
} from "../../../store/actions/practice";
import "./Quiz.css";

import {
  questionQuiz,
  ideaQuiz,
  groupQuiz,
  threeDotsHorizontal,
  Pancel,
  Group,
  BlankCirImg,
  CheckCirImg,
  redCross,
  greenCross,
  bgImg,
  explPopImg,
  learn_button,
  sideBarAlogImg,
  sideBarClockImg,
  sideBarMathImg,
  sideBarpuzzelImg,
  sideBarWritePanImg,
  groupQuizColor,
  lock,
  tickQuestion,
  externalLink,
  addMorePlus,
  congMojiImg,
  wrongMojiImg,
  xCrose,
  graphFirst,
  colorGraphFirst,
  penThirdQuiz,
  colorPenThirdQuiz,
  circlePlus,
  closeSide,
} from "../../../staticContent/image";
import SidebarCanvas from "../../Librarypage/Overview/CanvasSidebar/sidebarCanvas";
import { ClockMinusOutline } from "mdi-material-ui";
export default function Quiz(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [height, setHeight] = useState(false);
  const [answer, setAnswer] = useState("");
  const [actualAnswer, setActualAnswer] = useState("");
  // const [openSidebar, setOpenSidebar] = useState(false);
  const [showNotesPanel, setShowNotesPanel] = useState(false);
  const [sidebarBtn, setSidebarBtn] = useState(false);
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);
  const [showNotesDialauge, setShowNotesDialauge] = useState(false);
  const [days, setDays] = useState(false);
  const [daysType, setDaysType] = useState("today");
  const [data, setData] = useState([]);
  const [RelatedLoader, setRelatedLoader] = useState(false);
  const [relatedData, setRelatedData] = useState("");
  const [relatedDataCraousel, setRelatedDataCarousel] = useState([]);
  const [explainLoader, setExplainLoader] = useState(false);
  const [explainData, setExplainData] = useState("");
  const [notesData, setNotesData] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [tryAgainLoader, setTryAgainLoader] = useState(false);
  const [studyFlags, setStudyFlags] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tableContentsData, setTableContentData] = useState([
    { _id: 1, title: "Table of Questions" },
    { _id: 2, title: "Related Content" },
  ]);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [nextCorrectAnswer, setNextCorrectAnswer] = useState("");
  const [name, setName] = useState({ Username: "", title: "" });
  const divRef = useRef(null);
  const div2Ref = useRef(null);
  const [notesLoader, setNotesLoader] = useState();
  const [showNote, setNote] = useState({
    id: data[questionNumber]?._id,
    textNotes: "",
    updateAt: "",
    name: "",
  });
  const [notesType, setNotesType] = useState("view");
  const [sideLoader, setSideLoader] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [sidebarData, setSidebarData] = useState({});
  const [notesAddDialougue, setShowAddNotesDialauge] = useState(false);
  const [quizstudyModel, setQuizStudyModel] = useState(false);

  const timer = useRef();

  const startTime = useRef();
  const endTime = useRef();
  const min = useRef(0);
  const hr = useRef(0);
  const handleYesQuiz = () => {
    setQuizStudyModel(false);
    setTimeout(() => {
      document.getElementById("submitQuiz").click();
    }, 1000);
  };
  const handleNoQuiz = () => {
    setQuizStudyModel(false);
  };
  const getSrcImage = (itemd) => {
    if (answer !== "") {
      if (answer === itemd?._id) {
        if (answer === actualAnswer) {
          return greenCross;
        } else {
          return redCross;
        }
      }
    } else {
      return itemd.check ? CheckCirImg : BlankCirImg;
    }
  };
  const hanldeOpenShow = () => {
    setDays(!days);
  };
  const getOptionClass = (itemd) => {
    if (answer !== "") {
      if (answer === itemd?._id) {
        if (answer === actualAnswer) {
          return "row quiz_material correct_ques_ans DNA p-3 ";
        } else {
          return "row quiz_material wrong_ans_color DNA p-3 ";
        }
      }
    }
    return "row quiz_material bg-white DNA p-3";
  };

  const noteLoader = (value) => {
    setNotesLoader(true);
  };

  const hanldeNotesDialougue = () => {
    setShowAddNotesDialauge(true);

    setNote({ ...showNote, id: data[questionNumber]?._id });
  };
  const handleDays = (type) => {
    setDaysType(type);
    setDays(false);
  };

  const hanldeNextQuestionClick = () => {
    setShow(false);
    setShowNotesPanel(false);
    setShowA(false);
    setShowB(false);
    if (questionNumber < data?.length - 1) {
      setAnswer("");
      setQuestionNumber(questionNumber + 1);
    }
  };
  const hanldeBackQuestionClick = () => {
    setShow(false);
    setShowNotesPanel(false);
    setShowA(false);
    setShowB(false);
    if (questionNumber > 0) {
      setAnswer("");
      setQuestionNumber(questionNumber - 1);
    }
  };

  const hanldeNextButton = () => {
    setShow(false);
    setShowNotesPanel(false);
    setShowA(false);
    setShowB(false);
    let topic_Id = store?.getQuizData?.data?.quizSet?.topicId;
    if (
      data[questionNumber]?.quizzID.answer.find((itemxx, indd) => itemxx?.check)
        ?._id !== undefined
    ) {
      if (
        data[questionNumber]?.quizzID?.answer.find(
          (itemxx, indd) => itemxx?.check
        )?._id === nextCorrectAnswer
      ) {
        setData(
          data.map((ele, ind) =>
            ind === questionNumber
              ? { ...ele, attempted: true, status: true }
              : { ...ele }
          )
        );
        let body = {
          quizId: data[questionNumber]?._id,
          topicId: topic_Id ? topic_Id : "",
          quizStatus: true,
        };
        dispatch(getQuizStatus(body));
      } else {
        let body = {
          quizId: data[questionNumber]?._id,
          topicId: topic_Id ? topic_Id : "",
          quizStatus: false,
        };
        dispatch(getQuizStatus(body));
        setData(
          data.map((ele, ind) =>
            ind === questionNumber
              ? { ...ele, attempted: true, status: false }
              : { ...ele }
          )
        );
      }
    }
    setAnswer("");
    setQuestionNumber(questionNumber + 1);
  };

  const handleCheck = () => {
    let checkk = data[questionNumber]?.quizzID?.answer?.find(
      (ele) => ele.check
    );
    if (checkk === undefined) {
      return true;
    } else {
      return false;
    }
  };
  const [show, setShow] = useState(false);
  const handleLeftQuestion = () => {
    let leftQues = data.filter((ele) => ele.attempted);
    return data?.length - leftQues?.length;
  };

  const handleTryAgain = () => {
    setTryAgainLoader(true);
    const id = store?.getQuizData?.data?.quizSet?._id;
    let totalTime = minutes * 60000;
    let correctQuestion = calculateRightAnswer();
    let WrongQuestion = data?.length - calculateRightAnswer();
    const body = {
      totalTime: totalTime,
      quizSetId: id,
      correct: correctQuestion,
      wrong: WrongQuestion,
    };
    dispatch(getQuizFinalResult(body)).then((res) => {
      setTryAgainLoader(false);
      document.getElementById("tryagain").click();
      if (res.status === 200) {
        if (location?.state !== undefined) {
          history.push({
            pathname: "/practice",
            state: { prevPath: location?.state?.prevPath },
          });
        } else {
          history.push({
            pathname: "/library/overview",
          });
        }
      }
    });
    dispatch(addSKillUpgrade());
    dispatch(addAnswerStreak(store?.getQuizData?.data?.quizSet?._id));
  };

  const calculateRightAnswer = () => {
    let totalRightQues = data.filter((ele) => ele.status);
    return totalRightQues?.length;
  };

  // const handleExplain = () => {
  //   dispatch(explanationUsed(data[questionNumber]?._id));
  //   setShow(false);
  //   setExplainLoader(true);
  //   console.log(data[questionNumber]);
  //   dispatch(
  //     data[questionNumber]?.quizzID?.explanation
  //     // quizRelatedContent(data[questionNumber]?.topic, data[questionNumber]?._id)
  //   ).then((res) => {
  //     if (res.status === 200) {
  //       setExplainLoader(false);
  //       setExplainData(res);
  //     } else {
  //       setExplainLoader(false);
  //       alert("Error 500 !");
  //     }
  //   });
  // };

  const handleExplain = () => {
    dispatch(explanationUsed(data[questionNumber]?._id));
    setShow(false);
    setExplainLoader(true);
    console.log(data[questionNumber]);
    const explanation = data[questionNumber]?.quizzID?.explanation;
    console.log(`explanation: ${explanation}`)
    if (explanation){
      setExplainLoader(false);
      setExplainData(data[questionNumber]?.quizzID?.explanation);
      
    }
      else{
        setExplainLoader(false);
      }
    }


  const handleSliceContent = (text, limit) => {
    return text?.length > limit ? text.slice(0, limit) + "..." : text;
  };
  const formatTime = (milliseconds) => {
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor(milliseconds / 1000 / 60 / 60);

    return [
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
    ].join(":");
  };

  const handleNotesPanel = () => {
    setSidebarBtn(false);
    setShowNotesPanel(!showNotesPanel);
    setNotesLoader(true);
    // let quiz_set = store?.getQuizData?.data?.quizSet?._id;
    dispatch(getQuizNotes(daysType, data[questionNumber]?._id)).then((res) => {
      if (res.status == 200) {
        setNotesData(res?.data?.responseData);
        setNotesLoader(false);
      }
    });
  };
  const handleNotesDate = (day) => {
    setNotesLoader(true);
    // let quiz_set = store?.getQuizData?.data?.quizSet?._id;
    dispatch(getQuizNotes(day, data[questionNumber]?._id)).then((res) => {
      if (res.status == 200) {
        setNotesData(res?.data?.responseData);
        setNotesLoader(false);
      }
    });
  };

  const editNotes = (id, text, updateAt, name) => {
    setNotesType("view");
    setNote({ id: id, textNotes: text, updateAt: updateAt, name: name });
    setShowNotesDialauge(true);
  };
  const handleCorrectVarient = () => {
    let variant = data[questionNumber]?.quizzID?.answer?.find((ele, ind) => {
      return ele?.answerStatus;
    });

    return variant?.sNo.toUpperCase();
  };

  const get_explanation = () => {
    let explanation = data[questionNumber]?.quizzID?.explanation;
    return explanation;
  };
  const handleClickRecomend = (item, close) => {
    if (close === "close") {
      document.getElementById("explain_model").click();
    }
    setSideLoader(true);

    setOpenSidebar(true);
    dispatch(getTopics(item._id)).then((res) => {
      setSidebarData(res);
      setSideLoader(false);
    });
  };
  const clickEdit = () => {
    setNotesType("edit");
  };
  function truncate(str, n) {
    return str?.length > n ? str.slice(0, n - 1) + "..." : str;
  }
  const handleStatus = () => {
    let rightAns = data.filter((ele, ind) => ele.status);
    let rightAnsLen = rightAns?.length;
    if (rightAnsLen <= 3) {
      return "Beginner";
    } else if (rightAnsLen >= 4 && rightAnsLen < 7) {
      return "Medium";
    } else if (rightAnsLen >= 7 && rightAnsLen <= 8) {
      return "Advanced";
    } else if (rightAnsLen >= 9) {
      return "Maestro";
    } else {
      return "Beginner";
    }
  };

  const hanldeStudyFlags = (item, question) => {
    let topic_Id = store?.getQuizData?.data?.quizSet?.topicId;
    let bodyy = {
      quizId: data[questionNumber]?._id,
      topicId: topic_Id ? topic_Id : "",
      quizStatus: true,
    };
    dispatch(getQuizStatus(bodyy));
    let body = {
      quizId: data[questionNumber]?._id,
      feedbackId: item?._id,
    };
    dispatch(addQuizFeedBack(body));

    setData(
      data?.map((ele, ind) =>
        ele?._id === question?._id
          ? { ...ele, attempted: true, status: true }
          : { ...ele }
      )
    );
    setShow(false);
    if (questionNumber < data.length - 1) {
      setQuestionNumber(questionNumber + 1);
    } else {
      setQuizStudyModel(true);
    }
  };

  const handleRelatedLearnButon = (item) => {
    setRelatedDataCarousel(
      relatedDataCraousel.map((ele) =>
        item?._id === ele?._id
          ? { ...ele, loader: true }
          : { ...ele, loader: false }
      )
    );
    dispatch(getTopics(item._id)).then((res) => {
      if (res.status === 200) {
        if (res.status === 200) {
          history.push({
            pathname: "/study",
          });
        } else {
          setRelatedDataCarousel(
            relatedDataCraousel.map((ele) =>
              item?._id === ele?._id
                ? { ...ele, loader: false }
                : { ...ele, loader: false }
            )
          );
        }
      } else {
        setRelatedDataCarousel(
          relatedDataCraousel.map((ele) =>
            item?._id === ele?._id
              ? { ...ele, loader: false }
              : { ...ele, loader: false }
          )
        );
      }
    });
  };

  const handleAnswerClick = (dataa, item) => {
    setNextCorrectAnswer(
      dataa?.quizzID?.answer.find((itemxx, indd) => itemxx.answerStatus)?._id
    );
    let res = data.map((ele, ind) => {
      if (dataa?._id === ele?._id) {
        const arr = ele?.quizzID?.answer?.map((itemx) => {
          return itemx?._id === item?._id
            ? { ...itemx, check: !itemx?.check }
            : {
                ...itemx,
                check: false,
              };
        });
        return { ...ele, quizzID: { ...ele.quizzID, answer: arr } };
      } else {
        return { ...ele };
      }
    });

    setData(res);
  };
  function toHoursAndMinutes(n) {
    let num = n;
    let hours = num / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    let finalhr = rhours < 10 ? `0${rhours}` : rhours;
    let finalmin = rminutes < 10 ? `0${rminutes}` : rminutes;
    return finalhr + ":" + finalmin + " ";
  }
  useEffect(() => {
    dispatch(getQuizNotes(daysType, data[questionNumber]?._id)).then((res) => {
      if (res.status == 200) {
        setNotesData(res?.data?.responseData);
        setNotesLoader(false);
      }
    });
  }, [store.notesList]);
  useEffect(() => {
    dispatch(getQuizFeedBackFlags()).then((res) => {
      setStudyFlags(res?.data?.responseData);
    });
  }, []);
  const handleTimer = () => {
    setMinutes((minutes) => minutes + 1);
  };
  useEffect(() => {
    timer.current = setInterval(handleTimer, 60000);
    return () => clearInterval(timer.current);
  }, [min.current]);
  useEffect(() => {
    setData(store?.getQuizData?.data?.response);
    setName({
      ...name,
      Username: store?.getUserProfile?.data?.responseData?.firstName,
      title: store?.getPracticeData?.data?.response?.title
        ? store?.getPracticeData?.data?.response?.title
        : store?.getPracticeData?.data?.response?.topic_name,
    });
    // setName({...name,title:store?.getPracticeData?.data?.response?.title})
    setQuestionNumber(0);

    const d = new Date();
    startTime.current = d.getTime();
    return () => {
      const dx = new Date();
      endTime.current = dx.getTime();
      if (store?.getQuizData?.data?.quizSet?.topicId) {
        let body = {
          practiceTime: endTime.current - startTime.current,
          topicId: store?.getQuizData?.data?.quizSet?.topicId,
        };
        dispatch(practiceTimeTrack(body));
      } else {
        let body = {
          practiceTime: endTime.current - startTime.current,
          fileId: store?.getQuizData?.data?.quizSet?.fileId,
        };
        dispatch(practiceTimeTrack(body));
      }
      dispatch(addTotalPracticeTimeForReward());
    };
  }, []);
  const handleSubmit = () => {
    setShow(false);
    clearInterval(timer.current);
    setShowA(false);
    setShowB(false);
    setShowNotesPanel(false);
    let topic_Id = store?.getQuizData?.data?.quizSet?.topicId;
    if (
      data[questionNumber]?.quizzID?.answer.find(
        (itemxx, indd) => itemxx?.check
      )?._id !== undefined
    ) {
      if (
        data[questionNumber]?.quizzID?.answer.find(
          (itemxx, indd) => itemxx?.check
        )?._id === nextCorrectAnswer
      ) {
        setData(
          data.map((ele, ind) =>
            ind === questionNumber
              ? { ...ele, attempted: true, status: true }
              : { ...ele }
          )
        );
        let body = {
          quizId: data[questionNumber]?._id,
          topicId: topic_Id ? topic_Id : "",
          quizStatus: true,
        };
        dispatch(getQuizStatus(body));
      } else {
        let body = {
          quizId: data[questionNumber]?._id,
          topicId: topic_Id ? topic_Id : "",
          quizStatus: false,
        };
        dispatch(getQuizStatus(body));
        setData(
          data.map((ele, ind) =>
            ind === questionNumber
              ? { ...ele, attempted: true, status: false }
              : { ...ele }
          )
        );
      }
    }
  };
  return (
    <>
      <>
        <Header />

        <div class="">
          <div
            class="container-fluid pt-5 background_quiz_color pe-0"
            style={{}}
          >
            <div class="row  heigth mx-auto  pt-3">
              <div className="col-auto mt-5 d-flex flex-column bg-white main_sideBox">
                <div className="row sidebar_width mt-3 pt-1 mx-auto">
                  <div className="col-12 mb-2 d-flex justify-content-center p-1 sideBarImg_Cont">
                    <button className="border-0 sideBarImg_Cont">
                      <img
                        className="sideImg"
                        src={sidebarBtn ? colorGraphFirst : graphFirst}
                        onClick={() => {
                          setSidebarBtn(!sidebarBtn);
                          setShowNotesPanel(false);
                        }}
                        alt=""
                      />
                    </button>
                  </div>
                  <div className="col-12 mb-2 d-flex justify-content-center p-1 ">
                    <button className="border-0 sideBarImg_Cont ">
                      <img className="sideImg" src={sideBarClockImg} alt="" />
                    </button>
                  </div>
                  <div className="col-12  mb-2 d-flex justify-content-center p-1 ">
                    <button className="border-0 sideBarImg_Cont ">
                      <img
                        onClick={() => {
                          handleNotesPanel();
                        }}
                        className="sideImg"
                        src={showNotesPanel ? colorPenThirdQuiz : penThirdQuiz}
                        alt=""
                      />
                    </button>
                  </div>
                  <div className="col-12 mb-2 d-flex justify-content-center p-1 ">
                    <button className="border-0 sideBarImg_Cont ">
                      <img className="sideImg" src={sideBarpuzzelImg} alt="" />
                    </button>
                  </div>
                  <div className="col-12 mb-2 d-flex justify-content-center p-1 ">
                    <button className="border-0 sideBarImg_Cont ">
                      <img className="sideImg" src={sideBarMathImg} alt="" />
                    </button>
                  </div>
                  <div className="col-12 mb-2 d-flex justify-content-center p-1 ">
                    <button className="border-0 sideBarImg_Cont ">
                      <img
                        className="sideImg"
                        src={sideBarWritePanImg}
                        alt=""
                      />
                    </button>
                  </div>
                  <div className="col-12 mb-2 d-flex justify-content-center p-1 ">
                    <button className="border-0 sideBarImg_Cont ">
                      <img className="sideImg" src={sideBarAlogImg} alt="" />
                    </button>
                  </div>
                </div>
                <div className="row sidebar_width mt-auto mb-2 d-flex justify-content-center">
                  <div
                    className="col-12 d-flex justify-content-center
                   p-1"
                  >
                    <button className="border-0 sideBarImg_Cont ">
                      <img className="sideImg" src={circlePlus} alt="" />
                    </button>
                  </div>
                  {/* <div
                    className="col-12 d-flex justify-content-center
                   p-2 pt-3"
                  >
                    <button className="border-0 groupQuiz_image bg-white ">
                      <img className="groupQuiz_image" src={closeSide} alt="" />
                    </button>
                  </div> */}
                </div>
                {/* ***sidebarpopup*** */}
                {sidebarBtn == false ? (
                  ""
                ) : (
                  <div className="row">
                    <div className="custom_popup  bg-white p-0 position-absolute ">
                      <div className="row mx-auto position-absolute triangle_div"></div>
                      <div className="row heigth flex-column p-3 mx-auto">
                        <div className="col-12 p-0 d-flex flex-column pop_topBox border-bottom">
                          <h3 className="m-0 level_text head_txt ">
                            Topic Progress:{" "}
                            <span className="Previous_text">
                              {handleStatus()}
                            </span>
                          </h3>
                          <div class="sm_progressBar d-flex my-3">
                            <span className="slectedProgressBar inner_lgProgWidth"></span>
                            <span
                              className={`${
                                handleStatus() === "Medium" ||
                                handleStatus() === "Advanced" ||
                                handleStatus() === "Maestro"
                                  ? "slectedProgressBar"
                                  : "unslectedProgressBar"
                              } inner_lgProgWidth`}
                            ></span>
                            <span
                              className={`${
                                handleStatus() === "Advanced" ||
                                handleStatus() === "Maestro"
                                  ? "slectedProgressBar"
                                  : "unslectedProgressBar"
                              } inner_lgProgWidth`}
                            ></span>
                            <span
                              className={`${
                                handleStatus() === "Maestro"
                                  ? "slectedProgressBar"
                                  : "unslectedProgressBar"
                              } inner_lgProgWidth`}
                            ></span>
                          </div>
                          <h2 className="time_txt mt-auto">
                            {/* {hr.current < 10 ? `0${hr.current}` : hr.current}: */}
                            {/* {minutes < 10 ? `0${minutes}` : minutes}{" "} */}
                            {toHoursAndMinutes(minutes)}
                            <span className="Previous_text">
                              hours spent
                            </span>{" "}
                          </h2>
                        </div>
                        <div className="col-12 flex-grow-1 p-0 pop_centerBox">
                          <div className="row">
                            <div className="col-12 my-3">
                              <h3 className="m-0 question_showTxt text-truncate">
                                {handleLeftQuestion()} questions{" "}
                                <span className="Previous_text">
                                  left to reach
                                </span>{" "}
                                <span className="color_txt">100% mastery</span>
                              </h3>
                            </div>
                            <div className="col-10 px-1">
                              <div className="row mx-auto">
                                {data?.map((ele, ind) => {
                                  return (
                                    <div
                                      style={{ cursor: "pointer" }}
                                      // onClick={() => {
                                      //   handleQuestions(ele, ind);
                                      // }}
                                      className={`col-auto mb-3 mx-2 d-flex align-items-center justify-content-center qustion_NoBox ${
                                        ele?.attempted
                                          ? "attemded_Que"
                                          : "not_attemded"
                                      } `}
                                    >
                                      {ind + 1}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 p-0 mt-auto pop_bottomBox d-flex align-items-center border-top">
                          <h2 className="m-0 pt-3 Previous_text">
                            Previous Topics: <span>{handleStatus()}</span>{" "}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div class="col-lg-6 mt-5 ms-5 me-4">
                <div class="row">
                  <div class="col-1 p-0 mt-4">
                    <div class="row">
                      <div class="col newton d-flex align-items-center justify-content-center flex-column">
                        <i
                          style={{ cursor: "pointer" }}
                          onClick={hanldeBackQuestionClick}
                          class="fa fa-angle-up rounded-pill bg-dark up_down_icon_custom mb-2 d-flex align-items-center justify-content-center text-white"
                          aria-hidden="true"
                        ></i>
                        <i
                          style={{ cursor: "pointer" }}
                          onClick={hanldeNextQuestionClick}
                          class="fa fa-angle-down rounded-pill bg-dark up_down_icon_custom d-flex align-items-center justify-content-center text-white"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-11">
                    <div class="row  width_all_quiz_ques_ans">
                      <div class="col-12 bg-white border border-0 p-3 mb-2 ps-4 py-4">
                        <div class="row mb-3">
                          <div class="col-6 d-flex justify-content-start align-items-center">
                            <p class="m-0 quiz_para all_pro_family">
                              {console.log('printing quiz data')}
                              {console.log(data)}
                              {(questionNumber + 1).toString().length === 1
                                ? "0" + (questionNumber + 1)
                                : questionNumber + 1}
                              /{data?.length}
                            </p>
                          </div>
                          <div
                            onClick={() => {
                              setShow(!show);
                            }}
                            style={{ cursor: "pointer" }}
                            class="col-6  position-relative d-flex justify-content-end align-items-center mb-1
                            "
                          >
                            <img
                              src={threeDotsHorizontal}
                              className="width add_width_three_dot me-2"
                              alt="..."
                            />
                            {show && (
                              <div className="col-auto position-absolute justify-content-end d-flex pe-1 drop_down_add_material">
                                <div className="row  px-2 mx-auto studyGenerate bg-white  border py-2 d-flex align-items-center justify-content-end flex-column materials_shadow">
                                  {studyFlags?.map((ele, ind) => {
                                    return (
                                      <div
                                        className="col-12 summaryStudy"
                                        onClick={() => {
                                          hanldeStudyFlags(
                                            ele,
                                            data[questionNumber]
                                          );
                                        }}
                                      >
                                        <button
                                          type="button"
                                          class="btn m-0 p-0 studyGenerateFlashcards add_material_dropdwn py-1 shadow-none font_size_dropdown border-0"
                                        >
                                          {ele?.feedbackFlag}
                                        </button>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                          {/* {show && (
                            <div className="col justify-content-end d-flex pe-1">
                              <div className="row  px-2 mx-auto studyGenerate bg-white position-absolute border py-2 d-flex align-items-center justify-content-end flex-column materials_shadow">
                                {studyFlags?.map((ele, ind) => {
                                  return (
                                    <div
                                      className="col-12 summaryStudy"
                                      onClick={() => {
                                        hanldeStudyFlags(
                                          ele,
                                          data[questionNumber]
                                        );
                                      }}
                                    >
                                      <button
                                        type="button"
                                        class="btn m-0 p-0 studyGenerateFlashcards add_material_dropdwn py-1 shadow-none font_size_dropdown border-0"
                                      >
                                        {ele?.feedbackFlag}
                                      </button>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )} */}
                        </div>
                        <div class="col-12">
                          <h6 className="m-0 quiz_heading all_pro_family">
                            {data[questionNumber]?.quizzID?.question}
                          </h6>
                        </div>
                      </div>
                      {data[questionNumber]?.quizzID?.answer?.map(
                        (itemd, indexd) => (
                          <div class="col-12  mt-4 ">
                            <div
                              class={getOptionClass(itemd)}
                              style={{
                                borderLeft: `10px solid ${itemd.color}`,
                              }}
                            >
                              <div class="col d-flex align-items-center">
                                <span
                                  style={{ color: `${itemd.color}` }}
                                  class="me-3 warning_color ans_ques_quiz inc_font_quiz"
                                >
                                  {itemd?.sNo}.
                                </span>
                                <p class="m-0 ans_ques_quiz all_pro_family practice_color">
                                  {itemd.option}
                                </p>
                              </div>
                              <div class="col-auto d-flex align-items-center cp">
                                <img
                                  onClick={() => {
                                    handleAnswerClick(
                                      data[questionNumber],
                                      itemd
                                    );
                                  }}
                                  src={getSrcImage(itemd)}
                                  alt=""
                                  crose_button
                                  p-0
                                />
                              </div>
                            </div>
                          </div>
                        )
                      )}
                      <div class="col-12 pe-0 d-flex mt-4 justify-content-end align-items-center">
                        <button
                          style={{ cursor: "pointer" }}
                          onClick={handleExplain}
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal1"
                          class="btn border py-2 text-capitalize size_ince_btn me-2 shadow-none task_add_btn btn-weight add_width_height  d-flex align-items-center justify-content-center"
                        >
                          Explain
                          <img
                            src={ideaQuiz}
                            className="ms-2 width height"
                            alt="..."
                          />
                        </button>
                        <button
                          style={{ cursor: "pointer" }}
                          disabled={handleCheck()}
                          onClick={() => {
                            let res = data[
                              questionNumber
                            ]?.quizzID?.answer.find(
                              (itemfx, indexf) => itemfx.check
                            )._id;

                            setActualAnswer(
                              data[questionNumber]?.quizzID?.answer.find(
                                (itemfx, ind) => itemfx.answerStatus
                              )?._id
                            );

                            setAnswer(
                              data[questionNumber]?.quizzID?.answer.find(
                                (itemfx, indexf) => itemfx.check
                              )._id
                            );
                            if (
                              data[questionNumber]?.quizzID?.answer.find(
                                (itemfx, ind) => itemfx.answerStatus
                              )?._id ===
                              data[questionNumber]?.quizzID?.answer.find(
                                (itemfx, indexf) => itemfx.check
                              )._id
                            ) {
                              setShowA(true);
                            } else {
                              setShowB(true);
                            }
                          }}
                          type="button"
                          class="btn border py-2 text-capitalize size_ince_btn me-2 shadow-none task_add_btn btn-weight add_width_height d-flex align-items-center justify-content-center"
                        >
                          Check
                          <img
                            src={questionQuiz}
                            className="ms-2 width height"
                            alt="..."
                          />
                        </button>

                        {data.length !== 0 &&
                        questionNumber < data.length - 1 ? (
                          <button
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              hanldeNextButton();
                            }}
                            type="button"
                            class=" border  text-white py-2 px-4 shadow-none offcan-study text-capitalize add_task_button all_pro_family practice_color d-flex align-items-center justify-content-center"
                          >
                            Next Question
                          </button>
                        ) : (
                          <button
                            id="submitQuiz"
                            style={{ cursor: "pointer" }}
                            onClick={handleSubmit}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            type="button"
                            class=" border  text-white py-2 px-4 shadow-none offcan-study text-capitalize add_task_button all_pro_family practice_color d-flex align-items-center justify-content-center"
                          >
                            Next Question
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 position-relative mt-5   topics_width_quiz border border-0">
                <div class="row pt-4 px-3 bg-white border border-0">
                  <div class="col-12">
                    <p class="m-0 quiz_para all_pro_family">Topic</p>
                  </div>
                  <div class="col-12 mt-2">
                    <h6 className="m-0 quiz_heading all_pro_family inc_font_quiz">
                      {data[questionNumber]?.topic}
                    </h6>
                  </div>

                  <div class="col-12 mt-2 bg-white border border-0">
                    <div class="row ">
                      <div class="col-12">
                        <div
                          ref={(ref) => (div2Ref.current = ref)}
                          class="row "
                        >
                          {tableContentsData.map((itemdx, indexdx) => (
                            <div className="col-12">
                              <div
                                // className={
                                //   itemdx.expand
                                //     ? "row mx-auto"
                                //     : "row mx-auto border-bottom"
                                // }
                                className={`row mx-auto ${classNames({
                                  "border-bottom": !itemdx.expand,
                                  "mb-3": indexdx === 1,
                                })}`}
                              >
                                <div className="col-12 related_task_box_height padding_add ">
                                  <div className="row">
                                    <div className="col-auto ps-0">
                                      <p class="m-0 quiz_table_para all_pro_family practice_color">
                                        {itemdx.title}
                                      </p>
                                    </div>
                                    <div className="col pe-0 d-flex align-items-center justify-content-end">
                                      <img
                                        onClick={() => {
                                          setRelatedLoader(true);
                                          dispatch(
                                            quizRelatedContent(
                                              data[questionNumber]?.topicId
                                                ?.topic_name,
                                              data[questionNumber]?._id
                                            )
                                          ).then((res) => {
                                            if (res.status === 200) {
                                              setRelatedLoader(false);
                                              setRelatedData(res);
                                              setRelatedDataCarousel(
                                                res?.data?.recommended
                                              );
                                            } else {
                                              setRelatedLoader(false);
                                              alert("please check your server");
                                            }
                                          });
                                          setTableContentData(
                                            tableContentsData.map(
                                              (itemc, indexc) =>
                                                itemc._id === itemdx._id
                                                  ? {
                                                      ...itemc,
                                                      expand: !itemc.expand,
                                                    }
                                                  : {
                                                      ...itemc,
                                                      expand: false,
                                                    }
                                            )
                                          );
                                        }}
                                        src={
                                          itemdx.expand
                                            ? groupQuizColor
                                            : groupQuiz
                                        }
                                        className="groupQuiz_image_second"
                                        alt="..."
                                      />
                                    </div>
                                  </div>
                                </div>
                                {itemdx.expand &&
                                  (itemdx.title === "Table of Questions" ? (
                                    <>
                                      <div className="col-12">
                                        <div className="row">
                                          {data?.map((ele, ind) => {
                                            return (
                                              <div className="col-12  mb-2 pb-2  border-bottom">
                                                <div className="row">
                                                  <div className="col ps-0">
                                                    <p class="m-0 data_load_quiz_para all_pro_family practice_color">
                                                      Question {ind + 1}
                                                    </p>
                                                  </div>
                                                  <div className="col pe-0 d-flex align-items-center justify-content-end">
                                                    <img
                                                      src={
                                                        ele?.attempted
                                                          ? tickQuestion
                                                          : lock
                                                      }
                                                      className="ms-2 tick_question"
                                                      alt="..."
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}

                                          <div className="col-12 pb-2">
                                            <div className="row">
                                              <div className="col-12 data_load_quiz_para data_load_quiz_para_color ps-0 d-flex align-items-center">
                                                <img
                                                  src={addMorePlus}
                                                  className="add_external_link me-2"
                                                  alt="..."
                                                />

                                                <p className="m-0 p-0  all_pro_family ">
                                                  Load More
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {RelatedLoader ? (
                                        <Skeleton height={`3vh`} count={10} />
                                      ) : (
                                        <div className="col-12 mt-3">
                                          <div className="row">
                                            <div class="col-12 px-0">
                                              <h2 class="m-0 mb-2 expl_headText_size">
                                                {
                                                  relatedData?.data?.response
                                                    ?.topic_name
                                                }
                                              </h2>
                                              <div class="mb-2">
                                                <p
                                                  className="m-0 p-0 task_add_law"
                                                  dangerouslySetInnerHTML={{
                                                    __html: handleSliceContent(
                                                      relatedData?.data
                                                        ?.response
                                                        ?.topic_definition,
                                                      100
                                                    ),
                                                  }}
                                                ></p>
                                              </div>
                                            </div>
                                            <div className="col-12 px-0">
                                              <p className="m-0 p-0 task_add_law practice_color">
                                                <img
                                                  src={externalLink}
                                                  className=" add_external_link me-1"
                                                  alt="..."
                                                />{" "}
                                                More Details
                                              </p>
                                            </div>
                                            <div className="col-12 px-0">
                                              <div
                                                ref={(ref) =>
                                                  (divRef.current = ref)
                                                }
                                                className="row mx-auto mt-3 related_carousel overflow-auto"
                                              >
                                                {relatedDataCraousel?.map(
                                                  (item, ind) => {
                                                    return (
                                                      <div className="col-12 inner_carouselBox px-0">
                                                        <div className="row mx-auto">
                                                          <div className="col-12">
                                                            <div className="row border overflow-hidden mb-2">
                                                              <div className="col-6 position-relative">
                                                                <div className="row">
                                                                  <div className="col-12 p-0">
                                                                    <img
                                                                      src={
                                                                        item?.thumbnail
                                                                      }
                                                                      className=" secondLakhni"
                                                                      alt="..."
                                                                    />
                                                                  </div>
                                                                  <div className="col-12 p-0 ps-2 position-absolute bottom-0 mb-1">
                                                                    {item
                                                                      ?.category
                                                                      ?.category_name && (
                                                                      <button
                                                                        className="btn text-white videoDoc_btn text-capitalize shadow-none rounded-pill  d-flex align-items-center justify-content-center"
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                          backgroundColor: `${item?.category?.category_color}`,
                                                                        }}
                                                                      >
                                                                        {
                                                                          item
                                                                            ?.category
                                                                            ?.category_name
                                                                        }
                                                                      </button>
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="col-6 py-2 d-flex pe-2 ps-3">
                                                                <div className="row ">
                                                                  <div className="col-12 mb-auto  d-flex flex-column justify-content-center">
                                                                    <h6 className="card-data  m-0 d-flex align-items-start">
                                                                      {handleSliceContent(
                                                                        item?.title,
                                                                        20
                                                                      )}
                                                                    </h6>
                                                                  </div>

                                                                  <div className="col-12 mt-auto">
                                                                    <p className="card-texts mb-0 text-nowrap">
                                                                      {item.studyPageTimeTrack
                                                                        ? formatTime(
                                                                            item.studyPageTimeTrack
                                                                          )
                                                                        : "0:00"}{" "}
                                                                      spent ·
                                                                      Beginner
                                                                    </p>
                                                                    <div class=" mt-2 topic">
                                                                      <div class="progress progress_parent">
                                                                        <div
                                                                          class="progress-bar progress_bar"
                                                                          role="progressbar"
                                                                          aria-label="Basic example"
                                                                          aria-valuenow="25"
                                                                          aria-valuemin="0"
                                                                          aria-valuemax="100"
                                                                          style={{
                                                                            width: `${
                                                                              item?.progressBarPercentage
                                                                                ? item?.progressBarPercentage
                                                                                : "0%"
                                                                            }`,
                                                                          }}
                                                                        ></div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="col-12 mt-auto">
                                                                    {!item?.loader ? (
                                                                      <div
                                                                        className="row d-flex align-items-center justify-content-start"
                                                                        onClick={() => {
                                                                          handleRelatedLearnButon(
                                                                            item
                                                                          );
                                                                        }}
                                                                      >
                                                                        <div
                                                                          className="col-auto"
                                                                          style={{
                                                                            marginRight:
                                                                              "-5px",
                                                                          }}
                                                                        >
                                                                          <p
                                                                            style={{
                                                                              cursor:
                                                                                "pointer",
                                                                            }}
                                                                            className="card-data m-0 d-flex align-items-center"
                                                                          >
                                                                            Learn
                                                                          </p>
                                                                        </div>
                                                                        <div
                                                                          className="col-auto p-0 d-flex align-items-center justify-content-center rounded-pill"
                                                                          style={{
                                                                            width:
                                                                              "16px",
                                                                            height:
                                                                              "16px",
                                                                            backgroundColor:
                                                                              "#4E45C2",
                                                                          }}
                                                                        >
                                                                          <img
                                                                            alt=""
                                                                            style={{
                                                                              cursor:
                                                                                "pointer",
                                                                            }}
                                                                            className="w-100"
                                                                            src={
                                                                              learn_button
                                                                            }
                                                                          />
                                                                        </div>
                                                                      </div>
                                                                    ) : (
                                                                      <div
                                                                        class="spinner-border "
                                                                        role="status"
                                                                      >
                                                                        <span class="sr-only">
                                                                          Loading...
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="toaster_box">
                  {showA && (
                    <Toast
                      show={showA}
                      onClose={() => {
                        setShowA(false);
                      }}
                      className="border-0 border-bottom-0 overflow-hidden shadow-none"
                    >
                      <Toast.Header className="toaster_head border-0 p-3 position-relative height">
                        <div className="row height">
                          <div className="col-2 height bitMoj_IMg">
                            <img
                              src={congMojiImg}
                              className=" width height"
                              alt="..."
                            />
                          </div>
                          <div className="col height">
                            <div className="row">
                              <div className="cong_text">
                                <h3 className="mb-1">
                                  Congratulations, your answer is correct!
                                </h3>
                                <p className="m-0">
                                  Progress to the next question to improve your
                                  knowledge and earn more points.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Toast.Header>
                    </Toast>
                  )}
                  {showB ? (
                    <Toast
                      show={showB}
                      onClose={() => {
                        setShowB(false);
                      }}
                      position="bottom-end"
                      className="border-0 border-bottom-0 overflow-hidden shadow-none"
                    >
                      <Toast.Header className="toaster_head border-0 p-3 position-relative height">
                        <div className="row height">
                          <div className="col-2 height bitMoj_IMg">
                            <img
                              src={wrongMojiImg}
                              className=" width height"
                              alt="..."
                            />
                          </div>
                          <div className="col height">
                            <div className="row">
                              <div className="cong_text">
                                <h3 className="mb-1">
                                  Unfortunately, you chose the wrong answer
                                </h3>
                                <p className="m-0">
                                  Use the{" "}
                                  <span className="fw-bold text-dark">
                                    Explain
                                  </span>{" "}
                                  option or look at the{" "}
                                  <span className="fw-bold  me-1 text-dark">
                                    Relevant Content
                                  </span>
                                  to get the right answer.
                                </p>
                              </div>
                              <div className="col cross_img">
                                <img
                                  src="holder.js/20x20?text=%20"
                                  className="rounded me-2"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Toast.Header>
                    </Toast>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {showNotesPanel && (
                <div class="col px-0 ms-4 border-start bg-white  sidebar_open ">
                  <div className="row mx-auto h-100">
                    <div className="col-12 d-flex flex-column">
                      <div class="row border-top border-bottom">
                        <div class="col-12 pt-4">
                          <div class="row pb-2 mb-1 mx-auto">
                            <div class="col-9  notes d-flex justify-content-start align-items-end position-relative">
                              <h6 class="m-0 p-0 me-3 sidebarNotesNote border-0 sidebarBottomColor py-3">
                                Notes
                              </h6>
                            </div>
                            <div
                              class="col-3  notes d-flex justify-content-end align-items-center"
                              onClick={hanldeNotesDialougue}
                            >
                              <img className="sideImg" src={Pancel} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row pb-4 flex-grow-1 border-bottom">
                        <div class="col-12 col-md-12 position-relative">
                          <div class="accordion" id="accordionExample">
                            <div class="accordion-item border-white mb-3">
                              <h2 class="accordion-header" id="headingOne">
                                <button
                                  class="accordion-button bg-white text-dark shadow-none ps-0 ps-ms-5 collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne2"
                                  aria-expanded="false"
                                  aria-controls="collapseOne"
                                >
                                  <div class="col-12">
                                    <div class="row d-flex align-items-center justify-content-center ">
                                      <div class="col d-flex align-items-center justify-content-end ">
                                        <div class="row">
                                          <div class="col-auto topic-text d-flex justify-content-end align-items-center ">
                                            <div class="col-12 position-relative d-flex align-items-center justify-content-end">
                                              <p class="m-0 addnotesTextAll">
                                                {daysType}
                                              </p>
                                              <i
                                                onClick={hanldeOpenShow}
                                                class="fa fa-angle-down ms-2 d-flex align-items-center justify-content-center"
                                                aria-hidden="true"
                                              ></i>
                                            </div>

                                            {days && (
                                              <div className="col justify-content-end d-flex">
                                                <div className="row mx-auto mt-3 studyGenerate bg-white position-absolute border overflow-hidden d-flex align-items-center justify-content-end flex-column">
                                                  <div className="col-12 sideBarNotesMenuWeeklyData">
                                                    <p
                                                      className="m-0 py-2 px-1 p-0 addnotesTextAll"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        handleNotesDate(
                                                          "today"
                                                        );
                                                        handleDays("today");
                                                      }}
                                                    >
                                                      Today
                                                    </p>
                                                  </div>

                                                  <div className="col-12 sideBarNotesMenuWeeklyData">
                                                    <p
                                                      className="m-0 py-2 px-1 p-0 addnotesTextAll"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        handleNotesDate(
                                                          "weekly"
                                                        );
                                                        handleDays("weekly");
                                                      }}
                                                    >
                                                      Weekly
                                                    </p>
                                                  </div>
                                                  <div className="col-12 sideBarNotesMenuWeeklyData">
                                                    <p
                                                      className="m-0 py-2 px-1 p-0 addnotesTextAll"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        handleNotesDate("all");
                                                        handleDays("all");
                                                      }}
                                                    >
                                                      All
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </button>
                              </h2>
                              <div class="col-12 study-program">
                                <div class="row mx-auto">
                                  <div className="col-12">
                                    {notesLoader ? (
                                      <Skeleton height={`2vh`} count={20} />
                                    ) : (
                                      <div className="row mx-auto">
                                        {notesData?.map((ele, ind) => {
                                          return (
                                            <div class="col-12 mb-3 bg-light border border-0 large vision justify-content-between  align-items-baseline">
                                              <div className="row py-3 ">
                                                <div className="col-10">
                                                  <p class="m-0">
                                                    {truncate(ele?.notes, 140)}
                                                  </p>
                                                </div>
                                                <div className="col-2">
                                                  <img
                                                    onClick={() => {
                                                      editNotes(
                                                        ele._id,
                                                        ele?.notes,
                                                        ele?.updatedAt,
                                                        name?.Username
                                                      );
                                                    }}
                                                    className=" notes_three_dots"
                                                    src={threeDotsHorizontal}
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row  mt-auto py-4 my-2">
                        <div class="col-12 ps-4">
                          <img
                            onClick={() => {
                              setShowNotesPanel(false);
                            }}
                            className="sideImg"
                            src={Group}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <PracticeSideBarCanvas
          show={openSidebar}
          setShow={setOpenSidebar}
          sideLoader={sideLoader} */}
      </>

      <div
        class="modal main_PopContainer fade modal_bg_color_quiz"
        id="exampleModal"
        aria-hidden="true"
        aria-labelledby="exampleModalLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered add_padding_modal_second">
          {/* <div class="modal-content inner_popBox " > */}
          <div
            class={
              height === false
                ? "modal-content inner_popBox "
                : " modal-content  inner_popBox"
            }
          >
            <div class="modal-header position-relative px-0 pb-0 d-block pop_Head">
              <div className="row position-relative mx-auto h-100 ">
                <div className="col d-flex flex-column align-items-center text-center justify-content-end ">
                  <h3 className="popCong_txt text-white">
                    Congratulations,
                    <br />
                    you have completed the test
                  </h3>
                  <p className="popPara_txt  text-white">
                    We collected statistics on participants who <br />{" "}
                    participated in the quiz
                  </p>
                </div>
                <div className="col-auto position-absolute end-0">
                  <button
                    type="button"
                    id="tryagain"
                    class="cross_btn bg-white d-flex align-items-center bg-white justify-content-center  border-0"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <img
                      src={xCrose}
                      className="crose_button_close p-0"
                      alt="..."
                    />
                  </button>
                </div>
              </div>
              <div className="row w-100 heigth mx-auto popBg_Img position-absolute ">
                <img
                  src={bgImg}
                  className="sidebar_width heigth p-0"
                  alt="..."
                />
              </div>
            </div>
            <div class="modal-body px-5 pt-4 pop_Body">
              <div className="row">
                <div className="col-12" onClick={() => setHeight(!height)}>
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item border-0">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-butto border-0 w-100 d-flex align-items-center justify-content-center p-0 text-dark bg-white"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <div className="row d-flex align-items-center justify-content-between w-100 border-bottom mx-auto pb-3">
                            <div className="col-auto d-flex p-0 align-items-center justify-content-center user_NameBox">
                              <div className="symbol_name d-flex p-0 align-items-center justify-content-center text-white optiontetext">
                                {name?.Username.slice(0, 1)}
                              </div>
                              <div className="user_name text-start ms-2 ps-1">
                                <p className="m-0 name_txt">{name?.Username}</p>
                                <p className="m-0 time_txt">
                                  {/* {hours < 10 ? `0${hours}` : hours}:
                                  {minutes < 10 ? `0${minutes}` : minutes}{" "} */}
                                  {toHoursAndMinutes(minutes)}
                                  <span>hours spent</span>
                                </p>
                              </div>
                            </div>
                            <div className="col-auto d-flex flex-column text-start progress_box">
                              <p className="m-0">
                                Progress: <span>{handleStatus()}</span>
                              </p>
                              <div class="sm_progressBar d-flex mt-2">
                                <span className="slectedProgressBar inner_smProgWidth"></span>
                                <span
                                  className={`${
                                    handleStatus() === "Medium" ||
                                    handleStatus() === "Advanced" ||
                                    handleStatus() === "Maestro"
                                      ? "slectedProgressBar"
                                      : "unslectedProgressBar"
                                  } inner_smProgWidth`}
                                ></span>
                                <span
                                  className={`${
                                    handleStatus() === "Advanced" ||
                                    handleStatus() === "Maestro"
                                      ? "slectedProgressBar"
                                      : "unslectedProgressBar"
                                  } inner_smProgWidth`}
                                ></span>
                                <span
                                  className={`${
                                    handleStatus() === "Maestro"
                                      ? "slectedProgressBar"
                                      : "unslectedProgressBar"
                                  } inner_smProgWidth`}
                                ></span>
                              </div>
                            </div>
                            <div className="col-auto  d-flex align-items-center question_status">
                              <div className="arrow_Box  d-flex align-items-center">
                                <img src={greenCross} alt="" />
                                <span className="ans_text ms-1">
                                  {calculateRightAnswer()}
                                </span>
                              </div>
                              <div className="arrow_Box d-flex align-items-center ms-3">
                                <img src={redCross} alt="" />
                                <span className="ans_text ms-1">
                                  {data?.length - calculateRightAnswer()}
                                </span>
                              </div>
                            </div>
                            <div className="col-auto pe-0 d-flex align-align-items-center justify-content-center">
                              <img
                                src={groupQuiz}
                                className="groupQuiz_image_second"
                                alt="..."
                              />
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body p-0">
                          <div className="row mx-auto">
                            <div className="col-12 py-3 p-0">
                              <p className="qusestion_showTxt m-0">
                                Topic:{" "}
                                <span className="fw-bold">{name?.title}</span>
                              </p>
                            </div>
                            <div className="col-12 p-0">
                            <div className="row mx-auto d-grid" style={{ gridTemplateColumns: "repeat(auto-fill, minmax(24px, 1fr))", gap: "10px" }}>
                                {data?.map((ele, ind) => {
                                  return (
                                    <div
                                      className={`col-auto text-white d-flex align-items-center justify-content-center ques_box ${
                                        ele?.status
                                          ? "ques_box_right"
                                          : "ques_box_wrong"
                                      }`}
                                    >
                                      {ind + 1}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-4 pt-2 d-flex justify-content-center">
                  {!tryAgainLoader ? (
                    <button
                      onClick={() => {
                        handleTryAgain();
                      }}
                      type="button"
                      // data-bs-toggle="modal"
                      // data-bs-target="#exampleModal"
                      class="btn border py-2 text-capitalize mt-2 tryAgain_btn  shadow-none task_add_btn btn-weight add_width_height  d-flex align-items-center justify-content-center"
                    >
                      Try Again
                    </button>
                  ) : (
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ****popup explation work** */}
      <div
        class="modal main_PopContainer fade  modal_bg_color_quiz"
        id="exampleModal1"
        aria-hidden="true"
        aria-labelledby="exampleModalLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered add_padding_modal">
          <div class="modal-content explation_pop">
            <div class="modal-header position-relative px-0 pb-0 d-block explationpop_Head ">
              <div className="row position-relative mx-auto h-100 ">
                <div className="col px-4 ms-2  d-flex align-items-center ">
                  <h3 className="popCong_txt text-white">Explanation</h3>
                </div>
                <div className="col-auto position-absolute end-0">
                  <button
                    id="explain_model"
                    type="button"
                    class="cross_btn d-flex align-items-center bg-white justify-content-center   border-0"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <img
                      src={xCrose}
                      className="crose_button_close p-0"
                      alt="..."
                    />
                  </button>
                </div>
              </div>
              <div className="row w-100 heigth mx-auto popBg_Img position-absolute ">
                <img
                  src={explPopImg}
                  className="sidebar_width heigth p-0"
                  alt="..."
                />
              </div>
            </div>
            {explainLoader ? (
              <Skeleton height={`3vh`} count={16} />
            ) : (
              <div class="modal-body px-5 pt-4 pop_Body">
                <div className="row pt-2">
                  <div className="col-12 border-bottom p-0">
                    <div className="row">
                      <div className="col-12">
                        <h2 className="m-0 mb-3 expl_headText">
                          {data?.response?.topic}
                        </h2>
                        <div className="explan_txt">
                          {" "}
                          {console.log(explainData?.response)}
                          
                          {" "}
                        </div>
                        <div
                          className="explan_txt"
                          dangerouslySetInnerHTML={{
                            __html: 
                              get_explanation()
                          }}
                        ></div>
                      </div>

                      <div className="col-12 my-3 d-flex align-items-center justify-content-between">
                        <div className="more_btn">
                          <img
                            src={externalLink}
                            className=" add_external_link"
                            alt="..."
                          />
                          <button className="inner_btn ps-1 border-0 shadow-none p-0 btn text-capitalize">
                            More Details
                          </button>
                        </div>
                        <div className="correct_div">
                          <span>Correct Variant: {handleCorrectVarient()}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 related_carouselDiv p-0 mt-3">
                    <div className="row">
                      <div className="col-12 related_txt">Related Content</div>
                    </div>
                    <div
                      ref={(ref) => (divRef.current = ref)}
                      className="row mx-auto mt-3 related_carousel flex-nowrap overflow-auto"
                    >
                      {explainData?.recommended?.map((item, ind) => {
                        return (
                          <div className="col-6 inner_carouselBox ">
                            <div className="row mx-auto">
                              <div className="col-12">
                                <div className="row border overflow-hidden mb-1">
                                  <div className="col-6 position-relative">
                                    <div className="row">
                                      <div className="col-12 p-0">
                                        <img
                                          src={item?.thumbnail}
                                          className=" secondLakhni"
                                          alt="..."
                                        />
                                      </div>
                                      <div className="col-12 p-0 ps-2 position-absolute bottom-0 mb-1">
                                        {item?.category?.category_name && (
                                          <button
                                            className="btn text-white videoDoc_btn text-capitalize shadow-none rounded-pill  d-flex align-items-center justify-content-center"
                                            style={{
                                              cursor: "pointer",
                                              backgroundColor: `${item?.category?.category_color}`,
                                            }}
                                          >
                                            {item?.category?.category_name}
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 py-2 d-flex ps-3 pe-2">
                                    <div className="row ">
                                      <div className="col-12 mb-auto  d-flex flex-column justify-content-center">
                                        <h6 className="card-data  m-0 d-flex align-items-start">
                                          {handleSliceContent(item?.title, 20)}
                                        </h6>
                                      </div>

                                      <div className="col-12 mt-auto">
                                        <p className="card-texts mb-0 text-nowrap">
                                          {item.studyPageTimeTrack
                                            ? formatTime(
                                                item.studyPageTimeTrack
                                              )
                                            : "0:00"}{" "}
                                          spent · Beginner
                                        </p>
                                        <div class=" mt-2 topic">
                                          <div class="progress progress_parent">
                                            <div
                                              class="progress-bar progress_bar"
                                              role="progressbar"
                                              aria-label="Basic example"
                                              aria-valuenow="25"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                              style={{
                                                width: `${
                                                  item?.progressBarPercentage
                                                    ? item?.progressBarPercentage
                                                    : "0%"
                                                }`,
                                              }}
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-12 mt-auto">
                                        <div
                                          className="row d-flex align-items-center justify-content-start"
                                          onClick={() => {
                                            handleClickRecomend(item, "close");
                                          }}
                                        >
                                          <div
                                            className="col-auto"
                                            style={{
                                              marginRight: "-5px",
                                            }}
                                          >
                                            <p
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              className="card-data m-0 d-flex align-items-center"
                                            >
                                              Learn
                                            </p>
                                          </div>
                                          <div
                                            className="col-auto p-0 d-flex align-items-center justify-content-center rounded-pill"
                                            style={{
                                              width: "16px",
                                              height: "16px",
                                              backgroundColor: "#4E45C2",
                                            }}
                                          >
                                            <img
                                              alt=""
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              className="w-100"
                                              src={learn_button}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* yes no popups */}
      <div className="quizmodel">
        <Modal
          centered
          className="quizmodel"
          show={quizstudyModel}
          onHide={() => {
            setQuizStudyModel(false);
          }}
        >
          <Modal.Header className="p-2 py-4" closeButton>
            <Modal.Body className="p-0 submit_last_question">
              Are you really want to submit the quiz?
            </Modal.Body>
          </Modal.Header>

          <Modal.Footer className="p-0 py-2">
            <Button
              variant="secondary"
              className="btn btn-light py-2 px-2 me-2 shadow-none offcan-study data_submit_pop"
              onClick={handleYesQuiz}
            >
              Yes
            </Button>
            <Button
              variant="primary"
              className="btn btn-light py-2 px-2 me-2 shadow-none offcan-study data_submit_pop"
              onClick={handleNoQuiz}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <NotesDialague
          show={notesAddDialougue}
          setShow={setShowAddNotesDialauge}
          NotesId={showNote}
          type={"add"}
          bodyType="quiz"
          setNotesLoader={noteLoader}
        />
        <NotesDialague
          show={showNotesDialauge}
          setShow={setShowNotesDialauge}
          NotesId={showNote}
          type={notesType}
          bodyType="quiz"
          setNotesLoader={noteLoader}
          editAction={clickEdit}
        />
      </div>
      <SidebarCanvas
        show={openSidebar}
        setShow={setOpenSidebar}
        sideLoader={sideLoader}
        data={sidebarData}
      />
    </>
  );
}
import * as React from "react";
import { useEffect, useState } from "react";
import "./Header.css";
import {
  SelectProfileDarkLogo,
  bell,
  notificationCross,
  newbell,
  bellNotiImg
} from "../../staticContent/image";
import { clearStore } from "../../store/actions/getUserData";
import { Link, NavLink } from "react-router-dom";
// import { Switch, Redirect, Route } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";

import { connect } from "react-redux";
import { compose } from "redux";
import { getRouteCategories } from "../../store/selectors";
// import ProtectedRoute from '../../components/accessControl/ProtectedRoute';
import { signOut } from "../../store/actions/auth.actions";
import { getUserProfile } from "../../store/actions/getUserProfile";
import storage from "redux-persist/lib/storage";
import { useLocation } from "react-router-dom";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import {
  getNotification,
  markAllAsRead,
  removeNotification,
} from "../../store/actions/practice";

const Header = (props) => {
  const moment = extendMoment(originalMoment);
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const data = store.getUserProfile?.data?.responseData;
  const [showNotification, setShowNotification] = useState(false);
  const [disablePractice,setDisabledPractice] = useState(false)
  //assigning location variable
  const location = useLocation();
  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  useEffect(() => {
    dispatch(getUserProfile(store.auth?.user?.id));
    dispatch(getNotification());
    if(pathname==="/practice/quiz"||pathname==="/practice"){
      setDisabledPractice(true)
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowNotification(false)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    dispatch(signOut());
    dispatch(clearStore());
    storage.removeItem("persist:root");
  };
  const handleNotification = (item) => {
    if (item?.notificationType === "Quiz") {
      return item?.notificationType;
    } else if (item?.notificationType === "Achievement") {
      return item?.achievementId?.achievementType;
    } else if (item?.notificationType === "Goal") {
      return item?.goalName;
    } else {
      return item?.notificationType;
    }
  };

  const handleMark = () => {
    setShowNotification(false);
    dispatch(markAllAsRead()).then((res) => {
      dispatch(getNotification());
    });
  };

  const handleShowNotification = () => {
    setShowNotification(!showNotification);
    setAnchorEl(false);
    dispatch(removeNotification()).then((res) => {
      dispatch(getNotification());
    });
  };

  return (
    <div className="main-div">
      <nav className="navbar  navbar-expand-lg shadow-none">
        <div className="container-fluid d-block">
          <div className="row mx-auto">
            <div className="col-auto d-flex align-items-center justify-content-start">
              <img
                src={SelectProfileDarkLogo}
                alt="head"
                className="responsive ms-2"
              />
            </div>
            <div className="col-auto ms-auto me-4">
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="fa fa-bars" aria-hidden="true"></i>
              </button>
              <div
                className="navbar-collapse cp1 collapse header-link"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mb-2 mb-lg-0 d-flex align-items-center">
                  <li>
                    <NavLink
                      to="/library/overview"
                      className={
                        splitLocation[1] === "library"
                          ? "nav-link text-white ms-4"
                          : "nav-link topbardata ms-4"
                      }
                    >
                      Library
                    </NavLink>
                  </li>
                  {/* <li className="nav-item ">
                    <NavLink
                      onClick={(event) => event.preventDefault()}
                      to="/study"
                      className={
                        splitLocation[1] === "study"
                          ? "nav-link text-white ms-4"
                          : "nav-link topbardata ms-4"
                      }
                    >
                      Study
                    </NavLink>
                  </li> */}

                  <li className="nav-item  wraps-header position-relative">
                    <NavLink
                      to="/practice/overview"
                      className={
                        splitLocation[1] === "practice"
                          ? "nav-link text-white ms-4"
                          : "nav-link topbardata ms-4"
                      }
                    >
                      Achievements
                    </NavLink>
                    {/* <div className="tools-header">Coming soon</div> */}
                  </li>

                  <li className="nav-item ">
                    <NavLink disabled= {true}
                      to="/organize"
                      className={
                        splitLocation[1] === "organize"
                          ? "nav-link text-white ms-4"
                          : "nav-link topbardata ms-4"
                      }
                    >
                      Organize
                    </NavLink>
                  </li>
                  <li className="nav-item  wraps-header position-relative">
                    <NavLink
                      to="#"
                      className={
                        splitLocation[1] === "practice"
                          ? "nav-link text-white ms-4"
                          : "nav-link topbardata ms-4"
                      }
                    >
                      <img
                        onClick={() => {
                          handleShowNotification();
                        }}
                        src={
                          store?.getNotification?.data?.responseData[0]?.data[0]
                            ?.isShow===false
                            ? newbell
                            :bell
                        }
                        alt={""}
                        className="task_calendar_img"
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </NavLink>
                    {/* <div className="tools-header">Coming soon</div> */}
                  </li>

                  {showNotification && (
                    <div class="  p-0 position-absolute notification  " style={{zIndex:"8999"}}>
                      <div class="row mx-auto position-absolute triangle_div_notification"></div>
                      <div class="row  bg-white notification_hei_wid flex-column mx-auto">
                        <div class="col-12  pop_topBox mt-3 mb-2">
                          <div className="row mx-auto d-flex align-items-center">
                            <div className="col px-0">
                              <h3 class="m-0 notification_heading ">
                                Notifications
                              </h3>
                            </div>
                            <div class="col px-0 align-items-center justify-content-end sm_progressBar d-flex">
                              <img
                                onClick={() => {
                                  setShowNotification(false);
                                }}
                                src={notificationCross}
                                alt={""}
                                className="notification_close"
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-12 border-bottom">
                          <div class="row notes_highligthDiv mx-auto ">
                            <div class="col-auto px-0 pb-2 text_work border_bottom_height_color">
                              <p class="m-0 p-0 cp notification_heading notification_font_weight notification_color all_pro_family ">
                                Overall
                              </p>
                            </div>
                            <div class="col pe-0 d-flex align-items-center justify-content-end text_work ps-4">
                              <p
                                class="m-0 p-0 cp notification_heading notification_font_weight all_pro_family black"
                                onClick={() => {
                                  handleMark();
                                }}
                              >
                                Mark all as read
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 notif_div">
                          <div className={store?.getNotification?.data?.responseData.length < 1 ? "row h-100 d-flex align-items-center" : ""}>
                            {store?.getNotification?.data?.responseData.length < 1 ? (
                              <div className="col-12">
                                <div className="row mx-auto text-center">
                                  <div className="col-12 mb-3">
                                    <img
                                      src={bellNotiImg}
                                      alt={""}
                                      className="bellNotImg"
                                    />
                                  </div>
                                  <div className="col-12">
                                    <p className="m-0 p-0 without_any_notification">
                                      We’ll let you know when we<br />
                                      get news for you.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <>
                                {store?.getNotification?.data?.responseData?.map(ele => (
                                  <div className="col-12" key={ele?.id}>
                                    <div className="row mx-auto">
                                      <div className="col-12 my-3 p-0">
                                        <h4 className="m-0 notification_heading light_week">
                                          {ele?.trackerDay === 1 ? "Today" : ele?.trackerDay === 2 ? "Yesterday" : moment(ele?.createDate).format("ll")}
                                        </h4>
                                      </div>
                                      {ele?.data?.map(itemm => (
                                        <div className="col-12 p-0" key={itemm?.id}>
                                          <div className="row mx-auto border-bottom pb-2 mb-2">
                                            <div className="col-2 px-0">
                                              <img
                                                src={itemm?.logo}
                                                alt={""}
                                                className=""
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </div>
                                            <div className="col-10 px-0">
                                              {/* Wrap the content in a Link based on notification type */}
                                              {itemm?.notificationType === "Quiz" ? (
                                                <Link to="/library">
                                                  <h3 className="m-0 notification_heading">
                                                    +{itemm?.achievementId?.count} score{" "}
                                                    <span className="m-0 notification_heading noti_dec_size">
                                                      earned
                                                    </span>
                                                  </h3>
                                                </Link>
                                              ) : itemm?.notificationType === "Achievement" ? (
                                                <Link to="/practice/overview">
                                                  <h3 className="m-0 notification_heading">
                                                    <span className="m-0 notification_heading noti_dec_size">
                                                      You have received an
                                                    </span>{" "}
                                                    achievement
                                                  </h3>
                                                </Link>
                                              ) : itemm?.notificationType === "Goal" ? (
                                                <Link to="/organize">
                                                  <h3 className="m-0 notification_heading">
                                                    Complete your practice goal for
                                                    today
                                                  </h3>
                                                </Link>
                                              ) : null}
                                              <p className="m-0 notification_heading light_week noti_dec_size">
                                                {handleNotification(itemm)} ·{" "}
                                                {moment(itemm?.createdAt).startOf(itemm?.createdAt).fromNow()}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="imagedata ms-4">
                    <Button
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      {data?.picture ? (
                        <>
                          <img
                            src={data.picture}
                            alt="head"
                            style={{
                              width: "3.5rem",
                              height: "3.5rem",
                              borderRadius: "50% ",
                            }}
                          />
                        </>
                      ) : (
                        <p className="m-0 text-white rounded-pill username p-3 d-flex align-items-center justify-content-center">
                          {data?.firstName?.slice(0, 1).toUpperCase()}
                        </p>
                      )}

                      {/* <img
                        src={SelectProfileLogo}
                        alt="head"
                        className="responsive ms-2"
                      /> */}
                    </Button>
                    <Menu
                      className={`heading-login`}
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <Link to="/profile">
                        <MenuItem onClick={handleClose}>Profile</MenuItem>
                      </Link>
                      <Link to="/settings">
                        <MenuItem onClick={handleClose}>Settings</MenuItem>
                      </Link>
                      <Link to="/organize">
                        <MenuItem>Organize</MenuItem>
                      </Link>
                      <Link to="/practice/overview"> <MenuItem onClick={handleClose}>Achievements</MenuItem></Link>
                      {/* <MenuItem onClick={handleClose}>Summary</MenuItem> */}
                      {/* <MenuItem onClick={handleClose}>Notifications</MenuItem> */}
                      <hr className="m-0" />
                      <MenuItem
                        onClick={() => {
                          logOut();
                        }}
                      >
                        Log out
                      </MenuItem>
                    </Menu>
                  </div>
                </ul>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

const mapStateToProps = (state) => ({
  routeCategories: getRouteCategories(state),
});

export default compose(connect(mapStateToProps, null))(Header);

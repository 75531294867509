import React, { useState, useEffect } from "react";
import "./Playlist.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector, useDispatch } from "react-redux";
import { playlistContent } from "../../../store/actions/libraryPlaylist";
import { useHistory } from "react-router-dom";
import { getPlaylist } from "../../../store/actions/libraryPlaylist";
import { learn_button } from "../../../staticContent/image";

const Playlist = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    // get playlist :get api implementation
    dispatch(getPlaylist(store.auth?.user?.id));
    let timer1 = setTimeout(() => setLoader(true), 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);
  const showPlaylistData = (item) => {
    setLoader(true);
    // get plalist content api implementation in playlist page
    dispatch(playlistContent(item.userId, item._id));
    history.push({
      pathname: `/library/playlist/${item._id}`,
      state: { playlistId: item?._id, userId: item?.userId },
    });
  };
  const formatTime = (milliseconds) => {
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor(milliseconds / 1000 / 60 / 60);

    return [
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
    ].join(":");
  };

  return (
    <>
      <div className="row mx-auto">
        <div className="col-12">
          <div className="row mx-auto">
            <div className="col mt-5 mb-4 d-flex">
              <h5 className="m-0 playlist-heading">Browse your playlist</h5>
            </div>
          </div>
          {loader ? (
            <div className="row mx-auto ">
              {store.playlist?.data?.map((item, index) => {
                return (
                  <div
                    className="col-lg-3 col-6 mb-3"
                    style={{ cursor: "pointer" }}
                    id={`test${index}`}
                    key={index}
                    onClick={() => showPlaylistData(item)}
                  >
                    {/* <div
                  className="card lakhaniSecond shadow-none"
                  style={{ postion: "relative" }}
                > */}
                    <div className="row mx-auto border overflow-hidden mb-1">
                      <div className="col-6 position-relative">
                        <div className="row">
                          <div className="col-12 p-0">
                            <img
                              src={item.thumbnail}
                              className=" secondLakhni"
                              alt="..."
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6  custom_padding d-flex">
                        <div className="row">
                          <div className="col-12 mb-auto  d-flex flex-column justify-content-center">
                            <h3 className="card-data  m-0 d-flex align-items-start">
                              {item.playList_Name.slice(0, 20)}
                            </h3>

                            {/* <p className="card-texts m-0">Yuval Noah Harari</p> */}
                          </div>

                          {/* <div className="col-12 mt-auto">
                            <p className="card-texts-min mb-0">
                              {item?.totalTime
                                ? formatTime(item?.totalTime)
                                : "0:00"}{" "}
                              spent · Beginner
                            </p>
                            <div className=" mt-2 topic">
                              <div class="progress progress_parent">
                                <div
                                  class="progress-bar progress_bar"
                                  role="progressbar"
                                  aria-label="Basic example"
                                  aria-valuenow="25"
                                  aria-valuemin="0"
                                  style={{
                                    width: "25%",
                                  }}
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </div>
                          </div> */}

                          <div className="col-12 mt-auto">
                            <div className="row d-flex align-items-center justify-content-start">
                              <div
                                className="col-auto"
                                style={{
                                  marginRight: "-5px",
                                }}
                              >
                                <p
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  className="card-data m-0 d-flex align-items-center"
                                  // onClick={() => {
                                  //   handleClickRecomend(
                                  //     item
                                  //   );
                                  // }}
                                >
                                  Learn
                                </p>
                              </div>
                              <div className="col-auto p-0 d-flex align-items-center  justify-content-center rounded-pill">
                                <img
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  alt=""
                                  className="learn_arrowIcon"
                                  src={learn_button}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  // </div>
                );
              })}
            </div>
          ) : (
            <div>
              <Skeleton />
              <Skeleton count={50} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Playlist;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Copy,
  copyFocus,
  rightflashIcon,
  leftflashIcon,
  Focus,
  SideNoteLeft,
} from "../../../staticContent/image";
import { useSelector } from "react-redux";
import "./flashcards.css";
import { flashCardReadStatus } from "../../../store/actions/study";
import { addFocusData } from "../../../store/actions/study";

function FlashCards({
  cards,
  setIsOpen,
  isOpen,
  handleSummary,
  setSideBar,
  bottomButton,
  getShowType,
  isPdf,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [side, setSide] = useState(false);
  const [flashCardsData, setFlashcarddata] = useState([]);
  const [current, setCurrent] = useState(0);
  const [isPdff, setIsPdff] = useState(isPdf);

  const numbering = current + 1;
  const sideToggle = (type) => {
    setIsOpen(type);
  };
  function handleClick() {
    setSide(!side);
  }
  function previousCard() {
    setCurrent(current - 1);
    setSide(false);
  }
  function nextCard() {
    setCurrent(current + 1);
    setSide(false);
    dispatch(flashCardReadStatus(true, flashCardsData[current + 1]?._id));
  }
  const handleLearn = () => {
    history.push("/study");
  };
  const handleFocusButton = () => {
    if (getShowType !== "video") {
      let data = {
        content: store?.getStudyTopic,
        getShowType: "doc",
        type: "flashcards",
      }
      dispatch(addFocusData(data));
      history.push({
        pathname: "/study/focusmode",
        state: {
          content: store?.getStudyTopic,
          getShowType: "doc",
          type: "flashcards",
        },
      });
    }
  };
  useEffect(() => {
    // handleSummary({mode:"flashcards",issummary:false,topic:"Table of Contest"})
    setFlashcarddata(store?.getFlashCards?.data?.flashCards);
  }, []);
  useEffect(() => {
    dispatch(
      flashCardReadStatus(true, store?.getFlashCards?.data?.flashCards[0]?._id)
    );
  }, []);
  return (
    <div>
      <div className="row  my-4 mx-auto">
        <div className="col-12 ">
          <div className="row">
            <div className="col-12 background_color_change_flashcard">
              <div
                className={`card  col-10   ${
                  side ? "side" : ""
                } mt-5 mx-auto border-0 questionExportPdvf `}
                onClick={handleClick}
              >
                {!isPdf && (
                  <>
                    {!side ? (
                      <div className="row ">
                        <div className="col-12  mx-auto">
                          <div className="row background_white_flashcard text-center py-3 shadow_add_flash_data mb-3">
                            <div className="col-12 ">
                              <p className="m-0 p-0 question_flashcard_num">
                                Question
                              </p>
                            </div>
                            <div className="col-12 p-0 m-0 ">
                              <h5
                                className={`${
                                  flashCardsData[current]?.question
                                    .length > 300
                                    ? "height_add_ques_ans_mention "
                                    : "d-flex align-items-center justify-content-center"
                                }
                            m-0 p-0 w-75 mx-auto question_flashcard align-items-center `}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    flashCardsData[current]?.question,
                                }}
                              ></h5>

                              {/* {isPdf && <h5
                            className={`${
                              flashCardsData[current]?.flashcardId?.answer.length > 300
                                ? "height_add_ques_ans_mention "
                                : "height_add_ques_ans_mention "
                            } m-0 p-0 w-75 mx-auto  question_flashcard back_text `}
                            dangerouslySetInnerHTML={{
                              __html: flashCardsData[current]?.flashcardId?.answer,
                            }}
                          ></h5>} */}
                            </div>
                            <div className="col-12 ">
                              <p className="p-0 m-0 question_flashcard_num">
                                {numbering.toString().length === 1
                                  ? "0" + numbering
                                  : numbering}
                                /{flashCardsData?.length}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="row ">
                        <div className="col-12 mx-auto">
                          <div className="row background_white_flashcard flex-nowrap text-center shadow_add_flash_data  py-3 mb-3">
                            <div className="col-12 ">
                              <p className="m-0 p-0 question_flashcard_num back_text">
                                Correct Answer
                              </p>
                            </div>
                            <div className="col-12  d-flex p-0 m-0">
                              <h5
                                className={`${
                                  flashCardsData[current]?.answer
                                    .length > 300
                                    ? "height_add_ques_ans_mention "
                                    : "d-flex align-items-center justify-content-center"
                                } m-0 p-0 w-75 mx-auto  question_flashcard back_text align-items-center`}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    flashCardsData[current]?.answer,
                                }}
                              ></h5>
                            </div>
                            <div className="col-12 ">
                              <p className="p-0 m-0 question_flashcard_num back_text">
                                {/* {current + 1} */}
                                {numbering.toString().length === 1
                                  ? "0" + numbering
                                  : numbering}
                                /{flashCardsData?.length}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {isPdf && (
                  <div className="pdfflashCards-content ">
                    {console.log(flashCardsData)}
                    {flashCardsData.map((ele, ind) => {
                      return (
                        <div>
                          <div className="row ">
                            <div className="col-12  mx-auto">
                              <div className="row background_white_flashcard text-center py-3 shadow_add_flash_data mb-3">
                                <div className="col-12 ">
                                  <p className="m-0 p-0 question_flashcard_num">
                                    Question
                                  </p>
                                </div>
                                <div className="col-12 p-0 m-0 ">
                                  <h5
                                    className={`${
                                      ele?.question.length > 300
                                        ? "height_add_ques_ans_mention "
                                        : "d-flex align-items-center justify-content-center"
                                    }
                            m-0 p-0 w-75 mx-auto question_flashcard align-items-center d-flex`}
                                    dangerouslySetInnerHTML={{
                                      __html: ele?.question,
                                    }}
                                  ></h5>

                                  {/* {isPdf && <h5
                            className={`${
                              flashCardsData[current]?.flashcardId?.answer.length > 300
                                ? "height_add_ques_ans_mention "
                                : "height_add_ques_ans_mention "
                            } m-0 p-0 w-75 mx-auto  question_flashcard back_text `}
                            dangerouslySetInnerHTML={{
                              __html: flashCardsData[current]?.flashcardId?.answer,
                            }}
                          ></h5>} */}
                                </div>
                                <div className="col-12 ">
                                  <p className="p-0 m-0 question_flashcard_num">
                                    {ind + 1}/{flashCardsData?.length}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-12  mx-auto">
                              <div className="row background_white_flashcard text-center py-3 shadow_add_flash_data mb-3">
                                <div className="col-12 ">
                                  <p className="m-0 p-0 question_flashcard_num">
                                    Correct Answer
                                  </p>
                                </div>
                                <div className="col-12 p-0 m-0 ">
                                  <h5
                                    className={`${
                                      ele?.answer.length > 300
                                        ? "height_add_ques_ans_mention "
                                        : "d-flex align-items-center justify-content-center"
                                    }
                            m-0 p-0 w-75 mx-auto question_flashcard align-items-center d-flex`}
                                    dangerouslySetInnerHTML={{
                                      __html: ele?.answer,
                                    }}
                                  ></h5>
                                </div>
                                <div className="col-12 ">
                                  <p className="p-0 m-0 question_flashcard_num">
                                    {ind + 1}/{flashCardsData?.length}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div class="col d-flex justify-content-center align-items-center mt-1">
                {/* <i
                  onClick={() => {
                    current > 0 && previousCard();
                  }}
                  class="fa fa-angle-left black_arrowIcon cp rounded-pill  me-1 bg-dark right_left_icon d-flex align-items-center justify-content-center text-white"
                  aria-hidden="true"
                ></i> */}
                <img
                  onClick={() => {
                    current > 0 && previousCard();
                  }}
                  src={rightflashIcon}
                  alt=""
                  className="me-1 flashRight"
                />
                {/* <i
                  onClick={() => {
                    current + 1 < flashCardsData?.length && nextCard();
                  }}
                  class="fa fa-angle-right cp black_arrowIcon rounded-pill bg-dark right_left_icon d-flex align-items-center justify-content-center text-white"
                  aria-hidden="true"
                ></i> */}
                <img
                  onClick={() => {
                    current + 1 < flashCardsData?.length && nextCard();
                  }}
                  src={leftflashIcon}
                  alt=""
                  className="ms-1 flashRight"
                />
              </div>
            </div>
          </div>
        </div>

        {bottomButton && (
          <div className="row d-flex align-items-center justify-content-end mt-4 pe-0">
            <div className="col-5 d-flex align-items-center  justify-content-end  study-science me-0 pe-0">
              <button
                type="button"
                className="btn shadow-none colorfull cp d-flex align-items-center   me-3"
                onClick={handleFocusButton}
              >
                Focus
                <img src={Focus} alt="" className="study-image1 ms-2 " />
              </button>

              <button
                onClick={handleLearn}
                type="button"
                className="btn shadow-none colorfulls text-white wraps-tablemode cp  border position-relative"
              >
                Learn
              </button>
              {isOpen === true && (
                <div
                  onClick={() => {
                    setSideBar(false);
                  }}
                  className="row position-absolute imageHideSidebar"
                >
                  <div className="col d-flex align-items-center justify-content-end ">
                    <img
                      src={SideNoteLeft}
                      alt=""
                      className="study-image-next"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {/* {isOpen === true && (
            <div
              onClick={() => {
              props.setSideBar(false)
              }}
              className="row position-absolute imageHideSidebar cp"
            >
              <div className="col d-flex align-items-center justify-content-end ">
                <img src={SideNoteLeft} alt="" className="study-image-next" />
              </div>
            </div>
          )} */}
        {isPdf && (
          <div className="pdfflashCards-content ">
            {flashCardsData.map((ele, ind) => {
              return (
                <div>
                  <div className="row ">
                    <div className="col-12  mx-auto">
                      <div className="row background_white_flashcard text-center py-3 shadow_add_flash_data mb-3">
                        <div className="col-12 ">
                          <p className="m-0 p-0 question_flashcard_num">
                            Question
                          </p>
                        </div>
                        <div className="col-12 p-0 m-0 ">
                          <h5
                            className={`${
                              ele?.question.length > 300
                                ? "height_add_ques_ans_mention "
                                : "d-flex align-items-center justify-content-center"
                            }
                            m-0 p-0 w-75 mx-auto question_flashcard align-items-center d-flex`}
                            dangerouslySetInnerHTML={{
                              __html: ele?.question,
                            }}
                          ></h5>

                          {/* {isPdf && <h5
                            className={`${
                              flashCardsData[current]?.flashcardId?.answer.length > 300
                                ? "height_add_ques_ans_mention "
                                : "height_add_ques_ans_mention "
                            } m-0 p-0 w-75 mx-auto  question_flashcard back_text `}
                            dangerouslySetInnerHTML={{
                              __html: flashCardsData[current]?.flashcardId?.answer,
                            }}
                          ></h5>} */}
                        </div>
                        <div className="col-12 ">
                          <p className="p-0 m-0 question_flashcard_num">
                            {ind + 1}/{flashCardsData?.length}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-12  mx-auto">
                      <div className="row background_white_flashcard text-center py-3 shadow_add_flash_data mb-3">
                        <div className="col-12 ">
                          <p className="m-0 p-0 question_flashcard_num">
                            Correct Answer
                          </p>
                        </div>
                        <div className="col-12 p-0 m-0 ">
                          <h5
                            className={`${
                              ele?.answer.length > 300
                                ? "height_add_ques_ans_mention "
                                : "d-flex align-items-center justify-content-center"
                            }
                            m-0 p-0 w-75 mx-auto question_flashcard align-items-center d-flex`}
                            dangerouslySetInnerHTML={{
                              __html: ele?.answer,
                            }}
                          ></h5>
                        </div>
                        <div className="col-12 ">
                          <p className="p-0 m-0 question_flashcard_num">
                            {ind + 1}/{flashCardsData?.length}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default FlashCards;

import {
  GET_PLAYLIST,
  CREATE_NEW_PLAYLIST,
  BROWSE_PLAYLIST_CONTENT,
  SEARCH_CONTENT_FOR_PLAYLIST,
  EDIT_PLAYLIST,
  DELETE_PLAYLIST,
  ADD_CONTENT_FOR_PLAYLIST,
} from "../actions/types";
const INITIAL_STATE = {};

// create playlist first screen
export const createNewPlaylist = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case CREATE_NEW_PLAYLIST:
      return payload;
    case EDIT_PLAYLIST:
      return { ...state, ...payload };

    default:
      return state;
  }
};

// browse playlist content
export const browsePlaylistContent = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case BROWSE_PLAYLIST_CONTENT:
      return payload;
    case EDIT_PLAYLIST:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const getPlaylist = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_PLAYLIST:
      return payload;
    case DELETE_PLAYLIST:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const addContentToPlaylist = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case SEARCH_CONTENT_FOR_PLAYLIST:
      return payload;
    case ADD_CONTENT_FOR_PLAYLIST:
      return null;
    default:
      return state;
  }
};



export const searchCourseFilter = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case "SEARCH_COURSE":
      return payload;
    case "SEARCH_COURSE_FILTER":
      return null;
    default:
      return state;
  }
}



// export const ItemsCount = (state = INITIAL_STATE, action) => {
//   if (action.type === "count-items") {
//     return action.payload
//   }
//   else {
//     return state
//   }
// }



import React, { useState, useEffect } from "react";
import {
  Frame21261,
  fiveLine,
  StudyGroup,
  Note,
  Info,
} from "../../../staticContent/image";
import Popover from "react-text-selection-popover";
import ClearIcon from "@mui/icons-material/Clear";
import { Bar } from "../studyui";
import { useSelector, useDispatch } from "react-redux";
import { addMarkers } from "../../../store/actions/study";
import {
  getTopics,
  getSubTopics,
  firstTimeDocLogin,
} from "../../../store/actions/study";
import { lineHeight } from "@mui/system";
import { Steps, Hints } from "intro.js-react";
import { getUserProfile } from "../../../store/actions/getUserProfile";
import Skeleton from "react-loading-skeleton";

import "intro.js/introjs.css";

import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
// Set the workerSrc to the pdfjs-dist CDN
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

// import { addMarkers } from "../../../store/actions/study";
function DocContent(props) {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [fontsize, setFontSize] = useState(16);
  const [lineheight, setLineHeight] = useState(33);
  const [selectTextMode, setSelectedTextMode] = useState("regular Mode");
  const [showmodefont, setShowModeFont] = useState(false);
  const [showSubTopic, setShowSubTopic] = useState();
  const [highLightedId, setHighlightedId] = useState();
  const refParagraph = React.createRef();
  const [wallkThrough, setWalkThrough] = useState(false);
  const [stepss, setStepss] = useState({
    stepsEnabled: true,
    initialStep: 0,
    steps: [
      {
        element: ".topicsstudyTooltip",
        intro:
          "Explore the key topics covered in this document. Click on a topic to jump directly to its section.",
        position: "right",
        tooltipClass: "firstTooltip",
      },
      {
        element: ".firstStudyTooltip",
        intro:
          "Adjust the font size and line spacing to suit your reading preferences for better readability.",
        position: "top",
        tooltipClass: "secondTooltip",
      },
      {
        element: ".firstStudyTooltip",
        intro: (
          <>
            <p className="m-0 p-0 mb-1">Choose from three reading modes:</p>

            <li className="m-0 p-0 ">Regular </li>
            <li className="m-0 p-0 ">Large</li>
            <li className="m-0 p-0 ">Dyslexic Friendly</li>
            <p className="m-0 p-0 mb-1">Each mode adjusts the font and spacing for easier reading.</p>

          </>
        ),
        skipClass: "skipdata",
        position: "right",
        tooltipClass: "thirdTooltip",
      },
      {
        element: ".sideNotesImageWalk",
        intro:
          "Add a note here to capture your thoughts as you study. You can search and organize your notes later.",
        position: "left",
        tooltipClass: "fourthTooltip",
      },
      // {
      //   element: ".dnd-handle",
      //   intro: "Write your note here. It will be saved and searchable, so you can find it anytime.",
      //   position:"top",

      // },
      {
        element: ".notesSideWAlk",
        intro:
          "Collapse the notes/transcripts panel to focus on the document or video in full screen.",
        position: "top",
        tooltipClass: "fifthTooltip",
      },

      // {
      //   element: ".offcanvas-body",
      //   intro: "Lorem ipsum dolor sit amet, consectetur adipiscing elitId nunc eget semper pharetra diam at pharetra, sit",
      //   position:"left"
      // },
    ],
  });
  const { stepsEnabled, steps, showBullets, initialStep, hintsEnabled, hints } =
    stepss;
  // useEffect(() => {
  //   setShowSubTopic(props.html);
  // }, [props]);
  const [tooltip, setTooltip] = useState(false);
  const handleClickFont = () => {
    setShowModeFont(!showmodefont);
  };
  const displayTextMode = (value) => {
    setSelectedTextMode(value);
    props.selectedPara(value);
    setShowModeFont(!showmodefont);
  };
  useEffect(() => {
    if (store?.getUserProfile?.data?.responseData?.firstTimeLoginDoc === true) {
      setWalkThrough(true);
    }
    setHighlightedId(store?.getStudyTopic?.title?._id);
  }, []);

  useEffect(() => {
    props.handleSummary({ mode: "study", issummary: false, topic: "Topics" });
    setShowSubTopic(props.html);
  }, [props]);

  // useEffect(() => {
  //   const fileId = store?.getStudyTopic?.response[0]?.fileid;
  //   const topic_id = store?.getStudyTopic?.response[0]._id;
  //   dispatch(getSubTopics(fileId, topic_id)).then((res) => {
  //     setShowSubTopic(res?.data?.response[0]?.topic_definition);
  //   });
  // }, []);
  const handleSelected = () => {
    const data = window.getSelection().toString();
    if (data) {
      props.selectedPara(data);
    } else {
      return null;
    }
  };

  function highlighter(str, ixs, color) {
    let prgp = str;
    ixs.forEach((item, index) => {
      let start = item.start;
      let end = item.end;
      let text = item.selectedText;
      let stIn, enIn;
      stIn = prgp.indexOf(text, start);
      enIn = stIn + text.length;
      prgp = `${prgp.slice(
        0,
        stIn
      )}<span style="background-color:${color}; border-radius:20px;">${text}</span>${prgp.slice(
        enIn,
        prgp.length
      )}`;
    });
    return prgp;
  }

  // let arr = [{start:303,end:129,text:"mmit resources to a business problem or challenge Privacy is no exception: without executive sponsorship, privacy would be little"},
  // {start: 0, end: 9, text: "Organizat"}]
  const handleHighlighterr = (arr, string) => {
    var strr = string;
    arr.forEach((item, ind) => {
      let start = item.start;
      let end = item?.end;
      let text = item?.text;
      let stIn, enIn;
      stIn = strr.indexOf(text, start);
      enIn = stIn + text.length;
      strr = `${strr.slice(
        0,
        stIn
      )}<span id ="notSelectedHighlight">${text}</span>${strr.slice(
        enIn,
        strr.length
      )}`;
    });
    return strr;
  };

  useEffect(() => {
    dispatch(getTopics(store?.getStudyTopic?.title?._id));
  }, []);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     var subtipic = store?.getStudyTopic?.highlight;
  //     console.log(subtipic, "djl;jk")
  //     var prg = props.html
  //     if (subtipic) {
  //       prg = prg.replace(/[\r\n]/gm, "");
  //       var ixs = subtipic;
  //       var clr = `rgba(106, 75, 255, 0.5)`;
  //       var htmlFull = highlighter(prg, ixs, clr);

  //       document.getElementById("textHilight").innerHTML = htmlFull;
  //     }
  //   }, 700);
  //   return () => clearTimeout(timer);

  // }, [store]);

  const getSelection = () => {
    if (window.getSelection) {
      var sel = window.getSelection();
      var range = sel.getRangeAt(0);
      let selectedText = sel.toString();

      if (
        sel.rangeCount &&
        range.startContainer.isSameNode(range.endContainer)
      ) {
        var selectionAnchorOffset = sel.anchorOffset;
        var selectionFocusffset = sel.focusOffset;
        if (selectionAnchorOffset > selectionFocusffset) {
          selectionAnchorOffset = sel.focusOffset;
          selectionFocusffset = sel.anchorOffset;
        }
        let parentNodeSelection = sel.anchorNode.parentNode;
        let childNodesArr = parentNodeSelection.childNodes;

        // console.log(document.getElementById("highlight").innerHTML,"ygyug")
        // let data = {
        //   topic_id: highLightedId,

        //   selectedText:[selectedText]
        // }
        // dispatch(addMarkers({topic:"vishall"}))

        // dispatch(addMarkers())
        for (let i = 0; i < childNodesArr.length; i++) {
          if (childNodesArr[i].nodeType === 3) {
            if (childNodesArr[i].isEqualNode(sel.anchorNode)) {
              let contentNodeText = childNodesArr[i].textContent;
              let startTextNode = document.createTextNode(
                contentNodeText.slice(0, selectionAnchorOffset)
              );
              let endTextNode = document.createTextNode(
                contentNodeText.slice(
                  selectionFocusffset,
                  contentNodeText.length
                )
              );
              let highlightSpan = document.createElement("span");
              highlightSpan.innerHTML = sel.toString();
              highlightSpan.setAttribute("id", "notSelectedHighlight");
              childNodesArr[i].after(endTextNode);
              childNodesArr[i].after(highlightSpan);
              childNodesArr[i].after(startTextNode);
              childNodesArr[i].remove();

              dispatch(
                addMarkers({
                  file_id: highLightedId,
                  htmlText: document.getElementById("textHilight").innerHTML,
                  selectedText: [selectedText],
                })
              );
            }
          }
        }
      }
    }
  };

  // useSelector((state) => console.log(state));
  const pdfurl = useSelector((state) => state.addContentData?.data?.responseData?.[0]?.s3Key);
  
  
  // const pdfurl = 'https://ocw.mit.edu/ans7870/9/9.00SC/MIT9_00SCF11_text.pdf'
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    
  }

  function onDocumentLoadError(error) {
    console.error('Error loading document:', error);
  }

  // const [numPages, setNumPages] = useState<number>();
  // const [pageNumber, setPageNumber] = useState<number>(1);

  // function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
  //   setNumPages(numPages);
  // }

  return (
    <>
      {wallkThrough && (
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          onExit={() => {
            dispatch(firstTimeDocLogin());
            setWalkThrough(false);
            dispatch(getUserProfile(store.auth?.user?.id));
            
          }}
          initialStep={initialStep}
          onComplete={() => {
            dispatch(firstTimeDocLogin());
            setWalkThrough(false);
            dispatch(getUserProfile(store.auth?.user?.id));
          }}
          onChange={(e) => {
            if (e == 2) {
              // props.openNotes(true)
            }
          }}
          options={{
            showButtons: true,
            showBullets: false,
            prevLabel: "Previous",
            skipLabel: "Skip",
            // tooltipClass: "tooltip",
          }}
        />
      )}
      <div className="col-12 col-md-12 ">
        <div className="row mx-auto  border regular main_msgBo main_msgBoxNotShow text-container overflow-hidden">
          <div className="col-12 bg-light table_header position-relative border-bottom  px-0 firstStudyTooltip">
            <div className="row inner-box mx-auto  ">
              <div className="col-auto d-flex align-items-center justify-content-start py-2">
                <h6 className="m-0 me-2 number_font_dec">{fontsize}</h6>
                <span className="col-auto d-flex flex-column">
                  <i
                    style={{ cursor: "pointer" }}
                    className="fa fa-angle-up studyicon"
                    onClick={() => {
                      setFontSize(fontsize + 1);
                    }}
                  ></i>
                  <i
                    style={{ cursor: "pointer" }}
                    className="fa fa-angle-down studyicon"
                    onClick={() => {
                      if (fontsize > 1) {
                        setFontSize(fontsize - 1);
                      }
                    }}
                  ></i>
                </span>
              </div>
              <div className="col-auto d-flex align-items-center justify-content-start py-2 ">
                <img src={Frame21261} alt="" />
                <h6 className="m-0 mx-2 number_font_dec">{lineheight}</h6>
                <span className="col-auto d-flex flex-column">
                  <i
                    className="fa fa-angle-up studyicon"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setLineHeight(lineheight + 1);
                    }}
                  ></i>
                  <i
                    className="fa fa-angle-down studyicon"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (lineheight > 1) {
                        setLineHeight(lineheight - 1);
                      }
                    }}
                  ></i>
                </span>
              </div>
              <div
                className="col  wrap p-0 d-flex align-items-center justify-content-start border-start "
                style={{ height: "48px" }}
              >
                <div
                  className=" border-end"
                  style={{ padding: "13px 20px 13px 20px" }}
                >
                  <img
                    src={fiveLine}
                    alt=""
                    onMouseOver={() => {
                      setTooltip(true);
                    }}
                    onMouseOut={() => {
                      setTooltip(false);
                    }}
                    className="fourImageStudy"
                  />
                </div>

                {tooltip && (
                  <div className="tipss">Text to Speech Coming Soon!</div>
                )}
              </div>

              <div className="col-auto ms-auto d-flex align-items-center ">
                <div className="row">
                  <div className="col-auto topic-text d-flex justify-content-center align-items-center ">
                    <p className="m-0 me-0 me-1 studyDispalyOptions">
                      Display Options:
                    </p>
                    <div className="col position-relative d-flex align-items-center justify-content-end  p-0 border-0 shadow-none">
                      <p className="studyAllMode border-0 shadow-none m-0 p-0">
                        {selectTextMode}
                      </p>
                      <i
                        onClick={handleClickFont}
                        className="fa fa-angle-down ms-2 d-flex align-items-center justify-content-center"
                        aria-hidden="true"
                        style={{ cursor: "pointer" }}
                      ></i>
                      {showmodefont && (
                        <div className="col justify-content-end d-flex ">
                          <div
                            className="row  studyAllModeData  position-absolute  overflow-hidden"
                            style={{ marginTop: "7px" }}
                          >
                            <div
                              className="col-12 studyAllMode sideBarNotesMenuWeeklyData"
                              onClick={() => {
                                displayTextMode("regular Mode");
                                setFontSize(16);
                                setLineHeight(33);
                              }}
                            >
                              <p className="m-0 my-2 p-0 RegularLargeDyslexice">
                                Regular Mode
                              </p>
                            </div>
                            <div
                              className="col-12 sideBarNotesMenuWeeklyData "
                              onClick={() => {
                                displayTextMode("large Mode");
                                setFontSize(20);
                                setLineHeight(43);
                              }}
                            >
                              <p className="m-0 my-2 p-0 RegularLargeDyslexice">
                                Large Mode
                              </p>
                            </div>
                            <div
                              className="col-12 sideBarNotesMenuWeeklyData"
                              onClick={() => {
                                displayTextMode("dyslexic Mode");
                              }}
                            >
                              <p className="m-0 my-2 p-0 RegularLargeDyslexice">
                                Dyslexic Mode
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!props?.docLoader ? (
            <div className="row py-2 chid_msgBox mx-auto">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <div>
                      <div
                        style={{ height: "445px" }}
                        className={"studyScroll"}
                        ref={refParagraph}
                        suppressContentEditableWarning
                        // onMouseUp={props.selectedPara()}
                        onMouseUp={handleSelected}
                      >
                        
                        {pdfurl && (<Document 
                          file={store?.getStudyTopic?.title?.s3Key}
                          onLoadSuccess={onDocumentLoadSuccess}
                          onLoadError={onDocumentLoadError}
                          id="textHilight"
                          className={`${selectTextMode} mt-2`}>
                          {Array.from(new Array(numPages), (el, index) => (
                            <Page 
                              key={`page_${index + 1}`} 
                              pageNumber={index + 1}
                              renderTextLayer={false} 
                            />
                          ))}
                        </Document>)}
                        <p>
                          Page {pageNumber} of {numPages}
                        </p>
                        {/* {props?.html && (
                          <p
                            style={{
                              fontSize: `${fontsize}px`,
                              lineHeight: `${lineheight}px`,
                            }}
                            id="textHilight"
                            className={`${selectTextMode} mt-2`}
                            dangerouslySetInnerHTML={{
                              __html: showSubTopic,
                            }}
                          />
                        )} */}
                      </div>
                      <Popover selectionRef={refParagraph}>
                        <div>
                          <Bar bg="black" style={{ borderRadius: "7px" }}>
                            <div className="d-flex">
                              <div className="text-light d-flex ">
                                <div
                                  className="mx-2 py-2 ps-2"
                                  onClick={() => {
                                    getSelection();
                                  }}
                                >
                                  <img
                                    src={StudyGroup}
                                    alt={""}
                                    className="studyInfo"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                                <div className="mx-3 py-2 ps-2">
                                  <img
                                    src={Note}
                                    onClick={() => props.openNotes(true)}
                                    alt={""}
                                    className="studyInfo"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                                <div
                                  className="me-3 py-2 ps-2 "
                                  onClick={() => props.infoTooltip()}
                                >
                                  <img
                                    src={Info}
                                    alt={""}
                                    className="studyInfo"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </div>
                              <div>
                                <div
                                  className="mx-2 py-2 pe-2 ps-3"
                                  style={{
                                    borderLeft: "3px solid white",
                                    color: "white",
                                    fontWeight: "500",
                                  }}
                                >
                                  <ClearIcon
                                    onClick={() => {
                                      if (window.getSelection) {
                                        window.getSelection().removeAllRanges();
                                      } else if (document.selection) {
                                        document.selection.empty();
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </Bar>
                        </div>
                      </Popover>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          ) : (
            <Skeleton height={`2vh`} count={18} />
          )}
        </div>
      </div>
    </>
  );
}
export default DocContent;

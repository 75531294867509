import React, { useState, useEffect } from "react";
import "./AddSyllabus.css";
import {
  SelectProfileX32,
  SelectProfileX33,
  SelectProfileDarkLogo,
  Done,
  Link,
  CloudUploadImg,
  LeftArrow,
} from "../../staticContent/image";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserData,
  addDocument,
  getPreviousPageData,
} from "../../store/actions/getUserData";

import {
  getCourseList,
  skipPage,
  goBackPage,
} from "../../store/actions/getUserData";
import { Redirect } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import validator from "validator";
// Component start here
const AddSyllabus = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [courseItems, setcourseItem] = useState(store?.onboardcourse2);
  const [show, setShow] = useState(false);
  const [isDoc, setIsDoc] = useState(true);
  const [loader, setloader] = useState(false);
  //Get User Selected profile and school data
  const getAddSyllabusDetails = async () => {
    const courseData = await dispatch(getPreviousPageData(3));
    setcourseItem(
      courseData?.data.response.map((itemz, indexz) => {
        return {
          ...itemz,
          value: itemz?.link_ids ? itemz?.link_ids[0]?.link_url : undefined,
          validUrl: true,
        };
      })
    );
  };
  useEffect(() => {
    getAddSyllabusDetails();
  }, []);

  useEffect(() => {
    let timer1 = setTimeout(() => setShow(true), 800);

    return () => {
      clearTimeout(timer1);
    };
  }, []);

  // Redirect To Next Page
  if (store.profileStatus === "4") {
    return <Redirect to={`/select-profile/${store.profileStatus}`} />;
  } else if (store.profileStatus === "7") {
    return <Redirect to={`/library`} />;
  }
  // file upload funtion
  const updateProfilePic = (element) => {
    return new Promise(function (resolve, reject) {
      let file = element.target.files[0];
      let reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  };
  // save add syllabus data (on Next Button)
  const sendData = () => {
    // setloader(true)
if(courseItems[0]?.value!==undefined){
    dispatch(
      getUserData({
        body: {
          data: courseItems.map((itemx, indexz) => ({
            link_url: itemx.value,
            id: itemx._id,
          })),
        },
        onBoardingStatus: "3",
        setCode: "4",
      })
    )};

    const body = new FormData();

    courseItems.forEach((item) => {
      if (item.fileValue) {
        body.append("id", item._id);
        body.append("file", item.fileValue);
      }
    });

    dispatch(addDocument({ body, onBoardingStatus: "3",setCode:"4"}));

    setTimeout(() => {
      setloader(true);
    }, 500);
  };
  //Back Button
  const goBack = () => {
    dispatch(goBackPage(store.profileStatus - 1));
  };
  // This function used for input Selection Focus
  const selectFocus = (item) => {
    setcourseItem(
      courseItems.map((itemx, index) =>
        itemx?._id === item?._id
          ? { ...itemx, focus: true }
          : { ...itemx, focus: false }
      )
    );
  };

  return (
    <>
      <div className="container-fluid d-flex flex-column">
        <div className="row mx-auto pt-5">
          <div className="col-12 amet  align-items-center">
            <div className="row">
              <div className="col-11 d-flex justify-content-between align-items-center mx-auto">
                <img
                  className="onboard_img ps-5"
                  src={SelectProfileDarkLogo}
                  alt=""
                />
                <div className="progressSign">
                  <div className="completed-progressbar4"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="row  margin_top">
              <div className="col-4 d-flex justify-content-start">
                <div className="signIn_img">
                  <img src={SelectProfileX33} alt="" />
                </div>
              </div>
              <div className="col-lg-4  py-2">
                <form className=" all">
                  <div className="col-1 mb-4">
                    <div
                      className="d-flex align-items-center back-data"
                      onClick={() => {
                        goBack();
                      }}
                      style={{ display: "inline-block", cursor: "pointer" }}
                    >
                      {/* <i className="fa fa-arrow-left" aria-hidden="true"></i> */}
                      <img src={LeftArrow} alt="" style={{ width: "1rem" }} />
                      <p className="m-0 ms-2">Back</p>
                    </div>
                  </div>
                  <div className="col-12 sign  mt-2">
                    <h1 className="heading_onboard">Add syllabus</h1>
                    <p className="pragraph_onboard">
                      Add the courses you are enrolled in during the semester.
                    </p>
                  </div>
                  <div className="col-12 ">
                    <div className="row border enter  mx-auto overflow-hidden pb-3">
                      <div
                        className="col-12 ps-4 py-2 border-bottom uploaddata"
                        style={{ background: "", border: "2rem" }}
                      >
                        <div className="row">
                          <div className="col-1 d-flex fundaa info justify-content-start align-items-center">
                            <h5 className="m-0  ">#</h5>
                          </div>
                          <div className="col-5 d-flex fundaa info justify-content-start align-items-center">
                            <h5 className="m-0  ">Course</h5>
                          </div>
                          <div className="col-3 d-flex fundaa info justify-content-start align-items-center">
                            <h5 className="m-0  ">Add Link</h5>
                          </div>
                          <div className="col-3 d-flex fundaa info justify-content-center align-items-center">
                            <h5 className="m-0  ms-4">Upload</h5>
                          </div>
                        </div>
                      </div>
                      {courseItems?.map((item, index) => {
                        return (
                          <div className="col-12 " key={index}>
                            {show ? (
                              <div className="row  mt-3 align-items-center">
                                <div className="col-1 ps-4 d-flex justify-content-start align-items-center">
                                  <p className="m-0  sinumber">{index + 1}.</p>
                                </div>
                                <div className="col-5 fundaa ms-2">
                                  <h5 className="m-0 ">
                                    {item?.course_Name?.length > 20
                                      ? item.course_Name.slice(0, 25) + "..."
                                      : item.course_Name}
                                  </h5>
                                </div>
                                <div
                                  className={
                                    item?.focus
                                      ? "align-items-center border col-3 d-flex justify-content-start m-0 px-3 isFocus"
                                      : "align-items-center border col-3 d-flex justify-content-start m-0 px-3"
                                  }
                                >
                                  <img
                                    src={Link}
                                    className="link-logo"
                                    alt=""
                                  />

                                  <input
                                    onFocus={() => {
                                      selectFocus(item);
                                      // setIsDoc(false);
                                    }}
                                    id={item._id}
                                    onChange={(e) => {
                                      const isUrl = validator.isURL(
                                        e.target.value?.trim()
                                      );

                                      setcourseItem(
                                        courseItems.map((itemz, indexz) =>
                                          item._id === itemz._id
                                            ? {
                                                ...itemz,
                                                value: e.target.value,
                                                validUrl: true,
                                              }
                                            : itemz
                                        )
                                      );
                                      setIsDoc(false);
                                    }}
                                    type="text"
                                    className="form-control mb-0 py-2 addLinkInput text-truncate add_syllabus_input"
                                    placeholder="Enter an URL"
                                    value={item?.value}
                                  />
                                </div>
                                {!item.validUrl && item.value === "" ? (
                                  <></>
                                ) : null}
                                <div className="col-2 ms-4 d-flex justify-content-center">
                                  <div className="image-upload ps-4">
                                    <label htmlFor={item._id + "file"}>
                                      {item.fileValue ? (
                                        <img src={Done} alt="img" />
                                      ) : (
                                        <img
                                          className="cloudupload"
                                          src={CloudUploadImg}
                                          alt=""
                                        />
                                        // <i
                                        //   className="fa fa-cloud-upload text-dark"
                                        //   aria-hidden="true"
                                        // ></i>
                                      )}
                                    </label>
                                    <input
                                      id={item._id + "file"}
                                      type="file"
                                      accept="application/pdf,application/vnd.ms-excel"
                                      onChange={(e) => {
                                        updateProfilePic(e).then((rest) => {
                                          setcourseItem(
                                            courseItems.map((itemzz, indexzz) =>
                                              item._id === itemzz._id
                                                ? {
                                                    ...itemzz,
                                                    fileValue:
                                                      e.target.files[0],
                                                    image: rest,
                                                  }
                                                : itemzz
                                            )
                                          );
                                        });
                                        setIsDoc(false);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <Skeleton />
                                <Skeleton count={15} />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {!loader ? (
                    <div className=" col-12 clearfix border-0 syllabus-top m-0 p-0">
                      <button
                        disabled={isDoc}
                        type="button"
                        className="customebtn d-flex align-items-center justify-content-center mt-4"
                        onClick={() => sendData()}
                      >
                        {/* <i className="fa fa-spinner fa-spin me-3" aria-hidden="true" style={{ fontSize: "20px" }}></i> */}
                        Next Step
                      </button>
                    </div>
                  ) : (
                    <div className=" col-12 clearfix border-0">
                      <button className="customebtn mt-4">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </button>
                    </div>
                  )}
                  <div className="col-12 d-flex align-items-center justify-content-center mt-3">
                    <p
                      onClick={() => {
                        dispatch(skipPage());
                      }}
                      className="text-decoration-none plus"
                      style={{ cursor: "pointer" }}
                    >
                      Skip Step
                    </p>
                  </div>
                </form>
              </div>
              <div className="col-4 d-flex justify-content-end">
                <div className="signIn_img">
                  <img src={SelectProfileX32} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSyllabus;

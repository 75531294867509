import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import Overview from "./practiceSidebar/overview";
import Achievement from "./practiceSidebar/achievement";

function PracticeLayout() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const startTime = useRef();
  const endTime = useRef();

  const [selectedTab, setSelectedTab] = useState({
    selectedTab: "Acheivements",
    selectedUrl: "/practice/overview/achievements",
  });

  const sampleData = [
    {
      name: "Achievements",
      link: "/practice/overview/achievements",
    },
    {
      name: "Progress",
      link: "/practice/overview",
    },
    
  ];

  const handleClick = (tab, url) => {
    setSelectedTab({
      selectedTab: tab,
      selectedUrl: url,
    });
  };
  const dashboardPanel = () => {
    let path = history.location.pathname;
    switch (path) {
      case "/practice/overview":
        return <Achievement />;
      case "/practice/overview/achievements":
        return <Achievement />;
      default:
        return <Achievement />;
    }
  };
  return (
    <>
      <div className="Library-page">
        {/* <div className="row  general-data">
          <div
            className="col-md-2 general-data position-fixed top-0 bottom-0 border-end px-0"
            style={{ height: "100vh" }}
          >
            <div
              className="sidebar d-flex flex-column mx-auto"
              style={{ height: "inherit" }}
            >
              <div className="col-lg-12 mt-5 ps-0 ps-2 general mb-4">
                <h5 className="sidebar_text sidebar_text_gen m-0">PROGRESS & ACHIEVEMENTS</h5>
              </div>
              {sampleData?.map((item, i) => {
                return (
                  <div className="head-div col-12 p-0 " key={i}>
                    <NavLink
                      to={item.link}
                      key={i}
                      id={`test${i}`}
                      activeclassname="text-dark text-decoration-none overview"
                    >
                      <div
                        key={i}
                        className={`  cursor-pointer p-2 ${
                          selectedTab.selectedTab === item.name
                            ? "active selected"
                            : ""
                        }`}
                        // library or course click: route to library or course page
                        onClick={() => {
                          handleClick(item.name, item.link);
                        }}
                      >
                        <h5
                          className={
                            selectedTab.selectedTab === item.name
                              ? "libselect mb-0 sidebar_text"
                              : " sidebar_text mb-0"
                          }
                        >
                          {item.name}
                        </h5>
                      </div>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className="col-10 ms-auto ps-0 all-mainly pb-4"
            // style={{ height: "100vh" }}
          >
            {dashboardPanel()}
          </div>
        </div> */}
        <Achievement/>
      </div>
    </>
  );
}

export default PracticeLayout;

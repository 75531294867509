import { RelationManyToMany } from "mdi-material-ui";
import mernApi from "../apis/mern";
import {
  GET_TASK_TYPE,
  ADD_TASK,
  GET_TASK_DETAILS,
  ADD_TIME,
  GET_CONTENT_FROM_LIBRARY,
  ADD_CONTENT_LIBRARY,
  GET_ALL_TASK_LIST,
  Add_Goal,
  GET_ACTIVITY_DATA,
  TRACK_TASK_TIME,
  DELETE_TASK_API,
  GET_TRACKER_DETAILS,
  GET_TASK_SUGGESTION,
  GET_GOAL_CATEGORY,Get_GOAL_DATA
} from "./types";

export const getTaskType = () => (dispatch) => {
  return mernApi.get(`/api/task/getTaskType`).then((data) => {
    try {
      if (data.status === 200) {
        dispatch({
          type: GET_TASK_TYPE,
          payload: data,
        });
      }
    } catch (error) {
      console.log(error);
    }
    return data;
  });
};

export const addTask = (body) => (dispatch) => {
  return mernApi.post(`/api/task/addTask`, body).then((data) => {
    try {
      if (data.status === 200) {
        dispatch({
          type: ADD_TASK,
          payload: data,
        });
      }
    } catch (error) {
      console.log(error);
    }
    return data;
  });
};

export const getTaskDetails = (body) => (dispatch) => {
  return mernApi.post(`api/task/getTaskDetails`,body).then((data) => {
    try {
      if (data.status === 200) {
        dispatch({
          type: GET_TASK_DETAILS,
          payload: data,
        });
      }
    } catch (error) {
      console.log(error);
    }
    return data;
  });
};

export const addTime = (body) => (dispatch) => {
  return mernApi.put(`api/task/addTime`, body).then((data) => {
    try {
      if (data.status === 200) {
        dispatch({
          type: ADD_TIME,
          payload: data,
        });
      }
    } catch (error) {
      console.log(data);
    }
    return data;
  });
};
export const UpdateTrackTime = (body) => (dispatch) => {
  return mernApi.put(`/api/task/addTaskTimeTrack`, body).then((data) => {
    try {
      if (data.status === 200) {
        dispatch({
          type: "UpdateTrackTime",
          payload: data,
        });
      }
    } catch (error) {
      console.log(data);
    }
    return data;
  });
};
export const getContentFromLibrary = () => (dispatch) => {
  return mernApi
    .get(`/api/task/getallLibraryContent`)
    .then((data) => {
      try {
        if (data.status === 200) {
          dispatch({
            type: GET_CONTENT_FROM_LIBRARY,
            payload: data,
          });
        }
      } catch (error) {
        console.log(error);
      }
      return data;
    });
};
export const addContentLibrary = (id, taskid) => (dispatch) => {
  return mernApi
    .post(`/api/task/addMaterialLibrary?fileId=${id}&taskId=${taskid}`)
    .then((data) => {
      try {
        if (data.status === 200) {
          dispatch({
            type: ADD_CONTENT_LIBRARY,
            payload: data,
          });
        }
      } catch (error) {
        console.log(error);
      }
      return data;
    });
};

export const addContentTasks = (data) => (dispatch) => {
  return mernApi.post(`api/library/libraryuploadS3`, data).then((data) => {
    return data;
  });
};

export const getAllTaskList = (type, num) => (dispatch) => {
  return mernApi
    .get(`/api/task/listAllTaskTopics?goalCategory=${type}&skip=${num}`)
    .then((data) => {
      try {
        if (data.status === 200) {
          dispatch({
            type: GET_ALL_TASK_LIST,
            payload: data,
          });
        }
      } catch (error) {
        console.log(error);
      }
      return data;
    });
};
export const addGoal = (body) => (dispatch) => {
  try {
    return mernApi.post(`/api/task/addGoal`, body).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: Add_Goal,
          payload: data,
        });
      }

      return data;
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAllActivityDetails = (format) => (dispatch) => {
  try {
    
    return mernApi
      .get(`api/task/getGraphData?graphData=${format}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: GET_ACTIVITY_DATA,
            payload: data,
          });
        }

        return data;
      });
  } catch (error) {
    console.log(error);
  }
};
export const getGoalData = (skip) => (dispatch) => {
  try {
    
    return mernApi
      .get(`api/task/getGoalData?skip=${skip}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: Get_GOAL_DATA,
            payload: data,
          });
        }

        return data;
      });
  } catch (error) {
    console.log(error);
  }
};
export const getTaskData = (skip) => (dispatch) => {
  try {
    
    return mernApi
      .get(`api/task/getTaskData?skip${skip}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: Get_GOAL_DATA,
            payload: data,
          });
        }

        return data;
      });
  } catch (error) {
    console.log(error);
  }
};

export const trackTaskTime = (body) => (dispatch) => {
  try {
    return mernApi.put(`api/task/addTaskTimeTrack`, body).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: TRACK_TASK_TIME,
          payload: data,
        });
      }
      return data;
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteTask = (id) => (dispatch) => {
  try {
    return mernApi.delete(`/api/task/deleteTask?task_id=${id}`).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: DELETE_TASK_API,
          payload: data,
        });
      }
      return data;
    });
  } catch (error) {
    console.log(error);
  }
};

export const getTrackerDetails = () => (dispatch) => {
  try {
    return mernApi.get(`/api/task/getTracker`).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: GET_TRACKER_DETAILS,
          payload: data,
        });
      }
      return data;
    });
  } catch (error) {}
};

export const getTaskSuggestion = (word) => (dispatch) => {
  try {
    return mernApi
      .get(`/api/task/tags_Topics_suggestion?tags_topics=${word}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: GET_TASK_SUGGESTION,
            payload: data,
          });
        }
        return data;
      });
  } catch (error) {}
};
export const getCourseSuggestion = (word) => (dispatch) => {
  try {
    return mernApi
      .get(`/api/task/courseSuggestion?course=${word}&skip=0`)
      .then((data) => {
        if (data.status === 200) {
          // dispatch({
          //   type: GET_TASK_SUGGESTION,
          //   payload: data,
          // });
        }
        return data;
      });
  } catch (error) {}
};

export const clearTaskSuggestion = () => (dispatch) => {
  dispatch({
    type: "CLEAR_TASK_SUGGESTION",
    payload: null,
  });
};

export const goalStatus = (id, status) => (dispatch) => {
  mernApi
    .put(`api/task/goalStatus?goalId=${id}&goalStatus=${status}`)
    .then((data) => {
      if (data.status === 200) {
        dispatch({
          type: "GOALSTATUS",
          payload: data,
        });
      }
      return data;
    });
};
export const taskStatus = (id, status) => (dispatch) => {
  mernApi
    .put(
      `https://seekh.xyz/api/task/taskStatus?taskId=${id}&taskStatus=${status}`
    )
    .then((data) => {
      if (data.status === 200) {
        dispatch({
          type: "GOALSTATUS",
          payload: data,
        });
      }
      return data;
    });
};

export const taskModuleTrackerReward = (body) => (dispatch) => {
  mernApi.post(`api/task/taskModuleTrackerReward`, body).then((data) => {
    if (data.status === 200) {
      dispatch({
        type: "TRACKERTIME",
        payload: data,
      });
    }
    return data;
  });
};


export const getGoalCategory = () => (dispatch) => {
  try {
    return mernApi
      .get(`api/task/getGoalCategory`)
      .then((data) => {
        if (data.status === 200) {
          // dispatch({
          //   type: GET_TASK_SUGGESTION,
          //   payload: data,
          // });
        }
        return data;
      });
  } catch (error) {}
};
export const getGoalTime = () => (dispatch) => {
  try {
    return mernApi
      .get(`api/task/getGoalTime`)
      .then((data) => {
        if (data.status === 200) {
         
        }
        return data;
      });
  } catch (error) {}
};

export const updateDescription = (body)=>(dispatch)=>{
  return mernApi
  .put(`api/task/editDescription`,body)
}

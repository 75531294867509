import {
  GET_TASK_TYPE,
  ADD_TASK,
  GET_TASK_DETAILS,
  ADD_TIME,
  GET_CONTENT_FROM_LIBRARY,
  ADD_CONTENT_LIBRARY,
  GET_ALL_TASK_LIST,
  Add_Goal,
  GET_ACTIVITY_DATA,
  TRACK_TASK_TIME,
  GET_TRACKER_DETAILS,
  GET_TASK_SUGGESTION,GET_PRACTICE_HOUR_SPENT, Get_GOAL_DATA
} from "../actions/types";

export const INITIAL_STATE = [];

export const getTaskType = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_TASK_TYPE:
      return payload;
    default:
      return state;
  }
};

export const addTask = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case ADD_TASK:
      return payload;
    default:
      return state;
  }
};

export const getTaskDetails = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_TASK_DETAILS:
      return payload;
    default:
      return state;
  }
};
export const addTime = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case ADD_TIME:
      return payload;
      case "STUDY_PAGE_TIMETRACK":
        return payload;
    default:
      return state;
  }
};
export const getContentFromLibrary = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_CONTENT_FROM_LIBRARY:
      return payload;
    default:
      return state;
  }
};
export const addContentLibrary = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case ADD_CONTENT_LIBRARY:
      return payload;
    default:
      return state;
  }
};
export const getAllTaskList = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_ALL_TASK_LIST:
      return payload;
    default:
      return state;
  }
};

export const addGoal = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case Add_Goal:
      return payload;
    default:
      return state;
  }
};
export const getAllActivityDetails = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_ACTIVITY_DATA:
      return payload;
      case GET_PRACTICE_HOUR_SPENT:
        return payload
    default:
      return state;
  }
};
export const trackTaskTime = (state=INITIAL_STATE,action)=>{
    const {payload,type} = action;
    switch(type){
        case TRACK_TASK_TIME:
            return payload;
            default:
                return state;
    }
}
export const getTrackerDetails = (state=INITIAL_STATE,action)=>{
    const {payload,type}=action;
    switch(type){
        case GET_TRACKER_DETAILS:
        return payload;
        default:
            return state
    }
}

export const getTaskSuggestion = (state=INITIAL_STATE,action)=>{
  const {payload,type} = action;
  switch(type){
    case GET_TASK_SUGGESTION:
      return payload;
      case "CLEAR_TASK_SUGGESTION":
        return null
      default :
      return state
  }
}
export const getGoalData = (state=INITIAL_STATE,action)=>{
  const{payload,type} = action;
  switch(type){
    case Get_GOAL_DATA:
      return payload ;
      default:
        return state
}
}
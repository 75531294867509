import React,{useEffect} from "react";
import Header from "../../../layouts/Header/Header";
import "./achievement.css";
import Carousel from "react-multi-carousel";
import { useDispatch ,useSelector} from "react-redux";
import { getAchievementPractice,getSkillUpgrade,getAnswerStreak,getMonthlyStreak } from "../../../store/actions/practice";
import {
  SkillstartwithoutColor,
  SkillstartColor,
  PracticestartwithoutColor,
  PracticestartColor,
  MonthlystartwithoutColor,
  AnswerstartwithoutColor,
  AnswerstartColor,
  MonthlystartColor,
} from "../../../staticContent/image";

const Achievements = () => {
  const  dispatch = useDispatch()
  const store = useSelector((state)=>state)
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 8,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 6,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {

    // dispatch(getAchievementPractice())
    // dispatch(getSkillUpgrade())
    // dispatch(getAnswerStreak())
    // dispatch(getMonthlyStreak())
  }, [])

  return (
    <>
      <div className="row mt-5 mx-3 mb-5">
        <div className="col-12 mb-4 pb-2">
          <h6 className="mb-0 star_headingTxt mb-3">Achievements</h6>
        </div>
        <div className="col-12 px-0">
          {store?.getAnswerStreak?.data?.responseData?.length>0 && <div className="row mb-5 mx-auto">
            <div className="col-12 mb-3 pb-1 d-flex align-content-center">
              <h6 className="mb-0 same_headTxt">Answer Streaks</h6>
              <span className="sm_text d-flex mt-auto mb-1 ps-2">
                Current Streak: {store?.getAnswerStreak?.data?.currentStreak?store?.getAnswerStreak?.data?.currentStreak:"0"}
              </span>
            </div>
            <div className="col-12">
              <Carousel
                infinite={false}
                autoPlay={false}
                autoPlaySpeed={4000}
                responsive={responsive}
                containerclassName="w-100 "
                className="start_data_carousel"
              >
               { store?.getAnswerStreak?.data?.responseData?.map((ele)=><div className="col-auto margin_rigt  custom_widt px-0">
                  <div className="row mx-auto">
                    <div className="col-8 px-0 d-flex align-items-center flex-column">
                    
                      <img
                        className="star_width"
                        src={ele.achievement==null?ele?.rewards?.inactiveImage:ele?.rewards?.activeImage}
                        alt=""
                      />
                      
                      <div className="star_contentTxt d-flex align-items-center flex-column pt-3 mt-2">
                        {/* <p className="mb-0"></p> */}
                        <p className="badge-title mb-0">{ele?.rewards?.title}</p>
                        {/* <p class="mb-0">{ele?.rewards?.title}</p> */}
                        <p className="mb-0 badge-details"> {ele?.rewards?.point} in a row</p>
                        
                      </div>
                    </div>
                  </div>
                </div>)}
              
              </Carousel>
            </div>
          </div>}

         {store?.getMonthlyStreak?.data?.responseData?.length>0 && <div className="row mb-5 mx-auto">
            <div className="col-12 mb-3 pb-2 d-flex align-content-center">
              <h6 className="mb-0 same_headTxt">Monthly Challenges</h6>
            </div>
            <div className="col-12">
              <Carousel
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={4000}
                responsive={responsive}
                containerclassName="w-100"
                className="start_data_carousel_second"
              >
               {store?.getMonthlyStreak?.data?.responseData?.map((ele,ind)=> <div className="col-auto margin_rigt  custom_widt px-0">
                  <div className="row mx-auto">
                    <div className="col-8 px-0 d-flex align-items-center flex-column">
                      <img
                        className="star_width"
                        src={ele.achievement==null?ele?.rewards?.inactiveImage:ele?.rewards?.activeImage}
                        alt=""
                      />
                      <div className=" star_contentTxt line_height d-flex align-items-center flex-column pt-2 mt-1">
                        <p className="mb-0">{ele?.rewards?.month}</p>
                      </div>
                    </div>
                  </div>
                </div>)}
                
              </Carousel>
            </div>
          </div>}

          {store?.getSkillUpgrade?.data?.responseData.length>0 && <div className="row mb-5 mx-auto">
            <div className="col-12 mb-3 pb-2 d-flex align-content-center">
              <h6 className="mb-0 same_headTxt">Skill Upgrades</h6>
            </div>
            <div className="col-12">
              <Carousel
                infinite={false}
                autoPlay={false}
                autoPlaySpeed={4000}
                responsive={responsive}
                containerclassName="w-100"
                className="start_data_carousel_third"
              >
                {store?.getSkillUpgrade?.data?.responseData?.map((ele)=>
                <div className="col-auto margin_rigt  custom_widt px-0">
                  <div className="row mx-auto">
                    <div className="col-8 px-0 d-flex align-items-center flex-column">
                      <img
                        className="star_width"
                        src={ele.achievement==null?ele?.rewards?.inactiveImage:ele?.rewards?.activeImage}
                        alt=""
                      />
                      <div className=" star_contentTxt line_height d-flex align-items-center flex-column pt-2 mt-1">
                        <p className="mb-0">{ele?.rewards?.point} topics</p>
                      </div>
                    </div>
                  </div>
                </div>)}
                
              </Carousel>
            </div>
          </div>}

         {store?.getAchievementPractice?.data?.responseData.length>0 && <div className="row mb-5 mx-auto">
            <div className="col-12 mb-3 pb-2 d-flex align-content-center">
              <h6 className="mb-0 same_headTxt">Practice</h6>
            </div>
            <div className="col-12">
              <Carousel
                infinite={false}
                autoPlay={false}
                autoPlaySpeed={4000}
                responsive={responsive}
                containerclassName="w-100"
                className="start_data_carousel_fourth"
              >
              {store?.getAchievementPractice?.data?.responseData?.map((ele,ind)=>
                <div className="col-auto margin_rigt  custom_widt px-0">
                  <div className="row mx-auto">
                    <div className="col-8 px-0 d-flex align-items-center flex-column">
                      <img
                        className="star_width"
                        src={ele.achievement==null?ele?.rewards?.inactiveImage:ele?.rewards?.activeImage}
                        alt=""
                      />
                      <div className=" star_contentTxt line_height d-flex align-items-center flex-column pt-2 mt-1">
                        <p className="mb-0">{ele?.rewards?.hour} hours</p>
                      </div>
                    </div>
                  </div>
                </div>
                )}
               
              

               

              </Carousel>
            </div>
          </div>}
        </div>
      </div>
    </>
  );
};

export default Achievements;

import axios from "axios";
// import { BASE_URL } from '../../constants/url'
const mernApi = axios.create();

// Set your own baseURL
// mernApi.defaults.baseURL = "https://seekhoapp.herokuapp.com";
mernApi.defaults.baseURL = "http://localhost:8956";
// mernApi.defaults.baseURL = "https://seekh.onrender.com/";

// mernApi.defaults.baseURL = "https://65.0.109.253:3000";
// mernApi.defaults.baseURL = "https://seekho-empty-star-1298.fly.dev";

// mernApi.defaults.baseURL = "https://seekh.xyz";

// mernApi.defaults.baseURL = "http://3.23.163.204:3000";
// mernApi.defaults.baseURL = "https://wondrous-muffin-cfa588.netlify.app";

mernApi.setAuthToken = (jwtToken) => {
  mernApi.defaults.headers.common["Authorization"] = `Bearer ${jwtToken}`;
};

export default mernApi;

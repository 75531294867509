import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import TimeInput from "react-input-time";

import {
  dotsShowAll,
  union,
  hourGlassRound,
  circleImg,
  checkmark,
  hourGlass,
  tick,
} from "../../../../staticContent/image";
import { useDispatch } from "react-redux";
import { getTrackerDetails } from "../../../../store/actions/studyBuddy";
import "./ActivityTracker.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import {
  getAllTaskList,
  addTime,
  UpdateTrackTime,
  taskStatus,
} from "../../../../store/actions/studyBuddy";
function ActivityTracker() {
  const moment = extendMoment(originalMoment);
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openModel, setOpenModel] = useState(false);
  const [showSchool, setShowSchool] = useState(false);
  const [trackerData, setTrackerData] = useState([]);
  const [selectOption, setSelectOption] = useState("Specify the task");
  const [addTimeBtnStatus, setAddTimeBtnStatus] = useState(false);
  const [loader, setLoader] = useState(true);
  const [time, setTime] = useState({ start: "00:00", stop: "00:00" });
  const [optionData, setOptionData] = useState([]);
  const [specifyTaskId, setSpecificTaskId] = useState();
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    dispatch(getTrackerDetails()).then((res) => {
      if (res.status === 200) {
        setLoader(false);
        setTrackerData(res.data.responseData);
      }
    });
  }, [skip]);
  useEffect(() => {
    dispatch(getAllTaskList("work", skip)).then((res) => {
      if (res.status === 200) {
        setOptionData(...optionData, res?.data?.responseData);
      }
    });
  }, [skip]);

  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    setTime({ ...time, [name]: value });
  };
  const handleTask = (id) => {
    localStorage.setItem("taskDetailid", id);
    history.push({
      pathname: "/organize/addcontent",
      state: {
        id: id,
      },
    });
  };

  const hanldeAddTime = async () => {
    let valuestart = time.start;
    let valuestop = time.stop;

    let timeStart = new Date("12/21/2022 " + valuestart);
    let timeEnd = new Date("12/21/2022 " + valuestop);
    let difference = timeEnd - timeStart;

    let body = { taskId: specifyTaskId, timeTrack: Math.abs(difference) };

    dispatch(UpdateTrackTime(body)).then((res) => {
      if (res.status === 200) {
        setOpen(false);
        setTime({ start: "", stop: "" });
      }
    });
  };
  const handleScroll = (e) => {
    if (e.target.scrollTop * 4 >= window.innerHeight) {
      setSkip(skip++);
    }
  };
  const formatTime = (milliseconds) => {
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor(milliseconds / 1000 / 60 / 60);

    return [
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
    ].join(":");
  };
  const handleStatus = (item, itemxx) => {
    const res = trackerData.map((itd, indx) => {
      if (item.trackerDay === itd.trackerDay) {
        const arr = itd.data.map((itdg, indg) =>
          itdg._id === itemxx._id
            ? { ...itdg, tooltipShow: !itdg?.tooltipShow }
            : { ...itdg, tooltipShow: false }
        );
        return { ...itd, data: arr };
      } else {
        return itd;
      }
    });
    setTrackerData(res);
  };
  const hanldeCheckMarkClick = (item, itemxx) => {
    dispatch(taskStatus(itemxx?._id, false));
    const res = trackerData.map((itd, indx) => {
      if (item.trackerDay === itd.trackerDay) {
        const arr = itd.data.map((itdg, indg) =>
          itdg._id === itemxx._id
            ? { ...itdg, tooltipShow: false, taskStatus: false }
            : itdg
        );
        return { ...itd, data: arr };
      } else {
        return itd;
      }
    });
    setTrackerData(res);
  };

  const handleHourGlassClick = (item, itemxx) => {
    dispatch(taskStatus(itemxx?._id, true));

    const res = trackerData.map((itd, indx) => {
      if (item.trackerDay === itd.trackerDay) {
        const arr = itd.data.map((itdg, indg) =>
          itdg._id === itemxx._id
            ? { ...itdg, tooltipShow: false, taskStatus: true }
            : itdg
        );
        return { ...itd, data: arr };
      } else {
        return itd;
      }
    });
    setTrackerData(res);
  };
  const handleImageChange = (item) => {
    if (item === null) {
      return circleImg;
    } else if (item === true) {
      return hourGlassRound;
    } else {
      return tick;
    }
  };

  const handleThreedotsClick = (item, itemx) => {
    let res = trackerData.map((itd, indx) => {
      if (itd.trackerDay === item.trackerDay) {
        const arr = itd.data.map((itdg, ind) =>
          itdg._id === itemx?._id
            ? { ...itdg, editTime: true }
            : { ...itdg, editTime: false }
        );

        return { ...itd, data: arr };
      } else {
        return itd;
      }
    });
    setTrackerData(res);
  };
  return (
    <div>
      {loader ? (
        <Skeleton height={`3vh`} count={50} />
      ) : (
        <div class="row mx-auto bg-white">
          <div class="col-12">
            <div class="row">
              <div class="col-12 my-4">
                <div class="row mx-auto">
                  <div class="col mt-3 d-flex justify-content-start align-items-center activity_heading">
                    <h4 class="m-0">Tracker</h4>
                  </div>
                  <div class="col mt-3 d-flex justify-content-end align-items-center">
                    <Link to="/organize/tasks/create-task">
                      <button
                        type="button"
                        class=" border btn btn-light py-2 px-4 me-3 shadow-none offcan-study text-capitalize add_task_button"
                      >
                        Add Task
                      </button>
                    </Link>
                    <button
                      onClick={() => {
                        // setAddTimeBtnStatus(!addTimeBtnStatus);
                        setOpen(true);
                      }}
                      type="button"
                      style={
                        open
                          ? {
                              color: "black",
                              borderColor: "#6A4BFF !important",
                            }
                          : { backgroundColor: "#6A4BFF", color: "white" }
                      }
                      class=" border btn btn-light py-2 px-4 shadow-none  text-capitalize add_task_button"
                    >
                      Add Time
                    </button>

                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "420px",
                          // height: "380px",
                          bgcolor: "background.paper",
                          borderRadius: "10px",
                        }}
                      >
                        <div class="modal-dialog modal-dialog-centered">
                          <div class="modal-content">
                            <div class="modal-header  px-4 py-3 activity_heading border-bottom">
                              <h4
                                class="modal-title"
                                id="exampleModalToggleLabel"
                              >
                                Add New Time
                              </h4>
                              <button
                                onClick={() => {
                                  setOpen(false);
                                }}
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body px-4 pt-4">
                              <div className="row">
                                <div class="col-12">
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="row mx-auto position-relative">
                                        <div className="col-12 p-0">
                                          <label
                                            for="email"
                                            className="mb-2 mt-1"
                                          >
                                            <b className="label_txt time_trake_heading">
                                              Specify the tasks
                                            </b>
                                          </label>
                                        </div>
                                        <div className="col-12">
                                          <div className="row">
                                            <div
                                              onClick={() => {
                                                setOpenModel(!openModel);
                                              }}
                                            >
                                              <div
                                                className={
                                                  showSchool
                                                    ? "row  profile-borderwithselection popup_drop_height enter school p-2"
                                                    : "row  profile-borderr  school popup_drop_height enter p-2"
                                                }
                                              >
                                                <div className="col ps-2 pe-0 flex-1 d-flex align-items-center">
                                                  <input
                                                    disabled
                                                    type="search"
                                                    placeholder="Specify the task"
                                                    className={
                                                      "border-0  flex-grow-1 outline-none bg-transparent placholder_color_font"
                                                    }
                                                    value={selectOption}
                                                  />
                                                </div>
                                                <div className="col-auto pe-2 d-flex align-items-center drop-icon justify-content-end">
                                                  <i
                                                    aria-hidden="true"
                                                    className={
                                                      openModel
                                                        ? "fa fa-angle-up"
                                                        : "fa fa-angle-down"
                                                    }
                                                  ></i>
                                                </div>
                                              </div>
                                            </div>

                                            {openModel && (
                                              <div
                                                onScroll={(e) => {
                                                  handleScroll(e);
                                                }}
                                                className="col-12 border popup_drpdown  px-3 py-2  position-absolute"
                                              >
                                                <div className="row">
                                                  {optionData.map((item) => (
                                                    <>
                                                      <div
                                                        className="col-12 input-data py-1"
                                                        onClick={() => {
                                                          setOpenModel(
                                                            !openModel
                                                          );
                                                          setSpecificTaskId(
                                                            item._id
                                                          );
                                                          setSelectOption(
                                                            item.title
                                                          );
                                                        }}
                                                      >
                                                        <p className=" topics task_add_law m-0 text-capitalize ">
                                                          {item.title}
                                                        </p>
                                                      </div>
                                                    </>
                                                  ))}
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="modal-body  pb-0 pt-4 px-4">
                              <div class="row">
                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="exampleInputPassword1"
                                      class="form-label"
                                    >
                                      <b className="label_txt">Start</b>
                                    </label>

                                    <TimeInput
                                      name="newTime"
                                      className="input-time form-control shadow-none enter py-2 placholder_color_font popup_drop_height"
                                      initialTime="00:00"
                                      onChange={(event) => {
                                        handleTimeChange(event);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="exampleInputPassword1"
                                      class="form-label"
                                    >
                                      <b className="label_txt">Stop</b>
                                    </label>
                                    <TimeInput
                                      name="newTime"
                                      className="input-time form-control shadow-none enter py-2 placholder_color_font popup_drop_height"
                                      initialTime="00:00"
                                      onChange={(event) => {
                                        handleTimeChange(event);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer mb-3 pb-4 pt-3 px-4 border-0 d-flex align-items-center justify-content-start">
                              <button
                                class="border btn btn-light py-2 px-4  mx-0 shadow-none offcan-study text-capitalize add_task_button"
                                onClick={() => {
                                  hanldeAddTime();
                                }}
                              >
                                Add Time
                              </button>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>

            {trackerData.map((item, inde) => {
              return (
                <div class="row mx-auto mb-4 ">
                  <div class="col-12 remove_bottom_border">
                    <div class="row mx-auto">
                      <div class="col-12 border ">
                        <div class="row border-bottom py-1">
                          <div class="col d-flex justify-content-start align-items-center my-2">
                            <h6 class="m-0 time_trake_heading">
                              {item.trackerDay === 1
                                ? "Today"
                                : moment(item?.createDate).format("Do MMMM")}
                            </h6>
                          </div>
                          <div class="col d-flex justify-content-end align-items-center my-2">
                            <p class="m-0 time_trake_heading">
                              <span className="total_time_trake">
                                Total Time:{" "}
                              </span>
                              {item?.data[0]?.totalTime
                                ? formatTime(item?.data[0]?.totalTime)
                                : "0:00"}
                            </p>
                          </div>
                        </div>
                        {item.data.map((itemxx, ind) => {
                          let tags_topics = itemxx?.tags_topics;
                          let tagsArr = tags_topics?.split(",");

                          let resTags = tagsArr?.filter((ele, ind) => {
                            return ele != "";
                          });
                          return (
                            <div
                              class={
                                item.data.length - 1 === ind
                                  ? "row"
                                  : "row border-bottom"
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <div class="col-12 pe-0">
                                <div class="row">
                                  <div class="col-auto py-3 pe-1 d-flex align-items-center justify-content-center">
                                    <div class="row d-flex align-items-center justify-content-center">
                                      <div class="col">
                                        <img
                                          className="add_task_img_tracker"
                                          src={itemxx?.taskType?.task_icon}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div class="line-div col-1 mx-auto"></div> */}
                                  <div class="col py-3 border-end">
                                    <div
                                      class="row d-flex flex-column justify-content-start"
                                      onClick={() => {
                                        handleTask(itemxx._id);
                                      }}
                                    >
                                      <div class="col">
                                        <p class="m-0 add_task_para">
                                          {itemxx?.title}
                                        </p>
                                      </div>
                                      <div class="col-12 mt-1">
                                        <div class="row d-flex">
                                          <div className="col-auto pe-0">
                                            <button
                                              type="button"
                                              class="bg-white remove_border_color btn_radius_dec border text-uppercase py-1 px-2 shadow-none task_add_btn "
                                            >
                                              #{itemxx?.course}
                                            </button>
                                          </div>
                                          <div className="d-flex p-0 col">
                                            {resTags?.map((ele, indx) => {
                                              return (
                                                <button
                                                  type="button"
                                                  class="border-0 task_add_btn bg-light btn_radius_dec shadow-none text-capitalize px-2 py-1 ms-2"
                                                >
                                                  {ele}
                                                </button>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-2 d-flex p-0">
                                    <div className="row mx-auto add_width">
                                      <div class="col-5  border-end d-flex justify-content-center align-items-center">
                                        <img
                                          onClick={() => {
                                            handleStatus(item, itemxx);
                                          }}
                                          className="tracker_img"
                                          // src={itemxx?.taskStatus?checkmark:circleImg}
                                          src={handleImageChange(
                                            itemxx?.taskStatus
                                          )}
                                          alt=""
                                        />
                                        {itemxx.tooltipShow && (
                                          <div className="checkbox_dropdown position-absolute">
                                            <div className="row mx-auto">
                                              <div className="col-12 position-absolute height">
                                                <div className="row mx-auto height">
                                                  <div className="col-12 ms-1 mt-3 d-flex align-items-center justify-content-center">
                                                    <img
                                                      onClick={() =>
                                                        hanldeCheckMarkClick(
                                                          item,
                                                          itemxx
                                                        )
                                                      }
                                                      className="circle_img"
                                                      src={checkmark}
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div className="col-12 mt-1 mb-3 ms-1 d-flex align-items-center justify-content-center">
                                                    <img
                                                      onClick={() => {
                                                        handleHourGlassClick(
                                                          item,
                                                          itemxx
                                                        );
                                                      }}
                                                      className="circle_img"
                                                      src={hourGlass}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-12">
                                                <img
                                                  className="width"
                                                  src={union}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>

                                      <div class="col py-3 d-flex justify-content-center align-items-center me-0">
                                        <div class="row">
                                          {!itemxx?.editTime ? (
                                            <div class="col d-flex justify-content-center align-items-center">
                                              <p class="m-0  time_trake_heading total_time_trake">
                                                {itemxx?.taskTimeTrack
                                                  ? formatTime(
                                                      itemxx?.taskTimeTrack
                                                    )
                                                  : "0:00"}
                                              </p>
                                              <img
                                                onClick={() => {
                                                  handleThreedotsClick(
                                                    item,
                                                    itemxx
                                                  );
                                                }}
                                                className="add_task_time_imgs ms-1"
                                                src={dotsShowAll}
                                                alt=""
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className="col-auto me-2 mb-2 p-0 m-0 d-flex align-items-center justify-content-center"
                                              style={{ width: "58px" }}
                                            >
                                              <TimeInput
                                                name="time"
                                                className="time_input form-control shadow-none enter height_add_time_data py-2 px-2 btn_shadow_color"
                                                initialTime="00:00"
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default ActivityTracker;

import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useHistory, Redirect } from "react-router-dom";
import "./Task.css";
import {
  UpArrow,
  DownArrow,
  calendar,
  createTask,
} from "../../../staticContent/image";
import {
  getTaskType,
  addTask,
  getTaskSuggestion,
  getCourseSuggestion,
  clearTaskSuggestion,
} from "../../../store/actions/studyBuddy";
import Select from "react-select";
import { Store } from "react-notifications-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ControllerClassicOutline } from "mdi-material-ui";
function Task() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [startDate, setStartDate] = useState(new Date());
  const store = useSelector((state) => state);
  const timer = useRef(null);
  const [loader, setLoader] = useState(false);
  const [courseDropdown, setCourseDropdown] = useState(false);
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [taskSuggestion, setTaskSuggestion] = useState([]);
  const [openSuggestionBox, setOpenSuggestionBox] = useState(false);
  const [time, setTime] = useState(null);
  const [suggestionCourseList, setSuggestionCourseList] = useState([]);
  const [data, setData] = useState({
    title: "",
    course: "",
    tags_topics: "",
    description: "",
    dueDate: "",
    taskType: "",
  });
  const [taskType, setTaskType] = useState([]);
  const [options, setOptions] = useState([
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ]);
  const [tagsList, setTagsList] = useState([]);
  const [tagsDropDown, setTagsDropDown] = useState(false);
  const [tagTxt, setTagTxt] = useState("");
  useEffect(() => {
    dispatch(getTaskType()).then((res) => {
      setTaskType(res.data);
    });
  }, []);
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      type="button"
      class="btn border shadow-none px-3 py-2 add_new_task_form text-uppercase d-flex align-items-center justify-content-center"
      onClick={onClick}
      ref={ref}
    >
      <img className="task_calendar_img me-2" src={calendar} alt="" />
      {data.dueDate ? getDateFormat(data.dueDate) : "DD/MM/YY"}
    </button>
  ));
  const getDateFormat = (date) => {
    const d1 = new Date(date).toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
    const d2 = d1.split("/");
    return `${d2[1]}/${d2[0]}/${d2[2]}`;
  };
  const handleChange = (e) => {
    if (e.target.value.length <= 1) {
      setCourseDropdown(false);
    }
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    getSuggestionList(e);
  };
  const handleAdd = async () => {
    setLoader(true);
    if (
      data.title.trim() === "" ||
      data.description.trim() === "" ||
      data.dueDate === "" ||
      data.taskType.trim() === "" ||
      tagsList?.length == 0
    ) {
      showError("Please fill all the inputs", "Warning !!", "warning");
      setLoader(false);
    } else {
      let body = {
        title: data.title,
        course: data.course,
        tags_topics: tagsList?.join(),
        description: data.description,
        dueDate: data.dueDate,
        taskType: data.taskType,
      };
      const res = await dispatch(addTask(body));
      if (res.status === 200) {
        let id = res?.data?.responseData?._id;
        localStorage.setItem("taskDetailid", id);
        history.push({
          pathname: "/organize/addcontent",
          state: {
            id: id,
          },
        });
      }
      setLoader(false);
    }
  };

  // history.push({
  //   pathname: "/organize/addcontent",
  //   state: {
  //     id: id,
  //   },
  // });
  function showError(data, warn, type) {
    Store.addNotification({
      title: warn,
      message: data,
      type: type,
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  }

  const handleBack = () => {
    history.push("/organize/activity");
  };
  const handleTagsChange = (e) => {
    // setData({ ...data, tags_topics: e.target.value });
    setTagTxt(e.target.value);
    setOpenSuggestionBox(true);
    // setTagsDropDown(true);
    clearTimeout(time);
    if (e.target.value?.length <= 0) {
      setOpenSuggestionBox(false);
      setTagsDropDown(false);
      dispatch(clearTaskSuggestion([]));
    } else {
      const newTimer = setTimeout(() => {
        dispatch(getTaskSuggestion(e.target.value)).then((res) => {
          setTagsDropDown(true);
          setTaskSuggestion(res?.data?.responseData);
          if (res?.data?.responseData.length === 0) {
            setTaskSuggestion([...taskSuggestion, { tags: tagTxt }]);
          }
        });
      }, 500);
      setTime(newTimer);
    }
    if (e.target.value.length == 1) {
      setOpenSuggestionBox(false);
    }
  };
  const getSuggestionList = (e) => {
    // getCourseSuggestion;
    if (e.target.value !== "") {
      dispatch(getCourseSuggestion(e.target.value)).then((res) => {
        setSuggestionCourseList(res?.data?.responseData);
      });
    }
  };
  const onChange = (newValue, actionMeta) => {
    // switch (actionMeta.action) {
    //   case 'remove-value':
    //   case 'pop-value':
    //     if (actionMeta.removedValue.isFixed) {
    //       return;
    //     }
    //     break;
    //   case 'clear':
    //     newValue = colourOptions.filter((v) => v.isFixed);
    //     break;
    // }
    // setValue(orderOptions(newValue));
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (tagTxt.trim() !== "") {
        setTagsList([...tagsList, tagTxt]);
      }
      setTagTxt("");
      setTagsDropDown(false);
      setOpenSuggestionBox(false);
    }
  };
  return (
    <div>
      <div className="row mx-auto">
        <div className="col-12">
          <div class="row mx-auto">
            <div class="col-12 mt-5">
              <p
                class="add_new_task_para m-0"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleBack();
                }}
              >
                <i class="fa fa-angle-left me-2" aria-hidden="true"></i> Back to
                tasks
              </p>
            </div>
          </div>
          <div class="row mx-auto my-4 pb-5">
            <div class="col-6 ">
              <div class="row mx-auto bg-white form_main pb-4">
                <div class="col-12 px-4 border-bottom">
                  <div class="mt-4 mb-3">
                    <h6 class="add_new_task_heading m-0">Add New Task</h6>
                  </div>
                  <div class="mb-3">
                    <label
                      for="exampleInputEmail1"
                      class="form-label add_new_task_form"
                    >
                      Title
                    </label>
                    <input
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      type="text"
                      value={data.title}
                      name="title"
                      class="form-control large py-3 add_new_task_form add_new_task_form_weight shadow-none enter  enter-add-content"
                      placeholder=""
                    />
                  </div>
                  <div class="mb-3 position-relative">
                    <label
                      for="exampleInputEmail1"
                      class="form-label add_new_task_form"
                    >
                      Course
                    </label>
                    <input
                      onChange={(e) => {
                        setCourseDropdown(true);
                        handleChange(e);
                      }}
                      type="text"
                      value={data.course}
                      name="course"
                      class="form-control large py-3 add_new_task_form add_new_task_form_weight shadow-none enter  enter-add-content"
                      placeholder="PHY7A"
                    />
                    {courseDropdown && suggestionCourseList.length > 0 && (
                      <div className="col-12 border popup_drpdown  px-3 py-2 overflow-hidden position-absolute margin_top_drop_down">
                        <div className="row">
                          {suggestionCourseList?.map((item) => (
                            <>
                              <div
                                className="col-12 input-data py-1"
                                onClick={() => {
                                  // setTagsList([...tagsList, item.tags]);
                                  // setTagTxt("");
                                  // setTagsDropDown(false);
                                  setData({
                                    ...data,
                                    course: item?.course_identifier,
                                  });
                                  setCourseDropdown(false);
                                }}
                              >
                                <p className=" topics task_add_law m-0 text-capitalize cp ">
                                  {item?.course_identifier}
                                </p>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  <div class="mb-3 position-relative">
                    <label
                      for="exampleInputEmail1"
                      class="form-label add_new_task_form"
                    >
                      Tags, Topics associated
                    </label>

                    <div className="col-12 border popup_drop_height overflow-hidden">
                      <div className="row mx-auto flex-nowrap overflow-auto align-items-center ">
                        {tagsList.map((itemdx, indedx) => (
                          <div className="col-auto ms-2 py-2">
                            <div className="row bg-light btn_radius_dec">
                              <div className="col-12 add_task_long_para py-1">
                                {itemdx}
                                <img
                                  onClick={() => {
                                    const array = [...tagsList];
                                    const index = array.indexOf(itemdx);
                                    if (index > -1) {
                                      // only splice array when item is found
                                      array.splice(index, 1); // 2nd parameter means remove one item only
                                    }
                                    setTagsList(array);
                                  }}
                                  className="ms-2 cp"
                                  src={createTask}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className="col-auto">
                          <div className="row ">
                            <div className="col-12">
                              <input
                                onChange={(e) => {
                                  handleTagsChange(e);
                                }}
                                onKeyDown={(e) => {
                                  handleKeyDown(e);
                                }}
                                value={tagTxt}
                                type="text"
                                id="fname"
                                name="fname"
                                className="border-0 p-0"
                                placeholder="add tags"
                              ></input>
                              {openSuggestionBox && tagsDropDown && (
                                <div className="col-12 border popup_drpdown  px-3 py-2 overflow-hidden position-absolute margin_top_drop_down">
                                  <div className="row">
                                    {taskSuggestion?.map((item) => (
                                      <>
                                        <div
                                          className="col-12 input-data py-1"
                                          onClick={() => {
                                            setTagsList([
                                              ...tagsList,
                                              item.tags,
                                            ]);
                                            setTagTxt("");
                                            setTagsDropDown(false);
                                          }}
                                        >
                                          <p className=" topics task_add_law m-0 text-capitalize cp">
                                            {item.tags}
                                          </p>
                                        </div>
                                      </>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <p>
                      <label for="w3review" className="add_new_task_form">
                        Description
                      </label>
                    </p>
                    <textarea
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      id="w3review"
                      name="description"
                      rows="4"
                      cols="50"
                      value={data.description}
                      class="border large p-3 add_new_task_form add_new_task_form_weight add_new_focus outline-none"
                    ></textarea>
                  </div>
                </div>
                <div className="col-12 mt-3 px-4">
                  {/* <div class="mb-4">
                    <label
                      for="exampleInputPassword1"
                      class="form-label add_new_task_form"
                    >
                      Task Type
                    </label>
                    <select
                      name="taskType"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={data.taskType}
                      class="form-select large p-0 p-2 add_new_task_form shadow-none add_new_task_height"
                      aria-label="Default select example"
                    >
                      <option selected="">Select</option>
                      {taskType?.map((ele, ind) => {
                        return <option value={ele._id}>{ele.task_type}</option>;
                      })}
                    </select>
                  </div> */}
                  <div className="col-12">
                    <div className="row mx-auto position-relative">
                      <div className="col-12 p-0">
                        <label for="email" className="mb-2 mt-1">
                          <b className="label_txt add_new_task_form">
                            Task Type
                          </b>
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div
                            onClick={() => {
                              // data.taskType(!openModel);
                              setDropdownStatus(!dropdownStatus);
                            }}
                          >
                            <div
                              className={
                                dropdownStatus
                                  ? "row  profile-borderwithselection popup_drop_height enter school p-2"
                                  : "row  profile-borderr  school popup_drop_height enter p-2"
                              }
                            >
                              <div className="col ps-2 pe-0 flex-1 d-flex align-items-center">
                                <input
                                  disabled
                                  type="search"
                                  placeholder="Test Preparation "
                                  className={
                                    "border-0  flex-grow-1 outline-none bg-transparent large p-0  add_new_task_form shadow-none add_new_task_height"
                                  }
                                  value={
                                    taskType.find(
                                      (itemx) => itemx?._id === data?.taskType
                                    )?.task_type
                                  }
                                />
                              </div>
                              <div className="col-auto pe-2 d-flex align-items-center drop-icon justify-content-end">
                                <i
                                  aria-hidden="true"
                                  className={
                                    dropdownStatus
                                      ? "fa fa-angle-up"
                                      : "fa fa-angle-down"
                                  }
                                ></i>
                              </div>
                            </div>
                          </div>

                          {dropdownStatus && (
                            <div className="col-12 border popup_drpdown  px-3 py-2  position-absolute">
                              <div className="row">
                                {taskType?.map((item) => (
                                  <>
                                    <div
                                      className="col-12 input-data py-1"
                                      onClick={() => {
                                        setData({
                                          ...data,
                                          taskType: item._id,
                                        });
                                        setDropdownStatus(false);
                                      }}
                                    >
                                      <p className=" topics task_add_law m-0 text-capitalize cp">
                                        {item.task_type}
                                      </p>
                                    </div>
                                  </>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4 mb-2">
                    <div class="col-12 ">
                      <h6 className=" add_new_task_form">Due Date</h6>
                    </div>
                    <div class="row p-0 mx-auto d-flex align-items-center">
                      {/* <label for="dateofbirth">Date Of Birth</label> */}
                      <div class="col  ">
                        {/* <input
                          id="dateof25birth"
                          type="date"
                          name="dueDate"
                          value={data.dueDate}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className="border-0 shadow-0 outline-none"
                        /> */}
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => {
                            setData({ ...data, dueDate: date });
                          }}
                          customInput={<ExampleCustomInput />}
                        />
                      </div>

                      <div class="col d-flex justify-content-end align-items-center">
                        {!loader ? (
                          <button
                            onClick={() => {
                              handleAdd();
                            }}
                            type="button"
                            class=" border btn btn-light py-2 px-4 shadow-none offcan-study text-capitalize add_task_button"
                          >
                            Add Task
                          </button>
                        ) : (
                          <div className=" px-5">
                            <button class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Task;

import React, { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  Item1,
  searchClose,
  DefaultImg,
  learn_button,
  SideNoteLeft,
} from "../../../staticContent/image";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Group } from "../../../staticContent/image";
import { Steps, Hints } from "intro.js-react";

import "intro.js/introjs.css";
export const CLEAR_SEARCHBAR = "CLEAR_SEARCHBAR";
function OffCanvasExample({ ...props }) {
  const store = useSelector((state) => state);
  //  data destructuring from props
  const {
    show,
    setShow,
    sideLoader,
    data,
    selectedText,
    onHandlePractice,
    practiceLoader,
  } = props;

  const [search, setSearch] = useState(selectedText);
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  // function used for slice the string in this page :
  function truncate(str, n) {
    if (str) return str?.length > n ? str.slice(0, n - 1) + "...." : str;
  }
  const sideToggle = (type) => {
    setIsOpen(type);
  };

  // function for breakpoints of screens
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      paritialVisibilityGutter: 60,
    },
    // tablet: {
    //   breakpoint: { max: 1024, min: 464 },
    //   items: 2,
    //   paritialVisibilityGutter: 50

    // },
    // mobile: {
    //   breakpoint: { max: 464, min: 0 },
    //   items: 2,
    //   paritialVisibilityGutter: 50

    // }
  };
  useEffect(() => {
    setSearch(truncate(selectedText, 20));
  }, [selectedText]);
  const formatTime = (milliseconds) => {
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor(milliseconds / 1000 / 60 / 60);

    return [
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
    ].join(":");
  };

  const handleStatus = (questionPoints, totalQuiz) => {
    if (totalQuiz >= 5 && totalQuiz < 15) {
      return "Medium";
    } else if (totalQuiz >= 15 && totalQuiz < 25) {
      return "Advanced";
    } else if (totalQuiz >= 25) {
      return "Maestro";
    } else if (questionPoints >= 100) {
      return "Medium";
    } else {
      return "Beginner";
    }
  };
  return (
    <>
      <div>
        <Offcanvas
          show={show}
          onHide={() => setShow(false)}
          {...props}
          placement={"end"}
        >
          <Offcanvas.Body>
            {sideLoader && <Skeleton height={`100vh`} count={1} />}
            {!sideLoader && (
              <>
                <div className="container mt-3 ">
                  <div className="row">
                    <div className="col-12 mb-4 mt-2">
                      <Offcanvas.Header className="infotooltipHeader p-0 ">
                        <form
                          className="border get d-flex justify-content-between align-items-center  overflow-hidden align-items-center "
                          style={{ width: "440px", height: "40px" }}
                        >
                          <i className="fa fa-search ms-3"></i>{" "}
                          {selectedText && (
                            <input
                              onChange={handleChange}
                              className="form-control search  border-white shadow-none"
                              type="text"
                              value={search}
                              aria-label="Search"
                            />
                          )}
                          <img
                            onClick={() => {
                              setSearch("");
                            }}
                            src={searchClose}
                            alt={""}
                            className="closeImgInfo me-2"
                          />
                        </form>
                      </Offcanvas.Header>
                    </div>
                  </div>

                  <div className="">
                    <h3
                      className="fw-bold"
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      {truncate(selectedText, 70)}
                    </h3>
                  </div>
                  <div className="mt-3">
                    {data?.recommended?.[0]?.description && (
                      <div
                        className="offcanvas_data"
                        dangerouslySetInnerHTML={{
                          __html: truncate(
                            data.recommended[0].description,
                            310
                          ),
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="row mx-auto">
                  <div className="col-12 ">
                    <p className="mt-3 offcan-no-progress">
                      <b className="offcan-topic-progress slidebar me-1">
                        Topic Progress:
                      </b>
                      {handleStatus(
                        store?.getStudyTopic?.topicPoints,
                        store?.getStudyTopic?.CompleteQuizz
                      )}
                    </p>
                  </div>
                  <div className="mt-1">
                    <div className="row mx-auto d-flex align-items-center justify-content-between">
                      <div className="col selectedBar px-1">
                        <hr />
                        <p className="m-0 text-center mt-2 offcan-medium slidebar">
                          Beginner
                        </p>
                      </div>
                      <div
                        className={`${
                          handleStatus(
                            store?.getStudyTopic?.topicPoints,
                            store?.getStudyTopic?.CompleteQuizz
                          ) === "Medium" ||
                          handleStatus(
                            store?.getStudyTopic?.topicPoints,
                            store?.getStudyTopic?.CompleteQuizz
                          ) === "Advanced" ||
                          handleStatus(
                            store?.getStudyTopic?.topicPoints,
                            store?.getStudyTopic?.CompleteQuizz
                          ) === "Maestro"
                            ? "selectedBar"
                            : "unselectedBar"
                        } col  px-1 `}
                      >
                        <hr />
                        <p className="m-0 text-center mt-2 offcan-medium slidebar">
                          Medium
                        </p>
                      </div>
                      <div
                        className={`${
                          handleStatus(
                            store?.getStudyTopic?.topicPoints,
                            store?.getStudyTopic?.CompleteQuizz
                          ) === "Advanced" ||
                          handleStatus(
                            store?.getStudyTopic?.topicPoints,
                            store?.getStudyTopic?.CompleteQuizz
                          ) === "Maestro"
                            ? "selectedBar"
                            : "unselectedBar"
                        } col  px-1 `}
                      >
                        <hr />
                        <p className="m-0 text-center mt-2 offcan-medium slidebar">
                          Advanced
                        </p>
                      </div>
                      <div
                        className={`${
                          handleStatus(
                            store?.getStudyTopic?.topicPoints,
                            store?.getStudyTopic?.CompleteQuizz
                          ) === "Maestro"
                            ? "selectedBar"
                            : "unselectedBar"
                        } col  px-1 `}
                      >
                        <hr />
                        <p className="m-0 text-center mt-2 offcan-medium slidebar">
                          Maestro
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" row mt-4 mx-auto d-flex">
                    <div className="col py-2 d-flex align-items-center justify-content-center border infoTooltipcolorfull rounded-3 height_ics ">
                      <button className="btn shadow-none border-0 infoTooltipcolorfullss ">
                        Explore More
                      </button>
                    </div>
                    <div className="col py-2 ms-3 d-flex align-items-center justify-content-center border  infoTooltipcolorfulls height_ics">
                      {practiceLoader ? (
                        <div className="spinner-border me-4" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <button
                          onClick={() => {
                            onHandlePractice();
                          }}
                          type="button"
                          className="btn shadow-none border-0 infoTooltipcolorfullss text-white "
                        >
                          Practice
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <hr />
                </div>
                <div className="container mt-3" style={{ fontSize: "1rem" }}>
                  <h5
                    className="offcan-related"
                    style={{ fontSize: "20px", lineHeight: "20px" }}
                  >
                    Related to this
                  </h5>
                </div>
                <div className="mt-4 col-12 d-flex">
                  <div
                    className=" border-0 align-items-start overflow-hidden"
                    style={{ postion: "relative" }}
                  >
                    <div className="row col-12 mb-3 crousel_info carousel_customWidth">
                      <Carousel
                        // infinite={true}
                        responsive={responsive}
                        infinite={true}
                        ssr
                        partialVisbile
                        // deviceType={deviceType}
                        itemclassName="image-items"
                        className="data_second_scroll"
                        // className="image-items"
                      >
                        {/* {data?.recommended?.length > 0 &&
                        getRecommdedContent(data?.recommended)} */}

                        {data?.recommended
                          ?.slice(0, data?.recommended?.length / 2)
                          .map((item, id) => {
                            return (
                              <>
                                <div className="">
                                  <div className=" overflow-hidden shadow-none border card_borderRadius mx-2 align-items-start">
                                    <div className="row height mx-auto">
                                      <div className="col-6 height position-relative p-0">
                                        <img
                                          src={
                                            item?.thumbnail
                                              ? item.thumbnail
                                              : DefaultImg
                                          }
                                          className="width_add height"
                                          alt="..."
                                        />
                                        {item?.category?.category_name && (
                                          <button
                                            //  onClick={handleShow}
                                            className="btn Slides text-white d-flex align-items-center justify-content-center rounded-pill mt-2"
                                            style={{
                                              cursor: "pointer",
                                              lineHeight: "10px",
                                              textTransform: "capitalize",
                                              backgroundColor: `${item?.category?.category_color}`,
                                            }}
                                          >
                                            {item?.category?.category_name}
                                          </button>
                                        )}
                                      </div>
                                      <div className="col-6 custom_padding d-flex">
                                        <div className="row text-start">
                                          <div className="col-12 mb-auto  d-flex flex-column justify-content-center">
                                            <h6 className="card-data m-0">
                                              {item.title &&
                                                truncate(item.title, 20)}
                                            </h6>
                                          </div>
                                          {/* <div className="col-12">
                                            <p className="card-texts mb-2 ">
                                              Barbara Chiappetta
                                            </p>
                                          </div> */}
                                          <div className="col-12 mt-auto">
                                            <p className="card-texts-info mb-2">
                                              {item?.studyPageTimeTrack
                                                ? formatTime(
                                                    item?.studyPageTimeTrack
                                                  )
                                                : "00:00"}{" "}
                                              spent · Beginner
                                            </p>
                                          </div>
                                          {/* <div className="col-10 d-flex">
                                        <div className="col topic pe-1">
                                          <hr />
                                        </div>
                                        <div className="col topicc px-1">
                                          <hr />
                                        </div>
                                        <div className="col topicc px-1">
                                          <hr />
                                        </div>
                                        <div className="col topicc px-1">
                                          <hr />
                                        </div>
                                      </div> */}
                                          <div class=" topic">
                                            <div class="progress progress_parent">
                                              <div
                                                class="progress-bar progress_bar"
                                                role="progressbar"
                                                aria-label="Basic example"
                                                aria-valuenow="25"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                style={{
                                                  width: `${
                                                    item?.progressBarPercentage
                                                      ? item?.progressBarPercentage
                                                      : "0%"
                                                  }`,
                                                }}
                                              ></div>
                                            </div>
                                          </div>
                                          <div className="col-12 mt-auto">
                                            <div className="row d-flex align-items-center justify-content-start">
                                              <div
                                                className="col-auto"
                                                style={{
                                                  marginRight: "-5px",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  className="card-data m-0 d-flex align-items-center"
                                                  // onClick={() => {
                                                  //   handleClickRecomend(
                                                  //     item
                                                  //   );
                                                  // }}
                                                >
                                                  Learn
                                                </p>
                                              </div>
                                              <div
                                                className="col-auto p-0 d-flex align-items-center justify-content-center rounded-pill"
                                                // style={{
                                                //   width: "16px",
                                                //   height: "16px",
                                                //   backgroundColor: "#4E45C2",
                                                // }}
                                              >
                                                <img
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  alt=""
                                                  className="learn_arrowIcon"
                                                  src={learn_button}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </Carousel>
                    </div>
                  </div>
                </div>

                <div className=" col-12 d-flex">
                  <div
                    className=" mb-3  border-0 align-items-start overflow-hidden"
                    style={{ postion: "relative" }}
                  >
                    <div className="row col-12 crousel_info">
                      <Carousel
                        // infinite={true}
                        responsive={responsive}
                        infinite={true}
                        ssr
                        partialVisbile
                        // deviceType={deviceType}
                        itemclassName="image-items"
                        className="data_second_scroll"
                      >
                        {/* {data?.recommended?.length > 0 &&
                        getRecommdedContent(data?.recommended)} */}

                        {data?.recommended
                          ?.slice(
                            data?.recommended?.length / 2,
                            data?.recommended?.length
                          )
                          .map((item, id) => {
                            return (
                              <>
                                <div className="">
                                  <div className=" overflow-hidden shadow-none border card_borderRadius mx-2 align-items-start ">
                                    <div className="row ms-auto height mx-auto overflow-hidden  ">
                                      <div className="col-6 height position-relative p-0">
                                        <img
                                          src={
                                            item?.thumbnail
                                              ? item.thumbnail
                                              : DefaultImg
                                          }
                                          className="width_add height"
                                          alt="..."
                                        />
                                        {item?.category?.category_name && (
                                          <button
                                            //  onClick={handleShow}
                                            className="btn Slides text-white d-flex align-items-center justify-content-center rounded-pill mt-2"
                                            style={{
                                              cursor: "pointer",
                                              lineHeight: "10px",
                                              textTransform: "capitalize",
                                              backgroundColor: `${item?.category?.category_color}`,
                                            }}
                                          >
                                            {item?.category?.category_name}
                                          </button>
                                        )}
                                      </div>
                                      <div className="col-6 custom_padding d-flex">
                                        <div className="row text-start">
                                          <div className="col-12 mb-auto  d-flex flex-column justify-content-center">
                                            <h6 className="card-data m-0">
                                              {item.title &&
                                                truncate(item.title, 20)}
                                            </h6>
                                          </div>
                                          {/* <div className="col-12">
                                            <p className="card-texts mb-2">
                                              Barbara Chiappetta
                                            </p>
                                          </div> */}
                                          <div className="col-12 mt-auto">
                                            <p className="card-texts-info mb-2">
                                              {item?.studyPageTimeTrack
                                                ? formatTime(
                                                    item?.studyPageTimeTrack
                                                  )
                                                : "00:00"}{" "}
                                              spent · Beginner
                                            </p>
                                          </div>
                                          {/* <div className="col-10 d-flex">
                                        <div className="col topic pe-1">
                                          <hr />
                                        </div>
                                        <div className="col topicc px-1">
                                          <hr />
                                        </div>
                                        <div className="col topicc px-1">
                                          <hr />
                                        </div>
                                        <div className="col topicc px-1">
                                          <hr />
                                        </div>
                                      </div> */}
                                          <div class=" topic">
                                            <div class="progress progress_parent">
                                              <div
                                                class="progress-bar progress_bar"
                                                role="progressbar"
                                                aria-label="Basic example"
                                                aria-valuenow="25"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                style={{
                                                  width: `${
                                                    item?.progressBarPercentage
                                                      ? item?.progressBarPercentage
                                                      : "0%"
                                                  }`,
                                                }}
                                              ></div>
                                            </div>
                                          </div>
                                          <div className="col-12 mt-auto">
                                            <div className="row d-flex align-items-center justify-content-start">
                                              <div
                                                className="col-auto"
                                                style={{
                                                  marginRight: "-5px",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  className="card-data m-0 d-flex align-items-center"
                                                  // onClick={() => {
                                                  //   handleClickRecomend(
                                                  //     item
                                                  //   );
                                                  // }}
                                                >
                                                  Learn
                                                </p>
                                              </div>
                                              <div
                                                className="col-auto p-0 d-flex align-items-center justify-content-center rounded-pill"
                                                // style={{
                                                //   width: "16px",
                                                //   height: "16px",
                                                //   backgroundColor: "#4E45C2",
                                                // }}
                                              >
                                                <img
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  alt=""
                                                  className="learn_arrowIcon"
                                                  src={learn_button}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </Carousel>
                      <div
                        onClick={() => {
                          setShow(false);
                        }}
                        className="row position-absolute imageHideSidebar"
                      >
                        <div className="col cp d-flex align-items-center justify-content-start close_side_bar_data">
                          <img
                            src={SideNoteLeft}
                            alt=""
                            className="study-image-next"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
}

export default OffCanvasExample;

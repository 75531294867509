import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Group,
  Search,
  Pancel,
  CloseSearch,
} from "../../../staticContent/image";
import "../study.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// notesList:
// message: "Successfully get Notes"
// response: [
import {
  getNotes,
  getSearchNotes,
  getSearchTranscipt,
  setBookMark,
} from "../../../store/actions/study";
import Skeleton from "react-loading-skeleton";
import NotesDialague from "../addnotesdialogue/addnotesdialogue";

import { Steps, Hints } from "intro.js-react";

import "intro.js/introjs.css";
export const CLEAR_SEARCHBAR = "CLEAR_SEARCHBAR";
const Notes = (props) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [renderData, setRenderData] = useState([]);
  const [renderTab, setTab] = useState("note");
  const [getTypeNotes, setTypeNotes] = useState("today");
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [loader, setLoader] = useState(false);
  const topicId = props.NotesId;
  const [showNotesDialauge, setShowNotesDialauge] = useState(false);
  const[wallkThrough,setWalkThrough] = useState(true)
  
  const [showNote, setNote] = useState({
    id: "",
    textNotes: "",
    updateAt: "",
    name: "",
  });
  const [isEdit, setEdit] = useState("view");
  const [searchInput, setSearchInput] = useState("");
  const [searchTranscript, setSearchTranscript] = useState("");
  const [getTypeScript, setTypeScript] = useState(store?.getPracticeTranscripts?.data?.response);
  const [showmode, setShowMode] = useState(false);
  const [showTranscript, setShowTranscript] = useState(true);
  const [transcriptdata, setTranscriptData] = useState(null);
  const [names, setNames] = useState("");

  const [stepsVideo,setStepsVideo] = useState({
    stepsEnabled: true,
    initialStep: 0,
    steps: [
      {
        element: ".notesGuide",
        intro: "You can select notes and transcript from here",
        position:"left",
      },
      {
        element: ".table_of_content_guide",
        intro: "table of contents",
        position:"top",
      },
     
    ], 
})

 
  useEffect(() => {
    // get side bar data api
    setNames(store.notesList?.name);
    setRenderData(store.notesList?.responseData);
  }, [store.notesList]);
  // notes search bar input starts
  const searchFilterInput = (e) => {
    let value = e.target.value;

    setSearchInput(value);
    if (value?.trim() === "") {
      setLoader(true);
      dispatch(getNotes(getTypeNotes, topicId)).then((res) => {
        setLoader(false);
      });
    } else {
      if (value?.length >= 1) {
        setLoader(true);
        dispatch(getSearchNotes(value?.trim(), topicId)).then((res) => {
          setLoader(false);
        });
      }
    }
  };
  // notes search bar input ends
  //  transcript searchbar function starts
  const searchTranscriptInput = (e) => {
    const userId = store.auth?.user?.id;
    const feild = store.getStudyTopic?.title._id;
    let value = e.target.value;
    setSearchTranscript(value);
    setLoader(true);
    if (value === "") {
      setShowTranscript(true);
      setLoader(false);
      setTranscriptData(store?.getPracticeTranscripts?.data?.response);
    } else if (value?.length > 0) {
      dispatch(getSearchTranscipt(feild, value)).then((data) => {
        if (data.status === 200) {
          setLoader(false);
          setShowTranscript(false);
          setTranscriptData(data.data.responseData);
          typeScript();
        }
      });
    }
  };
  // transcript searchbar function ends
  useEffect(() => {
    setLoader(true);
    // get notes data api
    dispatch(getNotes(getTypeNotes, topicId)).then((res) => {
      setLoader(false);
    });
  }, []);
  const switchNotesDialauge = (type) => {
    setTab(type);
  };
  const handleClick = (event) => {
    setShowMode(!showmode);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //  save notes function starts
  const handleNotes = (type) => {
    setTypeNotes(type);
    setLoader(true);
    setShowMode(!showmode);

    dispatch(getNotes(type, topicId)).then((res) => {
      setLoader(false);
    });
    setAnchorEl(null);
  };
  const handleBookMark = (id, type) => {
    setTypeScript(
      getTypeScript.map((item, index) =>
        item._id === id ? { ...item, bookmark: !type } : { ...item }
      )
    );
    dispatch(setBookMark(id, type ? false : true)).then((res) => {});
  };
  // bookmark function ends
  const editNotes = (id, text, updateAt, name) => {
    setEdit("view");
    setNote({ id: id, textNotes: text, updateAt: updateAt, name: name });
    setShowNotesDialauge(true);
  };
  const clickEdit = () => {
    setEdit("edit");
  };
  const handleTrigger = () => props?.setSideBar(true);
  // function used for slice the string value
  function truncate(str, n) {
    return str?.length > n ? str.slice(0, n - 1) + "...." : str;
  }
  function typeScript() {
    if (getTypeScript === undefined) {
      setTypeScript(store?.getPracticeTranscripts?.data?.response);
    }
    return getTypeScript?.map((item, index) => {
      let bookmark = "fa fa-bookmark-o";
      let bookmarkType = false;
      if (item?.bookmark === true) {
        bookmark = "fa fa-bookmark";
        bookmarkType = true;
      }
      let num = item?.timestamp;
      let div = Math.floor(num / 60);
      let result = num - div * 60;
      return (
        <div
          className="col-12 mt-4 transcriptDataHeading "
          style={{ cursor: "pointer" }}
        >
          <h6 className="m-0 mb-1  timestamp ">
            {" "}
            {Math.floor(item?.timestamp / 60).toFixed(0)}:{result.toFixed(0)}
            {/* {item?.topic?.timestamp} */}
            <i
              style={{ marginLeft: "10px" }}
              onClick={() => {
                handleBookMark(item?._id, bookmarkType);
              }}
              className={bookmark}
              setEdit
            ></i>
          </h6>
          <p
            onClick={() => {
              props.videoPlayerRef.current.seekTo(
                Math.floor(item?.timestamp),
                "seconds"
              );
              // props.onClick(true);
            }}
            className="m-0 timepara"
            style={{ cursor: "pointer" }}
          >
            {item?.transcript?.slice(0, 200)}
          </p>
          {/* <h1>vishal</h1> */}
        </div>
      );
    });
  }
  function Searchtranscript() {
    return transcriptdata?.map((item, index) => {
      let bookmark = "fa fa-bookmark-o";
      let bookmarkType = false;
      if (item?.bookmark === true) {
        bookmark = "fa fa-bookmark";
        bookmarkType = true;
      }
      let num = item?.timestamp;
      let div = Math.floor(num / 60);
      let result = num - div * 60;
      return (
        <div
          className="col-12 mt-4 transcriptDataHeading "
          style={{ cursor: "pointer" }}
        >
          <h6 className="m-0 mb-1 timestamp ">
            {" "}
            {/* {Math.floor(item?.topic?.timestamp / 60)} : {result} */}
            {Math.floor(item?.timestamp / 60)} : {result.toFixed(0)}
            <i
              style={{ marginLeft: "10px" }}
              onClick={() => {
                handleBookMark(item?._id, bookmarkType);
              }}
              className={bookmark}
            ></i>
          </h6>
          <p
            onClick={() => {
              props.videoPlayerRef.current.seekTo(
                Math.floor(item?.timestamp),
                "seconds"
              );
              props.onClick(true);
            }}
            className="m-0 timepara"
            style={{ cursor: "pointer" }}
          >
            {item?.transcript}
          </p>
        </div>
      );
    });
  }
  return (
    <>
   
      <div className={`row mx-auto `} style={{ paddingTop: "2.5rem" }}>
        <NotesDialague
          show={showNotesDialauge}
          setShow={setShowNotesDialauge}
          NotesId={showNote}
          type={isEdit}
          editAction={clickEdit}
        />
        <div className="col-12 my-4">
          {renderTab === "note" ? (
            <div className="border get d-flex justify-content-around py-1 overflow-hidden align-items-center">
              <img src={Search} alt={""} className="sidebarnotesSearch ms-2 " />
              <input
                value={searchInput}
                onChange={(e) => {
                  searchFilterInput(e);
                }}
                className="form-control search me-2 border-white shadow-none"
                type=""
                placeholder="Search by keywords..."
                aria-label="Search"
              />
              {searchInput && (
                <img
                  onClick={() => {
                    setSearchInput("");
                    dispatch(clearSearchbar());
                  }}
                  src={CloseSearch}
                  className="closeImgInfo me-2"
                  alt={""}
                />
              )}
            </div>
          ) : (
            <div className="border get d-flex justify-content-around py-1 overflow-hidden align-items-center">
              <img src={Search} alt={""} className="sidebarnotesSearch ms-2 " />
              <input
                value={searchTranscript}
                onChange={(e) => {
                  searchTranscriptInput(e);
                }}
                className="form-control search me-2 border-white shadow-none"
                type=""
                placeholder="Search by keywords..."
                aria-label="Search"
              />
            </div>
          )}
        </div>
      </div>
      <div className="notesGuide">
      <div className="row border-top border-bottom ">
        <div className="col-12">
          <div className="row  mx-auto">
            <div className="col-9  notes d-flex justify-content-start align-items-end position-relative">
              <h6
                onClick={() => switchNotesDialauge("note")}
                className={
                  "m-0 p-0 me-3 sidebarNotesNote sidebarBottomColor py-3"
                }
                style={
                  renderTab === "note"
                    ? props.showType === "video"
                      ? {}
                      : {}
                    : { borderBottomColor: "#FFFFFF", color: "#737373" }
                }
              >
                Notes
              </h6>

              {renderTab === "note" && <div className="border-div"></div>}

              {/* <div className="position-absolute notesBottomColor"></div> */}
              {props.showType === "video" && (
                <>
                  <h6
                    onClick={() => switchNotesDialauge("type")}
                    className={
                      "m-0 p-0 ms-3 sidebarNotesNote sidebarBottomColor py-3"
                    }
                    style={
                      renderTab === "type"
                        ? {}
                        : { borderBottomColor: "#FFFFFF", color: "#737373" }
                    }
                  >
                    Transcript
                  </h6>
                  {renderTab === "type" && <div className="border-divv"></div>}
                </>
              )}
            </div>
            <div className="col-3  notes d-flex justify-content-end align-items-center ">
              {renderTab === "note" && (
                <div className="sideNotesImageWalk">
                  <img
                    src={Pancel}
                    onClick={() => props.openAddNotesDialauge(true)}
                    className="fa fa-pencil-square-o rounded  text-white"
                    aria-hidden="true"
                    alt={""}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row pb-4  border-bottom">
        {renderTab === "type" && (
          <div className="col-12 transcriptStudyNotes">
            {loader && <Skeleton height={`50vh`} count={1} />}

            {showTranscript ? (
              <>
              {store?.getPracticeTranscripts?.data?.response.length>1 && <div className="row mx-auto ">{!loader && typeScript() }</div>}
              </>
            ) : (
              <div className="row mx-auto">{!loader && Searchtranscript()}</div>
            )}
          </div>
        )}
        {renderTab === "note" && (
          <div className="col-12 col-md-12 position-relative">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item border-white mb-3">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button bg-white text-dark shadow-none ps-0 ps-ms-5 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne2"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    <div className="col-12">
                      <div className="row d-flex align-items-center justify-content-center ">
                        <div className="col d-flex align-items-center justify-content-end ">
                          <div className="row">
                            <div className="col-auto topic-text d-flex justify-content-end align-items-center ">
                              <div className="col-12 position-relative d-flex align-items-center justify-content-end">
                                <p className="m-0 addnotesTextAll">
                                  {getTypeNotes}
                                </p>
                                <i
                                  onClick={handleClick}
                                  className="fa fa-angle-down ms-2 d-flex align-items-center justify-content-center"
                                  aria-hidden="true"
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </div>
                              {showmode && (
                                <div className="col justify-content-end d-flex">
                                  <div className="row mx-auto mt-3 studyGenerate bg-white position-absolute border overflow-hidden d-flex align-items-center justify-content-end flex-column">
                                    <div
                                      className="col-12 sideBarNotesMenuWeeklyData"
                                      onClick={() => handleNotes("today")}
                                    >
                                      <p className="m-0 py-2 px-1 p-0 addnotesTextAll">
                                        Today
                                      </p>
                                    </div>

                                    <div
                                      className="col-12 sideBarNotesMenuWeeklyData"
                                      onClick={() => handleNotes("weekly")}
                                    >
                                      <p className="m-0 py-2 px-1 p-0 addnotesTextAll">
                                        Weekly
                                      </p>
                                    </div>
                                    <div
                                      className="col-12 sideBarNotesMenuWeeklyData"
                                      onClick={() => handleNotes("all")}
                                    >
                                      <p className="m-0 py-2 px-1 p-0 addnotesTextAll">
                                        All
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                </h2>
                <div className="col-12 study-program">
                  {loader && <Skeleton height={`50vh`} count={1} />}
                  {!loader &&
                    renderData?.map((item, index) => {
                      let result = item?.notes.replace(/[\n\r]+/g, " ");
                      return (
                        <div>
                          <div className="row mx-auto ">
                            <div className="col-12">
                              <div className="row bg-light py-2 mb-3  rounded-3 mx-auto">
                                <div
                                  onClick={() => {
                                    editNotes(
                                      item._id,
                                      item?.notes,
                                      item?.updatedAt,
                                      store.notesList?.name
                                    );
                                  }}
                                  className="col-12 vision"
                                >
                                  <p
                                    className="m-0"
                                    dangerouslySetInnerHTML={{
                                      __html: truncate(result, 150),
                                    }}
                                  ></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      </div>
      <div onClick={handleTrigger} className="row mx-auto my-3">
        <div className="col-12  ">
          <img src={Group} alt="" className="notesSideWAlk " />
        </div>
      </div>
    </>
  );
};
export default Notes;
export const clearSearchbar = (data) => (dispatch) => {
  dispatch({
    type: CLEAR_SEARCHBAR,
    payload: data,
  });
};

import React, { useState, useEffect, useRef } from "react";
import {
  booksImg,
  threeGroupImg,
  dotxMaterial,
  UploadFileLibrarImg,
  IIcon,
  Pancel,
  flagGoal,
  roundGoal,
  learn_button,
  threeDotsHorizontal,
  AddNotesClose,
} from "../../../staticContent/image";
import "./TaskAddContent.css";
import Carousel from "react-multi-carousel";
import { useDispatch, useSelector } from "react-redux";
import SidebarCanvas from "../../Librarypage/Overview/CanvasSidebar/sidebarCanvas";
import { getTopics } from "../../../store/actions/study";
import TimeInput from "react-input-time";

import {
  getTaskDetails,
  getContentFromLibrary,
  addContentLibrary,
  trackTaskTime,
  deleteTask,
  addTime,
  updateDescription,
} from "../../../store/actions/studyBuddy";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router-dom";
import { addContentFile } from "../../../store/actions/libraryOverview";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import NotesDialague from "../../Study/addnotesdialogue/addnotesdialogue";

function TaskAddContent() {
  const location = useLocation();
  const moment = extendMoment(originalMoment);
  const store = useSelector((state) => state);
  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editVal, setEditVal] = useState("");
  const [showNote, setNote] = useState({
    id: "",
    textNotes: "",
    updateAt: "",
    name: "",
  });

  const handleClick = () => {
    setShow(!show);
  };
  const [notesLoader, setNotesLoader] = useState(true);
  const [openModel, setOpenModel] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showSchool, setShowSchool] = useState(false);
  const [showNotesDialauge, setShowNotesDialauge] = useState(false);
  const [showAddNotesDialauge, setShowAddNotesDialauge] = useState(false);
  const [selectOption, setSelectOption] = useState(
    "Select content from library"
  );
  const [sideLoader, setSideLoader] = useState(false);
  const [sidebarData, setSidebarData] = useState({});

  const [data, setData] = useState();
  const [loadertip, setLoaderTip] = useState(false);
  const [optionData, setOptionData] = useState([
    { title: "UML Use Case Diagram Tutorial" },
    { title: "Introduction to  Neural Networks" },
    { title: "Chemistry 121 Lecture 2" },
  ]);
  const [notesType, setNotesType] = useState("view");
  const [notes, setNotes] = useState([]);
  const [relatedTask, setRelatedTask] = useState([]);
  const [relatedMaterials, setRelatedMaterials] = useState([]);
  const [downloadMatetrial, setDownloadMaterial] = useState([]);
  const [libraryContent, setLibraryContent] = useState([]);
  const [addContentid, setAddContent] = useState("");
  const [filesData, setFilesData] = useState([]);
  const [files, setFiles] = useState([]);
  const [renderr, setRenderr] = useState(false);
  const [time, setTime] = useState({ totalTime: 0, newTime: "" });
  const [browseLoader, setBrowseLoader] = useState(false);
  const [milli, setMilli] = useState("");
  const [TaskId, setTaskId] = useState("");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [filearr, setFileArr] = useState([]);
  const [AddContentLoader, setAddContentLoader] = useState(false);
  const startTime = useRef();
  const endTime = useRef();
  const divRef = useRef(null);
  const task_id = localStorage.getItem("taskDetailid");
  useEffect(() => {
    // let id = location?.state?.id;
    // let id = store?.addTask?.data?.responseData?._id;
    let id = localStorage.getItem("taskDetailid");
    const d = new Date();
    startTime.current = d.getTime();
    return () => {
      const dx = new Date();
      endTime.current = dx.getTime();

      const body = {
        taskId: id,
        timeTrack: endTime.current - startTime.current,
      };
      dispatch(trackTaskTime(body));
    };
  }, []);

  useEffect(() => {
    // let id = location?.state?.id;
    let id = localStorage.getItem("taskDetailid");
    let body = { taskId: id, fileId: filearr };
    // let id = store?.addTask?.data?.responseData?._id;
    setLoader(true);
    dispatch(getTaskDetails(body)).then((res) => {
      if (res.status === 200) {
        setData(res?.data?.responseData);
        setTime({ ...time, totalTime: res?.data?.responseData?.time });
        setRelatedTask(res?.data?.taskTopics);
        setRelatedMaterials(res?.data?.taskRelated);
        setNotes(res?.data?.taskNotes);
        setDownloadMaterial(res?.data?.taskMaterial);
        setLoader(false);
        setNotesLoader(false);
        setEditVal(res?.data?.responseData?.description);
      } else {
        setLoader(false);
        setNotesLoader(false);
        alert("warning 404 please check server response");
      }
    });
  }, [renderr, store.notesList]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      paritialVisibilityGutter: 60,
    },
  };
  const handleSlice = (text) => {
    if (text.length > 20) {
      return text.slice(0, 20);
    } else {
      return text;
    }
  };
  const handleDelete = () => {
    let id = localStorage.getItem("taskDetailid");
    dispatch(deleteTask(id)).then((res) => {
      if (res.status === 200) {
        history.push("/organize");
      }
    });
  };
  const hanldeBrowseLibrary = () => {
    dispatch(getContentFromLibrary()).then((res) => {
      setLibraryContent(res?.data?.responseData);
    });
  };
  const handleAddContent = () => {
    setFileArr([...filearr, addContentid]);
    setAddContentLoader(true);
    // let taskid = store?.addTask?.data?.responseData?._id;
    dispatch(addContentLibrary(addContentid, task_id)).then((res) => {
      document.getElementById("modal_close").click();
      setAddContentLoader(false);
      setRenderr(!renderr);
    });
    setShow(false);
  };

  const handleDrag = () => {
    if (filesData.length > 0) {
      setBrowseLoader(true);
      const userId = store.auth?.user?.id;
      const body = new FormData();
      
      // let taskid = store?.addTask?.data?.responseData?._id;
      body.append(`file`, filesData[0]);
      body.append("user_id", userId);
      body.append("taskId", task_id);
      dispatch(addContentFile(body)).then((data) => {
        setBrowseLoader(false);
        if (data) {
        } else {
          editPage();
        }
      });
      setFilesData([]);
      setShow(false);
    }
  };
  const showError = (data) => {};
  const editPage = () => {
    document.getElementById("addContentCloseModalBtn").click();
    history.push({
      pathname: "/library/overview/addcontent",
      state: {
        tab: "profile",
        redirection: "task",
      },
    });
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      "video/MP4": [],
      "video/MP3": [],
      "video/MOV": [],
      "video/MKV": [],
      "application/pdf": [],
      "text/csv": [],
    },
    onDrop: (acceptFiles) => {
      setFiles(
        acceptFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      );
      //  if(acceptFiles?.length >1){
      //   setFilesData(...filesData, acceptFiles);
      //  }else{
      setFilesData(acceptFiles);
      //}
    },
  });
  function truncate(str, n) {
    return str?.length > n ? str.slice(0, n - 1) + "..." : str;
  }
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div>
      {errors.map((e) => (
        <p key={e.code}>{e.message}</p>
      ))}
    </div>
  ));
  const handleBack = () => {
    history.push("/organize/tasks");
  };
  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    setTime({ ...time, [name]: value });
  };
  const handleSave = () => {
    setLoaderTip(true);
    let valuestart = time.totalTime;
    let valuestop = time.newTime;

    // setLoaderTip(true);
    // let valuestart = time.totalTime;
    // let valuestop = time.newTime;

    // let valuestart = parseInt(time.totalTime);
    // let valuestop =time.newTime
    let newTime = valuestop.split(":");
    let one = newTime[0] * 3600000;
    let second = newTime[1] * 60000;
    let finalTime = one + second;
    let result = finalTime + parseInt(valuestart);

    let id = localStorage.getItem("taskDetailid");
    let body = {
      taskId: id,
      time: result,
    };
    dispatch(addTime(body)).then((res) => {
      setLoaderTip(false);
      if (res.status == 200) {
        document.getElementById("close2").click();
        setTime({ ...time, newTime: "" });
        setRenderr(!renderr);
        setLoader(true);
      }
    });
  };
  const clickEdit = () => {
    setNotesType("edit");
  };
  const editNotesButton = (e) => {
    setIsEdit(true);
  };

  // const [tooltip, setToolTip] = useState(false);
  function msToTime(duration) {
    var milliseconds = Math.floor((duration % 1000) / 100),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return hours + ":" + minutes;
  }

  function calculateTime() {
    let valuestart = parseInt(time.totalTime);
    let valuestop = time.newTime;
    let newTime = valuestop.split(":");
    let one = newTime[0] * 3600000;
    let second = newTime[1] * 60000;
    let finalTime = one + second;
    let result = finalTime + valuestart;
    if (valuestop === "") {
      return valuestart;
    } else {
      return result;
    }
  }
  const handleNotes = () => {
    setShowAddNotesDialauge(true);
    setTaskId(localStorage.getItem("taskDetailid"));
    setNote({ ...showNote, id: localStorage.getItem("taskDetailid") });
  };
  const handleEditNotes = (ele) => {
    setShowNotesDialauge(true);
    setNotesType("edit");
    setTaskId(ele.taskId);
  };
  const editNotes = (id, text, updateAt, name) => {
    setNotesType("view");
    setNote({ id: id, textNotes: text, updateAt: updateAt, name: name });
    setShowNotesDialauge(true);
  };
  const formatTime = (milliseconds) => {
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor(milliseconds / 1000 / 60 / 60);

    return [
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
    ].join(":");
  };

  let tags_topics = data?.tags_topics;
  let tagsArr = tags_topics?.split(",");

  let resTags = tagsArr?.filter((ele, ind) => {
    return ele != "";
  });

  const noteLoader = (value) => {
    setNotesLoader(true);
  };
  const handleClickOnDownloadMaterials = (item) => {
    setSideLoader(true);

    setOpenSidebar(true);
    dispatch(getTopics(item._id)).then((res) => {
      setSidebarData(res);
      setSideLoader(false);
    });
  };

  const handleDescription = (e) => {
    setEditVal(e.target.value);
  };

  const saveDescriptionButton = () => {
    let id = localStorage.getItem("taskDetailid");
    let body = {
      taskId: id,
      description: editVal,
    };
    dispatch(updateDescription(body));
    setIsEdit(false);
  };
  const handledescClose = () => {
    setEditVal(store?.getTaskDetails?.data?.responseData?.description);
    setIsEdit(false);
  };

  return (
    <div>
      {loader ? (
        <Skeleton height={`3vh`} count={50} />
      ) : (
        <div className="row mx-auto">
          <div className="col-12">
            <div class="row mx-auto">
              <div class="col-12 mt-5">
                <p
                  class="add_new_task_para m-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleBack();
                  }}
                >
                  <i class="fa fa-angle-left me-2" aria-hidden="true"></i> Back
                  to Tasks
                </p>
              </div>
            </div>
            <div class="row mx-auto my-4">
              <div class="col-6 goal_width">
                <div class="row bg-white mx-auto form_main">
                  <div className="col-12 border-bottom">
                    <div class="row mx-auto py-4 ">
                      <div className="col-12">
                        <div className="row">
                          <div class="col-6 d-flex align-items-center justify-content-start">
                            <img
                              className="add_task_book_img me-3"
                              src={booksImg}
                              alt=""
                            />
                            <p class="m-0 add_task_para">
                              {data?.taskType?.task_type}
                            </p>
                          </div>
                          <div className="col-6 position-relative d-flex align-items-center justify-content-end mb-2">
                            <img
                              src={dotxMaterial}
                              alt={""}
                              onClick={handleClick}
                              className=" d-flex align-items-center justify-content-center study-option"
                              aria-hidden="true"
                              style={{ cursor: "pointer" }}
                            />
                            {show && (
                              <div className="col-auto position-absolute fix_data_add_material">
                                <div className="row  px-2 mx-auto studyGenerate bg-white  border py-2 d-flex align-items-center justify-content-end flex-column materials_shadow">
                                  <div className="col-12 summaryStudy">
                                    <button
                                      type="button"
                                      class="btn m-0 p-0 studyGenerateFlashcards add_material_dropdwn py-1 shadow-none text-capitalize border-0"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                    >
                                      Add Materials
                                    </button>
                                  </div>

                                  <div
                                    className="col-12 summaryStudy"
                                    onClick={() => {
                                      handleDelete();
                                    }}
                                  >
                                    <button
                                      type="button"
                                      class="btn m-0 p-0 studyGenerateFlashcards add_material_dropdwn py-1 shadow-none text-capitalize border-0"
                                      // data-bs-toggle="modal"
                                      // data-bs-target="#exampleModal"
                                    >
                                      Delete task
                                    </button>
                                  </div>
                                  <div className="col-12 summaryStudy">
                                    <button
                                      type="button"
                                      class="btn m-0 p-0 studyGenerateFlashcards add_material_dropdwn  py-1 shadow-none text-capitalize border-0"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal1"
                                    >
                                      Add time
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div class="col-12 mt-2">
                        <h6 class="m-0 activity_text_para_text">
                          {data?.title}
                        </h6>
                      </div>

                      <div class="col-12 mt-2 ">
                        <div class="row d-flex ">
                          <div className="col-auto pe-0">
                            <button
                              type="button"
                              class="  bg-white remove_border_color btn_radius_dec border text-uppercase py-1 px-2 shadow-none task_add_btn"
                            >
                              #{data?.course}
                            </button>
                          </div>
                          <p className="col px-0 ">
                            {resTags?.map((ele, ind) => {
                              return (
                                <button
                                  type="button"
                                  class="border-0 py-1 px-2 ms-2 btn_radius_dec shadow-none task_add_btn text-capitalize practice_btn_diff_color"
                                >
                                  {ele}
                                </button>
                              );
                            })}
                          </p>
                        </div>
                      </div>

                      <div class="col-12 mt-1 d-flex align-items-center">
                        <button
                          type="button"
                          class="border-0 bg-white p-1 shadow-none task_add_btn  total_time_trake d-flex"
                        >
                          <img
                            className="me-2 width height"
                            src={flagGoal}
                            alt=""
                          />

                          {moment(data?.dueDate).format("L")}
                        </button>
                        <button
                          type="button"
                          class="border-0 bg-white ms-2 p-1 shadow-none task_add_btn  total_time_trake d-flex "
                        >
                          <img
                            className="me-2 width height"
                            src={roundGoal}
                            alt=""
                          />
                          {data?.time ? formatTime(data?.time) : "00:00"}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 border-bottom">
                    <div class="row mx-auto py-4 ">
                      <div class="col-12">
                        <h6 class="m-0 activity_text_para">Description</h6>
                        <p
                          class="mt-3 add_task_long_para add_scrol_description"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal4"
                        >
                          {editVal}
                        </p>
                      </div>
                      <div class="col-12 mt-1">
                        <h6 class="m-0 activity_text_para">
                          Downloaded materials
                        </h6>
                      </div>
                      <div class="col-12 d-flex">
                        {downloadMatetrial?.map((item, ind) => {
                          return (
                            <div
                              className="three_releated_img my-3 m-1"
                              onClick={() => {
                                handleClickOnDownloadMaterials(item);
                              }}
                            >
                              <img
                                className="three_releated_img w-100  "
                                src={item?.thumbnail}
                                alt=""
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                      {downloadMatetrial.map((item, ind) => {
                        return (
                          <div class="col-12 mt-1">
                            <p class="m-0 text-dark task_add_law">
                              <i
                                class="fa fa-file-o me-2"
                                aria-hidden="true"
                              ></i>
                              {item.title}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-12">
                    <div class="row mx-auto py-4">
                      <div class="col-12 mb-4">
                        <div class="row">
                          <div class="col d-flex align-items-center justify-content-start">
                            <h6 class="m-0 activity_text_para">
                              Related Materials
                            </h6>
                          </div>
                          <div class="col d-flex justify-content-end align-items-center">
                            <i
                              onClick={() => {
                                divRef.current.scrollLeft -= 200;
                              }}
                              class="fa fa-angle-left me-3 cp"
                              aria-hidden="true"
                            ></i>
                            <i
                              onClick={() => {
                                divRef.current.scrollLeft += 200;
                              }}
                              class="fa fa-angle-right cp"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div
                          ref={(ref) => (divRef.current = ref)}
                          className="row flex-nowrap overflow-auto"
                        >
                          {relatedMaterials.map((item, ind) => {
                            const formatTime = (milliseconds) => {
                              const minutes = Math.floor(
                                (milliseconds / 1000 / 60) % 60
                              );
                              const hours = Math.floor(
                                milliseconds / 1000 / 60 / 60
                              );

                              return [
                                hours.toString().padStart(2, "0"),
                                minutes.toString().padStart(2, "0"),
                              ].join(":");
                            };
                            return (
                              <div className="col-6">
                                <div className="row mx-auto">
                                  <div className="col-12">
                                    <div className="row border overflow-hidden mb-1">
                                      <div className="col-6 position-relative">
                                        <div className="row">
                                          <div className="col-12 p-0">
                                            <img
                                              src={item.thumbnail}
                                              className=" secondLakhni"
                                              alt="..."
                                            />
                                          </div>
                                          <div className="col-12 p-0 ps-2 position-absolute bottom-0 mb-1">
                                            {item?.category?.category_name && (
                                              <button
                                                className="btn text-white videoDoc_btn text-capitalize shadow-none rounded-pill  d-flex align-items-center justify-content-center"
                                                style={{
                                                  cursor: "pointer",
                                                  backgroundColor: `${item?.category?.category_color}`,
                                                }}
                                              >
                                                {item?.category?.category_name}
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-6 custom_padding d-flex">
                                        <div className="row ">
                                          <div className="col-12 mb-auto  d-flex flex-column justify-content-center">
                                            <h6 className="card-data  m-0 d-flex align-items-start">
                                              {item.title &&
                                                handleSlice(item.title)}
                                            </h6>
                                          </div>

                                          <div className="col-12 mt-auto">
                                            <p className="card-texts mb-0 text-nowrap">
                                              {item.studyPageTimeTrack
                                                ? formatTime(
                                                    item.studyPageTimeTrack
                                                  )
                                                : "0:00"}{" "}
                                              spent · Beginner
                                            </p>
                                            {/* <div className="sm_progressBar d-flex mt-2">
                                              <div className="col topic pe-1">
                                                <hr />
                                              </div>
                                              <div className="col topicc px-1">
                                                <hr />
                                              </div>
                                              <div className="col topicc px-1">
                                                <hr />
                                              </div>
                                              <div className="col topicc px-1">
                                                <hr />
                                              </div>
                                            </div> */}
                                            <div class=" mt-2 topic">
                                              <div class="progress progress_parent">
                                                <div
                                                  class="progress-bar progress_bar"
                                                  role="progressbar"
                                                  aria-label="Basic example"
                                                  aria-valuenow="25"
                                                  aria-valuemin="0"
                                                  aria-valuemax="100"
                                                  style={{
                                                    width: `${
                                                      item?.progressBarPercentage
                                                        ? item?.progressBarPercentage
                                                        : "0%"
                                                    }`,
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="col-12 mt-auto"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            <div
                                              className="row d-flex align-items-center justify-content-start"
                                              onClick={() => {
                                                handleClickOnDownloadMaterials(
                                                  item
                                                );
                                              }}
                                            >
                                              <div
                                                className="col-auto"
                                                style={{ marginRight: "-5px" }}
                                              >
                                                <p
                                                  style={{ cursor: "pointer" }}
                                                  className="card-data m-0 d-flex align-items-center"
                                                >
                                                  Learn
                                                </p>
                                              </div>
                                              <div
                                                className="col-auto p-0 d-flex align-items-center justify-content-center rounded-pill"
                                                // style={{
                                                //   width: "16px",
                                                //   height: "16px",
                                                //   backgroundColor: "#4E45C2",
                                                // }}
                                              >
                                                <img
                                                  style={{ cursor: "pointer" }}
                                                  alt=""
                                                  className="learn_arrowIcon"
                                                  src={learn_button}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-4 ms-3 notes_width">
                <div class="row py-4 bg-white form_main">
                  <div class="col-12 ">
                    <div class="row mx-auto ">
                      <div class="col d-flex align-items-center justify-content-start">
                        <h6 class="m-0 activity_text_para_text activity_text_weight">
                          Related Topics
                        </h6>
                      </div>
                      <div class="col d-flex justify-content-end align-items-center">
                        <i class="fa fa-angle-left me-3" aria-hidden="true"></i>
                        <i
                          class="fa fa-angle-right me-2"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="row mx-auto ">
                      <div class="col-12">
                        <div class="row border mx-auto related_task_height">
                          {relatedTask?.map((item, ind) => {
                            return (
                              <div class="col-12  border-bottom p-0 px-3 related_task_box_height d-flex justify-content-between align-items-center">
                                <p class="m-0 task_add_law">
                                  {item?.topicId?.topic}
                                </p>
                                <i
                                  class="fa fa-angle-right"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-4 my-4 bg-white form_main">
                  <div class="col-12 notes pt-2  border-bottom">
                    <div className="row mx-auto">
                      <div className="col d-flex align-items-center justify-content-start">
                        <h6 class="m-0 p-0 me-3 organize_notes  py-3">Notes</h6>
                      </div>
                      <div className="col d-flex align-items-center justify-content-end">
                        <img
                          onClick={handleNotes}
                          className="cp"
                          src={Pancel}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mx-auto">
                    <div className="col-12">
                      {notesLoader ? (
                        <Skeleton height={`2vh`} count={10} />
                      ) : (
                        <div className="row mx-auto">
                          {notes?.map((ele, ind) => {
                            return (
                              <div class="col-12 large vision justify-content-between mt-3 bg-light align-items-baseline  border border-0">
                                <div className="row py-3">
                                  <div className="col-10">
                                    <p class="m-0">
                                      {truncate(ele?.notes, 150)}
                                    </p>
                                  </div>
                                  <div className="col-2 d-flex align-items-baseline justify-content-center">
                                    <img
                                      onClick={() => {
                                        editNotes(
                                          ele._id,
                                          ele?.notes,
                                          ele?.updatedAt,
                                          store.notesList?.name
                                        );
                                      }}
                                      className=" notes_three_dots"
                                      src={threeDotsHorizontal}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Modal --> */}
          <div
            class="modal fade modal_bg_color"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content modal_content_width_height">
                <div class="modal-header px-4 activity_heading">
                  <h4 class="modal-title " id="exampleModalLabel">
                    Add Material
                  </h4>
                  <button
                    id="addContentCloseModalBtn"
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class=" px-4">
                  <div className="col-12 mb-3 mt-4 p-0">
                    <h5 className="profiles wrapperss activity_text_weight d-flex align-items-center m-0">
                      Drag & Drop
                      <img
                        // onMouseOver={() => {
                        //   setToolTip(true);
                        // }}
                        // onMouseOut={() => {
                        //   setToolTip(false);
                        // }}
                        src={IIcon}
                        alt={""}
                        className="ms-2"
                      />
                      {/* {tooltip && (
                      <div className="tooltipss">
                        Lorem ipsum dolor,consectetur adipiscing elit.adipiscing
                        elit.
                      </div>
                    )} */}
                    </h5>
                  </div>
                  <div className="col-12">
                    <div
                      className="row mx-auto img_upload_height drag p-5 justify-content-center"
                      {...getRootProps()}
                    >
                      <div className="col-auto my-3">
                        {/* <i className="fa fa-download" aria-hidden="true"></i> */}
                        <img src={UploadFileLibrarImg} alt="" />
                      </div>
                      <div className="col-auto my-3 d-flex justify-content-center flex-column">
                        <p className="m-0">
                          Drag your documents,
                          <br />
                          video, files here to start uploading
                        </p>
                        <small
                          className="text-danger"
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          Only pdf & videos are allowed
                        </small>
                        {!browseLoader ? (
                          <div className="image-upload mt-2">
                            <label for="">
                              <p
                                className=" shadow-none text-white content-browse m-0"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                Browse Files
                              </p>
                            </label>
                            <input
                              id="file-input"
                              {...getInputProps()}
                              type="file"
                            />
                          </div>
                        ) : (
                          <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        )}

                        {fileRejectionItems}
                        {filesData?.length > 0 &&
                          filesData?.length + " file selected"}
                        {/*  */}
                      </div>
                    </div>
                  </div>
                </div>
                {filesData.length > 0 && handleDrag()}
                <div class="modal-footer border-0 pt-0 px-4">
                  <div className="col-12 d-flex my-4 align-items-center justify-content-center">
                    <h5 className="m-0 p-0 profiles activity_text_weight">
                      OR
                    </h5>
                  </div>
                  <div className="col-12 my-1 m-0">
                    {/* <button type="button" class="btn btn-primary"></button> */}
                    <div class=" border-0">
                      {/* <button type="button" class="web_button"></button> */}
                      <button
                        type="button"
                        class=" m-0 p-0 web_button   px-2 py-1 shadow-none text_change_add_page border-0 btn_height"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal2"
                        onClick={hanldeBrowseLibrary}
                      >
                        Browse from Library
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Modal task time */}
          <div
            class="modal fade modal_bg_color"
            id="exampleModal1"
            aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel"
            tabindex="-1"
          >
            <div class="modal-dialog modal-dialog-centered modal_width_tracker">
              <div class="modal-content">
                <div class="modal-header  px-4 activity_heading">
                  <h4 class="modal-title" id="exampleModalToggleLabel">
                    Task Time
                  </h4>
                  <button
                    id="close2"
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body  pb-0 px-4">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col">
                          <div class="mb-3">
                            <label
                              for="exampleInputPassword1"
                              class="form-label"
                            >
                              <b className="label_txt">Total Time</b>
                            </label>

                            <input
                              value={formatTime(time?.totalTime)}
                              disabled={true}
                              name="totalTime"
                              type="text"
                              class="form-control shadow-none enter py-2 height_dec without_ampm"
                              id="exampleInputPassword1"
                              placeholder={time?.totalTime}
                              // onChange={(e) => {
                              //   handleTimeChange(e);
                              // }}
                            />
                          </div>
                        </div>
                        <div class="col">
                          <div class="mb-3">
                            <label
                              for="exampleInputPassword1"
                              class="form-label"
                            >
                              <b className="label_txt">New Time</b>
                            </label>
                            {/* <input
                              name="newTime"
                              value={time.newTime}
                              onChange={(e) => {
                                handleTimeChange(e);
                              }}
                              type="time"
                              min='1:30' max='2:30'
                              class="form-control shadow-none enter py-2 without_ampm"
                              id="exampleInputPassword1"
                              placeholder="02:24AM"
                            /> */}
                            <TimeInput
                              name="newTime"
                              className="input-time form-control shadow-none enter py-2"
                              initialTime="00:00"
                              onChange={(event) => {
                                handleTimeChange(event);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <h6 className="m-0 p-0 activity_text_para add_new_task_form_weight">
                        Updated Total:
                        <span className="activity_time_goal">
                          {" "}
                          {formatTime(calculateTime())}
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
                {!loadertip ? (
                  <div class="modal-footer  py-4  px-4 border-0 d-flex align-items-center justify-content-start">
                    <button
                      class="border btn btn-light py-2 px-4  mx-0 shadow-none offcan-study text-capitalize add_task_button"
                      // data-bs-target="#exampleModalToggle2"
                      // data-bs-toggle="modal"
                      // data-bs-dismiss="modal"
                      onClick={() => {
                        handleSave();
                      }}
                    >
                      Save Changes
                    </button>
                  </div>
                ) : (
                  <div className="modal-footer  py-4  px-4 border-0 d-flex align-items-center justify-content-start">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* add library Modal */}
          <div
            class="modal fade modal_bg_color"
            id="exampleModal2"
            aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel"
            tabindex="-1"
          >
            <div class="modal-dialog modal-dialog-centered modal_width_tracker">
              <div class="modal-content">
                <div class="modal-header  px-4 activity_heading">
                  <h4 class="modal-title" id="exampleModalToggleLabel">
                    Add Content
                  </h4>
                  <button
                    id="modal_close"
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body px-4">
                  <div className="row">
                    <div class="col-12">
                      <div className="row">
                        <div className="col-12">
                          <div className="row mx-auto position-relative">
                            <div className="col-12 p-0">
                              <label for="email" className="mb-2 mt-1">
                                <b className="label_txt time_trake_heading">
                                  Select content from library
                                </b>
                              </label>
                            </div>
                            <div className="col-12 mb-9">
                              <div className="row">
                                <div
                                  onClick={() => {
                                    setOpenModel(!openModel);
                                  }}
                                >
                                  <div
                                    className={
                                      showSchool
                                        ? "row  profile-borderwithselection popup_drop_height enter school p-2"
                                        : "row  profile-borderr  school popup_drop_height enter p-2"
                                    }
                                  >
                                    <div className="col ps-2 pe-0 flex-1 d-flex align-items-center">
                                      <input
                                        disabled
                                        type="search"
                                        placeholder="Select content from library"
                                        className={
                                          "border-0  flex-grow-1 outline-none bg-transparent placholder_color_font"
                                        }
                                        value={selectOption}
                                      />
                                    </div>
                                    <div className="col-auto pe-2 d-flex align-items-center drop-icon justify-content-end">
                                      <i
                                        aria-hidden="true"
                                        className={
                                          openModel
                                            ? "fa fa-angle-up"
                                            : "fa fa-angle-down"
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>

                                {openModel && (
                                  <div className="col-12 border popup_drpdown px-3 py-2 position-absolute border-0">
                                    <div className="row libraryContent">
                                      {libraryContent.map((item) => (
                                        <>
                                          <div
                                            className="col-12 input-data py-1 d-flex align-items-center my-1"
                                            onClick={() => {
                                              setOpenModel(!openModel);
                                              setSelectOption(item.title);
                                              setAddContent(item?._id);
                                            }}
                                          >
                                            <p className=" topics task_add_law m-0 text-capitalize ">
                                              {item.title}
                                            </p>
                                          </div>
                                        </>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal-footer  pb-4 pt-2 px-4 border-0 d-flex align-items-center justify-content-start">
                  {AddContentLoader ? (
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <button
                      class="border btn btn-light py-2 px-4  mx-0 shadow-none offcan-study text-capitalize add_task_button"
                      // data-bs-target="#exampleModalToggle2"
                      // data-bs-toggle="modal"
                      // data-bs-dismiss="modal"
                      onClick={() => {
                        handleAddContent();
                      }}
                    >
                      Add Content
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        class="modal fade"
        id="exampleModal4"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div
            class={`modal-content ${
              isEdit
                ? "description_modal_open_inc_width"
                : "description_modal_open"
            }`}
          >
            <div class="modal-header bg_color_decription">
              <h5
                class="modal-title description_heading_title text-white"
                id="exampleModalLabel"
              >
                Finish Literature Survey for INFO 203 Assignment 2
              </h5>
              {/* <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button> */}
              <img
                id="descmodelclose"
                src={AddNotesClose}
                onClick={handledescClose}
                alt={""}
                aria-label="Close"
                data-bs-dismiss="modal"
                className="me-2"
              ></img>
            </div>
            <div
              class={`${
                isEdit ? " modal_body_bg_color_change" : " modal_body_bg_color "
              } modal-body  py-0`}
            >
              {!isEdit ? (
                <p class="mt-3 px-2 add_task_long_para add_scrol_description_content_height">
                  {editVal}
                </p>
              ) : (
                <textarea
                  className="border-0 desctextarea content_add_description"
                  rows="13"
                  cols="83"
                  value={editVal}
                  onChange={(e) => handleDescription(e)}
                ></textarea>
              )}
            </div>
            <div
              class={` ${
                isEdit
                  ? " modal_body_bg_color_change py-3 "
                  : " modal_body_bg_color border-top py-3 "
              } d-flex align-items-center justify-content-end pe-4`}
            >
              {!isEdit ? (
                <button
                  onClick={() => {
                    editNotesButton();
                  }}
                  type="button"
                  className=" addNotesavedatas bg-light border-0 px-2"
                >
                  Edit
                </button>
              ) : (
                <button
                  data-bs-dismiss="modal"
                  onClick={() => {
                    saveDescriptionButton();
                  }}
                  type="button"
                  className=" addNotesavedatas addnote_font_change bg-white border-0 px-2"
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <NotesDialague
          show={showAddNotesDialauge}
          setShow={setShowAddNotesDialauge}
          NotesId={showNote}
          type={"add"}
          bodyType="task"
          setNotesLoader={noteLoader}
        />
        <NotesDialague
          show={showNotesDialauge}
          setShow={setShowNotesDialauge}
          NotesId={showNote}
          type={notesType}
          bodyType="task"
          setNotesLoader={noteLoader}
          editAction={clickEdit}
        />
      </div>
      <SidebarCanvas
        show={openSidebar}
        setShow={setOpenSidebar}
        sideLoader={sideLoader}
        data={sidebarData}
      />
    </div>
  );
}

export default TaskAddContent;

import React, { useState, useEffect, useRef } from "react";
import "./TableMode.css";
import Vfile from "../../../staticContent/videos/filenew.mp4";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { focusDatacloseStudy } from "../../../staticContent/image";
import { useHistory } from "react-router-dom";
import { getPracticeTopicData } from "../../../store/actions/practice";
const TableMode = (props) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const videoMax = useRef(null);
  const ScrollRef = useRef(null);

  const [getActive, setActive] = useState(0);

  const [play, setPlay] = useState(false);
  const [settopic, setSetTopic] = useState();
  const [topicName, setTopicName] = useState();
  const [span, setSpan] = useState(600);
  const [read, setRead] = useState(true);
  const [topicId, setTopicID] = useState("");
  const [practiceLoader, setPracticeLoader] = useState(false);
  const history = useHistory();
  useEffect(() => {
    console.log("video page");
    console.log(props?.getContent?.response);
    setSetTopic(props?.getContent?.response[0]?.topicId?.definition);
    setTopicName(props?.getContent?.response[0]?.topicId?.topic);
    setTopicID(props?.getContent?.response[0]?.topicId?._id);
    // setTopicName(props?.getContent?.response?)
  }, []);

  const setSunTopic = (topic, index) => {
    setSetTopic(topic?.topicId?.definition);
    setTopicID(topic?.topicId?._id);
    setTopicName(topic?.topicId?.topic);
    setActive(index);
  };

  const handleTrigger = () => {
    props.setTableContent();
  };
  const ScrollUp = (e) => {
    ScrollRef.current.scrollTop -= 40;
  };
  const ScrollDown = () => {
    ScrollRef.current.scrollTop += 40;
  };
  const handleReadMore = () => {
    setSpan(1000000);
    setRead(false);
  };
  const handleReadLess = () => {
    setSpan(600);
    setRead(true);
  };
  var type = props?.showType === "video" ? "tableVid" : "Human";

  const handlePractice = () => {
    setPracticeLoader(true);
    let file_ID = store?.getStudyTopic?.title?._id;
    let topic_ID = topicId;
    dispatch(getPracticeTopicData(file_ID, topic_ID)).then((res) => {
      if (res.status === 200) {
        history.push({
          pathname: "/practice",
          state: { prevPath: "documentpractice" },
        });
        setPracticeLoader(false);
      } else {
        setPracticeLoader(false);
        alert("server issue");
      }
    });
  };
  const handleStatus = (questionPoints, totalQuiz) => {
    if (totalQuiz >= 5 && totalQuiz < 15) {
      return "Medium";
    } else if (totalQuiz >= 15 && totalQuiz < 25) {
      return "Advanced";
    } else if (totalQuiz >= 25) {
      return "Maestro";
    } else if (questionPoints >= 100) {
      return "Medium";
    } else {
      return "Beginner";
    }
  };
  return (
    <>
      <div className="row">
        {/* <VerticalCarousel data={data.slides} leadingText={data.leadingText} /> */}
        <div className="col-12 position-relative hypothese col-md-12">
          {/* video player starts  */}
          {props?.type === "focus" && (
            <ReactPlayer
              playing={play}
              ref={videoMax}
              className="video-containers"
              url={props.getUrlVid}
              controls={true}
              width="100%"
              height="100vh"
              // height='100%'
            />
          )}
          {/* videoplayer ends  */}

          {/* <video className='video-containers' controls>
                        <source src={props.urlVid} type="video/mp4" />

                        Your browser does not support the video tag.
                    </video> */}
          <div className="col-12">
            <div
              className={`row mx-auto py-5 position-absolute  ${type} overlaydata `}
            >
              <div
                className={
                  props.button === "table"
                    ? "col-auto pe-0 ps-5 tablemode-timestamp text-white"
                    : "col-auto focus-tablemode-timestamp pe-0 ps-5 text-white"
                }
                ref={ScrollRef}
              >
                {props?.getContent?.response.map((item, index) => {
                  let subTopic = item?.topicId?.topic;
                  let num = item?.timestamp ;
                  let div = Math.floor(num / 60);
                  let result = Math.floor(num - div * 60);
                  return (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSunTopic(item, index);

                        
                      }}
                      className="row mb-3 mx-auto"
                    >
                      <div
                        className={
                          getActive === index
                            ? "col-auto Ipsums IpsumsActive py-2 ps-4 pe-5 active"
                            : "col-auto Ipsums py-2 ps-4 pe-5"
                        }
                      >
                        <p className="m-0 topic mycls mb-2">
                          {getActive === index &&
                            (props?.type !== "focus" ? (
                              <i
                                className="fa fa-caret-right me-2"
                                aria-hidden="true"
                                onClick={() => {
                                  props.setTableContent();
                                  props.videoPlayerRef.current.seekTo(
                                    item?.timestamp,
                                    "seconds"
                                  );
                                  props.onClick(true);
                                }}
                              ></i>
                            ) : (
                              <i
                                className="fa fa-caret-right me-2"
                                aria-hidden="true"
                                onClick={() => {
                                  videoMax.current.seekTo(
                                    item?.timestamp ? item?.timestamp : 0,
                                    "seconds"
                                  );
                                  setPlay(true);
                                }}
                              ></i>
                            ))}
                          {`  ${item?.topicId?.topic}`}
                        </p>
                        {item?.timestamp ? (
                          <h6 className="m-0 lorem">
                            {Math.floor(item?.timestamp / 60) < 10
                              ? `0${Math.floor(item?.timestamp / 60)}`
                              : Math.floor(item?.timestamp / 60)}
                            :{result < 10 ? `0${result}` : Math.floor(result)}
                          </h6>
                        ) : (
                          <h6 className="m-0 lorem">{"00:00"}</h6>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* <div className="d-flex row" style={{ position: "absolute" }} >
                <div><i style={{ border: "1px solid white" }} className="fa-angle-up"></i></div>
                <div><i className="fa-angle-down"></i></div>
              </div> */}
              <div className="col-1 d-flex align-items-center">
                <div className="row">
                  <div className="col newton d-flex align-items-center justify-content-center flex-column">
                    {/* <i
                      onClick={() => ScrollUp()}
                      className="fa fa-chevron-circle-up text-white"
                      aria-hidden="true"
                    ></i> */}
                    <i
                      onClick={() => ScrollUp()}
                      class="fa fa-angle-up rounded-pill bg-dark up_down_icon mb-2 d-flex align-items-center justify-content-center text-white"
                      aria-hidden="true"
                    ></i>
                    {/* <i
                      onClick={() => ScrollDown()}
                      className="fa fa-chevron-circle-down text-white"
                      aria-hidden="true"
                    ></i> */}
                    <i
                      onClick={() => ScrollDown()}
                      class="fa fa-angle-down rounded-pill bg-dark up_down_icon d-flex align-items-center justify-content-center text-white"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              </div>
              <div className="col-6 text-white">
                <div className="row">
                  <div className="col-12">
                    <h6
                      className={
                        props.button == "table"
                          ? "mb-3 motion"
                          : "mb-3 video-motion"
                      }
                    >
                      {/* {getSubTopic?.sub_topic_name
                        ? getSubTopic?.sub_topic_name
                        : props?.getContent?.response?.[0]?.subTopic?.[0]
                          ?.sub_topic_name} */}
                      {topicName}
                    </h6>
                  </div>
                  <div
                    className={
                      props.button === "table"
                        ? " tableHeight"
                        : " col-12 video-maxheight"
                    }
                  >
                    <p
                      className={
                        props.button === "table" ? " law" : "video-max"
                      }
                    >
                      {settopic && (
                        <>
                          <span
                            className={
                              props.button === "table"
                                ? "heading-data-tablemode"
                                : "heading-data-focus mb-2"
                            }
                            dangerouslySetInnerHTML={{
                              __html:
                                settopic?.length > 600
                                  ? settopic?.slice(0, span)
                                  : settopic,
                            }}
                          ></span>
                        </>
                      )}
                      {read ? (
                        <span
                          className={
                            props.button === "table"
                              ? "read-more  ms-2"
                              : " read-more-video-max ms-2"
                          }
                          onClick={handleReadMore}
                        >
                          Read More
                        </span>
                      ) : (
                        <span
                          className={
                            props.button === "table"
                              ? "read-more ms-2"
                              : " read-more-video-max ms-2"
                          }
                          onClick={handleReadLess}
                        >
                          Read Less
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="row">
                      <div className="col-12 d-flex align-items-center">
                        <button
                          type="button"
                          className="btn py-2 px-3 explore text-white  height_ics btn_width_more"
                        >
                          Explore More
                        </button>
                        {!practiceLoader ? (
                          <button
                            onClick={() => {
                              handlePractice();
                            }}
                            type="button"
                            className="btn py-2 px-3 bg-white btn-white ms-3 height_ics explorePractice text-dark wraps-tablemode position-relative "
                          >
                            Practice
                            {/* <div className="tools-tablemode">Coming soon</div> */}
                          </button>
                        ) : (
                          <div
                            className="spinner-border mx-4"
                            role="status"
                            style={{
                              width: "1.77rem",
                              height: "1.77rem",
                            }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                      </div>
                    </div>

                    <hr className="will" />
                  </div>
                  <div className="col-12 mt-2">
                    <h5
                      className={
                        props.button === "table"
                          ? "m-0 p-0 progress_table"
                          : "m-0 p-0 progress_focus"
                      }
                    >
                      Topic Progress:{" "}
                      <span style={{ fontWeight: "400" }}>
                        {" "}
                        {handleStatus(
                          store?.getStudyTopic?.topicPoints,
                          store?.getStudyTopic?.CompleteQuizz
                        )}{" "}
                      </span>
                    </h5>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="row mx-auto d-flex align-items-center justify-content-between">
                      <div className="col selectedBar px-1 me-3 ">
                        <hr />
                        <p className="d-flex align-items-center justify-content-center mt-3 add_beg_mde_adv_size">
                          Beginner
                        </p>
                      </div>
                      <div
                        className={`${
                          handleStatus(
                            store?.getStudyTopic?.topicPoints,
                            store?.getStudyTopic?.CompleteQuizz
                          ) === "Medium" ||
                          handleStatus(
                            store?.getStudyTopic?.topicPoints,
                            store?.getStudyTopic?.CompleteQuizz
                          ) === "Advanced" ||
                          handleStatus(
                            store?.getStudyTopic?.topicPoints,
                            store?.getStudyTopic?.CompleteQuizz
                          ) === "Maestro"
                            ? "selectedBar"
                            : "unselectedBar"
                        } col  px-1 me-3`}
                      >
                        <hr />
                        <p className="d-flex align-items-center justify-content-center mt-3 add_beg_mde_adv_size">
                          Medium
                        </p>
                      </div>
                      <div
                        className={`${
                          handleStatus(
                            store?.getStudyTopic?.topicPoints,
                            store?.getStudyTopic?.CompleteQuizz
                          ) === "Advanced" ||
                          handleStatus(
                            store?.getStudyTopic?.topicPoints,
                            store?.getStudyTopic?.CompleteQuizz
                          ) === "Maestro"
                            ? "selectedBar"
                            : "unselectedBar"
                        } col  px-1 me-3`}
                      >
                        <hr />
                        <p className="d-flex align-items-center justify-content-center mt-3 add_beg_mde_adv_size">
                          Advanced
                        </p>
                      </div>
                      <div
                        className={`${
                          handleStatus(
                            store?.getStudyTopic?.topicPoints,
                            store?.getStudyTopic?.CompleteQuizz
                          ) === "Maestro"
                            ? "selectedBar"
                            : "unselectedBar"
                        } col  px-1 me-3`}
                      >
                        <hr />
                        <p className="d-flex align-items-center justify-content-center mt-3 add_beg_mde_adv_size">
                          Maestro
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-1 ms-auto">
                {" "}
                <img
                  src={focusDatacloseStudy}
                  onClick={handleTrigger}
                  style={{ cursor: "pointer" }}
                  alt={""}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableMode;

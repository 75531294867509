import React, { useEffect, useState, useRef } from "react";
import "./SelectProfile.css";
import {
  SelectProfileDarkLogo,
  SelectProfileX32,
  SelectProfileX33,
  UpArrow,
  DownArrow,
} from "../../staticContent/image";
import {
  getUserData,
  getSchoolList,
  getProfileList,
  getSchoolListWithSearch,
} from "../../store/actions/getUserData";
import { getrecommendContent } from "../../store/actions/libraryOverview";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { getPreviousPageData } from "../../store/actions/getUserData";

function SelectProfile() {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  let [num, setnum] = useState(0);

  const [formData, setFormData] = useState({
    profileId: "",
    schoolId: "",
    error: { profileErr: "", schoolErr: "" },
  });
  const [selectedProfileVal, setSelectedProfileVal] = useState(
    "Student, educator and etc..."
  );
  const [schoollist, setSchoolList] = useState([]);
  const [showProfile, setShowProfile] = useState(false);
  const [selectedSchoolVal, setSelectedSchoolVal] = useState(
    "Enter your school or university"
  );
  const [showSchool, setShowSchool] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loader, setloader] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    // School data on scroll API implementation start //

    dispatch(getSchoolListWithSearch(searchText, num)).then((res) => {
      setSchoolList([...schoollist, ...res.data.school]);
    });

    // School data on scroll API implementation end //

    setloader(false);
    dispatch(getrecommendContent(store.auth?.user?.id));
    dispatch(getProfileList());
  }, [num, searchText]);
  useEffect(() => {
    dispatch(getPreviousPageData(1)).then((response) => {
      const data = response?.data?.response;
      if (data.school) {
        setSearchText(data?.school?.school_name);
        setSelectedSchoolVal(data?.school?.school_name);
        setFormData({
          ...formData,
          schoolId: data?.school?._id,
          profileId: data?.profile?._id,
        });
        setSelectedProfileVal(data?.profile?.profile);
      }
    });
  }, []);

  // Schoolname API search on scroll function start //

  const handleScroll = (e) => {
    if (
      window.innerHeight + e.target.scrollTop + 1 >=
      e.target.scrollHeight + 300
    ) {
      setnum(num++);
    }
  };

  // Schoolname API search on scroll function end //

  // input profile onchange select function start //

  const handleSelectProfile = (item) => {
    setShowProfile(false);
    setSelectedProfileVal(item.profile);
    setFormData({ ...formData, profileId: item._id });
    setIsDisabled(false);

  };

  // input profile onchange select function end //

  // input schoolname onchange select function start //

  const handleSelectSchool = (item) => {
    setShowSchool(false);
    setSearchText(item.school_name);
    setSelectedSchoolVal(item.school_name);
    setFormData({ ...formData, schoolId: item._id });
  };

  // input schoolname onchange select function end //

  // Onboarding first button API call start //

  const sendData = () => {
    if (formData.profileId === "Student, educator and etc,,,") {
      setFormData({
        ...formData,
        error: { profileErr: "Field can't be empty", schoolErr: "" },
      });
    } else if (formData.schoolId === "") {
      setFormData({
        ...formData,
        error: { profileErr: "", schoolErr: "Field can't be empty" },
      });
    } else if (formData.profileId !== "" && formData.schoolId !== "") {
      dispatch(
        getUserData({
          body: {
            school: formData.schoolId,
            profile: formData.profileId,
          },
          onBoardingStatus: "1",
          setCode: "2",
        })
      );
      setloader(true);
      setFormData({ ...formData, error: { profileErr: "", schoolErr: "" } });
    }
  };

  // Onboarding first button API call end //

  if (store.profileStatus === "2") {
    return <Redirect to={`/select-profile/${store.profileStatus}`} />;
  }

  return (
    <div className="container-fluid d-flex flex-column">
      <div className="row mx-auto pt-5">
        <div className="col-12 amet  align-items-center">
          <div className="row">
            <div className="col-11 d-flex justify-content-between align-items-center mx-auto">
              <img
                className="onboard_img ps-5"
                src={SelectProfileDarkLogo}
                alt=""
              />
              <div className="progressSign">
                <div className="completed-progressbar2"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row margin_top">
            <div className="col-4 d-flex justify-content-start">
              <div className="signIn_img">
                <img src={SelectProfileX33} alt="" />
              </div>
            </div>
            <div className="col-lg-4  py-2">
              <form className=" all">
                <div className="container">
                  <div className="">
                    <h1 className="heading_onboard">Welcome to Seekh</h1>
                    <p className="pragraph_onboard my-3 m-0 p-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      Nullam vitae non quis auctor eget aliquet nullam
                      penatibus.aliquet nullam nflf penatibus.
                    </p>
                  </div>
                  <div className="col-12 position-relative">
                    <div className="row mx-auto overflow-hidden">
                      <div className="col-12 p-0 position-relative">
                        <label for="email" className="my-2">
                          <b className="enjoy text_black">
                            Select your profile
                          </b>
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div
                            onClick={() => {
                              setShowProfile(!showProfile);
                              setShowSchool(false);
                            }}
                            className={
                              showProfile
                                ? "col-12 school p-2 profile-borderwithselection"
                                : "col-12 profile-border school p-2"
                            }
                          >
                            <div className="row p-2">
                              <div className="col flex-1 d-flex align-items-center">
                                <p
                                  className={
                                    selectedProfileVal ===
                                      "Student, educator and etc..."
                                      ? "border-0 fontsize_14px flex-grow-1  outline-none text-profile  mb-0"
                                      : "border-0 fontsize_14px flex-grow-1  outline-none  mb-0 text_black"
                                  }
                                >
                                  {selectedProfileVal}
                                </p>
                              </div>

                              <div className="col-auto d-flex align-items-center drop-icon justify-content-end">
                                <img
                                  src={showProfile ? UpArrow : DownArrow}
                                  alt=""
                                  style={{ width: "14px" }}
                                />
                              </div>
                            </div>
                          </div>
                          <p className="mb-0 mt-2">
                            <small>
                              {" "}
                              {formData.error.profileErr
                                ? formData.error.profileErr
                                : null}{" "}
                            </small>{" "}
                          </p>
                          {showProfile ? (
                            <div className="col-12 border sele_drpdown ">
                              <div className="row">
                                {store.profileList?.data?.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      onClick={() => {
                                        handleSelectProfile(item);
                                      }}
                                      id={`border${index}`}
                                      className="col-12 input-data p-3 border-bottom hover_select"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <p className=" topics m-0 text-capitalize ">
                                        {item.profile}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 position-relative">
                    <div className="row mx-auto overflow-hidden">
                      <div className="col-12 position-relative p-0">
                        <label for="email" className="mb-2 mt-1">
                          <b className="enjoy text_black">Enter your school</b>
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          {/* <div
                          onClick={() => {
                            setShowProfile(false);
                            setShowSchool(!showSchool);
                          }}
                          className={
                            showSchool
                              ? "col-12  profile-borderwithselection  school p-2"
                              : "col-12  profile-border  school p-2"
                          }
                        >
                          <div className="row p-2">
                            <div className="col flex-1 d-flex align-items-center">
                              <p
                                className={
                                  selectedSchoolVal ===
                                  "Enter your school or university"
                                    ? "border-0 mb-0 fontsize_14px flex-grow-1 outline-none text-profile"
                                    : "border-0 mb-0 fontsize_14px flex-grow-1 outline-none text_black"
                                }
                              >
                                {selectedSchoolVal}
                              </p>
                            </div>

                            <div className="col-auto d-flex align-items-center drop-icon justify-content-end">
                              <img
                                src={showSchool ? UpArrow : DownArrow}
                                alt=""
                                style={{ width: "14px" }}
                              />
                            </div>
                          </div>
                        </div> */}
                          <div
                            className={
                              showSchool
                                ? "col-12  d-flex align-items-center selection_input_box"
                                : "col-12 border d-flex align-items-center "
                            }
                          >
                            <input
                              type="text"
                              id="inputPassword55"
                              aria-describedby="passwordHelpBlock"
                              placeholder="Enter your school or university"
                              className="py-3 px-1 form-control border-0 data_select_school shadow-none"
                              value={searchText}
                              onChange={(e) => {
                                setSearchText(e.target.value);
                                setSchoolList([]);
                                setShowSchool(true);
                                setnum(0);
                                setIsDisabled(false);


                              }}
                              onFocus={() => {
                                setShowSchool(true);
                              }}
                            ></input>
                            <img
                              onClick={() => {
                                setShowProfile(false);
                                setShowSchool(!showSchool);
                              }}
                              src={showSchool ? UpArrow : DownArrow}
                              alt=""
                              className="me-1"
                              style={{ width: "14px" }}
                            />
                          </div>

                          {showSchool && schoollist?.length > 0 && (
                            <div
                              className="col-12 border sele_drpdown"
                              onScroll={handleScroll}
                            >
                              <div className="row">
                                {schoollist?.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      onClick={() => {
                                        handleSelectSchool(item);
                                      }}
                                      className="col-12 input-data p-3 border-bottom hover_select"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <p className=" topics m-0 text-capitalize">
                                        {item.school_name}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {loader === false ? (
                    <div className="clearfix btn_mt border-0 m-0 p-0">
                      <button
                        disabled={isDisabled}
                        type="button"
                        className="customebtn d-flex align-items-center justify-content-center mt-4"
                        onClick={(e) => {
                          sendData(e);
                        }}
                      >
                        Next Step
                        {/* <i className="fa fa-spinner fa-spin mx-3" aria-hidden="true" style={{ fontSize: "20px" }}></i> */}
                      </button>
                    </div>
                  ) : (
                    <div className="clearfix border-0">
                      <button type="button" className="customebtn mt-4">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
            <div className="col-4 d-flex justify-content-end">
              <div className="signIn_img">
                <img src={SelectProfileX32} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectProfile;

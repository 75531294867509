import React, { useEffect } from "react";
import SelectCourse from "../Onboarding/SelectCourse";
import SetGoal from "../Onboarding/SetGoal";
import KeepTrack from "../Onboarding/KeepTrack";
import AddSyllabus from "../Onboarding/AddSyllabus";
import AddCourse from "../Onboarding/AddCourse";
import SelectProfile from "../Onboarding/SelectProfile";
import { useParams, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
// this component used for routing onboarding pages
const Onboarding = () => {
  const profileStatus = useSelector((state) => state.profileStatus);
   const pathToRediract = useSelector((state) => state?.auth?.attemptedPath); 
  const data = useParams();


  if (data.id === profileStatus + "") {
    if (data.id === "1") {
      return <SelectProfile />;
    } else if (data.id === "2") {
      return <AddCourse />;
    } else if (data.id === "3") {
      return <AddSyllabus />;
    } else if (data.id === "4") {
      return <KeepTrack />;
    } else if (data.id === "5") {
      return <SelectCourse />;
    } else if (data.id === "6") {
      return <SetGoal />;
    } else if (data.id === "7") { 
      if(pathToRediract === null){
      return <Redirect to={`/library/overview`} />;
      }else{
        return <Redirect to={pathToRediract} />;
      }
    }
  } else {   
    return <Redirect to={`/select-profile/${profileStatus}`} />;
  }
};

export default Onboarding;

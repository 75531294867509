import React, { useState, useEffect } from 'react';
import './VideoContent.css'
import { useSelector } from 'react-redux';
import ReactPlayer from "react-player";
// import VerticalCarousel from "./VerticalCarousel"; 
import TableMode from '../tablemode/TableMode'
const VideoContent = (props) => {
const store = useSelector((state)=>state)
const [play, setPlay] = useState(props.Autoplay)
const [getSubTopic, setSubTopic] = useState(props?.getContent?.response?.[0]?.subTopic?.[0]);
const [videoPlay,setVideoPlay] = useState()
  useEffect(() => {
    // get sub topic api
    setSubTopic(props?.getContent?.response?.[0]?.subTopic?.[0]);
    setVideoPlay(props.videoPlayerRef)
  }, [props]);

  const handleTrigger = () => {
    props.setTableContent();
  }
  // onplay react player function through props 
  const Autoplay = (data) => {
    setPlay(data)

  }
useEffect(() => {
  setTimeout(() => {
    handleClick()

  }, 2000);

}, [])
const handleClick = ()=>{
  if (store?.getStudyTopic?.title?.playTimestamp) {
    props.videoPlayerRef.current.seekTo(
      parseInt(store?.getStudyTopic?.title?.playTimestamp),
      "seconds"
    );
    setPlay(true)
  }
  

}


  return (
    <>
      <div className="row">
        {/* <VerticalCarousel data={data.slides} leadingText={data.leadingText} /> */}

        <div className="col-12 position-relative hypothese col-md-12">
          {/* react player starts  */}
          <ReactPlayer
            playing={props.Autoplay === true ? props.Autoplay : play}
            ref={props.videoPlayerRef}
            className="video-containers "
            url={props.urlVid}
            controls={true}
            width="100%"
            height="510px"
            onPlay={() => {
              setPlay(true)
            }}
            onPause={() => {
              setPlay(false)
            }}
          // height='100%'
          />
          {/* react player ends  */}

          {/* 
                    <video className='video-containers' controls>
                        <source src={props.urlVid} type="video/mp4" />

                        Your browser does not support the video tag.
                    </video> 
                    */}

          {props.show && (
            <>
              {/* table mode component start */}
              <TableMode
                getsubTopic={props.getContent?.response[0]?.topic}
                button="table"
                onClick={Autoplay}
                getContent={props.getContent}
                setTableContent={handleTrigger}
                videoPlayerRef={props.videoPlayerRef}
              />
              {/* table mode component ends  */}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default VideoContent

import * as React from "react";
import Box from "@mui/joy/Box";
import List from "@mui/joy/List";
import ListItem, { listItemClasses } from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import "./topic.css";
import { useSelector, useDispatch } from "react-redux";
import { getSubTopics } from "../../../store/actions/study";

export default function ExampleCollapsibleList(props) {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [sidebarData, setSidebarData] = React.useState(
    store?.getStudyTopic?.response
  );
  const [subTopic, setSubTopic] = React.useState(
    store?.getSubTopic?.data?.response
  );
  function sendTopic(name) {
    props.setTopicContent(name);
  }
  function truncate(str, n) {
    return str?.length > n ? str.slice(0, n - 1) + "..." : str;
  }

  return (
    <Box style={{ marginLeft: "-5rem" }} className="col-12">
      <List
        size="sm"
        sx={(theme) => ({
          // Gatsby colors
          "--joy-palette-primary-plainColor": "#8a4baf",
          "--joy-palette-neutral-plainHoverBg": "transparent",
          "--joy-palette-neutral-plainActiveBg": "transparent",
          "--joy-palette-primary-plainHoverBg": "transparent",
          "--joy-palette-primary-plainActiveBg": "transparent",

          "--List-insetStart": "10px",
          "--List-item-paddingY": "0px",
          "--List-item-paddingRight": "16px",
          "--List-item-paddingLeft": "21px",
          "--List-item-startActionWidth": "0px",
          // "--List-item-startActionTranslateX": "-50%",

          [`& .${listItemButtonClasses.root}`]: {
            borderLeft: "1px solid",
            borderColor: "#DFDFDF",
          },
          [`& .${listItemButtonClasses.root}.${listItemButtonClasses.selected}`]:
            {
              borderColor: "currentColor",
            },
          [`& .${listItemClasses.nested} > .${listItemButtonClasses.root}`]: {
            border: "none",
          },
          '& [class*="startAction"]': {
            color: "var(--joy-palette-text-tertiary)",
          },
          ml: 5,
        })}
      >
        {sidebarData?.map((item, index) => {
          return (
            <ListItem
              key={`index${index}`}
              a
              nested
              sx={{ ml: 0, mt: 1, width: 220 }}
              startAction={
                <IconButton
                  variant="plain"
                  size="sm"
                  color="neutral"
                  onClick={() => {
                    if (item?.subTopicCount > 0) {
                      setSidebarData(
                        sidebarData.map((itemx, index) =>
                          itemx?._id === item?._id
                            ? { ...itemx, show: !itemx.show }
                            : { ...itemx, show: false }
                        )
                      );
                    } else {
                      setSidebarData(
                        sidebarData.map((itemx, index) =>
                          itemx?._id === item?._id
                            ? { ...itemx, show: false }
                            : { ...itemx, show: false }
                        )
                      );
                    }
                  }}
                >
                  <Typography
                    component={"span"}
                    level="inherit"
                    sx={{
                      fontWeight: open ? "bold" : undefined,
                      color: item.show ? "#333333 !important" : "#737373",
                    }}
                  >
                    <div className="row mx-auto topic_conatiner w-100 ">
                      <div
                        className="col-auto listitem-topic"
                        style={{
                          fontSize: "12px",
                          lineHeight: "12px",
                          fontWeight: "400",
                        }}
                      ></div>
                      <div
                        className="col-auto text-start p-0 topic_width listitem-topic"
                        style={{
                          fontSize: "12px",
                          lineHeight: "14px",
                          fontWeight: "400",
                        }}
                        onClick={() => {
                          // handleSubTopic(item);
                        }}
                      >
                        {/* {item?.topic[0]?.topic} */}
                        {truncate(item?.topicId?.topic, 35)}
                      </div>
                    </div>
                  </Typography>
                </IconButton>
              }
            >
              <ListItemButton sx={{ mt: 0, mb: 0 }}></ListItemButton>
              {item.show && (
                <>
                  {subTopic.length !== 0 ? (
                    subTopic.map((itemx, indx) => {
                      return (
                        <>
                          <List
                            sx={{
                              "--List-item-paddingY": "8px",
                              ml: 1,
                            }}
                            key={`test${indx}`}
                          >
                            <ListItem>
                              <ListItemButton>
                                <span onClick={() => sendTopic(itemx)}>
                                  {" "}
                                  {itemx?.topic_name}
                                </span>
                              </ListItemButton>
                            </ListItem>
                          </List>
                        </>
                      );
                    })
                  ) : (
                    <div class="spinner-border text-dark ms-5" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  )}
                </>
              )}
            </ListItem>
          );
        })}
        {/*  */}
      </List>
    </Box>
  );
}

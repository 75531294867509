import React, { useState, useEffect } from "react";
import "./SelectCourse.css";
import {
  SelectProfileX32,
  SelectProfileX33,
  SelectProfileDarkLogo,
  LeftArrow,
} from "../../staticContent/image";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserData,
  getCourseList,
  skipPage,
  goBackPage,
} from "../../store/actions/getUserData";
import { Redirect } from "react-router-dom";
//Componnent Start here
const SelectCourse = () => {
  const store = useSelector((state) => state);
  const [loader, setloader] = useState(0);
  const dispatch = useDispatch();
  const [disabled, setdisabled] = useState(true);
  const [color, setcolor] = useState(0);
  const [timeVal, setTimeVal] = useState([
    { time: "30 minutes" },
    { time: "1 hour" },
    { time: "2 hour" },
  ]);
  const [courseData, setCourseData] = useState([]);
  // const [selectedVal, setSelectedVal] = useState("Select");

  useEffect(() => {
    dispatch(getCourseList(store.auth.user.id));
    setloader(0);
  }, []);

  useEffect(() => {
    setCourseData(store.courseList.data);
  }, [store]);

  // const setSelectedValue = (time, id) => {
  //   setSelectedVal(time.time)
  // }
  // this function used for (Add Time Button)
  const sendData = (e) => {
    e.preventDefault();
    // let valid = true;
    // let finalData = [];
    // courseData.forEach((item, index) => {
    //   if (item.value === undefined) {
    //     valid = false;
    //     finalData.push({ ...item, error: "Please select at least one item" });
    //   } else {
    //     finalData.push({ ...item, error: "" });
    //   }
    // });
    // if (valid) {

    dispatch(
      getUserData({
        body: {
          data: courseData.map((itemx, indexz) => ({
            _id: itemx?._id,
            time: itemx?.selectedValue,
          })),
        },
        onBoardingStatus: "5",
        setCode: "6",
      })
    );
    setloader(1);
  };
  // else {
  //   setCourseData(finalData);
  // }

  // Redirect To Next Page

  if (store.profileStatus === "6") {
    return <Redirect to={`/select-profile/${store.profileStatus}`} />;
  } else if (store.profileStatus === "7") {
    return <Redirect to={`/library`} />;
  }
  // Back Button Click
  const goBack = () => {
    dispatch(goBackPage(store.profileStatus - 1));
  };
  // open modal for input field options
  const handleOpen = (item) => {
    setCourseData(
      courseData.map((itemx, index) =>
        itemx?._id === item?._id
          ? { ...itemx, focus: true, modalShow: !itemx.modalShow }
          : { ...itemx, focus: false, modalShow: false }
      )
    );
  };

  return (
    <div className="container-fluid d-flex flex-column">
      <div className="row mx-auto pt-5">
        <div className="col-12 amet  align-items-center">
          <div className="row">
            <div className="col-11 d-flex justify-content-between align-items-center mx-auto">
              <img
                className="onboard_img ps-5"
                src={SelectProfileDarkLogo}
                alt=""
              />
              <div className="progressSign">
                <div className="completed-progressbar6"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="row margin_top">
            <div className="col-4 d-flex justify-content-start">
              <div className="signIn_img">
                <img src={SelectProfileX33} alt="" />
              </div>
            </div>
            <div className="col-lg-4 py-2">
              <form className=" all position-relative">
                <div className="container">
                  <div className="col-1 mb-4">
                    <div
                      className="d-flex align-items-center back-data"
                      onClick={() => {
                        goBack();
                      }}
                      style={{ display: "inline-block", cursor: "pointer" }}
                    >
                      {/* <i className="fa fa-arrow-left" aria-hidden="true"></i> */}
                      <img src={LeftArrow} alt="" style={{ width: "1rem" }} />
                      <p className="m-0 ms-2">Back</p>
                    </div>
                  </div>
                  <div className="col-12 mt-4 mb-4">
                    <h1 className="heading_onboard">
                      Specify the desired time to study the course
                    </h1>
                  </div>
                  <div className="col-12">
                    <div className="row border enter mx-auto">
                      <div className="col-12 py-2 select-course-top border-bottom bg-light">
                        <div className="row d-flex justify-content-start align-items-center">
                          <div className="col-1 ms-2 d-flex info justify-content-start align-items-center">
                            <h6 className="m-0 text-dark ">#</h6>
                          </div>
                          <div className="col-6 ms-2 d-flex info justify-content-start align-items-center">
                            <h6 className="m-0 text-dark ">Course</h6>
                          </div>
                          <div className="col-4 d-flex info justify-content-start align-items-center">
                            <h6 className="m-0 text-dark ">Time</h6>
                          </div>
                        </div>
                      </div>
                      {courseData?.map((item, index) => {
                        return (
                          <div className="col-12 mt-3" key={index}>
                            <div className="row ms-1 d-flex position-relative justify-content-start align-items-center">
                              <div className="col-1 d-flex justify-content-start align-items-center">
                                <p
                                  className="m-0 text-dark"
                                  style={{ fontWeight: "800" }}
                                >
                                  {" "}
                                  {index + 1}.
                                </p>
                              </div>
                              <div className="col-6 funda">
                                <h5 className="m-0 text-dark">
                                  {item?.course_Name?.length > 20
                                    ? item.course_Name.slice(0, 20) + "..."
                                    : item.course_Name}
                                  {/* {item?.course_name} */}
                                </h5>
                              </div>

                              <div className="col-5 pe-4">
                                <div className="row mx-auto position-relative">
                                  <div className="col-12 p-0">
                                    <div
                                      onClick={() => handleOpen(item)}
                                      className={
                                        item?.focus
                                          ? "row mx-auto border py-2 school isFocus"
                                          : "row mx-auto border py-2 school"
                                      }
                                    >
                                      <div className="col-9">
                                        <p
                                          // style={{ postion: "relative" }}
                                          disabled
                                          type="search"
                                          className={
                                            !courseData.color
                                              ? "border-0 fifth-input  flex-grow-1 shadow-none outline-none bg-transparent mb-0"
                                              : "border-0 fifth-input-2  flex-grow-1 shadow-none outline-none bg-transparent mb-0"
                                          }
                                          // placeholder={

                                          // }
                                        >
                                          {item.selectedValue
                                            ? item.selectedValue
                                            : "Select"}
                                        </p>
                                      </div>
                                      <div className="col-2 d-flex  drop-icon align-items-center">
                                        {item?.modalShow ? (
                                          <i
                                            className="fa fifth-arrow fa-angle-up"
                                            aria-hidden="true"
                                          ></i>
                                        ) : (
                                          <i
                                            className="fa fifth-arrow fa-angle-down"
                                            aria-hidden="true"
                                          ></i>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  {item.modalShow && (
                                    <>
                                      <div className="col-12">
                                        <div className="row border mt-2 bg-white sele_drpdown time_drpdown  mx-auto">
                                          {timeVal.map((time, index) => {
                                            return (
                                              <div
                                                key={index}
                                                onClick={() => {
                                                  setdisabled(false);

                                                  setcolor(1);
                                                  setCourseData(
                                                    courseData?.map(
                                                      (itemx, index) =>
                                                        itemx?._id === item?._id
                                                          ? {
                                                              ...itemx,
                                                              selectedValue:
                                                                time?.time,
                                                              modalShow:
                                                                !itemx.modalShow,
                                                              color: true,
                                                            }
                                                          : itemx
                                                    )
                                                  );
                                                }}
                                                className="col-12 input-data p-3 border-bottom"
                                              >
                                                <p
                                                  className="m-0  fifth-time "
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {time.time}
                                                </p>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div
                        className="col-12 select-course-border border-top mt-3 pt-2 py-2 bg-light"
                        style={{ paddingLeft: "31px" }}
                      >
                        <div className="row">
                          <div className="col d-flex justify-content-start align-items-center">
                            <h6 className="text-dark fifth text-align-center">
                              Desired Daily Time
                            </h6>
                          </div>
                          <div className="col-2  d-flex justify-content-end align-items-center me-3">
                            {/* <input type="text" className="px-3 py-2 time border">00:00</input> */}
                            <input
                              type="text"
                              className="text-dark px-3 py-2 fifth time border"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {loader === 0 ? (
                    <div className=" col-12 clearfix border-0 mt-4">
                      <button
                        disabled={disabled}
                        className="customebtn d-flex align-items-center justify-content-center"
                        onClick={(e) => sendData(e)}
                      >
                        {/* <i className="fa fa-spinner fa-spin me-3" aria-hidden="true" style={{ fontSize: "20px" }}></i> */}
                        Add Time
                      </button>
                    </div>
                  ) : (
                    <div className=" col-12 clearfix border-0 mt-4">
                      <button
                        className="customebtn "
                        onClick={(e) => sendData(e)}
                      >
                        {/* <i className="fa fa-spinner fa-spin me-3" aria-hidden="true" style={{ fontSize: "20px" }}></i> */}
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </button>
                    </div>
                  )}
                  <div className="col-12 d-flex align-items-center justify-content-center mt-3">
                    <p
                      onClick={() => {
                        dispatch(skipPage());
                      }}
                      className="text-decoration-none plus"
                      style={{ cursor: "pointer" }}
                    >
                      Skip Step
                    </p>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-4 d-flex justify-content-end">
              <div className="signIn_img">
                <img src={SelectProfileX32} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCourse;

import React from 'react'
import Header from '../Header/Header'
import PracticeLayout from '../../containers/Practice/PracticeLayout'

const practiceLayout = () => {
  return (
    <>
    <div className="row mx-auto">
      <div className="col-12">
        <Header />
      </div>
      <div className="col-12">
        <PracticeLayout />
      </div>
    </div>
  </>
  )
}

export default practiceLayout
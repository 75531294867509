import React, { useState, useRef, useEffect } from "react";
import "./AddCourse.css";
import { v4 as uuidv4 } from "uuid";
import {
  SelectProfileDarkLogo,
  SelectProfileX32,
  SelectProfileX33,
  PlusImg,
  LeftArrow,
} from "../../staticContent/image";
import {
  getUserData,
  skipPage,
  goBackPage,
  getCourseList,
} from "../../store/actions/getUserData";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { getPreviousPageData } from "../../store/actions/getUserData";

// component starting here

const AddCourse = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const formRef = useRef();
  const [loader, setloader] = useState(false);
  const[deleteButton,setDeleteButton] = useState(false)
  const [courseData, setCourseData] = useState({
    courseName: "",
    courseIdentifier: "",
    instructorName: "",
    error: { courseErr: "", courseIdentifierErr: "", allErr: "" },
  });
  const [formData, setFormData] = useState(
    store?.onboardcourse2?.map((item, index) => {
      return {
        questionId: uuidv4(),
        courseName: item.course_Name,
        courseIdentifier: item.course_identifier,
        instructorName: item.instructor_name,
        error: { courseErr: "", courseIdentifierErr: "", allErr: "" },
      };
    })
  );
  const [editCourse, setEditCourse] = useState(null);
  // Redirect To Next Page

  if (store.profileStatus === "3") {
    return <Redirect to={`/select-profile/${store.profileStatus}`} />;
  } else if (store.profileStatus === "7") {
    return <Redirect to={`/library`} />;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setloader(false);
    dispatch(getCourseList(store.auth.user.id));
  }, []);

  // Next Button call
  const addCourseData = () => {
    if (formData?.length < 1) {
      setCourseData({
        ...courseData,
        error: { allErr: "Please add atleast one course" },
      });
    } else {
      dispatch(
        getUserData({
          body: {
            data: formData?.map((itemx, indexz) => ({
              course_Name: itemx.courseName,
              course_identifier: itemx.courseIdentifier,
              instructor_name: itemx.instructorName,
            })),
          },
          onBoardingStatus: "2",
          setCode: "3",
          data: formData,
        })
      );
      setCourseData({
        ...courseData,
        error: { courseIdentifierErr: "", courseErr: "" },
      });
      setloader(true);
    }
    dispatch(getCourseList(store.auth.user.id));
  };

  // Delete added course from list

  const deleteCourse = (id) => {
    // dispatch(deleteCourse(id))
    setFormData(
      formData.filter((itemx, index) => {
        return itemx.questionId !== id;
      })
      
    );
    setEditCourse(null);
    setCourseData({ courseName: "", courseIdentifier: "", instructorName: "" });
    setDeleteButton(false)
  };

  // Edit Course
  const editCourseDetails = (item) => {
    setDeleteButton(true)
    setEditCourse(item.questionId);
    setCourseData({
      courseName: item.courseName,
      courseIdentifier: item.courseIdentifier,
      instructorName: item.instructorName,
    });
  };
  // add course
  const addCourse = () => {
    setDeleteButton(false)
    if (courseData.courseName.trim() === "") {
      setCourseData({
        ...courseData,
        error: { courseErr: "This field is required" },
      });
    } else if (courseData.courseIdentifier.trim() === "") {
      setCourseData({
        ...courseData,
        error: { courseIdentifierErr: "This field is required" },
      });
    } else if (
      courseData.courseName !== "" &&
      courseData.courseIdentifier !== ""
    ) {
      if (editCourse) {
        setEditCourse(null);
        setFormData(
          formData?.map((itemz, indexz) =>
            itemz.questionId === editCourse
              ? { ...courseData, questionId: editCourse }
              : itemz
          )
        );
        setCourseData({
          courseName: "",
          courseIdentifier: "",
          instructorName: "",
        });
      } else if (formData?.length >= 7) {
        setCourseData({
          courseName: "",
          courseIdentifier: "",
          instructorName: "",
          error: { courseIdentifierErr: "You can only add 7 courses" },
        });
      } else {
        setFormData([...formData, { ...courseData, questionId: uuidv4() }]);
        setCourseData({
          courseName: "",
          courseIdentifier: "",
          instructorName: "",
        });
      }
    }
  };
  // back button function
  const goBack = () => {
    dispatch(getPreviousPageData(1));
    dispatch(goBackPage(store.profileStatus - 1));
  };

  return (
    <div className="container-fluid d-flex flex-column">
      <div className="row mx-auto pt-5">
        <div className="col-12 amet  align-items-center">
          <div className="row">
            <div className="col-11 d-flex justify-content-between align-items-center mx-auto">
              <img
                className="onboard_img ps-5"
                src={SelectProfileDarkLogo}
                alt=""
              />
              <div className="progressSign">
                <div className="completed-progressbar3"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row  margin_top">
            <div className="col-4 d-flex justify-content-start">
              <div className="signIn_img">
                <img src={SelectProfileX33} alt="" />
              </div>
            </div>
            <div className="col-4  py-2">
              <form ref={formRef} className=" all">
                <div className="container">
                  <div className="col-1 mb-3">
                    <div
                      className="d-flex align-items-center back-data"
                      onClick={() => {
                        goBack();
                      }}
                      style={{ display: "inline-block", cursor: "pointer" }}
                    >
                      <img src={LeftArrow} alt="" style={{ width: "1rem" }} />
                      {/* <i className="fa fa-arrow-left" aria-hidden="true"></i> */}
                      <p className="m-0 ms-2">Back</p>
                    </div>
                  </div>
                  <div className="col-12">
                    <h1 className="heading_onboard">Add your course</h1>
                    <p className="pragraph_onboard">
                      Not ready yet? Don't worry, you can add additional courses
                      you can add additional course later from your settings
                      page,
                    </p>
                  </div>
                  <div className="col-12 d-flex align-items-center flex-wrap ">
                    {formData?.map((item, index) => {
                      return (
                        <p
                          key={index}
                          onClick={() => {
                            editCourseDetails(item);
                          }}
                          className={
                            item.questionId === editCourse
                              ? "addcourse-details selected-addcourse-details m-1 "
                              : "addcourse-details text_black m-1"
                          }
                        >
                          {item.courseIdentifier}
                        </p>
                      );
                    })}
                  </div>

                  <div className="col-12 mt-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label names "
                    >
                      Course Name
                    </label>
                    <input
                      value={courseData.courseName}
                      id="#courseName"
                      className="form-ctrl mb-0 border p-3 "
                      name="courseName"
                      type="text"
                      placeholder="Course Name"
                      onChange={(e) => {
                        setCourseData({
                          ...courseData,
                          courseName: e.target.value,
                        });
                      }}
                    />
                    <p className="text-danger mb-0 mt-1">
                      <small>{courseData?.error?.courseErr}</small>
                    </p>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="row">
                      <div className="col">
                        <label
                          htmlFor="example Email1"
                          className="form-label names "
                        >
                          Course Identifier
                        </label>
                        <input
                          value={courseData.courseIdentifier}
                          id="#courseIdentifier"
                          className="form-ctrl p-3  mb-0 border"
                          name="courseIdentifier"
                          type="text"
                          placeholder="Example, PHY7A"
                          onChange={(e) => {
                            setCourseData({
                              ...courseData,
                              courseIdentifier: e.target.value,
                            });
                          }}
                        />
                        <p className="text-danger mb-0 mt-1">
                          <small>
                            {courseData?.error?.courseIdentifierErr}
                          </small>
                        </p>
                        {/* <p className="text-danger mb-0 mt-1">
                          <small>{courseData?.error?.allErr}</small>
                        </p> */}
                      </div>
                      <div className="col">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label names "
                        >
                          Instructor Name
                        </label>
                        <input
                          value={courseData.instructorName}
                          id="#instructorName"
                          className="form-ctrl mb-0  p-3  border"
                          name="instructorName"
                          type="text"
                          placeholder="Cole Milton"
                          onChange={(e) => {
                            setCourseData({
                              ...courseData,
                              instructorName: e.target.value,
                            });
                          }}
                        />
                        {/* <p className="text-danger"><small>{courseData.error.instructorErr}</small></p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-between align-items-center mt-3">
                    <p
                      className="m-0 name"
                      style={{ cursor: "pointer" }}
                      onClick={() => addCourse()}
                    >
                      Add new course
                      <img
                        src={PlusImg}
                        alt=""
                        style={{ width: "1rem", marginLeft: "4px" }}
                      />
                      {/* <i
                      className="fa fa-plus ms-2 text-dark"
                      aria-hidden="true"
                    ></i> */}
                    </p>
                    {deleteButton && <p
                      onClick={() => {
                        deleteCourse(editCourse);
                      }}
                      style={{ cursor: "pointer" }}
                      className="m-0 text-danger name"
                    >
                      Delete this course
                      <i
                        className="fa fa-trash-o ms-2 text-danger"
                        aria-hidden="true"
                      ></i>
                    </p>}
                  </div>
                  <p className="text-danger mb-0 mt-1">
                          <small>{courseData?.error?.allErr}</small>
                        </p>
                  {loader === false ? (
                    <div className=" col-12 clearfix border-0  bttn_container">
                      <button
                        disabled={
                          courseData.courseName ||
                          courseData.courseIdentifier ||
                          courseData.instructorName ||
                          formData?.length > 0
                            ? false
                            : true
                        }
                        type="button"
                        className="d-flex align-items-center justify-content-center customebtn mt-4"
                        onClick={() => addCourseData()}
                      >
                        {/* <i className="fa fa-spinner fa-spin me-3" aria-hidden="true" style={{ fontSize: "20px" }}></i> */}
                        Next Step
                      </button>
                    </div>
                  ) : (
                    <div className=" col-12 clearfix border-0  bttn_container">
                      <button type="button" className="mt-4 customebtn">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </button>
                    </div>
                  )}
                  <div className="col-12 d-flex align-items-center justify-content-center mt-3">
                    <p
                      onClick={() => {
                        dispatch(skipPage());
                      }}
                      className="text-decoration-none name plus"
                      style={{ cursor: "pointer" }}
                    >
                      Skip Step
                    </p>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-4 d-flex justify-content-end">
              <div className="signIn_img">
                <img src={SelectProfileX32} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCourse;

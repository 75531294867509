import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDropzone } from "react-dropzone";
import "../addContent.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addLibraryContent,
  addContentFile,
  resetAddContentFile,
} from "../../../../../store/actions/libraryOverview";
import {
  UploadFileLibrarImg,
  AddContentPlus,
  IIcon,
} from "../../../../../staticContent/image";
import { Store } from "react-notifications-component";
import { Steps, Hints } from "intro.js-react";

import "intro.js/introjs.css";
const AddCourse = (props) => {
  const [files, setFiles] = useState([]);
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [data, setData] = useState([
    { link: "", id: uuidv4() },
    { link: "", id: uuidv4() },
    { link: "", id: uuidv4() },
  ]);
  const [filesData, setFilesData] = useState([]);
  const [loader, addLoader] = useState(0);
  const [inputdisable, setInputDisable] = useState(false);
  const [tooltip, setToolTip] = useState(false);
  const [wallkThrough, setWalkThrough] = useState(true);
  const [stepss, setStepss] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
    ],
  });
  const { stepsEnabled, steps, showBullets, initialStep, hintsEnabled, hints } =
    stepss;
  const addLink = () => {
    setData([
      ...data,
      {
        id: uuidv4(),
        link: "",
      },
    ]);
  };
  useEffect(() => {}, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      "video/MP4": [],
      "video/MP3": [],
      "video/MOV": [],
      "video/MKV": [],
      "application/pdf": [],
      "text/csv": [],
      "video/quicktime": [],
      "video/x-ms-wmv": [],
      "video/x-msvideo": [],
      "video/x-flv": [],
    },
    onDrop: (acceptFiles) => {
      setFiles(
        acceptFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      );
      //  if(acceptFiles?.length >1){
      //   setFilesData(...filesData, acceptFiles);
      //  }else{
      setFilesData(acceptFiles);
      //}
    },
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div>
      {errors.map((e) => (
        <p key={e.code}>{e.message}</p>
      ))}
    </div>
  ));

  const sendData = () => {
    if (
      filesData?.length >= 1 ||
      data[0].link !== "" ||
      data[1].link !== "" ||
      data[2].link !== ""
    ) {
      setInputDisable(true);
    }
    dispatch(resetAddContentFile());
    addLoader(1);
    const userId = store.auth?.user?.id;
    const body = new FormData();

    for (let i = 0; i < filesData?.length; i++) {
      body.append(`file`, filesData[i]);
    }
    body.append("user_id", userId);

    let urlSort = data?.map((itemx, indexz) => ({
      link_url: itemx.link,
      user_id: userId,
    }));
    if (data.length >= 1) {
      if (
        (filesData?.length >= 0 && data[0].link !== "") ||
        data[1].link !== "" ||
        data[2].link !== ""
      ) {
        body.append(
          "data",
          JSON.stringify({
            data: urlSort?.filter((o) => o.link_url),
          })
        );
      }
    }

    if (
      filesData?.length <= 0 &&
      data[0].link === "" &&
      data[1].link === "" &&
      data[2].link === ""
    ) {
      addLoader(0);

      showError(" Please add atleast link or file");
    } else {
      dispatch(addContentFile(body)).then((data) => {
        setTimeout(() => {
          if (data) {
            showError(data);
          } else {
            props.setTab("profile");
          }
          addLoader(0);
        });
      }, 50000);
      // setTimeout(() => {
      //   props.setTab("profile")
      // }, 1500);
    }
  };

  //  tooltip error starts
  function showError(data) {
    Store.addNotification({
      // title: "Wonderful!",
      message: data,
      type: "danger",
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });

    setInputDisable(false);
  }
  // tooltip error ends

  return (
    <>
      {wallkThrough && (
        <Steps
          enabled={wallkThrough}
          steps={steps}
          onExit={() => {
            // dispatch(firstTimeDocLogin());
            // setWalkThrough(false);
          }}
          initialStep={initialStep}
          onComplete={() => {
            // dispatch(firstTimeDocLogin());
            // setWalkThrough(false);
          }}
          options={{
            showButtons: true,
            showBullets: false,
            prevLabel: "Previous",
            skipLabel: "Skip",
            // tooltipClass: "tooltip",
          }}
        />
      )}
      <form action="">
        <div className="row border-tp position-relative ">
          <div className="col-12 position-absolute top_line top-0 end-0 start-0"></div>
          <div className="col-6 p-4 border-end one_side_add_content">
            <div className="row mx-auto">
              {data?.map((item, index) => {
                return (
                  <div className="col-9 mt-2 mb-3 p-0" key={index}>
                    <label
                      for="exampleInputEmail1"
                      className="form-label url-links"
                    >
                      Enter URL Link
                    </label>
                    <input
                      value={item.link}
                      name="url"
                      type="text"
                      disabled={inputdisable}
                      className=" shadow-none enter  enter-add-content ps-3"
                      id={item.id}
                      aria-describedby="emailHelp"
                      placeholder="Enter an URL"
                      onChange={(e) => {
                        setData(
                          data.map((itemz, indexz) =>
                            item.id === itemz.id
                              ? { ...itemz, link: e.target.value }
                              : itemz
                          )
                        );
                      }}
                    />
                  </div>
                );
              })}
              <div className="col-12 mt-1 p-0">
                <button
                  disabled={data?.length > 4 ? true : false}
                  onClick={() => {
                    addLink();
                  }}
                  type="button"
                  className="btn shadow-none addcontent-addlink d-flex align-items-center justify-content-start p-0 m-0"
                >
                  <img src={AddContentPlus} alt="" className="me-2" />
                  Add Link
                </button>
              </div>
            </div>
          </div>
          <div className="col-6 p-4 ">
            <div className="row mx-4 ">
              <div className="col-12 my-3 p-0">
                <h5 className="profiles wrapperss d-flex align-items-center m-0">
                  Drag & Drop
                  <img
                    onMouseOver={() => {
                      setToolTip(true);
                    }}
                    onMouseOut={() => {
                      setToolTip(false);
                    }}
                    src={IIcon}
                    alt={""}
                    className="ms-2 cp"
                  />
                  {tooltip && (
                    <div className="tooltipss">
                      Drag your documents, videos, or any files here to upload them and keep all your resources in one place.
                    </div>
                  )}
                </h5>
              </div>

              <div className="col-12">
                <div
                  className="row drag p-5 justify-content-center"
                  {...getRootProps()}
                >
                  <div className="col-auto my-3">
                    {/* <i className="fa fa-download" aria-hidden="true"></i> */}
                    <img src={UploadFileLibrarImg} alt="" />
                  </div>
                  <div className="col-auto my-3 d-flex justify-content-center flex-column">
                    <p className="m-0">
                      Drag your documents,
                      <br />
                      video, files here to start uploading
                    </p>
                    <small
                      className="text-danger"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Only pdf & videos are allowed
                    </small>
                    <div className="image-upload mt-2">
                      <label for="">
                        <p
                          className=" shadow-none text-white content-browse m-0"
                          style={{ cursor: "pointer" }}
                        >
                          Browse Files
                        </p>
                      </label>
                      <input
                        disabled={true}
                        id="file-input"
                        {...getInputProps()}
                        //  type="file"
                        // onChange={(e) => {

                        //     setFilesData(...filesData, e.target.files)
                        // }}
                      />
                    </div>
                    {fileRejectionItems}
                    {filesData?.length > 0 &&
                      filesData?.length + " file selected"}
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="row position-absolute bottom_brn">
        <div className="col-auto ms-auto my-4">
          {loader === 0 && (
            <button
              onClick={(e) => {
                e.preventDefault();
                sendData();
              }}
              className="btn saves text-white shadow-none"
            >
              Next Step
            </button>
          )}
          {loader === 1 && (
            <div className="col-auto ms-auto fw-normal ">
              <button
                type="button"
                className="btn saves shadow-none text-white fw-normal py-2 "
              >
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AddCourse;

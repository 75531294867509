import {
    GET_TOPIC,
    GET_HTML,
    GET_NOTES,
    ADD_NOTES,
    DELETE_NOTES,
    EDIT_NOTES,
    GET_SUBTOPIC,GET_FLASH_CARDS_FAILED,GET_FLASH_CARDS
} from '../actions/types';
const INITIAL_STATE = {
    state: {}
};

// create playlist first screen
export const getStudyTopic = (state = INITIAL_STATE.state, action) => {

    const { payload, type } = action;
    switch (type) {
        case GET_TOPIC:
            return payload;
        default:
            return state;
    }
}
export const getHtml = (state = INITIAL_STATE.state, action) => {

    const { payload, type } = action;
    switch (type) {
        case GET_HTML:
            return payload;
        default:
            return state;
    }
}
export const getSubTopic = (state = INITIAL_STATE.state,action)=>{
    const {payload,type} = action;
    switch(type){
        case GET_SUBTOPIC:
            return payload;
        default:
            return state
    }

}


export const getNotes = (state = INITIAL_STATE.state, action) => {
    const { payload, type } = action;



    switch (type) {
        case GET_NOTES:
            return payload;
        case ADD_NOTES:
            return {
               ...state, responseData: [...state.responseData, payload],
            }
        case DELETE_NOTES:
            return {
                ...state,
                responseData: state.responseData.filter((item, index) => item._id !== payload)
            }
        case EDIT_NOTES:
            return {
                responseData: state.responseData.map(note => note._id === payload._id ?
                    // transform the one with a matching id
                    { ...note, notes: payload.notes } :
                    // otherwise return original todo
                    note
                )
            }
        // return { ...state., payload };
        default:
            return state;
    }
}
export const getFlashCards = (state=INITIAL_STATE.state,action)=>{
    const {payload,type} = action;
    switch(type){
        case GET_FLASH_CARDS_FAILED:
            return payload;
            case GET_FLASH_CARDS:
                return payload;
                default:
                    return state
    }
}
export const getSummary = (state=INITIAL_STATE.state,action)=>{
    const{payload,type} = action;
    switch(type){
        case "GET_SUMMARY":
            return payload;
            default :
            return state
    }
}
export const getFocusData = (state=INITIAL_STATE.state,action)=>{
    const{payload,type} = action;
    switch(type){
        case "ADD_FOCUS_DATA":
            return payload;
            default :
            return state
    }
}




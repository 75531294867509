import React, { useState, useEffect } from "react";
// updated code
import LibraryOverview from "./Overview/Overview";
import Course from "../Librarypage/Courses/Course";
import Playlist from "../Librarypage/Playlist/Playlist";
import NewPlaylist from "./Playlist/createNewPlaylist/CreatePlaylist";
import { NavLink, useLocation} from "react-router-dom";
import "./Library.css";
// import { createPlaylist } from "../../store/actions/libraryPlaylist";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AddContent from "./Overview/AddContent/AddContent";
import BrowsePlaylist from "../../containers/Librarypage/Playlist/browserPlaylist/BrowsePlaylist";
import BrowseCourse from "../../containers/Librarypage/Overview/BrowseCourse/BrowseCourse";
import { getPlaylist } from "../../store/actions/libraryPlaylist";
import {
  CreatePlaylistPlusIcon,
  YourPlaylistPlusIcon,
} from "../../staticContent/image";
// import { Steps, Hints } from "intro.js-react";

// import "intro.js/introjs.css";
const sampleData = [
  {
    name: "Overview",
    link: "/library/overview",
  },
  {
    name: "Courses",
    link: "#",
  },
];
// /library/courses
const LibraryDashboard = () => {

  const location = useLocation();
  const history = useHistory();
  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState({
    selectedTab: "",
    selectedUrl: "",
  });
  const [plusButton, setPlusButton] = useState(false);

  const handleClick = (tab, url) => {
    setSelectedTab({
      selectedTab: tab,
      selectedUrl: url,
    });
  };
  const dashboardPanel = () => {
    let path = history.location.pathname;
    switch (path) {
      case "/library/overview":
        return <LibraryOverview />;
      case "/library/courses":
        return <Course />;
      case "/library/playlist":
        return <Playlist />;
      case "/library/create-playlist":
        return <NewPlaylist />;
      case "/library/overview/addcontent":
        return <AddContent />;
      case `/library/playlist/${location?.state?.playlistId}`:
        return <BrowsePlaylist />;
        case `/library/playlist/${ store?.browsePlaylistData?.data?._id}`:
          return <BrowsePlaylist />;
      case "/library/overview/browsecourse":
        return <BrowseCourse />;
      default:
       history.push("/library/overview");
    }
  };

  var path = history.location.pathname;
  useEffect(() => {
    dispatch(getPlaylist(store.auth?.user?.id));

  }, []);
  useEffect(() => {
    if(path === "/library/overview"){
      setSelectedTab({
        selectedTab: "Overview",
        selectedUrl: "/library/overview",
      })
      setPlusButton(false);
    }
    else if(path === "/library/courses"){
      setSelectedTab({
        selectedTab: "Courses",
        selectedUrl: "/library/courses",
      })
      setPlusButton(false);
    }
    else if(path === "/library/playlist"){
      setSelectedTab({
        selectedTab: "Your Playlist >",
        selectedUrl: "/library/playlist",
      })

    }
    else if(path === "/library/create-playlist"){
      setSelectedTab({
        selectedTab: "Your Playlist >",
        selectedUrl: "/library/create-playlist",
      })
    }
    else if(path.slice(0,18)==="/library/playlist/"){
      setSelectedTab({
        selectedTab: "Your Playlist >",
        selectedUrl: "/library/create-playlist",
      })
    }
    else{
      setSelectedTab({
        selectedTab: "Overview",
        selectedUrl: "/library/overview",
      })
      setPlusButton(false);
    }
  }, [path])
  

  return (
    <>
      {/* {wallkThrough && (
        <Steps
          enabled={wallkThrough}
          steps={steps}
          onExit={() => {
          
          }}
          initialStep={initialStep}
          onComplete={() => {
           
          }}
          options={{
            showButtons: true,
            showBullets: false,
            prevLabel: "Previous",
            skipLabel: "Skip",
          
          }}
        />
      )} */}
      {/* libraby side pannel start  */}
      <div className="Library-page">
        <div className="row  general-data">
          <div
            className="col-md-2 general-data position-fixed top-0 bottom-0 border-end px-0"
            style={{ height: "100vh" }}
          >
            <div
              className="sidebar d-flex flex-column mx-auto"
              style={{ height: "inherit" }}
            >
              <div className="col-lg-12 mt-5 ps-0 ps-2 general mb-4">
                <h5 className="sidebar_text sidebar_text_gen m-0">GENERAL</h5>
              </div>
              {sampleData?.map((item, i) => {
                return (
                  <div className="head-div col-12 p-0 " key={i}>
                    <NavLink
                      to={item.link}
                      key={i}
                      id={`test${i}`}
                      activeclassname="text-dark text-decoration-none overview"
                    >
                      <div
                        key={i}
                        className={`  cursor-pointer p-2 ${
                          selectedTab.selectedTab === item.name
                            ? "active selected"
                            : ""
                        }`}
                        // library or course click: route to library or course page
                        onClick={() => {
                          setPlusButton(false);
                          handleClick(item.name, item.link);
                        }}
                      >
                        <h5
                          className={
                            selectedTab.selectedTab === item.name
                              ? "libselect mb-0 sidebar_text"
                              : " sidebar_text mb-0"
                          }
                        >
                          {item.name}
                        </h5>
                      </div>
                    </NavLink>
                  </div>
                );
              })}
              <div
                className="head-div col-12 p-0"
                activeclassname="text-dark text-decoration-none overview"
              >
                <div>
                  <div
                    className={`   cursor-pointer p-2  ${
                      selectedTab.selectedTab === "Your Playlist >"
                        ? "active selected "
                        : ""
                    }`}
                  >
                    <div className="d-flex w-100 justify-content-between align-items-center">
                      <div className="">
                        <NavLink to="/library/playlist">
                          <h5
                            className={
                              selectedTab.selectedTab === "Your Playlist >"
                                ? " libselect mb-0 sidebar_text d-flex align-items-center justify-contnent-start"
                                : " sidebar_text mb-0"
                            }
                            // playlist click : route to playlist page

                            onClick={() => {
                              // dispatch(getPlaylist(store.auth?.user?.id));
                              setPlusButton(true);
                              handleClick(
                                "Your Playlist >",
                                "/library/playlist"
                              );
                            }}
                          >
                            Your Playlist{" "}
                            {plusButton && (
                              <div className="margin_button">
                                <NavLink to="/library/create-playlist">
                                  <p
                                    className="m-auto "
                                    onClick={() => {
                                      handleClick(
                                        "Your Playlist >",
                                        "/library/create-playlist"
                                      );
                                    }}
                                  >
                                    <i
                                      class="fa fa-angle-right ms-2"
                                      aria-hidden="true"
                                    ></i>
                                  </p>
                                </NavLink>
                              </div>
                            )}
                          </h5>
                        </NavLink>
                      </div>
                      {plusButton && (
                        <div className="margin_button">
                          <NavLink to="/library/create-playlist">
                            <p
                              className="m-auto "
                              onClick={() => {
                                handleClick(
                                  "Your Playlist >",
                                  "/library/create-playlist"
                                );
                              }}
                            >
                              <img
                                className=""
                                src={YourPlaylistPlusIcon}
                                alt=""
                              />
                            </p>
                          </NavLink>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
              <div className="head-div col-12 p-0 mt-auto mb-5 pb-3 ">
                <NavLink
                  onClick={() => {}}
                  to={"/library/create-playlist"}
                  key={"#8574"}
                  id={`test${58596}`}
                  activeclassname="text-dark text-decoration-none overview"
                >
                  <div
                    key={"#3ds"}
                    className={`mt-2  createPlaylistData cursor-pointer d-flex align-items-center p-2 ${
                      selectedTab.selectedTab === "Create Playlist"
                        ? "active selected p-2 "
                        : ""
                    }`}
                    onClick={() => {
                      handleClick(
                        "Your Playlist >",
                        "/library/create-playlist"
                      );
                    }}
                  >
                    <h5 className="m-0 sidebar_text pe-5 py-2 yourplaylistTooltip ">
                      {"Create Playlist"}
                      <img
                        src={CreatePlaylistPlusIcon}
                        alt=""
                        className="ms-1"
                      />
                    </h5>

                    {/* <i className="fa fa-plus ms-2 playlist" aria-hidden="true"></i> */}
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="col-10 ms-auto ps-0 all-mainaly">
            {dashboardPanel()}
          </div>
        </div>
      </div>
      {/* library side pannel end  */}
    </>
  );
};

export default LibraryDashboard;

import React,{useState} from 'react'
import  {QuestioncsvUpload}  from '../../store/actions/study'
import { useDispatch } from 'react-redux'
import { Store } from "react-notifications-component";

function QuestionUpload() {
  const dispatch = useDispatch()
const [value,setVallue] = useState("")
const handleChange = (e) =>{
setVallue(e.target.files[0])
}
const handleClick = ()=>{
  let body = new FormData();
body.append("file", value);
dispatch(QuestioncsvUpload(body)).then((res)=>{
  if(res?.data?.status===200){
    showError("Uploaded Successfully","success","Congratulations")
   }
   else{

     showError(res?.data?.message,"danger","Error!")
   }


})
}
function showError(data,type,message) {
  Store.addNotification({
    title: message,
    message: data,
    type: type,
    insert: "bottom",
    container: "bottom-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}
  return (
    <div>
      <div>
      <h1>upload your question from here: please select csv format only</h1>
      <input type="file" accept=".csv" onChange={handleChange} />
      <button onClick={handleClick }>upload</button>
    </div>
    </div>
  )
}

export default QuestionUpload;

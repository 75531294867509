import React, { useState, useEffect, useRef } from "react";
import "./Organize.css";
import Activity from "./Activity/Activity";
import ActivityTracker from "./Task/ActivityTracker/ActivityTracker";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import Task from "./Task/Task";
import TaskAddContent from "./TasksAddContent/TaskAddContent";
import Goal from "./Goal/Goal";
import { taskModuleTrackerReward } from "../../store/actions/studyBuddy";

function Organize() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const startTime = useRef();
  const endTime = useRef();

  const [selectedTab, setSelectedTab] = useState({
    selectedTab: "",
    selectedUrl: "/organize/activity",
  });

  const sampleData = [
    {
      name: "Activity",
      link: "/organize/activity",
    },
    {
      name: "Tasks",
      link: "/organize/tasks",
    },
    {
      name: "Goals",
      link: "/organize/goal",
    },
  ];
  useEffect(() => {
    let path = history.location.pathname;
    if (path == "/organize/activity") {
      setSelectedTab({
        selectedTab: "Activity",
        selectedUrl: "/organize/activity",
      });
    } else if (path == "/organize/tasks") {
      setSelectedTab({ selectedTab: "Tasks", selectedUrl: "/organize/tasks" });
    } else if (path == "/organize/goal") {
      setSelectedTab({ selectedTab: "Goals", selectedUrl: "/organize/goal" });
    } else if (path == "/organize/addcontent") {
      setSelectedTab({
        selectedTab: "Tasks",
        selectedUrl: "/organize/addcontent",
      });
    } else if (path == "/organize/tasks/create-task") {
      setSelectedTab({
        selectedTab: "Tasks",
        selectedUrl: "/organize/create-task",
      });
    } else {
      setSelectedTab({
        selectedTab: "Activity",
        selectedUrl: "/organize/activity",
      });
    }
  }, []);
  useEffect(() => {
    const d = new Date();
    startTime.current = d.getTime();
    return () => {
      const dx = new Date();
      endTime.current = dx.getTime();

      const body = {
        taskModule: endTime.current - startTime.current,
      };
      dispatch(taskModuleTrackerReward(body));
    };
  }, []);

  const handleClick = (tab, url) => {
    setSelectedTab({
      selectedTab: tab,
      selectedUrl: url,
    });
  };
  const dashboardPanel = () => {
    let path = history.location.pathname;
    switch (path) {
      case "/organize/activity":
        return <Activity />;
      case "/organize/tasks":
        return <ActivityTracker />;
      case "/organize/goal":
        return <Goal />;

      case "/organize/addcontent":
        return <TaskAddContent />;

      case "/organize/tasks/create-task":
        return <Task />;

      default:
        return <Activity />;
    }
  };
  return (
    <>
      <div className="Library-page">
        <div className="row  general-data">
          <div
            className="col-md-2 general-data position-fixed top-0 bottom-0 border-end px-0"
            style={{ height: "100vh" }}
          >
            <div
              className="sidebar d-flex flex-column mx-auto"
              style={{ height: "inherit" }}
            >
              <div className="col-lg-12 mt-5 ps-0 ps-2 general mb-4">
                <h5 className="sidebar_text sidebar_text_gen m-0">GENERAL</h5>
              </div>
              {sampleData?.map((item, i) => {
                return (
                  <div className="head-div col-12 p-0 " key={i}>
                    <NavLink
                      to={item.link}
                      key={i}
                      id={`test${i}`}
                      activeclassname="text-dark text-decoration-none overview"
                    >
                      <div
                        key={i}
                        className={`  cursor-pointer p-2 ${
                          selectedTab.selectedTab === item.name
                            ? "active selected"
                            : ""
                        }`}
                        // library or course click: route to library or course page
                        onClick={() => {
                          handleClick(item.name, item.link);
                        }}
                      >
                        <h5
                          className={
                            selectedTab.selectedTab === item.name
                              ? "libselect mb-0 sidebar_text"
                              : " sidebar_text mb-0"
                          }
                        >
                          {item.name}
                        </h5>
                      </div>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className="col-10 ms-auto ps-0 pe-0 all-mai pb-4"
            // style={{ height: "100vh" }}
          >
            {dashboardPanel()}
          </div>
        </div>
      </div>
    </>
  );
}

export default Organize;

import {
    ADD_CONTENT_URL_OVERVIEW,
    GET_ADDED_CONTENT,
    GET_ADDED_COURSE,
    GET_RECOMEND_CONTENTS,
    GET_CATEGARY,
    GET_CONTINUE_STUDY_LIST,
    SEARCH_COURSE_NAME,
    CLEAR_SEARCHBAR,
    ADDED_CONTENT_DATA,
    SEARCHED_COURSE,

} from '../actions/types';
const INITIAL_STATE = {
    data: []
};
// eslint-disable-next-line import/no-anonymous-default-export


// add content url page 
export const addContentUrl = (state = INITIAL_STATE, action) => {
    const { payload, type } = action;
    switch (type) {
        case ADD_CONTENT_URL_OVERVIEW:
            return payload;
        default:
            return state;
    }
}

// get list of added urls and files
export const getAddedContentList = (state = INITIAL_STATE, action) => {
    const { payload, type } = action;
    switch (type) {
        case GET_ADDED_CONTENT:
            return payload;
        case ADD_CONTENT_URL_OVERVIEW:
            return { ...state, ...payload }
        default:
            return state;
    }
}


// get list of added urls and files
export const addContentData = (state = INITIAL_STATE, action) => {
    const { payload, type } = action;
    switch (type) {
        // case GET_ADDED_CONTENT_DATA:
        //     return payload;
        case ADDED_CONTENT_DATA:
            return { ...state, ...payload }
        default:
            return state;
    }
}


// GET EXISTING COURSE LIST ON EDIT PAGE ADD CONTENT
export const getAddedCourseList = (state = INITIAL_STATE, action) => {
    const { payload, type } = action;
    switch (type) {
        case GET_ADDED_COURSE:
            return payload;
        default:
            return state;
    }
}





export const getCategoryList = (state = INITIAL_STATE, action) => {
    const { payload, type } = action;
    switch (type) {
        case GET_CATEGARY:
            return payload;
        default:
            return state;
    }
}

export const getRecommendContent = (state = INITIAL_STATE, action) => {
    const { payload, type } = action;
    switch (type) {
        case GET_RECOMEND_CONTENTS:
            return payload;
        case "RESET":
            return INITIAL_STATE.data;
        default:
            return state;
    }
}

export const getContinueStudyList = (state = INITIAL_STATE, action) => {
    const { payload, type } = action;
    switch (type) {
        case GET_CONTINUE_STUDY_LIST:
            return payload;
        case "RESET":
            return INITIAL_STATE.data;
        default:
            return state;
    }
}

// getContinueStudyList

export const getsearchCourseName = (state = INITIAL_STATE.data, action) => {
    const { payload, type } = action;
    switch (type) {
        case SEARCH_COURSE_NAME:
            return payload;
        case CLEAR_SEARCHBAR:
            return null;
        default:
            return state;
    }
}

export const getSearchedCourseList = (state = INITIAL_STATE, action) => {
    const { payload, type } = action;


    switch (type) {
        case SEARCHED_COURSE:
            return payload;
        default:
            return state;
    }
}


// export const getsearchContent = (state = INITIAL_STATE, action) => {
//     const { payload, type } = action;
//     switch (type) {
//         case SEARCH_CONTENT:
//             return payload;
//         default:
//             return state;
//     }
// }





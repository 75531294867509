import React from "react";
import Library from "../../containers/Librarypage/Library";
import Header from "../Header/Header";

const Mainlayout = () => {
  return (
    <>
      <div className="row mx-auto">
        <div className="col-12">
          <Header />
        </div>
        <div className="col-12">
          <Library />
        </div>
      </div>
    </>
  );
};

export default Mainlayout;

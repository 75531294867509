import React, { useState, useEffect, useRef } from "react";
import { Scale, Vector, AddNotesClose } from "../../../staticContent/image";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./addnotesdialogue.css";
// import { uuid } from "uuidv4";
import { v4 as uuid } from 'uuid';

import { addNotes, deleteNotes, editNotes } from "../../../store/actions/study";
import { useSelector, useDispatch } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Draggable from "react-draggable";
import { FlaskEmptyMinusOutline } from "mdi-material-ui";

const AddNotes = (props) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);

  const [isWide, setIsWide] = useState(
    props.notWide ? false : props.type === "view" ? true : false
  );

  const [notes, setNotes] = useState({
    id: uuid(),
    textNotes: "",
    updateAt: "",
    name: "",
  });
  const ScrollDiv = useRef();
  useEffect(() => {
    const noteType = props.type;
    if (noteType === "view" || noteType === "edit") {
      setNotes({
        id: props.NotesId.id,
        textNotes: props.NotesId.textNotes,
        updateAt: props.NotesId.updateAt,
        name: props.NotesId.name,
      });
    } else {
      setNotes({ id: uuid(), textNotes: "" });
    }
  }, [props]);

  const toggleAnimation = () => {
    setIsWide((prev) => !prev);
  };
  //  save notes button functionality starts
  const saveNotesButton = (e) => {
    if (props.bodyType == "task") {
      props.setNotesLoader(true);
      let body = {
        taskId: props.NotesId.id,
        notes: notes.textNotes,
        // Study_Flag:"62d6998132b4406fc69e7147"z
      };
      dispatch(addNotes(body)).then((res) => {});
      props.setShow(false);
    } else if (props.bodyType === "quiz") {
      let body = {
        quizId: props.NotesId.id,
        notes: notes.textNotes,
      };
      dispatch(addNotes(body)).then((res) => {});
      props.setNotesLoader(true);
      props.setShow(false);
    } else {
      let body = {
        fileid: props.NotesId,
        notes: notes.textNotes,
        // Study_Flag:"62d6998132b4406fc69e7147"
      };
      dispatch(addNotes(body)).then((res) => {});
      props.setShow(false);
    }
  };
  // save notes functionality ends

  // edit notes functionality starts
  const editNotesButton = (e) => {
    // setEdit(true);

    props.editAction();
  };
  // edit notes functionality ends

  // update button on notes functionality starts
  const saveEditNotesButton = (e) => {
    if (props.bodyType === "task" || props.bodyType === "quiz") {
      props.setNotesLoader(true);
      const body = {
        id: props.NotesId.id,
        notes: notes.textNotes,
        // Study_Flag:"62d6998132b4406fc69e7147"
      };
      dispatch(editNotes(body)).then((res) => {});
      props.setShow(false);
    } else {
      const body = {
        id: props.NotesId.id,
        notes: notes.textNotes,
        // Study_Flag:"62d6998132b4406fc69e7147"
      };
      dispatch(editNotes(body)).then((res) => {});

      props.setShow(false);
    }
  };
  // update button on notes functionality ends
  // delete notes button functinality starts
  const deleteNotesButton = (e) => {
    dispatch(deleteNotes(props.NotesId.id)).then((res) => {});
    props.setShow(false);
    props.setNotesLoader(true);
  };
  // delete notes button functionality ends

  // add notes time functionality starts

  const timeSince = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " min ago";
    }
    return Math.floor(seconds) + " sec ago";
  };
  // add notes time fnctionality ends

  const studyFlags = [
    {
      _id: "62d6998132b4406fc69e7147",
      Study_Flag: "to do later",
      createdAt: "2022-07-19T11:46:09.485Z",
      updatedAt: "2022-07-19T11:46:09.485Z",
      __v: 0,
      color: "#8852FF",
      bgColor: "rgba(136, 82, 255, 0.2)",
    },
    {
      _id: "62d699df32b4406fc69e714b",
      Study_Flag: "review later",
      createdAt: "2022-07-19T11:47:43.932Z",
      updatedAt: "2022-07-19T11:47:43.932Z",
      __v: 0,
      color: "#F837FF",
      bgColor: "rgba(248, 55, 255, 0.2)",
    },
    {
      _id: "62d699f632b4406fc69e714f",
      Study_Flag: "repeat later",
      createdAt: "2022-07-19T11:48:06.817Z",
      updatedAt: "2022-07-19T11:48:06.817Z",
      __v: 0,
      color: "#FF575F",
      bgColor: "rgba(255, 87, 95, 0.2)",
    },
    {
      _id: "62d69a0d32b4406fc69e7153",
      Study_Flag: "relevant for midterm",
      createdAt: "2022-07-19T11:48:29.033Z",
      updatedAt: "2022-07-19T11:48:29.033Z",
      __v: 0,
      color: "#13C30A",
      bgColor: "rgba(19, 195, 10, 0.2)",
    },
    {
      _id: "62d69a3132b4406fc69e7157",
      Study_Flag: "review",
      createdAt: "2022-07-19T11:49:05.321Z",
      updatedAt: "2022-07-19T11:49:05.321Z",
      __v: 0,
      color: "#8852FF",
      bgColor: "rgba(136, 82, 255, 0.2)",
    },
    {
      _id: "62d69a3132b4406fc69e7157",
      Study_Flag: "review later",
      createdAt: "2022-07-19T11:49:05.321Z",
      updatedAt: "2022-07-19T11:49:05.321Z",
      __v: 0,
      color: "#F837FF",
      bgColor: "rgba(248, 55, 255, 0.2)",
    },
    {
      _id: "62d69a3132b4406fc69e7157",
      Study_Flag: "repeat later",
      createdAt: "2022-07-19T11:49:05.321Z",
      updatedAt: "2022-07-19T11:49:05.321Z",
      __v: 0,
      color: "#FF575F",
      bgColor: "rgba(255, 87, 95, 0.2)",
    },
    {
      _id: "62d69a3132b4406fc69e7157",
      Study_Flag: "review",
      createdAt: "2022-07-19T11:49:05.321Z",
      updatedAt: "2022-07-19T11:49:05.321Z",
      __v: 0,
      color: "#13C30A",
      bgColor: "rgba(19, 195, 10, 0.2)",
    },
    {
      _id: "62d69a3132b4406fc69e7157",
      Study_Flag: "review",
      createdAt: "2022-07-19T11:49:05.321Z",
      updatedAt: "2022-07-19T11:49:05.321Z",
      __v: 0,
      color: "#8852FF",
      bgColor: "rgba(136, 82, 255, 0.2)",
    },
  ];

  var handleClickProduts = (item) => {};

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 7,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
      paritialVisibilityGutter: 50,
    },
  };
  const colorhips = [
    "badge-primary",
    "badge-secondary",
    "badge-success",
    "badge-danger",
    "badge-warning",
    "badge-info",
    "badge-light",
    "badge-dark",
  ];
  const { show, setShow } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <div className="main-addNotes">
          <div className="container">
            <div className="row">
              <Draggable
                defaultPosition={{ x: 200, y: 200 }}
                handle=".dnd-handle"
                cancel=".dnd-cancel "
              >
                <div
                  className={
                    isWide
                      ? "col-8 bg-white addnote Note model-container wide"
                      : "col-8 bg-white addnote Note model-container home-card"
                  }
                  style={
                    isWide
                      ? props.type === "view"
                        ? {
                            height: "405px",
                            width: "460px",
                            borderRadius: "10px",
                            transform: `translate(-217px, 85px)`,
                          }
                        : {
                            height: "420px",
                            width: "680px",
                            borderRadius: "10px",
                            margin: "2rem 2rem",
                          }
                      : {
                          height: "160px",
                          width: "320px!important",
                          borderTopRightRadius: "4px",
                          borderTopLeftRadius: "4px",
                          margin: "16rem 20rem",
                        }
                  }
                >
                  <div className="row dnd-handle">
                    <div className={`col-12 additem text-white`}>
                      <div className="row">
                        <div className="col">
                          <h6 className="m-0 addnotesNote">
                            <img className="me-3" src={Vector} alt="" />
                            {props.type === "add" ? "Add note" : "Note"}
                          </h6>
                        </div>
                        <div className="col d-flex justify-content-end align-items-center">
                          {props.type === "add" && (
                            <img
                              onClick={() => {
                                toggleAnimation();
                              }}
                              src={Scale}
                              className="me-3 cp"
                              alt=""
                            />
                          )}
                          <img
                            src={AddNotesClose}
                            alt={""}
                            className="cp"
                            onClick={() => {
                              setShow(false);
                            }}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={props.type === "view" ? "row bg-light" : " row "}
                  >
                    <div className="col-12">
                      {isWide && props.type === "add" && (
                        <div className="row d-flex align-items-center">
                          {/* <div className="col-auto">
                      <i
                        className="fa fa-angle-left addnotesIcon"
                        aria-hidden="true"
                      ></i>
                    </div> */}
                          <div className="col flex-1 d-flex align-items-center my-3 science ">
                            <div className="container">
                              {/* {
                                <Carousel
                                  className=" multipleButtonW "
                                  infinite={true}
                                  responsive={responsive}
                                  itemClass="carousel-item-padding-40-px"
                                  // ssr
                                  partialVisbile
                                  // deviceType={deviceType}
                                >
                                  {studyFlags.map((item, index) => (
                                    <div
                                      className="mx-2 notes-Div d-flex align-items-center justify-content-center p-1 .dnd-cancel"
                                      key={index}
                                      style={{
                                        backgroundColor: `${item.bgColor}`,
                                        cursor: "pointer",
                                      }}
                                    >
                                      <span
                                        className=" m-2  addNotesButton "
                                        style={{ color: `${item.color}` }}
                                      >
                                        {item.Study_Flag}
                                      </span>
                                    </div>
                                  ))}
                                </Carousel>
                              } */}
                              {
                                <div className="row  flex-nowrap">
                                  <div className="col-auto d-flex align-items-center justify-content-center prev_icon arrow_icon">
                                    <i
                                      onClick={() => {
                                        ScrollDiv.current.scrollLeft -= 200;
                                      }}
                                      class="fa inner_Icon fa-angle-left"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                  <div className="col-11 me-3 p-0">
                                    <div
                                      className="row mx-auto scrollTopic_Parent"
                                      ref={ScrollDiv}
                                    >
                                      {studyFlags.map((item, index) => (
                                        <div className="col mx-1 p-0">
                                          <div
                                            className=" notes-Div d-flex align-items-center justify-content-center p-1 .dnd-cancel"
                                            key={index}
                                            style={{
                                              backgroundColor: `${item.bgColor}`,
                                              cursor: "pointer",
                                            }}
                                          >
                                            <span
                                              className=" m-2  addNotesButton "
                                              style={{ color: `${item.color}` }}
                                            >
                                              {item.Study_Flag}
                                            </span>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  <div className="col-auto ps-0  d-flex align-items-center justify-content-center next_icon arrow_icon">
                                    <i
                                      onClick={() => {
                                        ScrollDiv.current.scrollLeft += 200;
                                      }}
                                      class="fa inner_Icon fa-angle-right"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              }

                              {/* {studyFlags.map((item, index) => (
                          <div key={index}>
                            <img
                              alt={`img`}
                              style={{ borderRadius: "50px" }}
                              width={"30%"}
                              src={item.Img}
                              onClick={() => {
                                handleClickProduts(item.ImgName);
                              }}
                            />
                          </div>
                        ))} */}
                            </div>
                            {/* <button type="button" className="btn border px-3 me-3">
                      to do later
                    </button>
                    <button type="button" className="btn border px-3 me-3">
                      review later
                    </button>
                    <button type="button" className="btn border px-3 me-3">
                      repeat later
                    </button>
                    <button type="button" className="btn border px-3 me-3">
                      relevant for midterm
                    </button>
                    <button type="button" className="btn border px-3 me-3">
                      review later
                    </button> */}
                          </div>
                          {/* <div className="col-auto">
                    <i className="fa fa-arrow-right ms-3" aria-hidden="true"></i>
                  </div> */}
                        </div>
                      )}
                      <div className="row mx-auto">
                        <div className="col-12 mt-2">
                          {/* <label
                      for="floatingTextarea2"
                      className="aaddnotesWritenote"
                    ></label> */}

                          <div
                            className="form-floating shadow-none addnotesFloting p-0 "
                            style={
                              isWide
                                ? props.type === "view"
                                  ? { height: "295px" }
                                  : props.type === "edit"
                                  ? { height: "320px" }
                                  : { height: "260px" }
                                : { height: "60px" }
                            }
                          >
                            {props.type === "view" && (
                              <div>{notes.textNotes}</div>
                            )}
                            {props.type !== "view" && (
                              <textarea
                                onChange={(e) => {
                                  setNotes({
                                    ...notes,
                                    textNotes: e.target.value,
                                  });
                                }}
                                className="form-control border-0 shadow-none p-0 rounded-0 aaddnotesWritenote dnd-cancel add_notes_add_size"
                                placeholder="Write a note"
                                id="floatingTextarea2"
                                style={
                                  isWide
                                    ? { height: "320px" }
                                    : { height: "55px" }
                                }
                                defaultValue={notes.textNotes}
                              ></textarea>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {props.type === "add" && (
                          <div className="col-5 align-items-center mt-3  ">
                            <button
                              onClick={() => {
                                saveNotesButton();
                              }}
                              type="button"
                              className="  addNotesavedata bg-white border-0 px-2"
                            >
                              Save
                            </button>
                          </div>
                        )}

                        {props.type === "edit" && (
                          <div className="col-6 d-flex justify-content-start align-items-center my-2">
                            <button
                              onClick={() => {
                                saveEditNotesButton();
                              }}
                              type="button"
                              className=" addNotesavedata bg-white border-0 px-2"
                            >
                              Update
                            </button>
                          </div>
                        )}

                        {props.type === "view" && (
                          <>
                            {" "}
                            <div className="row border-top mx-auto mt-3"></div>
                            <div className="col d-flex justify-content-start addNotesDialogueNotesBy align-items-center py-3">
                              {" "}
                              Noticed By{" "}
                              <span className="addNotesName mx-2">
                                {
                                  store?.getUserProfile?.data?.responseData
                                    ?.firstName
                                }
                              </span>{" "}
                              {timeSince(new Date(notes.updateAt))}
                            </div>
                          </>
                        )}
                        {props.type === "view" && (
                          <div className="col d-flex justify-content-end align-items-center ">
                            <button
                              onClick={() => {
                                editNotesButton();
                              }}
                              type="button"
                              className=" addNotesavedatas bg-light border-0 px-2"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => {
                                deleteNotesButton();
                              }}
                              type="button"
                              className="  addNotesavedatas bg-light  border-0 px-2"
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Draggable>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddNotes;

import React, { useState } from "react";
import "./SetGoal.css";
import {
  SelectProfileDarkLogo,
  SelectProfileX32,
  SelectProfileX33,
  LeftArrow,
} from "../../staticContent/image";
import {
  getUserData,
  skipPage,
  goBackPage,
} from "../../store/actions/getUserData";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
// component start here
const SetGoal = () => {
  const [loader, setloader] = useState(false);
  // static time declare for set daily goal
  const [data, setData] = useState([
    {
      time: "30",
      id: uuidv4(),
      isSelected: false,
    },
    {
      time: "60",
      id: uuidv4(),
      isSelected: false,
    },
    {
      time: "90",
      id: uuidv4(),
      isSelected: false,
    },
    {
      time: "120",
      id: uuidv4(),
      isSelected: false,
    },
  ]);
  const [goalTime, setGoalTime] = useState("");
  const store = useSelector((state) => state);

  const dispatch = useDispatch();

  // this function used for settime value
  const setTimeValue = (item) => {
    setGoalTime(item.time);
    setData(
      data.map((itemx, indexx) =>
        itemx?.id === item?.id
          ? { ...itemx, isSelected: true }
          : { ...itemx, isSelected: false }
      )
    );
  };
  // this function used for Finish Button
  const sendData = (e) => {
    e.preventDefault();
    dispatch(
      getUserData({
        body: {
          goal: goalTime,
        },
        onBoardingStatus: "6",
        setCode: "7",
      })
    );
    setloader(true);
  };
  // this function used for Back Button

  const goBack = () => {
    dispatch(goBackPage(store.profileStatus - 1));
  };
  // Redirect To Next Page
  if (store.profileStatus === "7") {
    return <Redirect to={`/select-profile/7`} />;
  }

  return (
    <div className="container-fluid d-flex flex-column">
      <div className="row pt-5">
        <div className="col-12 amet  align-items-center">
          <div className="row">
            <div className="col-11 d-flex justify-content-between align-items-center mx-auto">
              <img
                className="onboard_img ps-5"
                src={SelectProfileDarkLogo}
                alt=""
              />
              <div className="progressSign">
                <div className="completed-progressbar6"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row margin_top">
            <div className="col-4 d-flex justify-content-start">
              <div className="signIn_img">
                <img src={SelectProfileX33} alt="" />
              </div>
            </div>
            <div className="col-4 set_onboarding_width p-0">
              <form className="all">
                <div className="container">
                  <div
                    className="d-flex align-items-center back-data mb-3"
                    onClick={() => {
                      goBack();
                    }}
                    style={{ display: "inline-block", cursor: "pointer" }}
                  >
                    {/* <i className="fa fa-arrow-left" aria-hidden="true"></i> */}
                    <img src={LeftArrow} alt="" style={{ width: "1rem" }} />
                    <p className="m-0 ms-2">Back</p>
                  </div>
                  {/* <div className="col-1 mb-4" onClick={() => goBack()}>
                    <a
                      href="##"
                      className="text-decoration-none plus back-data text-dark d-flex align-items-center justify-content-start"
                    >
                      <i className="fa fa-arrow-left" aria-hidden="true"></i>
                      <img src={LeftArrow} alt="" style={{ width: "1rem" }} />
                      <p className="m-0 ms-2" style={{fontSize:"12px"}}>Back</p>
                    </a>
                  </div> */}
                  <div className="col-12 sign  mt-2">
                    <h3 className="heading_onboard">Set a daily goal:</h3>
                    <p className="pragraph_onboard">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
                      <br />
                      Ac ornare quis leo ac nunc pretium amet, viverra.
                    </p>
                  </div>
                  <div className="col d-flex justify-content-evenly align-items-center goal-time next d-flex pt-3 pb-3">
                    <div className="row next next-height border align-items-center me-auto ms-1 ">
                      {data.map((item, index) => {
                        const datalen = data?.length;
                        let styleN =
                          "align-items-center col d-flex flex-column px-2   setGoalContainer";
                        let finlStyle =
                          datalen === index + 1
                            ? styleN + "py-3"
                            : styleN + " mint py-3";
                        let finl2Stylee = item.isSelected
                          ? finlStyle + " color-blue white "
                          : finlStyle;
                        return (
                          <div
                            key={index}
                            id={item.id}
                            className={`${finl2Stylee} + py-3 `}
                            onClick={() => {
                              setTimeValue(item);
                            }}
                          >
                            <h5
                              className={
                                !item.isSelected
                                  ? `m-0 _time + ${styleN} `
                                  : `m-0 time-white ${styleN} `
                              }
                            >
                              {item.time}
                            </h5>
                            <p
                              className={
                                !item.isSelected
                                  ? `m-0  _minutes ${styleN} `
                                  : `m-0 min-white ${styleN} `
                              }
                            >
                              min
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {loader === false ? (
                    <div className=" col-12 clearfix border-0">
                      <button
                        type="submit"
                        className=" d-flex align-items-center justify-content-center mt-4 customebtn"
                        disabled={goalTime ? false : true}
                        onClick={(e) => {
                          sendData(e);
                        }}
                      >
                        Finish
                        {/* <i className="fa fa-spinner fa-spin mx-3" aria-hidden="true" style={{ fontSize: "20px" }}></i> */}
                      </button>
                    </div>
                  ) : (
                    <div className=" col-12 clearfix border-0 mt-4">
                      <button type="submit" className="  customebtn">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </button>
                    </div>
                  )}
                  <div className="col-12 d-flex align-items-center justify-content-center mt-3">
                    <p
                      onClick={() => {
                        dispatch(skipPage());
                      }}
                      className="text-decoration-none plus "
                      style={{ cursor: "pointer" }}
                    >
                      Skip Step
                    </p>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-4 d-flex justify-content-end">
              <div className="signIn_img">
                <img src={SelectProfileX32} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetGoal;

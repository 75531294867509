import React, { useState, useRef } from "react";
import Header from "../../layouts/Header/Header";
import { useLocation, useHistory } from "react-router-dom";
import "./practice.css";
import VideoPractice from "./videoPractice";
import DocumentPractice from "./documentPractice";
import {
  clockQuiz,
  vectorQuiz,
  groupQuiz,
  groupQuizColor,
  learn_button,
} from "../../staticContent/image";
import Quiz from "./Quiz/Quiz";
const Practice = () => {
  const history = useHistory();
  const location = useLocation();
let path = history.location.pathname;
  const getScreen = () => {
    let path = history.location.pathname;
    switch (location?.state?.prevPath) {
      case "videopractice":
        return <VideoPractice />;
        break;
      case "documentpractice":
        return <DocumentPractice />;
        break;
      default:
        return <DocumentPractice />;
    }
  };
  return <>{location.pathname === "/practice/quiz" ? <Quiz /> : getScreen()}</>;
};

export default Practice;
